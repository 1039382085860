import styled, { css } from 'styled-components'

export const StyledIcon = styled.i.attrs(({ src, color, contentSize, contentWidth, contentHeight, ...props }) => props)`
  display: inline-block;
  vertical-align: baseline;
  mask-position: center;
  mask-repeat: no-repeat;
  mask-image: url('${(props) => props.src}');
  ${({ color }) =>
    color
      ? css`
          background: ${color};
        `
      : css`
          background: currentColor;
        `}
  ${({ contentSize, contentWidth, contentHeight }) =>
    contentSize
      ? css`
          mask-size: ${contentSize};
        `
      : contentWidth || contentHeight
      ? css`
          mask-size: ${contentWidth ?? 'auto'} ${contentHeight ?? 'auto'};
        `
      : css`
          mask-size: contain;
        `}
`
