import Base64Image from 'component/Base64Image'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: auto;
  font-size: 0.35897rem;
  line-height: 150%;
  letter-spacing: 0.01026rem;
  color: var(--main-color);
  cursor: pointer;
`

export const Image = styled(Base64Image).attrs({
  aspectRatio: 1,
  placeholder: `/loading/discover-actor.svg`,
})`
  width: 100%;
  border-radius: 0.5rem;
  margin-bottom: 0.15rem;
`

export const Name = styled.div`
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-align: center;
  text-overflow: ellipsis;
`
