import AreaTitle from 'component/AreaTitle'
import styled from 'styled-components'

export const Wrapper = styled.div``

export const Title = styled(AreaTitle)`
  font-weight: bold;
`

export const Text = styled.div`
  position: relative;
  margin: 18px 0 26px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px 24px;
  font-size: 0.3589rem;
  padding-bottom: 20px;
  height: calc(21px * 3 + 8px * 2);
`
