import { Wrapper, Image, Text } from './Styled'

/** 元件 - PageModal用的 空紀錄顯示 */
export default function EmptyResult({ src, text, width, height, ...props }) {
  return (
    <Wrapper {...props}>
      <Image
        style={
          src
            ? {
                backgroundImage: `url(${src})`,
                backgroundSize: width && height ? `${height} ${width}` : undefined,
                width: width && height ? width : undefined,
                height: height && height ? width : undefined,
              }
            : {}
        }
      />
      <Text>{text ? text : '暂无纪录'}</Text>
    </Wrapper>
  )
}
