import type videojs from 'video.js'
import type Component from 'video.js/dist/types/component'
import type PlaybackRateMenuButtonType from 'video.js/dist/types/control-bar/playback-rate-menu/playback-rate-menu-button'

type VideoJs = typeof videojs

let installed = false

// revert effect of https://github.com/videojs/video.js/pull/7779
export const addOldPlaybackRateMenuButton = (videojs: VideoJs) => {
  if (installed) {
    return
  }

  installed = true

  const PlaybackRateMenuButton = videojs.getComponent(
    'PlaybackRateMenuButton'
  ) as unknown as typeof PlaybackRateMenuButtonType
  class OldPlaybackRateMenuButton extends PlaybackRateMenuButton {
    /**
     * Updates ARIA accessibility attributes
     */
    updateARIAAttributes() {
      // Current playback rate
      this.el().setAttribute('aria-valuenow', String(this.player().playbackRate()))
    }

    /**
     * This gets called when an `PlaybackRateMenuButton` is "clicked". See
     * {@link ClickableComponent} for more detailed information on what a click can be.
     *
     * @param {EventTarget~Event} [event]
     *        The `keydown`, `tap`, or `click` event that caused this function to be
     *        called.
     *
     * @listens tap
     * @listens click
     */
    handleClick(event) {
      // select next rate option
      const currentRate = this.player().playbackRate()
      const rates = this.playbackRates()
      const currentIndex = rates.indexOf(currentRate)
      // this get the next rate and it will select first one if the last one currently selected
      const newIndex = (currentIndex + 1) % rates.length

      this.player().playbackRate(rates[newIndex])
    }
  }

  videojs.registerComponent('OldPlaybackRateMenuButton', OldPlaybackRateMenuButton as unknown as typeof Component)
}
