import { Wrapper, Icon, Name } from './Styled'
import { useAdLinkHandler } from 'hooks/useAdLinkHandler'

/**
 * @typedef {import("../../../../../types/api").SchemaOholoMaterialShow & { pageUuid?: string }} DataItemLink
 */
export default function ItemLink(/** @type {DataItemLink} */ { pageUuid, ...link }) {
  const handleAdLink = useAdLinkHandler()

  const handleRedirect = () => {
    handleAdLink({
      link,
      pageUuid,
    })
  }

  return (
    <Wrapper onClick={handleRedirect}>
      <Icon src={link.icon_path} source="picture" hidePlaceholderOnLoaded />
      <Name>{link.name}</Name>
    </Wrapper>
  )
}
