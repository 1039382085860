import styled from 'styled-components'

export const VirtualPagesElement = styled.div`
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  overflow: clip;
  touch-action: pan-x pinch-zoom;
  background: black;
  min-width: 0;
  min-height: 0;
  --page-padding-top: 0px;
  --page-padding-bottom: 0px;
  --page-gap: 0;
`

export const VirtualPagesScrollerElement = styled.div`
  height: var(--scroller-height);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: var(--page-gap);
  &.has-transition {
    transition: transform 0.5s;
  }
`
export const VirtualPageElement = styled.div`
  height: var(--page-height);
`
