import PageTabs, { PageTab } from 'component/PageTabs'
import Video from './components/Video'
import Short from './components/Short'
import Comic from './components/Comic'

export default function PurchaseHistory({ ...props }) {
  return (
    <PageTabs
      {...props}
      title="我的购买"
      type="underline"
      tabs={[
        { id: 'video', name: '长视频' },
        { id: 'short', name: '短视频' },
        // { id: 'comic', name: '漫畫' },
      ]}
    >
      <PageTab>
        <Video />
      </PageTab>
      <PageTab>
        <Short />
      </PageTab>
      <PageTab>
        <Comic />
      </PageTab>
    </PageTabs>
  )
}
