import { Image } from './Styled'
import { formatDate } from 'utils/timeFormat'
import { fetchPostAdEvent } from 'api'
import { useCallback } from 'react'

export default function CarouselItem(info) {
  const handleAdClick = useCallback(() => {
    if (info) {
      fetchPostAdEvent({
        date: formatDate(new Date()),
        material_id: info.id,
        material_name: info.name,
        oholo_site_id: info.site_id,
        oholoer_id: info.oholoer_id,
      })
      window.open(info?.link, '_blank')
    } else {
      console.warn('no ad assigned')
    }
  }, [info])
  return <Image src={info.icon_path} onClick={() => handleAdClick()} />
}
