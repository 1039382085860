import useOpenModal from '../../hooks/useOpenModal'

import notEnoughCoinImg from '../../assets/not-enough-coin.svg'
import BigConfirmModal from 'component/Modal/BigConfirmModal'

export default function PurchaseFailed() {
  const { closeHintModal, openDepositModal } = useOpenModal()

  return (
    <BigConfirmModal
      // title="你要購買本項商品嗎?"
      // message="確認購買後會自動扣除金幣"
      cover={notEnoughCoinImg}
      onCancel={closeHintModal}
      confirmText="购买金币"
      onConfirm={openDepositModal}
    />
  )
}
