import { useMemo, useCallback, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { useSelector } from 'react-redux'
import { selectIsReadyToShowSplashAd, selectIsViewedAdvertisement, selectCurrentSplashAd } from 'redux/selector/app'
import { fetchPostAdEvent } from 'api'
import { TRUE } from 'constant/common'
import Timer from './component/Timer'
import { Wrapper, Item, LateLoadingContainer } from './Styled'
import SplashInviteCode from 'component/SplashInviteCode'
import { patchUrlProtocol } from 'utils/urlUtils'
import { formatDate } from 'utils/timeFormat'
import SplashVersionNumber from 'component/SplashVersionNumber'
import { EncryptedImg } from 'component/EncryptedElements'

/** App 入站廣告頁面 */
export default function LaunchScreenAd() {
  const isReadyToShowSplashAd = useSelector(selectIsReadyToShowSplashAd)
  const isViewedAdvertisement = useSelector(selectIsViewedAdvertisement)

  const { item: adEntrance, img: base64Img } = useSelector(selectCurrentSplashAd) ?? {}

  /** 從幾秒開始倒數 */
  const duration = adEntrance?.countdown
  /** 廣告結束後是否自動進入首頁 */
  const isAutoIntoLobby = adEntrance?.auto_close === TRUE

  const isShow = useMemo(() => {
    return isReadyToShowSplashAd && !isViewedAdvertisement && !isEmpty(adEntrance)
  }, [isReadyToShowSplashAd, isViewedAdvertisement, adEntrance])

  const handleOnClick = useCallback(() => {
    const link = patchUrlProtocol(adEntrance?.link)
    fetchPostAdEvent({
      date: formatDate(new Date()),
      material_id: adEntrance.id,
      material_name: adEntrance.name,
      oholo_site_id: adEntrance.site_id,
      oholoer_id: adEntrance.oholoer_id,
    })
    window.open(link, '_blank')
  }, [adEntrance])

  const [lateLoading, setLateLoading] = useState(false)

  return (
    <>
      {isShow && (
        <Wrapper>
          <Item base64Img={base64Img} onClick={handleOnClick} />
          <Timer duration={duration} isAutoIntoLobby={isAutoIntoLobby} onLateLoading={setLateLoading} />
          <SplashInviteCode />
          <SplashVersionNumber />
          {lateLoading && (
            <LateLoadingContainer>
              <EncryptedImg alt="" src={`${process.env.PUBLIC_URL}/loading/long-loading.enc.gif`} />
              <div>全力冲刺中</div>
            </LateLoadingContainer>
          )}
        </Wrapper>
      )}
    </>
  )
}
