import styled, { css } from 'styled-components'

export const TabsType = {
  Fill: 'fill',
  Underline: 'underline',
}

export const Tab = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 0.35897rem;
  scroll-margin: var(--cms-grid-column-gap);
  ${({ type }) =>
    type === TabsType.Underline
      ? css`
          position: relative;
          color: var(--component-tabs--color);

          &.active {
            font-weight: 700;

            &::after {
              position: absolute;
              display: block;
              height: 0.1rem;
              border-radius: 0.05rem;
              width: 0.45rem;
              bottom: 0;
              left: 50%;
              transform: translateX(-50%);
              content: '';
              background: var(--component-tabs--active-color);
            }
          }
        `
      : css`
          &.active {
            background: var(--component-tabs--active-color);
          }

          background: var(--main-color);
          -webkit-background-clip: text !important;
          -webkit-text-fill-color: transparent !important;
          background-clip: text !important;
          text-fill-color: transparent !important;
        `}
`

export const TabContainerInner = styled.div`
  align-self: stretch;
  padding: 0 var(--cms-grid-column-gap);

  gap: 0.5rem;
  display: flex;
  align-items: stretch;
  height: 0.79rem;
  flex-wrap: nowrap;
  white-space: nowrap;
  max-width: 100%;
  overflow-x: auto;
`

export const Tabs = styled.div`
  overflow-x: auto;
  display: flex;

  ${({ align }) =>
    align === 'spread'
      ? css`
          justify-content: space-between;
        `
      : align === 'center'
      ? css`
          justify-content: center;
        `
      : align === 'right'
      ? css`
          justify-content: flex-end;
        `
      : css`
          justify-content: flex-start;
        `}
`
