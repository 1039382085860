import { useAppNavigate } from 'app-layered-layout/useAppNavigate'
import useConfirmModal from './useConfirmModal'
import { useEffect, useState } from 'react'

// SYNC with src/sw.js
const HASH_ROUTE = '/$_virtual/current_hash'

export const useUpdateChecker = () => {
  const navigate = useAppNavigate()
  const { openConfirm } = useConfirmModal()

  const [notified, setNotified] = useState(false)

  useEffect(() => {
    if (notified) return

    const checkCallback = async (ev) => {
      if (ev.data && typeof ev.data === 'object' && ev.data.type === 'current-hash') {
        const hash = ev.data.data
        if (hash !== process.env.REACT_APP_GIT_SHA) {
          console.log('current', hash, 'previous', process.env.REACT_APP_GIT_SHA)
          try {
            const controllerHash = await fetch(HASH_ROUTE).then((res) => res.text())
            if (controllerHash !== hash) {
              console.error("service worker is updated, but client.claim haven't take effect yet.")
              return
            }
          } catch (err) {
            return
          }
          openConfirm({
            title: '版本已更新至最新',
            message: '是否立刻重启以套用新版本？',
            onConfirm: () => {
              navigate(window.history.length * -1)
              setTimeout(() => {
                window.location.replace('/')
              }, 100)
            },
          })
          setNotified(true)
        }
      }
    }

    navigator.serviceWorker?.addEventListener('message', checkCallback)

    let checkTimer = setInterval(() => {
      if (navigator.serviceWorker?.controller) {
        const sw = navigator.serviceWorker
        sw.controller.postMessage('get-hash')
      }
    }, 30 * 1000)

    let first = true

    const onCheckUpdate = async () => {
      if (navigator.serviceWorker?.controller) {
        if (first) {
          const reg = await navigator.serviceWorker.getRegistration()
          reg.update().catch(() => {})
          first = false
        }
        navigator.serviceWorker?.controller.postMessage('check-update')
      }
      updateTimer = setTimeout(onCheckUpdate, 1 * 60 * 1000)
    }
    let updateTimer = setTimeout(onCheckUpdate, 10 * 1000)

    return () => {
      navigator.serviceWorker?.removeEventListener('message', checkCallback)
      clearInterval(checkTimer)
      clearTimeout(updateTimer)
    }
  }, [navigate, notified, openConfirm])
}
