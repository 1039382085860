import { useNavigateTo } from 'hooks/useNavigateTo'

export default function useOpenModal({ videoInfo }) {
  const { navigateToViewMore, navigateToEnableVip } = useNavigateTo()

  const openEnableVipModal = () => {
    navigateToEnableVip({})
  }

  const openViewMoreModal = () => {
    const params = {
      producer_id: videoInfo.producer_id,
    }

    navigateToViewMore({
      params,
    })
  }

  return {
    openEnableVipModal,
    openViewMoreModal,
  }
}
