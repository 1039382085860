import { useEffect, useRef, useCallback } from 'react'
import { useSelector } from 'react-redux'
//
import { selectPictureOrigin } from 'redux/selector/app'
import { fetchBinFile, fetchPostAdEvent } from 'api'
import { imageParser } from 'utils/imageParser'
import { Image } from './Styled'
import { formatDate } from 'utils/timeFormat'
import { catchPromiseCancel } from 'utils/catchPromiseCancel'

export default function CarouselItem(info) {
  const pictureOrigin = useSelector(selectPictureOrigin)
  const imgRef = useRef(null)

  const onAdItemClick = useCallback(() => {
    if (info) {
      fetchPostAdEvent({
        date: formatDate(new Date()),
        material_id: info.id,
        material_name: info.name,
        oholo_site_id: info.site_id,
        oholoer_id: info.oholoer_id,
      })
      window.open(info?.link, '_blank')
    } else {
      console.warn('no ad assigned')
    }
  }, [info])

  useEffect(() => {
    if (!pictureOrigin || !info.icon_path || imgRef.current.src) return

    const controller = new AbortController()
    const shortcutImagePath = `${pictureOrigin}/${info.icon_path}`

    let url
    fetchBinFile({
      url: shortcutImagePath,
      signal: controller.signal,
    })
      .then((textBase64) => (url = imgRef.current.src = imageParser(textBase64)))
      .catch(catchPromiseCancel)

    return () => {
      if (url) URL.revokeObjectURL(url)
      controller.abort()
    }
  }, [pictureOrigin, info])

  return <Image ref={imgRef} onClick={onAdItemClick} />
}
