import { createContext, useCallback, useContext, useEffect, useMemo } from 'react'
import { useImmer } from 'use-immer'

const STORAGE_KEY_SEARCH_HISTORY = 'searchWordsHistory'

const historyContext = createContext({
  add: (scope, item) => {},
  remove: (scope, item) => {},
  clear: (scope) => {},
  get: (scope) => [],
  history: {},
})

export const useSearchHistory = () => {
  return useContext(historyContext)
}

export const SearchHistoryProvider = ({ children }) => {
  const [history, updateHistory] = useImmer(() => load())

  const add = useCallback(
    (scope, item) => {
      const list = history[scope] ?? []
      const newList = [...new Set([item, ...list])]
      updateHistory((draft) => {
        draft[scope] = newList
      })
    },
    [history, updateHistory]
  )

  const remove = useCallback(
    (scope, item) => {
      const list = history[scope] ?? []
      const set = new Set(list)
      set.delete(item)
      const newList = [...set]
      updateHistory((draft) => {
        draft[scope] = newList
      })
    },
    [updateHistory, history]
  )

  const clear = useCallback(
    (scope) => {
      updateHistory((draft) => {
        draft[scope] = []
      })
    },
    [updateHistory]
  )

  const get = useCallback(
    (scope, item) => {
      const list = history[scope] ?? []
      return list
    },
    [history]
  )

  function save(history) {
    const json = JSON.stringify(history)
    window?.localStorage?.setItem(STORAGE_KEY_SEARCH_HISTORY, json)
  }

  function load() {
    const history = window?.localStorage?.getItem(STORAGE_KEY_SEARCH_HISTORY)
    if (!history) return {}
    try {
      const result = JSON.parse(history)
      return result && typeof result == 'object' ? result : {}
    } catch {
      return {}
    }
  }

  useEffect(() => {
    save(history)
  }, [history])

  const state = useMemo(
    () => ({
      add,
      remove,
      clear,
      get,
      history,
    }),
    [add, clear, get, history, remove]
  )

  return <historyContext.Provider value={state}>{children}</historyContext.Provider>
}
