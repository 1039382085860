import { TitleMoreArrow, TitleMoreText, Wrapper } from './Styled'

export default function ButtonSearchMore({ handleClick, text = '更多', ...props }) {
  return (
    <Wrapper onClick={handleClick} {...props}>
      <TitleMoreText>{text}</TitleMoreText>
      <TitleMoreArrow></TitleMoreArrow>
    </Wrapper>
  )
}
