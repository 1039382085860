import { useState } from 'react'
import {
  Screen1,
  Screen1Icon,
  Screen1IconDecorateArrowClick,
  Screen1IconDecorateArrowDown,
  Screen1IconDecorateArrowUp,
  ScreenItem,
  ScreenDescription,
  Wrapper,
} from './Styled'

export default function Tutorial({ isOpen = true, onFinished = () => {} }) {
  const [currentDisplay, setCurrentDisplay] = useState('screen1')
  const screen = isOpen ? currentDisplay : null
  return (
    <Wrapper className={screen == null ? 'hidden' : ''}>
      <Screen1
        className={screen !== 'screen1' ? 'hidden' : ''}
        onClick={() => {
          setCurrentDisplay(null)
          onFinished()
        }}
      >
        <ScreenDescription>全区滑动和点击中心</ScreenDescription>
        <ScreenItem>
          <Screen1Icon>
            <Screen1IconDecorateArrowUp />
          </Screen1Icon>
          上一页
        </ScreenItem>
        <ScreenItem>
          <Screen1Icon>
            <Screen1IconDecorateArrowClick />
          </Screen1Icon>
          更多功能
        </ScreenItem>
        <ScreenItem>
          <Screen1Icon>
            <Screen1IconDecorateArrowDown />
          </Screen1Icon>
          下一页
        </ScreenItem>
      </Screen1>
    </Wrapper>
  )
}
