import { useCallback } from 'react'
import { TopicTextWrapper, Topics, UploaderInfoElement, UploaderName, VideoTitle } from './Styled'
import { useNavigateTo } from 'hooks/useNavigateTo'

const UploaderInfo = ({ uploaderId = '', uploader = '', videoName = '', topics = [], ...props }) => {
  const { navigateToViewUploader } = useNavigateTo()
  const openUploaderPage = useCallback(
    (ev) => {
      ev.preventDefault()
      ev.stopPropagation()
      navigateToViewUploader({
        uploaderId,
      })
    },
    [navigateToViewUploader, uploaderId]
  )

  const { navigateToViewMore } = useNavigateTo()

  const onClick = (text, params) => {
    navigateToViewMore({
      showAd: false,
      headerTitle: text,
      type: 'short',
      params,
    })
  }

  return (
    <UploaderInfoElement {...props}>
      {topics.length > 0 && (
        <Topics>
          <TopicTextWrapper>
            {topics.map((t, index) => (
              <span key={index} onClick={() => onClick(t.text, t.params)}>
                {t.text}
              </span>
            ))}
          </TopicTextWrapper>
        </Topics>
      )}
      <UploaderName onClick={openUploaderPage}>{uploader}</UploaderName>
      <VideoTitle>{videoName}</VideoTitle>
    </UploaderInfoElement>
  )
}

export default UploaderInfo
