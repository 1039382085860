import PurchaseVideo from 'component/PurchaseVideo'
import useHintModal from './useHintModal'
import { useCallback } from 'react'

export const useOpenPurchaseVideoModal = ({ videoInfo, type }) => {
  const { openHintModal } = useHintModal()
  const openPurchaseVideoModal = useCallback(() => {
    openHintModal({
      children: PurchaseVideo,
      props: {
        videoInfo,
        type,
      },
    })
  }, [openHintModal, videoInfo, type])
  return openPurchaseVideoModal
}
