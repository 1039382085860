import HeaderBox from 'component/PageModalHeaderBox'

import { StyledSwiper, StyledSwiperSlide } from './Styled'

import { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import Tabs from 'component/Tabs'
import { HeaderWrap, PageWrapperNonScroll } from 'commonStyledComponents'
import { TabsType } from 'component/Tabs/Styled'

export default forwardRef(function PageTabs(
  { uuid, children, title = '', tabs = /** @type { { id: string, name: string }[] } */ ([]), defaultValue, ...props },
  ref
) {
  const [tab, setTab] = useState(defaultValue ?? tabs[0]?.id)

  const tabOrDefaultTab = tab ?? tabs[0]?.id

  const map = {}

  for (const [index, tab] of tabs.entries()) {
    map[tab.id] = index
    map[index] = tab.id
  }

  const onProgress = (swiper) => {
    if (map[swiper.activeIndex] != null && map[swiper.activeIndex] !== tab) {
      setTab(map[swiper.activeIndex])
    }
  }

  const swiperRef = useRef()

  const changeTab = (tabName) => {
    if (map[tabName] === swiperRef?.current?.swiper.realIndex) {
      setTab(tabName)
    } else {
      swiperRef?.current?.swiper.slideTo(map[tabName])
    }
  }

  useImperativeHandle(ref, () => ({
    changeTab,
  }))

  return (
    <PageWrapperNonScroll {...props}>
      <HeaderWrap>
        <HeaderBox style={{ flexShrink: 0 }} headerTitle={title} modalId={uuid} />
        {tabs.length > 1 && (
          <Tabs options={tabs} type={TabsType.Underline} value={tabOrDefaultTab} onChange={changeTab} />
        )}
      </HeaderWrap>
      <StyledSwiper ref={swiperRef} onSlideChange={onProgress} cssMode>
        {children}
      </StyledSwiper>
    </PageWrapperNonScroll>
  )
})

export const PageTab = StyledSwiperSlide
