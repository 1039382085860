import styled from 'styled-components'

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const Image = styled.div`
  width: 3.7rem;
  height: 3.7rem;
  background-image: url(${process.env.PUBLIC_URL}/empty-result.svg);
  background-size: 2.75rem auto;
  background-position: center center;
  background-repeat: no-repeat;
`

export const Text = styled.div`
  margin-top: 0.2rem;
  font-size: 0.3589rem;
  color: #787878;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.38px;
`
