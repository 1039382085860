import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectPictureOrigin } from 'redux/selector/app'
import { fetchBinFile } from 'api'
import { Wrapper, Icon, Name } from './Styled'
import { useAdLinkHandler } from 'hooks/useAdLinkHandler'
/**
 * @typedef {import("../../../../../types/api").SchemaOholoMaterialShow & { pageUuid?: string }} DataItemLink
 */

export default function ItemLink(/** @type {DataItemLink} */ { pageUuid, ...link }) {
  const pictureOrigin = useSelector(selectPictureOrigin)
  const [base64Img, setBase64Img] = useState('')

  const handleAdLink = useAdLinkHandler()

  useEffect(() => {
    if (!pictureOrigin || !link.icon_path) return

    const controller = new AbortController()
    const shortcutImagePath = `${pictureOrigin}/${link.icon_path}`

    fetchBinFile({
      url: shortcutImagePath,
      signal: controller.signal,
    }).then((textBase64) => setBase64Img(textBase64))
  }, [pictureOrigin, link.icon_path])

  const handleRedirect = () => {
    handleAdLink({
      link,
      pageUuid,
    })
  }

  return (
    <Wrapper onClick={handleRedirect}>
      <Icon base64Img={base64Img} />
      <Name>{link.name}</Name>
    </Wrapper>
  )
}
