import { AppOutlet } from 'app-layered-layout/AppProvider'
import { useAppLocation } from 'app-layered-layout/useAppLocation'
import Navigation from 'component/Navigation'
import { URL_QUERY_DISABLE_LAYOUT } from 'constant/routes'
import { useSelector } from 'react-redux'
import { selectTokenInfo, selectUserInfo } from 'redux/selector/user'

export const Layout = () => {
  const tokenInfo = useSelector(selectTokenInfo)
  const userInfo = useSelector(selectUserInfo)
  const location = useAppLocation()
  const url = new URL(location.search, 'http://localhost/')
  const disableLayout = url.searchParams.get(URL_QUERY_DISABLE_LAYOUT) != null

  return (
    <>
      {tokenInfo?.access_token && userInfo?.id && <AppOutlet />}
      {!disableLayout && <Navigation />}
    </>
  )
}
