// @ts-check
import { createSlice } from '@reduxjs/toolkit'
import { call, cancel, cancelled, fork, put, select, take } from 'redux-saga/effects'

export const createListSaga = (
  sliceName,
  /** @type {import("@redux-saga/types").ActionSubPattern<import("@redux-saga/types").Action<string>>} */ FETCH_LIST,
  /** @type {import("@redux-saga/types").ActionSubPattern<import("@redux-saga/types").Action<string>>} */ RESET_LIST,
  /** @type {(arg0: { isLoading: boolean; nextPage?: any; list?: any; hasMore?: boolean; page_result?: any; }) => any} */ patchDataAction,
  /** @type {() => any} */ clearDataAction,
  /** @type {any} */ fetchListApi
) => {
  function* handleList() {
    let task
    while (true) {
      const data = yield take([FETCH_LIST, RESET_LIST])

      if (task && task.isRunning()) {
        if (data.type === RESET_LIST) {
          yield cancel(task)
          yield put(patchDataAction({ isLoading: false }))
        } else {
          // we are already runnning, abort the mission
          continue
        }
      }

      if (data.type === RESET_LIST) {
        yield put(clearDataAction())
      } else if (data.type === FETCH_LIST) {
        const oldData = yield select((state) => state[sliceName[0].toLowerCase() + sliceName.slice(1)].data)
        yield put(patchDataAction({ isLoading: true }))
        task = yield fork(fetchList, oldData)
      }
    }
  }

  /**
   * @param {{ nextPage: any; list: string | any[]; }} oldData
   */
  function* fetchList(oldData) {
    const page = oldData.nextPage
    const data = {
      current: page,
    }

    const response = yield call(fetchListApi, data)
    const newData = {
      nextPage: page + 1,
      isLoading: false,
      list: oldData?.list?.concat(response?.data),
      hasMore: response?.page_result?.total > response?.page_result?.current * response?.page_result?.pageSize,
      page_result: response.page_result,
    }

    if (!(yield cancelled())) {
      yield put(patchDataAction(newData))
    }
  }

  return handleList
}

/**
 * @param {T} sliceName
 * @template {string } T CamelCase name
 * @returns
 */
export const createListSlice = (sliceName) => {
  const initialState = {
    data: {
      nextPage: 1,
      isLoading: true,
      hasMore: true,
      list: [],

      page_result: { current: null, pageSize: null, total: null },
    },
  }

  const slice = createSlice({
    name: sliceName[0].toLowerCase() + sliceName.slice(1),
    initialState,
    reducers: /** @type {{ [key in `request${T}Data` | `clear${T}Data` | `patch${T}Data`]: any }} */ ({
      [`request${sliceName}Data`](state, action) {},
      [`clear${sliceName}Data`](state, action) {
        state.data = {
          nextPage: 1,
          isLoading: true,
          hasMore: true,
          list: [],

          page_result: { current: null, pageSize: null, total: null },
        }
      },
      [`patch${sliceName}Data`](state, action) {
        Object.assign(state.data, action.payload)
      },
    }),
  })

  return slice
}

export const createListSelector = (sliceName) => (state) => state[sliceName[0].toLowerCase() + sliceName.slice(1)].data
