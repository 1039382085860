import { useEffect, useState } from 'react'

export default function UsePageVisible() {
  const [isVisible, setVisible] = useState(document.visibilityState === 'visible')
  function listener(e) {
    setVisible(document.visibilityState === 'visible')
  }
  useEffect(() => {
    window.addEventListener('visibilitychange', listener)
    return () => {
      window.removeEventListener('visibilitychange', listener)
    }
  }, [])
  return { isVisible }
}
