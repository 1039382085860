import AreaTitle from 'component/AreaTitle'
import Base64Image from 'component/Base64Image'
import styled from 'styled-components'

export const Item = styled.img`
  font-size: 0.4rem;
  font-weight: bold;
  width: calc(calc(100% - var(--cms-grid-column-gap)) / 2);
  height: 2.8rem;
  border-radius: 10px;
  background-image: url(${process.env.PUBLIC_URL}/loading/load-image.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
`

export const StyledAreaTitle = styled(AreaTitle)`
  margin: var(--cms-grid-row-gap) 0;
`

export const ImageWrapper = styled.div`
  overflow: hidden;
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr 1fr;
  flex-direction: column;
  align-items: stretch;
  gap: 0.2rem;
  padding: 0.2rem;

  > * {
    user-select: none;
  }

  > :global(.lazyload-wrapper) {
    display: contents;
  }
`

export const ImageBox = styled(Base64Image).attrs((props) => {
  return { aspectRatio: props.vertical ? 0.762 : 1.407 }
})`
  border-radius: 0.25rem;
`
