import Video from './components/Video'
import Short from './components/Short'
import Comic from './components/Comic'
import PageTabs, { PageTab } from 'component/PageTabs'

export default function BrowseCollection({ ...props }) {
  return (
    <PageTabs
      {...props}
      title="我的收藏"
      tabs={[
        { id: 'video', name: '长视频' },
        { id: 'short', name: '短视频' },
        ...(process.env.REACT_APP_HAS_PROFILE_COMIC_SUB_PAGE ? [{ id: 'comic', name: '漫画' }] : []),
      ]}
    >
      <PageTab>
        <Video />
      </PageTab>
      <PageTab>
        <Short />
      </PageTab>
      {process.env.REACT_APP_HAS_PROFILE_COMIC_SUB_PAGE ? (
        <PageTab>
          <Comic />
        </PageTab>
      ) : null}
    </PageTabs>
  )
}
