import styled from 'styled-components'

export const IconPwd = styled.i.attrs((props) => {
  const background = props?.isConfirmPassword
    ? `url(${process.env.PUBLIC_URL}/icon/confirm_password.svg) no-repeat`
    : `url(${process.env.PUBLIC_URL}/icon/password.svg) no-repeat`

  return {
    style: {
      background,
      backgroundSize: 'contain',
    },
  }
})`
  cursor: pointer;
  user-select: none;
  width: 0.5128rem;
  height: 0.5128rem;
  margin-right: 0.1025rem;
`

export const IconEye = styled.i`
  cursor: pointer;
  user-select: none;
  width: 0.3846rem;
  height: 0.3846rem;
  background: url(${process.env.PUBLIC_URL}/icon/eye.svg) no-repeat center;
  background-size: contain;
`
