import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import actions from 'redux/action'

export default function useDepositChannelBox() {
  const dispatch = useDispatch()

  const openDepositChannelBox = useCallback(() => {
    dispatch(actions.openDepositChannelBox())
  }, [dispatch])

  const closeDepositChannelBox = useCallback(() => {
    dispatch(actions.closeDepositChannelBox())
  }, [dispatch])

  return {
    openDepositChannelBox,
    closeDepositChannelBox,
  }
}
