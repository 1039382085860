// import Decimal from 'decimal.js'
// import toFormat from 'toformat'

// const AmountFormat = toFormat(Decimal)

// AmountFormat.format = {
//   decimalSeparator: '.',
//   groupSeparator: ',',
//   groupSize: 3,
//   secondaryGroupSize: 0,
//   fractionGroupSeparator: '',
//   fractionGroupSize: 0,
// }

// export default AmountFormat

export const formatAmount = (v) => {
  if (typeof v === 'string') v = Number(v)
  if (Number.isFinite(v)) return v.toFixed(2)
  else return '-'
}
