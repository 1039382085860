import { ColoredIcon } from 'component/ColoredIcon'
import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  font-size: 0.35rem;
  padding: 0 0.2rem;
  gap: 0.25rem;
`

export const CardWrapper = styled.div`
  display: grid;
  grid-template-rows: 40% 20% 1fr;
  align-items: end;
  color: var(--page-game--has-background-color);
  background: url(${process.env.PUBLIC_URL}/account/bg-creditcard.png);
  background-size: cover;
  height: 2.8rem;
  border-radius: 0.25rem;
  overflow: hidden;

  > div {
    padding: 0.2rem 0.4rem;
  }

  .card-title {
    font-size: 0.6rem;
    font-weight: 700;
  }

  .card-code {
    background: var(--page-game--withdraw-card-bottom-background);
  }
`

export const Input = styled.input`
  background: var(--input-background);
  border-radius: 0.25rem;
  padding: 0.25rem;
  width: 100%;
  &:hover,
  &:focus {
    background: var(--input-focus-background);
  }
`

export const IconInputWrapper = styled.div`
  position: relative;
  width: 100%;
  color: var(--input-color);
  ::placeholder {
    color: var(--input-placeholder-color);
  }
  input {
    border: none;
    padding-left: 30px;
  }
  input:hover,
  input:focus {
    background: #ececec;
  }
`

export const Icon = styled(ColoredIcon)`
  position: absolute;
  left: 0.2rem;
  height: 100%;
  width: 0.4rem;
`

export const IconPassword = styled(Icon).attrs({
  src: `${process.env.PUBLIC_URL}/account/icon-password-gray.svg`,
})``

export const IconEye = styled.div`
  position: absolute;
  right: 0;
  height: 100%;
  width: 30px;
  padding: 0.1rem;
  background: url(${process.env.PUBLIC_URL}/account/icon-eye-hide.svg);
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: center;
  &.visible {
    background-image: url(${process.env.PUBLIC_URL}/account/icon-eye-show.svg);
  }
`

export const RightAlignHintText = styled.div`
  text-align: right;
  font-size: 0.25rem;
`

export const TransferShortcut = styled.button`
  position: absolute;
  right: 0.25rem;
  top: 0.25rem;
  font-size: 0.35rem;
  padding-top: calc(env(safe-area-inset-top, 0px) - 0.25rem);
`
