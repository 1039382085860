import { memo } from 'react'
import { Wrapper, Title, Text } from './Styled'

function NullResult() {
  return (
    <Wrapper>
      <Title text="搜索结果"></Title>
      <Text>无搜寻结果</Text>
    </Wrapper>
  )
}

export default memo(NullResult)
