import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  width: 7.253rem;
  padding: 0.41rem;
  background-color: #ffffff;
  border-radius: 0.373rem;
`

export const Title = styled.div`
  color: var(--component-modal-title-color);
  font-style: normal;
  font-weight: 700;
  font-size: 0.41rem;
  margin: 0.41rem 0 0;
  text-align: center;
  line-height: 130%;
`
export const Message = styled.div`
  color: var(--component-modal-content-color);
  font-size: 0.36rem;
  margin: 0.21rem 0 0.41rem;
  text-align: center;
  line-height: 150%;
`
export const FatMessage = styled.div`
  color: var(--component-modal-title-color);
  font-size: 0.4rem;
  font-weight: 700;
  margin: 0.41rem 0;
  text-align: center;
  line-height: 150%;
`
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  gap: 0.21rem;
`
export const BaseButton = styled.div`
  width: 0;
  flex: 1 1 auto;
  font-weight: 400;
  font-size: 0.36rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0.92rem;
  border-radius: 0.5rem;
`
export const CancelButton = styled(BaseButton)`
  background: var(--component-modal-confirm--cancel-background);
  color: var(--component-modal-confirm--cancel-color);
`
export const ConfirmButton = styled(BaseButton)`
  background: var(--component-modal-confirm--confirm-background);
  color: var(--component-modal-confirm--confirm-color);
`
