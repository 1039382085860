import { useEffect, useMemo, useRef, useState } from 'react'

/**
 *
 * @param {number} direction 1: portrait, 2:landscape
 * @returns
 */
export default function useScreenOrientation(direction) {
  const listener = useRef(null)
  const [angle, setAngle] = useState(window.screen.orientation?.angle)
  const [type, setType] = useState(window.screen.orientation?.type)

  useEffect(() => {
    window.screen.orientation?.addEventListener('change', (event) => {
      const angle = event.target.angle
      const type = event.target.type
      setAngle(angle)
      setType(type)
    })
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      window.screen.orientation?.removeEventListener('change', listener.current)
    }
  }, [])

  const rotateClassName = useMemo(() => {
    if (direction == 1 && angle == 90) {
      // portrait 90deg
      return 'rotate-90'
    } else if (direction == 1 && angle == 270) {
      // portrait 270deg
      return 'rotate-270'
    } else if (direction == 2 && (angle == 0 || angle == 180) && type !== 'landscape-primary') {
      // landscape
      return 'rotate-90'
    }
    return 'rotate-0'
  }, [direction, angle, type])

  return { angle, rotateClassName }
}
