import { useLayoutEffect, useRef, useState } from 'react'

export const useMutex = () => {
  const [runningState, setRunningState] = useState(false)
  const runningLive = useRef(false)
  useLayoutEffect(() => {
    runningLive.current = runningState
  })

  const cache = useRef()
  if (!cache.current) {
    cache.current = new WeakMap()
  }

  const wrap = (method) => {
    if (cache.current.has(method)) {
      return cache.current.get(method)
    }
    const wrapped = (...props) => {
      if (runningLive.current) {
        if (process.env.NODE_ENV === 'development') {
          console.warn('return early because method is running')
        }
        return
      }
      setRunningState(true)

      let result
      try {
        result = method(...props)
      } catch (err) {
        setRunningState(false)
        throw err
      }

      if (typeof result?.then !== 'function') {
        setRunningState(false)
        return result
      } else {
        return Promise.resolve(result).then(
          (r) => {
            setRunningState(false)
            return result
          },
          (err) => {
            setRunningState(false)
            throw err
          }
        )
      }
    }
    cache.current.set(method, wrapped)
    return wrapped
  }

  return {
    wrap,
  }
}
