import styled, { css } from 'styled-components'
import DecorationRaw from './assets/decoration.svg'
import ButtonSearchMore from 'component/ButtonSearchMore'
export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 0.74rem;
  width: 100%;
  padding: 0 var(--cms-grid-column-gap);
  flex-shrink: 0;

  ${({ hasDecoration }) =>
    hasDecoration
      ? css`
          padding-left: calc(var(--cms-grid-column-gap) + var(--component-area-title--decorate-left-offset) + 0.105rem);
        `
      : ''}
`

export const TitleText = styled.div`
  font-size: 0.41rem;
  font-weight: 700;
  color: var(--component-area-title--color);
`

export const TitleDecoration = styled.div`
  background: var(--component-area-title--dec-color);
  mask-image: url('${DecorationRaw}');
  mask-size: 100% 100%;
  position: absolute;
  left: var(--component-area-title--decorate-left-offset);
  top: 0;
  bottom: 0;
  width: 0.105rem;
`

export const TitleSubText = styled.div`
  flex: 1 1 auto;
  min-width: 0;
  font-size: 0.25rem;
  color: var(--component-area-title--sub-color);
`

export const TitleExtra = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
`

export const StyledButtonSearchMore = styled(ButtonSearchMore)`
  color: var(--component-area-title--more-color) !important;
`
