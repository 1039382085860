import { forwardRef, useEffect, useState } from 'react'
import { unpackAesImages } from 'utils/cryoto'

const useDecryptedImageUrl = (encryptedUrl, key, iv) => {
  const [encryptedBuffer, setEncryptedBuffer] = useState(null)
  useEffect(() => {
    const con = new AbortController()
    const signal = con.signal
    async function run() {
      if (!encryptedUrl) {
        setEncryptedBuffer(null)
        return
      }
      const arrayBuffer = await (await fetch(encryptedUrl, { signal })).arrayBuffer()
      setEncryptedBuffer(arrayBuffer)
    }
    run().catch(process.env.NODE_ENV === 'development' ? console.error : () => {})
    return () => {
      con.abort()
    }
  }, [encryptedUrl])
  const [decryptedBlob, setDecryptedBlob] = useState(null)
  useEffect(() => {
    const con = new AbortController()
    const signal = con.signal
    async function run() {
      if (encryptedBuffer == null) {
        setDecryptedBlob(null)
        return
      }
      try {
        const res = await unpackAesImages(encryptedBuffer, key, iv)
        if (signal.aborted) return
        if (res[0]) {
          setDecryptedBlob(res[0])
        }
      } catch (err) {
        throw err
      }
    }
    run().catch(process.env.NODE_ENV === 'development' ? console.error : () => {})
    return () => {
      con.abort()
    }
  }, [encryptedBuffer, iv, key])
  const [blobUrl, setBlobUrl] = useState(null)
  useEffect(() => {
    if (decryptedBlob == null) {
      setBlobUrl(null)
      return
    }
    const res = URL.createObjectURL(decryptedBlob)
    setBlobUrl(res)
    return () => {
      URL.revokeObjectURL(res)
    }
  }, [decryptedBlob])

  return blobUrl
}

const ASSET_PASSWORD = process.env.REACT_APP_ASSET_PASSWORD
const fixedIv = [0x00, 0x01, 0x05, 0x03, 0x04, 0x05, 0x09, 0x07, 0x08, 0x09, 0x0a, 0x0b, 0x0c, 0x0d, 0x0e, 0x0f]

export const EncryptedDiv = forwardRef(function EncryptedDiv({ backgroundImage, maskImage, style, ...props }, ref) {
  const decryptedBackgroundImage = useDecryptedImageUrl(backgroundImage, ASSET_PASSWORD, fixedIv)
  const decryptedMaskImage = useDecryptedImageUrl(maskImage, ASSET_PASSWORD, fixedIv)

  const newStyleObject = {
    ...(style ?? {}),
    ...(decryptedBackgroundImage ? { backgroundImage: `url(${decryptedBackgroundImage})` } : {}),
    ...(decryptedMaskImage ? { maskImage: `url(${decryptedMaskImage})` } : {}),
  }

  return <div ref={ref} {...props} style={newStyleObject} />
})

export const EncryptedImg = forwardRef(function EncryptedImg({ src, ...props }, ref) {
  const decryptedImgSrc = useDecryptedImageUrl(src, ASSET_PASSWORD, fixedIv)

  // eslint-disable-next-line jsx-a11y/alt-text
  return <img ref={ref} {...props} src={decryptedImgSrc} />
})
