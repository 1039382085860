import Cookie from 'js-cookie'

const hasBug = () => {
  const key = 'test-' + Math.random() * 100000
  const value = String(Math.random)
  localStorage.setItem(key, value)
  const result = localStorage.getItem(key)
  localStorage.removeItem(key)
  return value !== result
}

if (hasBug()) {
  console.warn('local storage bugged, readonly for now')
}

const PREFIX = 'combined-storage-'

class CombinedStorage {
  /**
   * @param {string} key
   * @returns {string|undefined}
   */
  getItem(key) {
    const fromCookie = Cookie.get(PREFIX + key)
    const fromLocalStorage = localStorage.getItem(key)
    return fromCookie ?? fromLocalStorage
  }
  /**
   * @param {string} key
   * @param {string} value
   * @returns {void}
   */
  setItem(key, value) {
    Cookie.set(PREFIX + key, value)
    localStorage.setItem(key, value)
  }
  /**
   * @param {string} key
   * @returns {void}
   */
  removeItem(key) {
    Cookie.remove(PREFIX + key)
    localStorage.removeItem(key)
  }
}

export const combinedStorage = new CombinedStorage()
