import styled from 'styled-components'

export const Wrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  padding: 0.25rem 0.6rem;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  text-align: center;
  font-size: 0.3rem;
  z-index: 10;
  div {
    width: 1.2rem;
    height: 1.2rem;
    margin-bottom: 0.35rem;
  }
  ${(props) =>
    props.lockLandScape
      ? `
        @media (orientation: portrait){
            transform: translate(-50%, -50%) rotate(90deg);
        }
        `
      : ''}
`
