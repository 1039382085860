import { createBrowserRouter, createHashRouter } from 'react-router-dom'
import PageHomePage from 'page/HomePage'
import PageHomeVideo from 'page/HomeVideo'
import PageProfile from 'page/Profile'
import PageUnknown from 'page/Unknown'
import PageAppCenter from 'page/AppCenter'
import PageCategory from 'page/Category'
import GameLobby from 'page/GameLobby'

import { UNDEFINED, INDEX, PROFILE, APP_CENTER, CATEGORY, GAME, HOME_VIDEO } from 'constant/routers'
import { AppLayout } from 'app-layered-layout/AppLayout'
import App from 'App'
import { Layout } from 'layout/Layout'
import { BareLayout } from 'layout/BareLayout'
import { namedRouteDefinitions } from 'hooks/useNavigateTo'

const videoRoutes = namedRouteDefinitions
  .filter((d) => d.meta.isVideo === true)
  .map((d) => {
    return {
      path: d.name,
      element: d.component,
    }
  })
const childRoutes = namedRouteDefinitions
  .filter((d) => d.meta.hasNav !== true && d.meta.isVideo !== true)
  .map((d) => {
    return {
      path: d.name,
      element: d.component,
    }
  })

export const routes = [
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '',
        element: <AppLayout />,
        children: [
          {
            path: '',
            element: <Layout />,
            children: [
              {
                path: INDEX,
                element: <PageHomePage />,
              },
              {
                path: HOME_VIDEO,
                element: <PageHomeVideo />,
                children: [
                  {
                    path: CATEGORY.replace(HOME_VIDEO + '/', ''),
                    element: <PageCategory />,
                  },
                  ...videoRoutes.map((i) => ({
                    ...i,
                    path: i.path.replace(HOME_VIDEO.replace(/^\//, '') + '/', ''),
                  })),
                ],
              },
              {
                path: APP_CENTER,
                element: <PageAppCenter />,
              },
              {
                path: PROFILE,
                element: <PageProfile />,
                children: [
                  {
                    path: ':deviceCode',
                    element: <PageProfile />,
                  },
                ],
              },
              {
                path: GAME,
                element: <GameLobby />,
              },
            ],
          },
          {
            path: '',
            element: <BareLayout />,
            children: [...childRoutes],
          },
        ],
      },
      {
        path: UNDEFINED,
        element: <PageUnknown />,
      },
    ],
  },
]

export const router = process.env.REACT_APP_USE_HASH_ROUTER ? createHashRouter(routes) : createBrowserRouter(routes)
