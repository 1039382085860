import { ColoredIcon } from 'component/ColoredIcon'
import styled, { keyframes } from 'styled-components'

const moveIn = keyframes`
 from { transform: translateY(50%); }
 to { transform: translateY(0%); }
`

const fadeIn = keyframes`
 from { opacity: 0; }
 to { opacity: 1; }
`

export const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--main-background);
  border-radius: 0.375rem 0.375rem 0 0;
  font-size: 0.35rem;
  padding: 0.4rem;
  padding-bottom: calc(0.4rem + env(safe-area-inset-bottom, 0px));
  overflow: hidden;
  animation: ${moveIn} 0.3s ease-in;
`

export const WrapperMask = styled.div`
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  animation: ${fadeIn} 0.3s ease-in;
`

export const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  animation: ${fadeIn} 0.3s ease-in;
`

export const Title = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto;
  font-size: 0.5rem;
  font-weight: bold;
  padding-top: 0.1rem;
  padding-bottom: 0.4rem;
  width: 100%;
  text-align: center;
`

export const ButtonSubmit = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8.8rem;
  height: 0.923rem;
  margin-top: 8px;
  background: var(--button-primary-background);
  color: var(--button-primary-color);
  border-radius: 2.5rem;
`

export const ErrorText = styled.div`
  width: 100%;
  padding: 0 0.2rem;
  font-size: 0.3rem;
  color: var(--danger-color);
  text-align: left;
`

export const HintText = styled.div`
  color: var(--danger-color);
  padding: 0.1rem;
  text-align: center;
  line-height: 150%;
`

export const BtnSwitchLogin = styled.button`
  color: var(--main-color);
  position: relative;
  padding-left: 0.3rem;
  padding-bottom: 0.2rem;

  &:before {
    position: absolute;
    left: 4.5px;
    content: ' | ';
    height: 100%;
    width: 1px;
  }
`

export const BottomText = styled.div`
  margin-top: 0.9rem;
  margin-bottom: 0.5rem;
  color: #aaaaaa;
  letter-spacing: 0.36px;
  font-size: 0.32rem;
`

export const IconClose = styled(ColoredIcon).attrs({
  src: `${process.env.PUBLIC_URL}/icon/close.svg`,
  color: '#AAAAAA',
})`
  position: absolute;
  right: 0;
  height: 0.6rem;
  width: 0.6rem;
`

export const ButtonRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.3rem;
  width: 100%;
  padding: 0.3rem;
`
