import { decode } from 'js-base64'
import classnames from 'classnames'

import { Wrapper, ContentWrapper, TopImage, Content, ButtonWrap, BtnLeft, BtnRight, Group } from './Styled'

export default function SystemItem(info) {
  const content = decode(info?.content)

  const { button_a, button_b, link_a, link_b } = info

  const onAnnouncementButtonAClick = () => {
    info?.handleCancel()

    if (button_a && link_a) window.open(link_a, '_blank')
  }

  const onAnnouncementButtonBClick = () => {
    info?.handleCancel()

    if (button_b && link_b) window.open(link_b, '_blank')
  }

  return (
    <Wrapper
      className={classnames({
        visible: info.currentDisplayAnnouncement.id === info.id,
      })}
      onClick={info?.handleCancel}
    >
      <Group>
        <TopImage onClick={(e) => e.stopPropagation()} />
        <ContentWrapper onClick={(e) => e.stopPropagation()}>
          <Content dangerouslySetInnerHTML={{ __html: content }} />

          <ButtonWrap>
            {button_a && button_b && <BtnLeft onClick={onAnnouncementButtonBClick}>{button_b}</BtnLeft>}

            {!button_a && button_b ? (
              <BtnRight onClick={onAnnouncementButtonBClick}>{button_b}</BtnRight>
            ) : (
              <BtnRight onClick={onAnnouncementButtonAClick}>{button_a || '我知道了'}</BtnRight>
            )}
          </ButtonWrap>
        </ContentWrapper>
      </Group>
    </Wrapper>
  )
}
