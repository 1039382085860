import styled from 'styled-components'
import closeImg from './assets/close.svg'
import { EncryptedDiv } from 'component/EncryptedElements'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #10041b;
  font-size: 0.426rem;
`

export const BgImg = styled(EncryptedDiv).attrs({
  backgroundImage: `${process.env.PUBLIC_URL}/profile-icon/bg-account-credential.enc.png`,
})`
  position: relative;
  width: 7.4359rem;
  height: 13.02564rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`

export const QRCodeWrapper = styled.div`
  position: absolute;
  left: 0.67rem;
  bottom: 2.62rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.82rem;
  height: 2.82rem;
  background-color: #ffffff;
  padding: 0.2051rem;
  border-radius: 0.2307rem;
`

export const TextBox = styled.div`
  position: absolute;
  left: 3.74rem;
  top: 7.74rem;
  display: flex;
  flex-direction: column;
  gap: 0.2307rem;
  font-size: 0.266rem;
  color: #ffffff;
`

export const TextBoxTitle = styled.div`
  font-weight: bold;
  font-size: 0.928rem;
  letter-spacing: 0.513192px;

  text-shadow: 0px 1.06452px 4.25806px rgba(0, 0, 0, 0.25), 0px 2.12903px 8.51613px rgba(0, 0, 0, 0.36);
`

export const TextBoxId = styled.div`
  color: white;
  font-size: 0.358rem;
  font-weight: 700;
  white-space: nowrap;
`

export const TextBoxHint = styled.div`
  color: white;
  white-space: nowrap;
`

export const Landing = styled.div`
  position: absolute;
  top: 12.12rem;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  font-size: 0.25641rem;
  color: white;
`

export const BtnCopySharingUrl = styled.button`
  position: absolute;
  top: 10.9rem;
  left: 50%;
  transform: translateX(-50%);
  width: 6.35897rem;
  height: 0.923rem;
  font-size: 0.3589rem;
  border-radius: 2.5641rem;
  color: white;
  background: var(
    --gradient-pink,
    linear-gradient(180deg, #fb196a 0%, #ff7baa 0.01%, #fb196a 32.29%, #fb196a 69.27%, #d41459 100%)
  );
  box-shadow: 0px 4px 3px 0px rgba(0, 0, 0, 0.15);
`
export const BtnClose = styled.button`
  position: absolute;
  right: 0.2rem;
  top: 0.4rem;
  width: 0.64rem;
  height: 0.64rem;
  background: url('${closeImg}');
`

export const Link = styled.a``
