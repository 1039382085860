import SyncLoader from 'react-spinners/SyncLoader'

import { Wrapper } from './Styled'

export default function BounceLoading() {
  return (
    <Wrapper>
      <SyncLoader size={'0.32rem'} color={'#bdbebd'} />
    </Wrapper>
  )
}
