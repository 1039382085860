import ConfirmModal, { CONFIRM_MODAL_TYPE } from 'component/Modal/ConfirmModal'
import { useCallback, useRef } from 'react'
import useHintModal from './useHintModal'

export default function useConfirmModal() {
  const { openHintModal, closeHintModal } = useHintModal()

  const currentCancel = useRef()
  const currentConfirm = useRef()

  const onCancelProxy = useCallback(() => {
    closeHintModal()
    currentCancel.current?.()
  }, [closeHintModal])

  const onConfirmProxy = useCallback(() => {
    closeHintModal()
    currentConfirm.current?.()
  }, [closeHintModal])

  const openConfirm = useCallback(
    ({ title, message, onConfirm, onCancel, type = CONFIRM_MODAL_TYPE.OK_CANCEL, confirmText, cancelText }) => {
      currentCancel.current = onCancel
      currentConfirm.current = onConfirm
      openHintModal({
        children: ConfirmModal,
        props: {
          title,
          message,
          onConfirm: onConfirmProxy,
          onCancel: onCancelProxy,
          type: type,
          confirmText,
          cancelText,
        },
      })
    },
    [onCancelProxy, onConfirmProxy, openHintModal]
  )

  const formatApiErrorMessage = useCallback((error) => {
    return error?.message || '发生错误'
  }, [])

  return { openConfirm, formatApiErrorMessage }
}
