import styled from 'styled-components'

export const JumbotronWrap = styled.div`
  height: 3.5rem;
  width: calc(100% - 0.4rem);
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  margin: 0 0.2rem;

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 200ms linear;
  }

  // pagination
  .swiper-pagination {
    bottom: 0;

    .swiper-pagination-bullet {
      background: rgba(255, 255, 255, 0.8);

      &.swiper-pagination-bullet-active {
        background: #ffffff;
      }
    }
  }

  .swiper-scrollbar {
    display: none;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
