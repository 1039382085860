import { useCallback, useEffect, useState } from 'react'

import { Wrapper, SectionTitle, Section, AccountInfoRow, CopyButton, FieldRow, ErrorHintText } from './Styled'

import { FullWidthPrimaryButton, HeaderWrap, PageWrapperNonScroll, PageScrollWrap } from 'commonStyledComponents'
import { formatAmount } from 'utils/amountFormat'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Input } from '../Styled'
import { fetchGameDepositChannelDetail, postGameCompanyDeposit } from 'api'
import NoteBox from 'component/NoteBox'
import PageModalHeaderBox from 'component/PageModalHeaderBox'
import { useParamsOfPage } from 'hooks/useNavigateTo'
import { useAppNavigate } from 'app-layered-layout/useAppNavigate'
import { useToastAlert } from 'hooks/useToastAlert'

export default function BankTransfer() {
  const { payment_channel_id, amount } = useParamsOfPage('game-deposit/bank-transfer')
  const navigate = useAppNavigate()
  const { toast } = useToastAlert()

  // 頁面資料
  const [accountInfo, setAccountAccount] = useState(null)
  const [infoLoading, setInfoLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const MAX_LEN = 10

  const { values, errors, handleChange, handleSubmit, handleBlur, isValid, dirty, touched } = useFormik({
    initialValues: {
      remark: '',
    },
    validationSchema: Yup.object().shape({
      remark: Yup.string()
        .required('请输入真实姓名')
        .test('maxlen', `请勿超过${MAX_LEN}个字`, (v) => v?.toString().length <= MAX_LEN),
    }),
    onSubmit: async () => {
      await submitDeposit()
    },
  })

  const loadDetail = useCallback(async () => {
    try {
      setInfoLoading(true)
      const detail = await fetchGameDepositChannelDetail({
        channelId: payment_channel_id,
      })
      // setAccountAccount({
      //   receiptBank:'招商銀行',
      //   branchName:'北京分行',
      //   receiptAccount:'',
      //   receiptName:'張某某'
      // })
      if (detail.data) {
        setAccountAccount(detail.data)
      } else {
        toast('暂无帐户信息')
      }
    } catch (ex) {
      toast('无法取得帐户信息')
    }
    setInfoLoading(false)
  }, [payment_channel_id, toast])

  async function submitDeposit() {
    if (submitLoading) {
      toast('请勿重复提交')
      return
    }
    const payload = {
      channelId: payment_channel_id,
      remark: values.remark.toString(),
      amount,
    }
    try {
      setSubmitLoading(true)
      const result = await postGameCompanyDeposit(payload)
      toast('提交成功')
      navigate(-2)
      console.log(result)
    } catch (ex) {
      console.warn(ex)
      if (ex && ex.message) toast('存款失败')
    } finally {
      setSubmitLoading(false)
    }
  }

  async function handleCopy(text) {
    if (text && navigator.clipboard) {
      await navigator.clipboard.writeText(text)
      toast('已复制到剪贴簿')
    }
  }

  useEffect(() => {
    loadDetail()
  }, [loadDetail])

  return (
    <PageWrapperNonScroll>
      <HeaderWrap>
        <PageModalHeaderBox headerTitle={'银行转帐'} />
      </HeaderWrap>
      <PageScrollWrap>
        <Wrapper>
          <Section>
            <FieldRow>
              <span>转帐金额</span>
              <span>¥ {formatAmount(amount)}元</span>
            </FieldRow>
          </Section>
          <Section>
            <SectionTitle>帐户资讯</SectionTitle>
            {accountInfo ? (
              <>
                <AccountInfoRow>
                  <span>银行名称</span>
                  <span>{accountInfo.receiptBank}</span>
                  <CopyButton onClick={() => handleCopy(accountInfo.receiptBank)}></CopyButton>
                </AccountInfoRow>
                <AccountInfoRow>
                  <span>分行名称</span>
                  <span>{accountInfo.branchName}</span>
                  <CopyButton onClick={() => handleCopy(accountInfo.branchName)}></CopyButton>
                </AccountInfoRow>
                <AccountInfoRow>
                  <span>帐户</span>
                  <span>{accountInfo.receiptAccount}</span>
                  <CopyButton onClick={() => handleCopy(accountInfo.receiptAccount)}></CopyButton>
                </AccountInfoRow>
                <AccountInfoRow>
                  <span>户名</span>
                  <span>{accountInfo.receiptName}</span>
                  <CopyButton onClick={() => handleCopy(accountInfo.receiptName)}></CopyButton>
                </AccountInfoRow>
              </>
            ) : (
              <>{infoLoading ? '载入中' : '无资料'}</>
            )}
          </Section>
          <Section>
            <FieldRow>
              <span>转帐备注</span>
              <div>
                <Input
                  placeholder="请输入真实姓名"
                  name="remark"
                  type="text"
                  maxLength={MAX_LEN}
                  value={values.remark}
                  onBlur={handleBlur}
                  onChange={handleChange}
                ></Input>
              </div>
            </FieldRow>
            <ErrorHintText>{touched.remark && errors.remark}</ErrorHintText>
          </Section>
          <NoteBox
            background="none"
            text={['请务必提供真实资讯，以供核对转帐资料。', '请在汇款备注写入真实姓名及相关资讯，以加速充值流程。']}
          ></NoteBox>
          <FullWidthPrimaryButton type="submit" onClick={handleSubmit} disabled={!isValid || !dirty}>
            送出
          </FullWidthPrimaryButton>
        </Wrapper>
      </PageScrollWrap>
    </PageWrapperNonScroll>
  )
}
