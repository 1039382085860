import { URL_QUERY_SKIP_LOGIN_TOKEN } from 'constant/routes'
import { createContext, useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import { selectTokenInfo } from 'redux/selector/user'
import { useNavigateTo } from './useNavigateTo'

const GamePopupContext = createContext({
  openGamePopup: ({ id, gameType, tpCode, direction }) => {},
  closeGamePopup: () => {},
})

export const GamePopupProvider = ({ children }) => {
  const currentPopup = useRef(
    /** @type {{ gameId: number, gameType: number, tpCode: string, direction: number, window: Window|null }} */ (null)
  )
  const tokenInfo = useSelector(selectTokenInfo)
  const { navigateToGameDeposit } = useNavigateTo()

  useEffect(() => {
    const listener = (ev) => {
      if (ev.data?.source === 'game-now' && currentPopup.current) {
        currentPopup.current.window.close()

        if (ev.data?.type === 'deposit') {
          navigateToGameDeposit({
            fromGame: currentPopup.current.gameId,
            fromGameTpCode: currentPopup.current.tpCode,
            fromGameType: currentPopup.current.gameType,
            fromGameDirection: currentPopup.current.direction,
          })
        }
      }
    }
    window.addEventListener('message', listener)
    return () => {
      window.removeEventListener('message', listener)
    }
  }, [navigateToGameDeposit])

  const openGamePopup = useCallback(
    ({ id, gameType = '', tpCode = '', direction = 0 }) => {
      const url = `/game-now?id=${id}&gameType=${gameType ?? ''}&tpCode=${tpCode ?? ''}${
        direction > 0 && '&direction=' + direction
      }&${URL_QUERY_SKIP_LOGIN_TOKEN}=${encodeURIComponent(JSON.stringify(tokenInfo))}`
      if (currentPopup.current) {
        try {
          currentPopup.current?.window.close()
        } catch (err) {}
        currentPopup.current = null
      }

      const newPopup = window.open(url, '_blank')
      newPopup.focus()
      currentPopup.current = {
        gameId: id,
        gameType,
        tpCode,
        direction,
        window: newPopup,
      }
    },
    [tokenInfo]
  )

  const closeGamePopup = useCallback(() => {
    if (currentPopup.current) {
      currentPopup.current.window.close()
      currentPopup.current = undefined
    }
  }, [])

  return useMemo(
    () => <GamePopupContext.Provider value={{ openGamePopup, closeGamePopup }}>{children}</GamePopupContext.Provider>,
    [children, closeGamePopup, openGamePopup]
  )
}

export const useOpenGamePopup = () => {
  const ctx = useContext(GamePopupContext)
  return ctx.openGamePopup
}

export const useCloseGamePopup = () => {
  const ctx = useContext(GamePopupContext)
  return ctx.closeGamePopup
}
