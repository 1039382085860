import styled from 'styled-components'

export const PageWrapper = styled.div`
  position: relative;
  flex: 1;
  overflow: hidden;
  @media (orientation: portrait) {
    padding-top: env(safe-area-inset-top, 0px);
    padding-bottom: env(safe-area-inset-bottom, 0px);
  }
  @media (orientation: landscape) {
    padding-left: env(safe-area-inset-left, 0px);
    padding-right: env(safe-area-inset-right, 0px);
  }
`

export const GameFrame = styled.iframe`
  flex: 1;
  width: 100%;
  height: 100%;
`
