import { useState, useCallback, useRef } from 'react'
import { PASSWORD_MIN_LENGTH, PASSWORD_MAX_LENGTH } from 'constant/common'
import { Wrapper, ErrorMessage, TextField, TextFieldBox } from '../../Styled'
import { IconPwd, IconEye } from './Styled'

export default function PasswordFieldBox({ isConfirmPassword, handleChange, error, ...otherAttrs }) {
  const [isTextDisplay, setIsTextDisplay] = useState(false)
  const inputRef = useRef(null)

  const handleClick = useCallback(() => {
    setIsTextDisplay((state) => !state)
  }, [setIsTextDisplay])

  const handleFocus = useCallback(() => {
    try {
      inputRef.current?.focus()
    } catch {}
  }, [inputRef])

  return (
    <Wrapper>
      <TextFieldBox>
        <IconPwd isConfirmPassword={isConfirmPassword} onClick={handleFocus} />

        <TextField
          {...otherAttrs}
          ref={inputRef}
          min={PASSWORD_MIN_LENGTH}
          max={PASSWORD_MAX_LENGTH}
          type={isTextDisplay ? 'text' : 'password'}
          onBlur={handleChange}
        />

        <IconEye onClick={handleClick} />
      </TextFieldBox>

      <ErrorMessage>{error}</ErrorMessage>
    </Wrapper>
  )
}
