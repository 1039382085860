import { PageWrapper } from 'commonStyledComponents'
import AreaTitle from 'component/AreaTitle'
import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--main-color);

  ${(props) =>
    props.isPopup
      ? css`
          flex: 1 1 auto;
          padding: 0;
          min-height: 0;
          overflow-y: auto;
        `
      : css`
          padding: calc(12px + env(safe-area-inset-top)) 0 0;
        `}
`

export const OuterWrapper = styled(PageWrapper)`
  ${(props) =>
    props.isPopup
      ? css`
          display: flex;
          flex-direction: column;
        `
      : css``}
`

export const StyledAreaTitle = styled(AreaTitle)`
  margin: 0.307rem 0;
`
