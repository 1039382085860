import EmptyResult from 'component/EmptyResult'
import ShortVideoBox from 'component/ShortVideoBox'
import { ShortWrap } from './Styled'
import { useImmer } from 'use-immer'
import { fetchShortBuyList } from 'api'
import { useEffect, useMemo, useRef } from 'react'
import { InfiniteScrollCmsGridWrapper } from 'commonStyledComponents'
import { usePageActivated } from 'app-layered-layout/usePageActivated'

const Short = () => {
  const [state, updateState] = useImmer({
    nextPage: 1,
    isLoading: true,
    hasMore: true,
    list: [],

    page_result: { current: null, pageSize: null, total: null },
  })

  const firstEnter = useRef(true)
  const pageActivated = usePageActivated()

  const handleFetchVideoList = async () => {
    if (state.current === state.page_result.current || !state.hasMore) return

    const data = {
      current: state.nextPage,
    }

    try {
      const response = await fetchShortBuyList(data)
      firstEnter.current = false
      updateState((draft) => {
        draft.list = draft?.list?.concat(response?.data)
        draft.hasMore = response?.page_result?.total > response?.page_result?.current * response?.page_result?.pageSize
        draft.nextPage = response?.page_result?.current + 1
        draft.page_result = response.page_result

        draft.isLoading = false
      })
    } catch (error) {
      updateState((draft) => {
        draft.isLoading = false
      })
    }
  }

  const style = useMemo(
    () => ({
      display: 'flex',
      gap: 'var(--cms-grid-row-gap, 0.093rem) var(--cms-grid-column-gap, 0.093rem)',
      flexWrap: 'wrap',
    }),
    []
  )

  const shortInfo = useMemo(
    () => ({
      list: state?.list ?? [],
      updateList: (reducer) => {
        updateState((draft) => {
          draft.list = reducer(draft.list)
        })
      },
    }),
    [state?.list, updateState]
  )

  useEffect(() => {
    if (pageActivated && !firstEnter.current) {
      updateState((draft) => {
        draft.nextPage = 1
        draft.isLoading = true
        draft.hasMore = true
        draft.list = []
        draft.page_result = { current: null, pageSize: null, total: null }
      })
    }
  }, [pageActivated, updateState])

  return !state?.list?.length && !state.isLoading ? (
    <EmptyResult />
  ) : (
    <ShortWrap>
      <InfiniteScrollCmsGridWrapper
        style={style}
        pageStart={0}
        loadMore={handleFetchVideoList}
        hasMore={state?.hasMore}
        // loader={<div className="loader" key={0}>Loading ...</div>}
        useWindow={false}
      >
        {state?.list?.map((item, i) => (
          <ShortVideoBox ey={i} videoInfo={item} columns={3} shortInfo={shortInfo} noTitle>
            {i}
          </ShortVideoBox>
        ))}
      </InfiniteScrollCmsGridWrapper>
    </ShortWrap>
  )
}

export default Short
