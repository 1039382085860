import { useCallback } from 'react'
import { useSelector } from 'react-redux'
//
import { selectPictureOrigin } from 'redux/selector/app'
//
import { fetchPostAdEvent } from 'api'
//
import { AdImage } from './Styled'
import { formatDate } from 'utils/timeFormat'

export default function AdItem(adInfo) {
  const pictureOrigin = useSelector(selectPictureOrigin)

  const onItemClick = useCallback(() => {
    if (adInfo) {
      fetchPostAdEvent({
        date: formatDate(new Date()),
        material_id: adInfo.id,
        material_name: adInfo.name,
        oholo_site_id: adInfo.site_id,
        oholoer_id: adInfo.oholoer_id,
      })
      window.open(adInfo?.link, '_blank')
    } else {
      console.warn('no ad assigned')
    }
  }, [adInfo])

  if (!pictureOrigin) return null

  return <AdImage src={adInfo?.icon_path} onClick={onItemClick} />
}
