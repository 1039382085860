import EmptyResult from 'component/EmptyResult'
import { AdvertisementType } from 'constant/advertisement'
import { Dividing, TitleBox } from 'page/Category/Styled'
import AdItem from 'page/ViewMore/component/AdItem'
import { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { selectAdInfo, selectProducerList } from 'redux/selector/app'
import StaticItem from '../Statictem'
import { isEmpty } from 'lodash'

export default function ContentProducer() {
  const producerList = useSelector(selectProducerList)
    ?.data.map((item, index) => [item, index])
    .sort(([x, xIndex], [y, yIndex]) => {
      if (y.recommend_seqno !== x.recommend_seqno) {
        return y.recommend_seqno - x.recommend_seqno
      }
      return xIndex - yIndex
    })
    .map((i) => i[0])
  const currentSecPromoteList = producerList?.filter((_, index) => index < 6)
  const currentSecList = producerList?.filter((_, index) => index >= 6)
  const adInfo = useSelector(selectAdInfo)
  const adCategory = adInfo?.[AdvertisementType.Category] || []

  const hasNoContent = producerList?.length === 0 ?? 0

  return (
    <>
      <TitleBox>{`推荐厂商`}</TitleBox>
      {/* 推薦的 片商或演員 */}
      {currentSecPromoteList?.map((info, i) => (
        <StaticItem
          key={info.id}
          name={info.name}
          icon_path={info.icon_path}
          params={{
            producer_id: info.id,
          }}
        />
      ))}

      {/* 分隔區 */}
      <Dividing />

      {hasNoContent && <EmptyResult style={{ height: 'calc(100% - 1rem)' }} />}

      {/* 非推薦的 片商或演員 */}
      {currentSecList?.length > 0 && (
        <>
          <TitleBox>{`全部厂商`}</TitleBox>
          {currentSecList?.map((info, i) => {
            // 檢查循環與是否有ad可用
            const needToShowAd = (i + 1) % 12 === 0 && adCategory.length > 0
            const adIndex = ~~((i - 11) / 12)
            const wrappedIndex = adIndex % adCategory.length

            return (
              <Fragment key={i}>
                <StaticItem
                  key={info.id}
                  {...info}
                  params={{
                    producer_id: info.id,
                  }}
                />
                {needToShowAd && !isEmpty(adCategory[wrappedIndex]) && <AdItem {...adCategory[wrappedIndex]} />}
              </Fragment>
            )
          })}
        </>
      )}
    </>
  )
}
