import Base64Image from 'component/Base64Image'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.2rem;
  color: var(--page-actor--color);
`

export const ActorInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0.2rem;
  gap: 0.4rem;
`

export const ActorIcon = styled(Base64Image).attrs({
  aspectRatio: null,
  placeholder: '/loading/load-actor.svg',
  imageStyle: {
    objectPosition: 'top',
  },
})`
  border-radius: 100%;
  width: 2.5rem;
  height: 2.5rem;
  object-fit: cover;
`

export const ActorName = styled.div`
  font-size: 0.6rem;
  padding-bottom: 0.2rem;
`

export const ActorInfo = styled.div`
  font-size: 0.3rem;
  padding: 0.1rem;
`

export const Description = styled.div`
  font-size: 0.35rem;
  padding: 0.2rem 0;
`

export const DescriptionRow = styled.div`
  padding-bottom: 0.2rem;
`
