import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'

export const StyledSwiper = styled(Swiper)`
  align-self: stretch;
  flex-grow: 1;
  max-width: 100vw;
  width: 100%;
`

export const StyledSwiperSlide = styled(SwiperSlide).attrs({
  onLoadCapture: (e) => {
    e.stopPropagation()
  },
})`
  display: flex;
`

export const SlideContentWrapper = styled.div`
  display: contents;
`
