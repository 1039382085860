import { PageScrollWrap, PrimaryButton } from 'commonStyledComponents'
import { EncryptedDiv } from 'component/EncryptedElements'
import styled from 'styled-components'

export const Wrapper = styled(PageScrollWrap)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 100%;
  padding-bottom: 0;
  overflow: hidden;
  background: var(--page-share-background);
`

export const ScrollContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  text-align: center;
  width: 100%;
  padding-bottom: 1rem;
  overflow: auto;
  max-width: 10rem;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 0.4rem;
`

export const Content = styled(EncryptedDiv).attrs({
  backgroundImage: `${process.env.PUBLIC_URL}/share/img-bg.enc.png`,
})`
  position: relative;
  display: flex;
  flex-grow: 1;
  /* width: 100%; */
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 0.3rem;
  border-radius: 0.5rem;
  text-align: center;
  color: var(--page-share-text-color);
  font-size: 0.35rem;
  background-size: cover;
  background-position: top;
  padding-bottom: ${(14.6 / 8.55) * 100}%;
`

export const ContentItems = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.3rem;
  height: 100%;
  width: 7.5rem;
  top: 0;
  border-radius: 0.5rem;
  text-align: center;
  color: var(--page-share-text-color);
  font-size: 0.35rem;
`

export const TitleImg = styled.div`
  background: url('${process.env.PUBLIC_URL}/share/img-title.svg');
  position: relative;
  flex-shrink: 0;
  width: 5rem;
  height: 1.65rem;
  /* padding-bottom: 25%; */
  /* min-height: 2rem; */
  margin: 0.2rem 0 0.6rem 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`

export const SubTitle = styled.div`
  color: var(--page-share-subtitle-color);
  font-size: 1.5rem;
  flex-grow: 1;
`

export const ContentTitle = styled.div`
  font-size: 0.5rem;
  color: var(--page-share-title-color);
`

export const ContentLink = styled.a`
  color: var(--page-share-link-color);
  text-decoration: underline;
`

export const QRCodeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  background-color: #ffffff;
  padding: 0.2rem;
  border-radius: 0.1103rem;
`

export const CopyButton = styled(PrimaryButton)`
  width: 100%;
`

export const DownloadButton = styled(CopyButton)`
  background: var(--button-secondary-background);
  color: var(--button-secondary-color);
`

export const LogoImg = styled.div`
  background: url('${process.env.PUBLIC_URL}/share/img-logo.svg');
  height: 2rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 1.2rem;
`

// header
const HeaderPadder = styled.div`
  height: calc(env(safe-area-inset-top, 0px) - 0.21rem);
  min-height: 0;
`

const HeaderWrapRaw = styled.div`
  width: 100%;
  padding: 0 0 0.21rem;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 0.21rem;
  color: var(--page-share-title-color);
`

export const HeaderWrapper = ({ children, ...props }) => {
  return (
    <HeaderWrapRaw {...props}>
      <HeaderPadder />
      {children}
    </HeaderWrapRaw>
  )
}
