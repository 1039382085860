import { all } from 'redux-saga/effects'
import appSaga from 'redux/saga/app'
import userSaga from 'redux/saga/user'
import timerSaga from 'redux/saga/timer'
import favouriteSaga from 'redux/saga/favourite'
import collectionSaga from 'redux/saga/collection'
import shortFavouriteSaga from 'redux/saga/shortFavourite'
import shortCollectionSaga from 'redux/saga/shortCollection'
import comicCollectionSaga from 'redux/saga/comicCollection'

export default function* rootSaga() {
  yield all([
    appSaga(),
    userSaga(),
    timerSaga(),
    favouriteSaga(),
    collectionSaga(),
    shortFavouriteSaga(),
    shortCollectionSaga(),
    comicCollectionSaga(),
  ])
}
