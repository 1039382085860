import { useState } from 'react'
import range from 'lodash/range'
import classnames from 'classnames'
import { Wrapper, LineContainer, Line, CIRCLE_ELEMENT_QTY } from './Styled'

/**
 * @param
 *  isFetching {boolean} Spinner 是否旋轉
 *  moveDistance {Number} 手指移動的距離
 *  pullDownThreshold {Number} 下拉閾值
 */
export default function FadeLoading({ isFetching = false, pullDownThreshold, moveDistance }) {
  const [stepThreshold] = useState(pullDownThreshold / CIRCLE_ELEMENT_QTY)
  const elements = range(CIRCLE_ELEMENT_QTY)
  const visibleIndex =
    pullDownThreshold && moveDistance && !isFetching
      ? Math.floor(moveDistance / stepThreshold) - 1
      : CIRCLE_ELEMENT_QTY - 1

  return (
    <Wrapper>
      <LineContainer className={classnames({ pullDownRefresh: isFetching })}>
        {elements.map((v, i) => (
          <Line key={`spinner-line${i}`} index={i} visibleIndex={visibleIndex} />
        ))}
      </LineContainer>
    </Wrapper>
  )
}
