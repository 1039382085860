import { useRef, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { selectPictureOrigin } from 'redux/selector/app'
import { imageParser } from 'utils/imageParser'
import { fetchBinFile } from 'api'
import { Wrapper, ImageIcon } from './Styled'
import { useAdLinkHandler } from 'hooks/useAdLinkHandler'
import { catchPromiseCancel } from 'utils/catchPromiseCancel'

export default function PopularItem(props) {
  const pictureOrigin = useSelector(selectPictureOrigin)
  const imgRef = useRef(null)
  const handleAdLink = useAdLinkHandler()

  const onItemClick = useCallback(() => {
    handleAdLink({
      link: props,
      pageUuid: props.pageUuid,
    })
  }, [handleAdLink, props])

  useEffect(() => {
    if (!pictureOrigin || imgRef.current.src) return

    const controller = new AbortController()
    /** 圖片路徑 */
    const imagePath = `${pictureOrigin}/${props.icon_path}`

    let url

    fetchBinFile({
      url: imagePath,
      signal: controller.signal,
    })
      .then((textBase64) => (url = imgRef.current.src = imageParser(textBase64)))
      .catch(catchPromiseCancel)

    return () => {
      if (url) URL.revokeObjectURL(url)
      controller.abort()
    }
  }, [pictureOrigin, props.icon_path])

  return (
    <Wrapper>
      <ImageIcon ref={imgRef} onClick={onItemClick} />
    </Wrapper>
  )
}
