import { Wrapper, Image, Name } from './Styled'

/** 元件 - 片商 */
export default function ListItem({ name, imageUrl, autoWidth, avatarWidth, avatarHeight }) {
  return (
    <Wrapper>
      <Image src={imageUrl} source="preview" />

      <Name>{name}</Name>
    </Wrapper>
  )
}
