import AesImage from 'component/AesImage'
import styled from 'styled-components'

export const Wrapper = styled.div`
  --columns: ${(props) => props.columns ?? 2};
  position: relative;
  width: ${(props) =>
    props?.width != null
      ? props.width
      : 'calc(calc(100% - var(--cms-grid-column-gap) * calc(var(--columns) - 1)) / var(--columns))'};

  overflow: hidden;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  > * {
    user-select: none;
  }

  > :global(.lazyload-wrapper) {
    display: contents;
  }
`

export const BookDecoration = styled.div`
  height: 0.3rem;
  position: relative;
`

export const BookDecorationItem1 = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 14px;
  right: 14px;
  background: rgba(180, 180, 180, 0.5);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
`

export const BookDecorationItem2 = styled.div`
  position: absolute;
  top: 40%;
  bottom: 0;
  left: 10px;
  right: 10px;
  background: rgba(180, 180, 180, 0.5);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
`

export const StyledAesImage = styled(AesImage)`
  border-radius: 10px;
`

export const VideoInfo = styled.div`
  padding: 0.21rem 0 0;
  text-align: left;
`

export const VideoName = styled.div`
  font-size: 0.35897rem;
  line-height: 150%; /* 0.53846rem */
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const VideoNameWhite = styled(VideoName)`
  font-size: ${(props) => props.fontSize || '0.35897rem'};
  color: white;
  text-align: center;
`

export const VideoTags = styled.div`
  color: var(--component-comic-box--tag-color);
  font-size: 0.25rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 150%;
`
