import styled from 'styled-components'

export const CategoryBox = styled.div`
  display: inline-flex;
  width: 100%;
  margin-bottom: 0.4rem;
  flex: 0 0 auto;
`

export const CategoryType = styled.div`
  padding: 0.15rem 0.41rem;
  background: transparent;
  border-radius: 0.4rem;
  white-space: nowrap;

  &.selected {
    background: var(--page-search-video-viacategory--active-background);
    color: var(--page-search-video-viacategory--active-color);
  }
`

export const CategoryContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  /* margin-top: 0.68rem; */
  font-size: 0.373rem;
  width: 100%;
  overflow-x: scroll;
`

export const Content = styled.div`
  display: flex;
  /* gap: 0.32rem; */
  white-space: nowrap;
  padding: 0.053rem var(--cms-grid-column-gap, 0.093rem);
  /* border-radius: 0.266rem; */
  overflow-x: auto;

  > * {
    cursor: pointer;
  }
`

export const HintBoxWrapper = styled.div`
  background: var(--page-header-background);
  position: absolute;
  z-index: 2;
  pointer-events: none;
  top: 0;
  left: 0;
  right: 0;
`

export const HintBox = styled.div`
  color: var(--page-header-color);
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.4rem;
  white-space: nowrap;
`

export const NoDataWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-self: center;
  justify-content: center;
`
