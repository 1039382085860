import Base64Image from 'component/Base64Image'
import styled from 'styled-components'

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-bottom: calc(0.5rem + env(safe-area-inset-top, 0));
`

// actor icon list item
export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const ActorIcon = styled(Base64Image).attrs({
  aspectRatio: null,
  placeholder: `/loading/load-actor.svg`,
  imageStyle: {
    objectPosition: 'center top',
  },
})`
  border-radius: 100%;
  width: 2rem;
  height: 2rem;
  object-fit: cover;
`

export const ActorName = styled.div`
  font-size: 0.3rem;
  padding: 0.1rem;
`
