import { Button } from 'page/GameDeposit/Styled'
import styled from 'styled-components'

export const Wrapper = styled(Button)`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 0.3rem 0.1rem;
  align-items: center;
  font-weight: 700;
  .button-icon {
    width: 0.8rem;
    height: 0.8rem;
    margin: 0.2rem;
  }
`

export const PromoteFlag = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 0.1rem;
  border-radius: 0rem 0.25rem 0rem 0.1rem;
  color: #fff;
  background: var(--page-game-deposit--promote-tag-background);
  font-size: 0.25rem;
  text-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
`
