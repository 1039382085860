import PagePullLoadIcon from '../PagePullLoadIcon'
import { PagePullWrap } from './Style'

export default function PagePullLoad({ paused = false, ...props }) {
  if (paused) {
    return (
      <PagePullWrap {...props}>
        <PagePullLoadIcon paused={true} pausedAt={1000} />
      </PagePullWrap>
    )
  } else {
    return (
      <PagePullWrap {...props}>
        <PagePullLoadIcon />
      </PagePullWrap>
    )
  }
}
