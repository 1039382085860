import {
  Wrapper,
  BalanceWrapper,
  Balance,
  Section,
  SectionTitle,
  ButtonListWrapper,
  CoinButton,
  PriceText,
  ItemWrapper,
} from './Styled'
import { HeaderWrap, PageWrapperNonScroll } from 'commonStyledComponents'
import PageModalHeaderBox from 'component/PageModalHeaderBox'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAlert } from 'react-alert'
import { selectUserInfo } from 'redux/selector/user'
import NoteBox from 'component/NoteBox'
import { fetchProductList, fetchPurchaseProduct, fetchUserInfo } from 'api'
import DepositChannelBox from 'component/DepositChannelBox'
import DepositSuccess from 'page/Deposit/component/DepositSuccess'
import useDepositChannelBox from 'hooks/useDepositChannelBox'
import actions from 'redux/action'
import { usePageActivated } from 'app-layered-layout/usePageActivated'
import { useAppNavigate } from 'app-layered-layout/useAppNavigate'
import { catchPromiseCancel } from 'utils/catchPromiseCancel'

export default function Wallet() {
  const pageActivated = usePageActivated()
  const userInfo = useSelector(selectUserInfo)
  const [products, setProducts] = useState([])
  const dispatch = useDispatch()

  const alert = useAlert()
  const { closeDepositChannelBox, openDepositChannelBox } = useDepositChannelBox()
  const [selectedDepositItem, setSelectedDepositItem] = useState([])
  const [isShowDepositSuccess, setIsShowDepositSuccess] = useState(false)
  const navigate = useAppNavigate()

  useEffect(() => {
    if (!pageActivated) {
      return
    }
    const controller = new AbortController()
    const signal = controller.signal
    async function loadInfo() {
      var prods = await fetchProductList({ signal, product_type: 1 }).catch(catchPromiseCancel)
      if (signal.aborted) return
      setProducts(prods.data)
      var userInfo = await fetchUserInfo({ signal }).catch(catchPromiseCancel)
      if (signal.aborted) return
      dispatch(actions.updateUser(userInfo))
    }
    loadInfo()
    return () => {
      controller.abort()
    }
  }, [dispatch, pageActivated])

  async function handleDeposit(payTypeId) {
    const newWindow = window.open('', '_blank')
    try {
      const response = await fetchPurchaseProduct({
        payTypeId: payTypeId,
        productId: selectedDepositItem?.id,
      })
      newWindow.location.href = response?.pay_url
      /** 監聽頁面跳轉回來的事件 */
      document.addEventListener('visibilitychange', onSuccessCallback)
      closeDepositChannelBox()
    } catch (error) {
      console.log(error)
      newWindow.close()
      alert.show(error?.message || '充值失败, 请联系客服!', { position: 'middle' })
    }
  }

  function handleSelect(item) {
    setSelectedDepositItem(item)
    openDepositChannelBox()
  }

  function handlePopupConfirm() {
    setIsShowDepositSuccess(false)
    navigate(-1)
  }

  function handlePopupDismiss() {
    setIsShowDepositSuccess(false)
  }

  function onSuccessCallback() {
    setIsShowDepositSuccess(true)
    document.removeEventListener('visibilitychange', onSuccessCallback)
  }

  return (
    <PageWrapperNonScroll>
      <HeaderWrap>
        <PageModalHeaderBox headerTitle={'钱包'} />
      </HeaderWrap>
      <Wrapper>
        <BalanceWrapper>
          <label>金币余额</label>
          <Balance>{userInfo.coin}</Balance>
        </BalanceWrapper>
        <Section>
          <SectionTitle>请选择</SectionTitle>
          <ButtonListWrapper>
            {products.map((item, i) => (
              <CoinButton key={i} onClick={() => handleSelect(item)}>
                <span>{item.name}</span>
                <img src="account/icon-coin.svg" alt=""></img>
                <PriceText>{item.price}元</PriceText>
              </CoinButton>
            ))}
          </ButtonListWrapper>
        </Section>
        <NoteBox
          text={['支付不成功，请多尝试支付。', '无法拉起支付订单，是由于拉起订单人数较多，请稍后再试。']}
        ></NoteBox>
      </Wrapper>
      <DepositChannelBox selectedDepositItem={selectedDepositItem} onSubmit={handleDeposit} />
      <DepositSuccess isOpen={isShowDepositSuccess} onConfirm={handlePopupConfirm} onDismiss={handlePopupDismiss} />
    </PageWrapperNonScroll>
  )
}
