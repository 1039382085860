import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: var(--component-area-outside-padding, 0);
`

export const InnerWrapper = styled.div`
  width: 100%;
  padding: var(--component-area-inside-padding, 0);
  border: var(--component-area-border, none);
  border-radius: var(--component-area-border-radius, 0);
  background: var(--component-area-background, none);
`
