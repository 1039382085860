import { useState } from 'react'
//
import { fetchBuyVideo, fetchShortList, fetchVideoList } from 'api'
//
import useOpenModal from './hooks/useOpenModal'
//
import { TRUE } from 'constant/common'
//
import Loading from './component/Loading'

import confirmPurchaseImg from './assets/confirm-purchase.png'
import BigConfirmModal from 'component/Modal/BigConfirmModal'
import { useSharedCache } from 'hooks/useDirtyWorkData'

/**
 * @typedef {Object} PurchaseVideoParams
 * @property {import("../../../types/api").SchemaVideoShow | import("../../../types/api").SchemaShortVideoShow} videoInfo
 * @property {'video' | 'short'} type
 */

export default function PurchaseVideo(/** @type {PurchaseVideoParams} */ { videoInfo, type = 'video' }) {
  const { closeHintModal, openPurchaseSuccessModal, openPurchaseFailedModal } = useOpenModal()

  const [isLoading, setIsLoading] = useState(false)

  const { addDirtyData } = useSharedCache()

  const onFetchPurchaseVideo = () => {
    setIsLoading(true)

    fetchBuyVideo({ video_id: videoInfo.id })
      .then(() => {
        if (type === 'video') {
          fetchVideoList({
            id: videoInfo.id,
            detail: TRUE,
            current: 1,
            pageSize: 1,
          }).then((response) => {
            closeHintModal()

            addDirtyData('video', response.data)

            openPurchaseSuccessModal()
          })
        }

        if (type === 'short') {
          fetchShortList({
            id: videoInfo.id,
            detail: TRUE,
            current: 1,
            pageSize: 1,
          }).then((response) => {
            closeHintModal()

            addDirtyData('short', response.data)

            openPurchaseSuccessModal()
          })
        }
      })
      .catch((error) => {
        openPurchaseFailedModal()
      })
      .then(() => {
        setIsLoading(false)
      })
  }

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <BigConfirmModal
          // title="你要购买本项商品吗?"
          // message="确认购买后会自动扣除金币"
          cover={confirmPurchaseImg}
          onCancel={closeHintModal}
          confirmText="确认购买"
          onConfirm={onFetchPurchaseVideo}
        />
        // <Wrapper>
        //   <Box>
        //     <Image />

        //     <Text>你要购买本项商品吗?</Text>
        //     <SmallText>确认购买后会自动扣除金币</SmallText>

        //     <Button onClick={onFetchPurchaseVideo}>确认购买</Button>
        //     <RedText onClick={closeHintModal}>不，谢谢</RedText>
        //   </Box>
        // </Wrapper>
      )}
    </>
  )
}
