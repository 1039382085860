import { useState } from 'react'

import { Wrapper, VideoName, StyledBase64Image, ImageWrapper } from './Styled'
import { useHasPayment } from 'hooks/useHasPayment'
import { usePaymentTypeImage } from 'component/PaymentTypeImages/hooks/usePaymentTypeImage'
import { useNavigateTo } from 'hooks/useNavigateTo'
import { videoPaymentType } from 'constant/video'

/**
 * @typedef {Object} NormalVideoInfo
 * @property {import("component/VideoBox").SchemaVideoShow} videoInfo
 * @property {number} [columns]
 * @property {string} [width]
 * @property {undefined} [shortInfo]
 */

/**
 * @typedef {Object} ShortVideoInfo
 * @property {import("../../../types/api").SchemaShortVideoShow} videoInfo
 * @property {number} [columns]
 * @property {string} [width]
 * @property {{ list: Object[] }} shortInfo
 */

/**
 * 元件 - 影片 Item
 * @param { NormalVideoInfo | ShortVideoInfo } param0
 * @returns
 */
export default function VideoBoxVertical(
  /** @type { NormalVideoInfo | ShortVideoInfo } */ {
    videoInfo,
    width,
    columns = 2,
    shortInfo,
    overflow,
    noTitle,
    textAlign = 'left',
    allowUnload = false,
    noLazy,
    fontSize,
    ...props
  }
) {
  const hasPayment = useHasPayment()
  const isVideo = shortInfo == null
  const { navigateToViewVideo, navigateToViewShort } = useNavigateTo()

  const { title, coverphoto_v } = videoInfo

  const imageJSX = usePaymentTypeImage(videoInfo)

  const [base64Img, setBase64Img] = useState()

  const onVideoBoxClick = () => {
    if (shortInfo == null) {
      navigateToViewVideo({
        ...videoInfo,
        posterImg: `data:image/jpeg;base64, ${base64Img}`,
      })
    } else {
      navigateToViewShort({
        videoList: shortInfo.list,
        videoInfo,
        posterImg: `data:image/jpeg;base64, ${base64Img}`,
      })
    }
  }

  const showPayment = isVideo ? hasPayment : hasPayment && videoInfo.payment_type !== videoPaymentType.FREE

  return (
    <Wrapper {...props} onClick={onVideoBoxClick} width={width} columns={columns}>
      <ImageWrapper>
        <StyledBase64Image
          overflow={overflow}
          aspectRatio={1 / 1.414}
          src={coverphoto_v}
          onBase64ImageLoad={setBase64Img}
          noLazy={noLazy}
          allowUnload={allowUnload}
        />
        {showPayment && imageJSX}
      </ImageWrapper>

      {!noTitle && (
        <VideoName textAlign={textAlign} fontSize={fontSize}>
          {title}
        </VideoName>
      )}
    </Wrapper>
  )
}
