import { Provider, useSelector } from 'react-redux'
import { Provider as AlertProvider } from 'react-alert'

import 'init.css'
import 'App.css'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import 'swiper/css/effect-creative'
import 'swiper/css/effect-fade'

import store from 'redux/store'

import useHero from 'hooks/useHero'

import LaunchSplash from 'component/LaunchSplash'
import Announcement from 'component/Announcement'
import LaunchScreenAd from 'component/Advertisement/LaunchScreen'
import Alert, { ALERT_OPTIONS } from 'component/Alert'
import HintModal, { ReactHintModal } from 'component/Modal/HintModal'
import GlobalModal, { ReactGlobalModal } from 'component/Modal/GlobalModal'
import { AppOutlet } from 'app-layered-layout/AppProvider'
import { GamePopupProvider } from 'hooks/useOpenGamePopup'
import UpdateChecker from 'component/UpdateChecker'
import { report, useAnalyticsPV } from 'hooks/useAnalytics'
import MaintenancePage from 'component/MaintenancePage'
import {
  selectIsShowingFullPageError,
  selectIsShowingLaunchSplash,
  selectIsShowingMaintenancePage,
  selectIsViewedAdvertisement,
} from 'redux/selector/app'
import FullPageError from 'component/FullPageError'
import { useEffect, useRef } from 'react'

if (process.env.REACT_APP_SITE_CODE === 'ald88') {
  require('./App.ald88.css')
}

ReactHintModal.setAppElement('#root')
ReactGlobalModal.setAppElement('#root')

function App() {
  // eslint-disable-next-line
  const heroInfo = useHero()
  useAnalyticsPV()

  const showLaunchSplash = useSelector(selectIsShowingLaunchSplash)
  const showMaintenance = useSelector(selectIsShowingMaintenancePage)
  const showFullPageError = useSelector(selectIsShowingFullPageError)

  const isViewedAdvertisement = useSelector(selectIsViewedAdvertisement)
  const logged = useRef(false)
  useEffect(() => {
    if (isViewedAdvertisement) {
      if (!logged.current) {
        logged.current = true
        report({
          SerialNumber: 1,
          Event: 'app_screen',
          Trigger: '進入首頁',
          Parameters: 'page_title',
          Value: 'cms',
        })
      }
    }
  })

  return (
    <Provider store={store}>
      <GamePopupProvider>
        <AlertProvider template={Alert} {...ALERT_OPTIONS}>
          <div id="app">
            {/* App 內各頁面 */}
            <AppOutlet />

            {/* 公告頁面(分為系統公告 和 彈窗廣告公告) */}
            <Announcement />

            {/* App 入站廣告頁面 */}
            <LaunchScreenAd />

            {/* App 入站讀取資料時的顯示畫面 */}
            {showLaunchSplash && <LaunchSplash />}

            {showMaintenance && <MaintenancePage />}

            {showFullPageError && <FullPageError />}

            {/* App Hint模式 Modal */}
            <HintModal />

            {/* App 全域模式 Modal */}
            <GlobalModal />

            {/* 更新檢查 */}
            <UpdateChecker />
          </div>
        </AlertProvider>
      </GamePopupProvider>
    </Provider>
  )
}

export default App
