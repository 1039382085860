import { StyledButtonSearchMore, TitleDecoration, TitleExtra, TitleSubText, TitleText, Wrapper } from './Styled'

export default function AreaTitle({
  text = '',
  subText = '',
  hasDecoration = false,
  hasMore = false,
  onMore = () => {},
  children = <></>,
  ...props
}) {
  // wrapper has extra left padding when it have decoration
  return (
    <Wrapper {...props} hasDecoration={hasDecoration}>
      {hasDecoration && <TitleDecoration />}
      <TitleText>{text}</TitleText>
      <TitleSubText>{subText}</TitleSubText>
      {hasMore && <StyledButtonSearchMore handleClick={onMore} />}
      <TitleExtra>{children}</TitleExtra>
    </Wrapper>
  )
}
