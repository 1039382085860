import { useCallback, useEffect, useRef } from 'react'
import classnames from 'classnames'
import { useSelector } from 'react-redux'
//
import { selectPictureOrigin } from 'redux/selector/app'
//
import { fetchBinFile, fetchPostAdEvent } from 'api'
//
import { imageParser } from 'utils/imageParser'
//
import { Wrapper, AdImage, BtnCancel } from './Styled'
import { patchUrlProtocol } from 'utils/urlUtils'
import { formatDate } from 'utils/timeFormat'
import { catchPromiseCancel } from 'utils/catchPromiseCancel'

export default function AdItem(info) {
  const pictureOrigin = useSelector(selectPictureOrigin)
  const imgRef = useRef(null)

  const fetchedSrc = useRef(null)
  useEffect(() => {
    const shortcutImagePath = `${pictureOrigin}/${info?.icon_path}`

    if (!pictureOrigin || !info?.icon_path || (imgRef.current.src && fetchedSrc.current === shortcutImagePath)) return

    const controller = new AbortController()

    fetchBinFile({
      url: shortcutImagePath,
      signal: controller.signal,
    })
      .then((textBase64) => {
        fetchedSrc.current = shortcutImagePath
        imgRef.current.src = imageParser(textBase64)
      })
      .catch(catchPromiseCancel)

    return () => {
      controller.abort()
    }
  }, [pictureOrigin, info])

  const handleOnClick = useCallback(() => {
    if (info) {
      fetchPostAdEvent({
        date: formatDate(new Date()),
        material_id: info.id,
        material_name: info.name,
        oholo_site_id: info.site_id,
        oholoer_id: info.oholoer_id,
      })
      const link = patchUrlProtocol(info?.link)
      window.open(link, '_blank')
    } else {
      console.warn('no ad assigned')
    }
  }, [info])

  if (!pictureOrigin) return null

  return (
    <Wrapper
      className={classnames({
        visible: info.currentDisplayAnnouncement.id === info.id,
      })}
    >
      <AdImage ref={imgRef} onClick={handleOnClick} />

      <BtnCancel onClick={info?.handleCancel} />
    </Wrapper>
  )
}
