import Video from './components/Video'
import Short from './components/Short'
import PageTabs, { PageTab } from 'component/PageTabs'

export default function BrowseFavourite({ ...props }) {
  return (
    <PageTabs
      {...props}
      title="我的喜欢"
      tabs={[
        { id: 'video', name: '长视频' },
        { id: 'short', name: '短视频' },
      ]}
    >
      <PageTab>
        <Video />
      </PageTab>
      <PageTab>
        <Short />
      </PageTab>
    </PageTabs>
  )
}
