export class ApiError extends Error {
  constructor(msg, type, start, end) {
    super(msg)
    this.start = start
    this.end = end
    this.type = type
  }

  /**
   * Check if it is a MaintenanceError
   * @param {*} err
   * @returns {boolean}
   */
  static isMaintenanceError(err) {
    if (err == null) return false
    return err.type === ERROR_TYPE.MAINTENANCE
  }

  /**
   * Check if it is a Forbidden
   * @param {*} err
   * @returns {boolean}
   */
  static isForbidden(err) {
    if (err == null) return false
    return err.type === ERROR_TYPE.USER_FORBIDDEN
  }
}

export const ERROR_TYPE = {
  USER_FORBIDDEN: 'user forbidden',
  MAINTENANCE: 'maintenance',
}
