import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  name: 'deposit',
  isOpenDepositChannelBox: false,
}

const slice = createSlice({
  name: 'deposit',
  initialState,
  reducers: {
    openDepositChannelBox: (state, action) => {
      state.isOpenDepositChannelBox = true
    },
    closeDepositChannelBox: (state, action) => {
      state.isOpenDepositChannelBox = false
    },
  },
})

export const { actions } = slice
export default slice.reducer
