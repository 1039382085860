import { useMemo } from 'react'
import { useAppLocation } from './useAppLocation'

export const useAppLocationQuery = () => {
  const location = useAppLocation()
  const query = useMemo(() => {
    const url = new URL(location.search, 'http://localhost')
    return Object.fromEntries([...url.searchParams])
  }, [location.search])
  return query
}
