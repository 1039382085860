import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  width: 7.88rem;
  background-color: #ffffff;
  border-radius: 0.373rem;
  overflow: hidden;
`

export const CoverImage = styled.img`
  width: 7.89rem;
  height: 6.28rem;
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  gap: 0.41rem;
  padding: 1.58rem 1.07rem 0.41rem;
`

export const BaseButton = styled.div`
  font-weight: 400;
  font-size: 0.36rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0.92rem;
  border-radius: 2.56rem;
`
export const ConfirmButton = styled(BaseButton)`
  background: var(--component-modal-big-confirm--confirm-background);
  color: var(--component-modal-big-confirm--confirm-color);
`
export const CancelButton = styled.button`
  background: transparent;
  border: 0;
  font-size: 0.256rem;
  line-height: 130%;
  color: #5a5a5a;
`
