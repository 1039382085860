const CODE_LT = '<'.charCodeAt(0)
const CODE_QUESTION_MARK = '?'.charCodeAt(0)
const CODE_X = 'x'.charCodeAt(0)
const CODE_M = 'm'.charCodeAt(0)
const CODE_L = 'l'.charCodeAt(0)
const CODE_S = 's'.charCodeAt(0)
const CODE_V = 'v'.charCodeAt(0)
const CODE_G = 'g'.charCodeAt(0)

const CODE_R = 'R'.charCodeAt(0)
const CODE_I = 'I'.charCodeAt(0)
const CODE_F = 'F'.charCodeAt(0)
const CODE_W = 'W'.charCodeAt(0)
const CODE_E = 'E'.charCodeAt(0)
const CODE_B = 'B'.charCodeAt(0)
const CODE_P = 'P'.charCodeAt(0)

/**
 * Guess mime from binary, only support a few image format
 * @param {number[]} buf
 * @returns {string|undefined}
 */
export const guessMIME = (buf) => {
  if (buf[0] === 0x89 && buf[1] === 0x50 && buf[2] === 0x4e && buf[3] === 0x47) {
    // Assume perfectly normal png
    return 'image/png'
  }

  if (
    // SOI
    buf[0] === 0xff &&
    buf[1] === 0xd8 &&
    // anything looks like app marker
    buf[2] === 0xff &&
    buf[3] >= 0xe0 &&
    buf[3] <= 0xef
  ) {
    // Assume perfectly normal jpg
    return 'image/jpeg'
  }

  if (buf[0] === 0x47 && buf[1] === 0x49 && buf[2] === 0x46 && buf[3] === 0x38) {
    // Assume perfectly normal gif
    return 'image/gif'
  }

  if (
    buf[0] === CODE_LT &&
    ((buf[1] === CODE_QUESTION_MARK && buf[2] === CODE_X && buf[3] === CODE_M && buf[4] === CODE_L) ||
      (buf[1] === CODE_S && buf[2] === CODE_V && buf[3] === CODE_G))
  ) {
    return 'image/svg+xml'
  }

  if (
    buf[0] === CODE_R &&
    buf[1] === CODE_I &&
    buf[2] === CODE_F &&
    buf[3] === CODE_F &&
    buf[8] === CODE_W &&
    buf[9] === CODE_E &&
    buf[10] === CODE_B &&
    buf[11] === CODE_P
  ) {
    return 'image/webp'
  }

  return undefined
}
