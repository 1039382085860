import { useState } from 'react'

import { Wrapper, VideoName, StyledBase64Image, ImageWrapper, FavoriteIcon, VideoInfo } from './Styled'
import { useNavigateTo } from 'hooks/useNavigateTo'

/**
 * @typedef {Object} NormalVideoInfo
 * @property {import("component/VideoBox").SchemaVideoShow} videoInfo
 * @property {number} [columns]
 * @property {string} [width]
 * @property {undefined} [shortInfo]
 */

/**
 * @typedef {Object} ShortVideoInfo
 * @property {import("../../../types/api").SchemaShortVideoShow} videoInfo
 * @property {number} [columns]
 * @property {string} [width]
 * @property {{ list: Object[] }} shortInfo
 */

/**
 * 元件 - 個人中心短視頻影片 Item
 * @param { NormalVideoInfo | ShortVideoInfo } param0
 * @returns
 */
export default function ShortVideoBox(
  /** @type { NormalVideoInfo | ShortVideoInfo } */ {
    videoInfo,
    width,
    columns = 2,
    shortInfo,
    overflow,
    noTitle,
    textAlign = 'left',
    allowUnload = false,
    noLazy,
    ...props
  }
) {
  // const hasPayment = useHasPayment()
  // const isVideo = shortInfo == null
  const { navigateToViewVideo, navigateToViewShort } = useNavigateTo()

  const { title, coverphoto_v } = videoInfo

  const [base64Img, setBase64Img] = useState()

  const onVideoBoxClick = () => {
    // 使用是否有短視頻資訊來判斷要跳轉哪個頁面
    if (shortInfo == null) {
      navigateToViewVideo({
        ...videoInfo,
        posterImg: `data:image/jpeg;base64, ${base64Img}`,
      })
    } else {
      navigateToViewShort({
        videoList: shortInfo.list,
        videoInfo,
        posterImg: `data:image/jpeg;base64, ${base64Img}`,
      })
    }
  }

  return (
    <Wrapper {...props} onClick={onVideoBoxClick} width={width} columns={columns}>
      <ImageWrapper>
        <StyledBase64Image
          overflow={overflow}
          aspectRatio={1 / 1.414}
          src={coverphoto_v}
          onBase64ImageLoad={setBase64Img}
          noLazy={noLazy}
          allowUnload={allowUnload}
        />
        <VideoInfo>
          <FavoriteIcon></FavoriteIcon>
          {videoInfo.like_count}
        </VideoInfo>
      </ImageWrapper>
      {!noTitle && <VideoName textAlign={textAlign}>{title}</VideoName>}
    </Wrapper>
  )
}
