import { videoFavoriteState, videoLikedState } from 'constant/video'
import { useCallback, useEffect, useRef, useState } from 'react'
import CoverImage from '../CoverImage'
import InfoWrapper from '../InfoWrapper'
import Toolbar from '../Toolbar'
import { StyledCoverImage, StyledShortPlayer, StyledTouchEffect, StyledUploaderInfo, StyledVirtualPage } from './Styled'
import PromotionInfo from '../PromotionInfo'
import { useSelector } from 'react-redux'
import { selectTopicList } from 'redux/selector/app'
import { fetchPostAdEvent } from 'api'
import { formatDate } from 'utils/timeFormat'

const PlayerPage = ({
  mode = 'video',
  videoInfo: videoInfoProp,
  onBase64ImageLoad,
  posterSrc = null,
  onLike,
  onSave,

  promoteInfo,

  preloadedImages,
}) => {
  const [videoInfo, setVideoInfo] = useState(
    /** @type {import('../../../../../types/api').SchemaShortVideoShow | undefined} */ (videoInfoProp)
  )

  if (videoInfoProp != null && videoInfoProp !== videoInfo) {
    setVideoInfo(videoInfoProp)
  }

  /**
   *
   * @param {MouseEvent} ev
   */
  const onDoubleCLick = (ev) => {
    // console.log(ev)
    // console.log(touchEffectRef.current)

    const spawnEffect = touchEffectRef.current?.spawnEffect
    /**
     * @type {HTMLDivElement|undefined}
     */
    const container = touchEffectRef.current?.el
    if (container && spawnEffect) {
      const basePosition = container.getBoundingClientRect()
      const { x, y } = basePosition
      const startRotation = Math.PI * (Math.random() * 2 - 1) * 0.1
      const waveRotation = Math.PI * (Math.random() * 2 - 1) * 0.2
      const offsetX = (Math.random() - 0.5) * 50
      const offsetY = (Math.random() - 0.5) * 50
      const startScale = 0
      const endScale = 1.1 + Math.random() * 0.1
      spawnEffect(
        ev.clientX - x + offsetX,
        ev.clientY - y + offsetY,
        startRotation,
        startRotation + waveRotation,
        startScale,
        endScale
      )
    }

    if (videoInfo.like !== videoLikedState.TRUE) {
      onLike?.()
    }
  }
  const touchEffectRef = useRef()

  /**
   * on promotion page click
   */
  const onClick = useCallback(() => {
    if (promoteInfo) {
      fetchPostAdEvent({
        date: formatDate(new Date()),
        material_id: promoteInfo.id,
        material_name: promoteInfo.name,
        oholo_site_id: promoteInfo.site_id,
        oholoer_id: promoteInfo.oholoer_id,
      })
      window.open(promoteInfo?.link, '_blank')
    }
  }, [promoteInfo])

  const playerRef = useRef()

  useEffect(() => {
    if (mode !== 'video') {
      // console.log('pause player')
      playerRef.current?.currentTime(0)
      playerRef.current?.pause()
    } else {
      // console.log('resume player')
      playerRef.current?.play()
    }
  }, [mode])

  const topicList = useSelector(selectTopicList)

  let topics = []

  if (mode === 'video' && videoInfo) {
    topics =
      videoInfo.topic_ids
        ?.map((id) => {
          const topicItem = topicList.find((t) => t.id === id)
          if (topicItem) {
            return {
              text: '#' + topicItem.name,
              params: {
                topic_ids: String(topicItem.id),
              },
            }
          } else {
            return null
          }
        })
        .filter((text) => text != null) ?? []
  }

  const coverImageItemMain =
    videoInfoProp == null
      ? []
      : posterSrc
      ? [
          <StyledCoverImage
            key={'loaded-' + videoInfoProp.coverphoto_v}
            src={posterSrc}
            onBase64ImageLoad={onBase64ImageLoad}
            base64SrcSource={videoInfoProp.coverphoto_v}
          />,
        ]
      : [
          <StyledCoverImage
            key={videoInfo.coverphoto_v}
            onBase64ImageLoad={onBase64ImageLoad}
            base64SrcSource={videoInfo.coverphoto_v}
          />,
        ]

  const coverArray = [
    ...preloadedImages.map((i) => <StyledCoverImage key={'loaded-' + i.key} src={i.value} base64SrcSource={i.key} />),
    ...coverImageItemMain,
  ]

  return (
    <StyledVirtualPage>
      {videoInfo && (
        <InfoWrapper style={mode === 'video' ? {} : { display: 'none' }}>
          {coverArray}
          <StyledShortPlayer ref={playerRef} videoInfo={videoInfo} onDoubleCLick={onDoubleCLick} />
          <StyledUploaderInfo
            topics={topics}
            uploader={'@' + videoInfo.uploader_name}
            uploaderId={videoInfo.uploader_id}
            videoName={videoInfo.title}
          />
          <StyledTouchEffect ref={touchEffectRef} />
        </InfoWrapper>
      )}
      {mode === 'video' && videoInfo && (
        <Toolbar
          key={videoInfo.id}
          liked={videoInfo.like === videoLikedState.TRUE}
          saved={videoInfo.favorite === videoFavoriteState.TRUE}
          likeCount={videoInfo.like_count}
          saveCount={videoInfo.favorite_count}
          onLike={onLike}
          onSave={onSave}
        ></Toolbar>
      )}
      {mode !== 'video' && promoteInfo && (
        <InfoWrapper onClick={onClick}>
          <CoverImage
            src={posterSrc}
            onBase64ImageLoad={onBase64ImageLoad}
            base64SrcSource={promoteInfo.icon_path}
            source={'picture'}
            style={{ pointerEvents: 'none' }}
          />
          <PromotionInfo promoter="@官方推荐" description={promoteInfo?.name} link={promoteInfo?.link} />
        </InfoWrapper>
      )}
    </StyledVirtualPage>
  )
}

export default PlayerPage
