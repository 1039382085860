import { Button } from 'page/GameDeposit/Styled'
import styled from 'styled-components'

export const Wrapper = styled(Button)`
  position: relative;
  padding: 0.4rem 0.1rem;
  overflow: hidden;
  .button-text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const PromoteFlag = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 0.1rem;
  border-radius: 0rem 0.25rem 0rem 0.1rem;
  color: #fff;
  background: var(--page-game-deposit--promote-tag-background);
  font-size: 0.25rem;
  text-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
`

export const RecommendFlag = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 0.1rem;
  border-radius: 0.25rem 0rem 0.1rem 0rem;
  color: #fff;
  background: var(--page-game-deposit--recommend-tag-background);
  font-size: 0.25rem;
  text-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
`
