import { ColoredIcon } from 'component/ColoredIcon'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  justify-content: center;
  align-items: center;
  font-size: 0.35rem;
  padding: 0 0.2rem;
  margin-bottom: 0.5rem;
  gap: 0.25rem;
  text-align: left;
`

export const CharBlockRow = styled.div`
  display: flex;
`

export const Icon = styled(ColoredIcon).attrs((props) => ({
  src: props.src,
}))`
  width: 0.4rem;
  height: 0.4rem;
  margin-right: 0.1rem;
  vertical-align: middle;
`

export const CharBlock = styled.input`
  background: #ececec;
  display: inline-block;
  width: 0.8rem;
  height: 0.8rem;
  margin-right: 0.2rem;
  text-align: center;
  border-radius: 0.1rem;
`
