import { positions } from 'react-alert'

import useAlertComponent from './hooks/useAlertComponent'

export const ALERT_OPTIONS = {
  timeout: 5000,
  position: positions.TOP_RIGHT,
}

/** 元件 - 提示彈窗(目前比較少用) */
export default function Alert(settings) {
  const AlertComponent = useAlertComponent(settings?.options?.type)

  return <AlertComponent {...settings} />
}
