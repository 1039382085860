import { useDispatch, useSelector } from 'react-redux'
//
import { selectUserInfo } from 'redux/selector/user'
//
import useOpenModal from './hooks/useOpenModal'

import { PageWrapper } from 'commonStyledComponents'
import Carousel from './component/Carousel'
import {
  Wrapper,
  IDBox,
  RegisterBox,
  PageDecoration,
  Area3,
  SmallButton,
  Info,
  ProfileIcon,
  IconShare,
  IconPromote,
  IconAbout,
  CopyButton,
  // ConfigButton,
  // IconSystemConfig,
  IconIdentity,
  ScanIcon,
  ScanButton,
} from './Styled'
import { useCallback, useEffect, useState } from 'react'
import PullToRefresh from 'react-simple-pull-to-refresh'
import { fetchUserInfo } from 'api'
import actions from 'redux/action'
import { useNavigateTo } from 'hooks/useNavigateTo'
import { useToastAlert } from 'hooks/useToastAlert'
import { default as useOpenSystemConfigModal } from '../SystemConfig/hooks/useOpenModal.js'
import IDCardScanner from 'page/SystemConfig/component/IDCardScanner'
import { useAlert, positions } from 'react-alert'
import { fetchFindPreviousAccount } from 'api'
import { FETCH_USER_INFO } from 'redux/constant/user'
import { useAppNavigate } from 'app-layered-layout/useAppNavigate'
import { usePageActivated } from 'app-layered-layout/usePageActivated'
import PagePullLoad from 'component/Loading/PagePullLoad'

export default function Profile() {
  const userInfo = useSelector(selectUserInfo)
  const { navigateToBusinessRelationship, navigateToShare, navigateToAbout } = useNavigateTo()
  const activated = usePageActivated()

  const { openLoginModal } = useOpenModal()

  const dispatch = useDispatch()
  const { toast } = useToastAlert()
  // const defaultName = hasPayment ? userInfo.name : '永久免費'

  const { openIDCard } = useOpenSystemConfigModal()

  const onRefresh = useCallback(async () => {
    const userInfo = await fetchUserInfo()
    dispatch(actions.updateUser(userInfo))
  }, [dispatch])

  const handleCopyUid = useCallback(async () => {
    if (userInfo?.show_id && navigator.clipboard) {
      await navigator.clipboard.writeText(userInfo?.show_id)
      toast('已复制ID至剪贴簿')
    }
  }, [toast, userInfo?.show_id])

  /** userInfo.type 為 1, 是遊客 */
  const isUserLogin = userInfo.type !== 1

  const navigate = useAppNavigate()
  const [scannerShowing, setScannerShowing] = useState(false)
  const alert = useAlert()

  const onScan = (decodedText) => {
    // success, use decodedText
    console.log(decodedText)
    fetchFindPreviousAccount({ code: decodedText })
      .then(() => {
        // 重新使用deviceId獲取用戶資料
        setScannerShowing(false)
        alert.info('欢迎回来', {
          position: positions.MIDDLE,
          offset: '300px',
          timeout: 1500,
        })
        dispatch({ type: FETCH_USER_INFO })
        setTimeout(() => {
          navigate('/', { replace: true, dropStack: true })
        }, 1500)
      })
      .catch((e) => {
        console.log(e)
        if (e.message) {
          alert.info('无法找回会员ID，' + e.message, {
            position: positions.MIDDLE,
            offset: '300px',
            timeout: 1500,
          })
        } else {
          alert.info('无法找回会员ID，请联系客服' + e.message, {
            position: positions.MIDDLE,
            offset: '300px',
            timeout: 1500,
          })
        }
        // openHintModal({
        //   children: FindAccountFailed,
        //   props: {},
        // });
      })
  }
  const onScanError = () => {
    // openHintModal({
    //   children: FindAccountFailed,
    //   props: {},
    // });

    alert.info('辨识失败，这似乎不是正确的身份卡', {
      position: positions.MIDDLE,
      offset: '300px',
      timeout: 1500,
    })
  }

  const [usingFlag, setUsingFlag] = useState(() => localStorage.getItem('DEBUG') != null)

  useEffect(() => {
    if (activated) {
      setUsingFlag(localStorage.getItem('DEBUG') != null)
    }
  }, [activated])

  return (
    <PageWrapper>
      <Wrapper>
        {false && <PageDecoration />}
        <PullToRefresh
          onRefresh={onRefresh}
          pullingContent={<PagePullLoad paused />}
          refreshingContent={<PagePullLoad />}
        >
          {/* <ConfigButton onClick={openSystemConfigModal} className="no-prevent-swipe">
            <IconSystemConfig />
          </ConfigButton> */}
          <ScanButton onClick={() => setScannerShowing(true)}>
            <ScanIcon></ScanIcon>
            找回账号
          </ScanButton>
          <Info>
            <ProfileIcon />
            <IDBox>
              ID: {userInfo?.show_id}
              <CopyButton onClick={handleCopyUid}></CopyButton>
            </IDBox>
            {!isUserLogin && <RegisterBox onClick={openLoginModal}>注册/登入</RegisterBox>}
          </Info>
          <Area3>
            <SmallButton onClick={navigateToShare}>
              <IconShare />
              分享
            </SmallButton>
            <SmallButton onClick={navigateToBusinessRelationship}>
              <IconPromote />
              商务合作
            </SmallButton>
            <SmallButton onClick={navigateToAbout}>
              <IconAbout />
              关于
            </SmallButton>
            <SmallButton onClick={openIDCard}>
              <IconIdentity />
              身份卡
            </SmallButton>
          </Area3>
          {/** 個人中心廣告區 */}
          <Carousel />
          {usingFlag ? (
            <div style={{ color: 'red', lineHeight: '1.5' }}>
              警告：除錯選項使用中
              <br /> Warning: using debug flags
            </div>
          ) : null}
        </PullToRefresh>
      </Wrapper>
      {scannerShowing && (
        <IDCardScanner
          qrCodeSuccessCallback={onScan}
          qrCodeErrorCallback={onScanError}
          onClose={() => setScannerShowing(false)}
        />
      )}
    </PageWrapper>
  )
}
