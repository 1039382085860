import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { TouchEffectContainer, TouchEffectItem } from './Styled'

const PARTICLE_EASE_OUT_DELAY_MS = 100
const PARTICLE_EASE_OUT_TRANSITION_MS = 2000

/**
 * @typedef {Object} Particle
 * @property {number} id
 * @property {number} x
 * @property {number} y
 * @property {number} scale
 * @property {number} opacity 0~1,
 * @property {number} rotation 1~PI*2,rotation in radius
 */

const TouchEffect = forwardRef((props, ref) => {
  const [particles, setParticles] = useState(/** @type {Particle[]} */ ([]))
  const controllerRef = useRef(/** @type {undefined | AbortController} */ (undefined))

  useEffect(() => {
    const controller = new AbortController()
    controllerRef.current = controller
    return () => {
      controller.abort()
      controllerRef.current = null
    }
  }, [])

  const spawnEffect = async (x, y, startRotation = 0, endRotation = 0, startScale = 1, endScale = 1) => {
    const signal = controllerRef.current?.signal
    const id = Math.random()
    /**
     * @type {Particle}
     */
    const particle = {
      id,
      x,
      y,
      rotation: startRotation,
      scale: startScale,
      opacity: 1,
    }
    setParticles((list) => {
      return list.concat(particle)
    })
    await new Promise((resolve) => setTimeout(resolve, PARTICLE_EASE_OUT_DELAY_MS))
    if (signal.aborted) return
    setParticles((list) => {
      return list.map((item) => {
        if (item.id === id) {
          return {
            id,
            x,
            y,
            rotation: endRotation,
            scale: endScale,
            opacity: 0,
          }
        } else {
          return item
        }
      })
    })
    await new Promise((resolve) => setTimeout(resolve, PARTICLE_EASE_OUT_TRANSITION_MS))
    if (signal.aborted) return
    setParticles((list) => {
      return list.filter((item) => {
        return item.id !== id
      })
    })
  }

  const containerRef = useRef()

  useImperativeHandle(ref, () => ({
    spawnEffect,
    el: containerRef.current,
  }))

  return (
    <TouchEffectContainer {...props} ref={containerRef}>
      {particles.map((item, index) => {
        return (
          <TouchEffectItem
            key={index}
            style={{
              opacity: `${item.opacity}`,
              transform: `translate(${item.x}px, ${item.y}px) translate(-50%, -50%) rotate(${item.rotation}rad) scale(${item.scale})`,
            }}
            src={`${process.env.PUBLIC_URL}/short-effect/heart.svg`}
          />
        )
      })}
    </TouchEffectContainer>
  )
})

export default TouchEffect
