import { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import BeatLoader from 'react-spinners/BeatLoader'

import { FETCH_USER_INFO } from 'redux/constant/user'

import HeaderBox from 'component/PageModalHeaderBox'
import { UserNameFieldBox, PasswordFieldBox } from 'component/TextFieldBox'

import { fetchLogin } from 'api'

import { validationSchema } from './validation'
import { ContentBox, BtnSubmit, BtnSwitchLogin, BottomText, StyledPageScrollWrap } from './Styled'
import { HeaderWrap, PageWrapperNonScroll } from 'commonStyledComponents'
import { useNavigateTo } from 'hooks/useNavigateTo'
import { useAppNavigate } from 'app-layered-layout/useAppNavigate'
import { useToastAlert } from 'hooks/useToastAlert'

export default function Login() {
  const dispatch = useDispatch()
  const { navigateToRegister } = useNavigateTo()
  const navigate = useAppNavigate()
  const [isLoading, setIsLogin] = useState(false)

  const { toast } = useToastAlert()

  const { values, errors, handleChange, handleSubmit } = useFormik({
    initialValues: {
      user_name: '',
      password: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape(validationSchema),
    onSubmit: () => {
      handleLogin()
    },
  })

  const openRegisterModal = useCallback(() => {
    // 關閉Modal
    navigateToRegister({}, { replace: true })
  }, [navigateToRegister])

  const handleLogin = () => {
    setIsLogin(true)

    const data = {
      user_name: values.user_name,
      password: values.password,
    }

    fetchLogin(data)
      .then((response) => {
        // 顯示成功訊息
        toast('登入成功')
        // openHintModal({
        //   children: LoginSuccess,
        //   props: {},
        // })

        // 重新使用deviceId獲取用戶資料
        dispatch({ type: FETCH_USER_INFO })

        setIsLogin(false)

        // 關閉Modal
        navigate(-1)
        navigate('/', { replace: true, forceReplaceNonCurrentPage: true })
      })
      .catch((error) => {
        toast('登入失败')
        setIsLogin(false)
      })
  }

  return (
    <PageWrapperNonScroll>
      <HeaderWrap>
        <HeaderBox headerTitle={'登入会员'} />
      </HeaderWrap>
      <StyledPageScrollWrap noPadding>
        <ContentBox>
          <UserNameFieldBox
            name="user_name"
            placeholder="输入帐号"
            error={errors.user_name}
            handleChange={handleChange}
          />

          <PasswordFieldBox
            name="password"
            placeholder={'输入密码'}
            error={errors.password}
            handleChange={handleChange}
          />

          <BtnSubmit type="submit" disabled={isLoading} onClick={handleSubmit}>
            {isLoading ? <BeatLoader size={12} margin={2} color={'#da9dc8'} /> : '登入'}
          </BtnSubmit>

          <BottomText>
            <span>没有帐号</span>
            <BtnSwitchLogin onClick={openRegisterModal}>去注册</BtnSwitchLogin>
          </BottomText>
        </ContentBox>
      </StyledPageScrollWrap>
    </PageWrapperNonScroll>
  )
}
