import EmptyResult from 'component/EmptyResult'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FETCH_SHORT_COLLECTION, RESET_SHORT_COLLECTION } from 'redux/constant/shortCollection'
import { selectShortCollectionData } from 'redux/selector/shortCollection'
import ShortVideoBox from 'component/ShortVideoBox'
import { ShortWrap } from './Styled'
import { InfiniteScrollCmsGridWrapper } from 'commonStyledComponents'

const Short = () => {
  const dispatch = useDispatch()
  const videoShortCollections = useSelector(selectShortCollectionData)

  const runOnce = useRef(false)
  const handleFetchVideoList = useCallback(() => {
    if (!runOnce.current) {
      runOnce.current = true
      dispatch({ type: RESET_SHORT_COLLECTION })
    }

    dispatch({ type: FETCH_SHORT_COLLECTION })
  }, [dispatch])

  const shortInfo = useMemo(
    () => ({
      list: videoShortCollections?.list ?? [],
      updateList: () => dispatch({ type: RESET_SHORT_COLLECTION }),
    }),
    [dispatch, videoShortCollections]
  )

  useEffect(() => {
    dispatch({ type: RESET_SHORT_COLLECTION })
    dispatch({ type: FETCH_SHORT_COLLECTION })
  }, [dispatch])

  return !videoShortCollections?.list?.length && !videoShortCollections.isLoading ? (
    <EmptyResult />
  ) : (
    <ShortWrap>
      <InfiniteScrollCmsGridWrapper
        pageStart={0}
        loadMore={handleFetchVideoList}
        hasMore={videoShortCollections?.hasMore}
        // loader={<div className="loader" key={0}>Loading ...</div>}
        useWindow={false}
      >
        {videoShortCollections?.list?.map((item, i) => (
          <ShortVideoBox key={i} videoInfo={item} columns={3} shortInfo={shortInfo} noTitle>
            {i}
          </ShortVideoBox>
        ))}
      </InfiniteScrollCmsGridWrapper>
    </ShortWrap>
  )
}

export default Short
