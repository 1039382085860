import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { AdvertisementType } from 'constant/advertisement'
import { selectAdInfo } from 'redux/selector/app'
import PopularItem from './component/PopularItem'
import { Wrapper, ItemsWrapper, StyledAreaTitle } from './Styled'

export default function PopularGames({ pageUuid }) {
  const adInfo = useSelector(selectAdInfo)

  /** 熱門遊戲廣告位 */
  const adItems = useMemo(() => {
    return adInfo?.[AdvertisementType.PopularGames]
  }, [adInfo])

  if (!adItems?.length) return null

  return (
    <Wrapper>
      <StyledAreaTitle text="热门游戏" hasDecoration />

      <ItemsWrapper>
        {adItems?.map((item) => (
          <PopularItem key={item?.id} pageUuid={pageUuid} {...item} />
        ))}
      </ItemsWrapper>
    </Wrapper>
  )
}
