import { takeEvery, takeLatest, call, select, put } from 'redux-saga/effects'
import actions from 'redux/action'

import { FETCH_NEW_TOKEN_INFO } from '../constant/user'

function* updateRegisterVerificationCodeTime() {
  yield call(decrease)
}

function* decrease() {
  yield select((state) => state.timer.register.verificationCodeSeconds)
}

function* updateTokenExpired() {
  const tokenInfo = yield select((state) => state.user.tokenInfo)
  const expiredTimestamp = tokenInfo.expires_at

  // 離過期時間剩三分鐘, 則更新token
  const updateTokenSeconds = 3 * 60

  const tokenTimer = yield new Promise((resolve) => {
    const timer = setInterval(() => {
      const current = Date.now() / 1000
      if (expiredTimestamp - current <= updateTokenSeconds) {
        resolve(timer)
      }
    }, 1000)
  })

  yield clearInterval(tokenTimer)
  yield put({ type: FETCH_NEW_TOKEN_INFO })
}

export default function* watchTimer() {
  yield takeEvery(actions.updateRegisterVerificationCodeSeconds, updateRegisterVerificationCodeTime)

  yield takeLatest(actions.updateTokenTimer, updateTokenExpired)
}
