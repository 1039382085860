import { StyledAnimatedIcon, ToolbarButton, ToolbarButtonTextGroup, ToolbarElement, ToolbarSeparator } from './Styled'
import likeAnimationData from './animated-icons/like.json'
import saveAnimationData from './animated-icons/save.json'

const Toolbar = ({ liked = false, saved = false, likeCount = 0, saveCount = 0, onLike, onSave }) => {
  return (
    <ToolbarElement>
      <ToolbarButton onClick={() => onLike?.()}>
        <StyledAnimatedIcon animationData={likeAnimationData} active={liked}></StyledAnimatedIcon>
        <ToolbarButtonTextGroup>
          {likeCount} <br />
          喜欢就点赞
        </ToolbarButtonTextGroup>
      </ToolbarButton>
      <ToolbarSeparator></ToolbarSeparator>
      <ToolbarButton onClick={() => onSave?.()}>
        <StyledAnimatedIcon animationData={saveAnimationData} active={saved}></StyledAnimatedIcon>
        <ToolbarButtonTextGroup>
          {saveCount} <br />
          添加到收藏
        </ToolbarButtonTextGroup>
      </ToolbarButton>
    </ToolbarElement>
  )
}

export default Toolbar
