import { useCallback, useEffect, useState } from 'react'
import { useImmer } from 'use-immer'
import { useSelector } from 'react-redux'
//
// import { selectUserInfo } from "redux/selector/user";
//
import { TRUE } from 'constant/common'
import { fetchVideoList, fetchActorInfoList } from 'api'

import Carousel from './component/Carousel'

import {
  Wrapper,
  Content,
  VideoInfo,
  Text,
  Info,
  InfoItem,
  StyledVideoPlayer,
  VipLink,
  PaidLink,
  ActorTag,
  InfoItemLink,
} from './Styled'
import { selectUserInfo } from 'redux/selector/user'
import { videoPaymentType } from 'constant/video'
import { useOpenPurchaseVideoModal } from 'hooks/useOpenPurchaseVideoModel'
import useOpenModal from './hooks/useOpenModal'
import { useSharedCache } from 'hooks/useDirtyWorkData'
import { useNavigateTo, useParamsOfPage } from 'hooks/useNavigateTo'
import { usePageActivated } from 'app-layered-layout/usePageActivated'
import { useAppNavigate } from 'app-layered-layout/useAppNavigate'
import Recommendation from './component/Recommendation'
import { useToastAlert } from 'hooks/useToastAlert'
import { catchPromiseCancel } from 'utils/catchPromiseCancel'

export default function ViewVideo() {
  const { addDirtyData, useMappedData } = useSharedCache()

  const pageActivated = usePageActivated()
  const videoInfo_ = useParamsOfPage('watch')
  const navigate = useAppNavigate()
  const { navigateToActor, navigateToProducer, navigateToViewVideo } = useNavigateTo()

  /** @type {import("../../../types/api").SchemaVideoShow[]} */
  const [videoInfo] = useMappedData('video', [videoInfo_])

  const [state, updateState] = useImmer({
    actorInfo: {},
    producerVideoCount: null,
    originalVideo: /** @type {null|import("../../../types/api").SchemaVideoShow} */ (null),
  })

  const userInfo = useSelector(selectUserInfo)

  const openPurchaseVideoModal = useOpenPurchaseVideoModal({ videoInfo })
  const { openEnableVipModal } = useOpenModal({ videoInfo })
  const {
    // id,
    video_code,
    show_video_code,
    title,
    actor_ids,
    posterImg,
    producer_name,
    producer_icon,
    producer_id,
    // view_count,
    payment_type,
    product_coin,
    buy_video,
  } = videoInfo

  const validDisplayActorIds = actor_ids?.filter((i) => state.actorInfo[i] != null) ?? []

  const isVIP = userInfo.is_vip === TRUE
  const isBuyVideo = buy_video === TRUE

  /** 影片類型為金幣, 且用戶沒有買過影片 */
  const isCoinVideoNeverBuy = payment_type === videoPaymentType.COIN && !isBuyVideo

  /** 影片類型為VIP, 但用戶非VIP */
  const isVipVideoNotVip = payment_type === videoPaymentType.VIP && !isVIP

  /** 是否顯示番號 */
  const isDisplayVideoCode = show_video_code === TRUE

  useEffect(() => {
    if (!pageActivated) return

    const controller = new AbortController()
    const signal = controller.signal

    fetchVideoList({
      id: videoInfo_?.id,
      detail: TRUE,
      current: 1,
      pageSize: 1,
      signal,
    })
      .then((response) => {
        const data = response?.data[0]

        const producer_video_count = data?.producer_video_count
        const formatProducerVideoCount = producer_video_count ? producer_video_count : 0

        addDirtyData('video', response?.data)

        updateState((draft) => {
          draft.producerVideoCount = formatProducerVideoCount
        })
      })
      .catch(catchPromiseCancel)

    return () => {
      controller.abort()
    }
  }, [pageActivated, videoInfo_, updateState, addDirtyData])

  const actorIds = videoInfo?.actor_ids?.join(',') ?? ''
  const actorIdLength = videoInfo?.actor_ids?.length ?? 0

  useEffect(() => {
    if (actorIds === '') return
    const controller = new AbortController()
    const { signal } = controller
    /** 獲取演員資訊 */
    fetchActorInfoList({
      signal,
      ids: actorIds,
      pageSize: actorIdLength,
    })
      .then((response) => {
        const info = {}
        response?.data?.forEach((item, i) => {
          info[item?.id] = {
            id: item?.id,
            name: item?.name,
            icon_path: item?.icon_path,
          }
        })

        updateState((draft) => void (draft.actorInfo = info))
      })
      .catch(catchPromiseCancel)
    return () => {
      controller.abort()
    }
  }, [actorIdLength, actorIds, updateState])

  const ong_video = videoInfo?.ong_video

  useEffect(() => {
    if (!ong_video) return
    const controller = new AbortController()
    const { signal } = controller
    fetchVideoList({
      signal,
      video_code: ong_video,
    })
      .then((response) => {
        if (response?.data[0]) {
          updateState((draft) => void (draft.originalVideo = response?.data[0]))
        }
      })
      .catch(catchPromiseCancel)
    return () => {
      controller.abort()
    }
  }, [ong_video, updateState])

  const { toast } = useToastAlert()

  const handleOriginalVideoClick = useCallback(() => {
    if (state.originalVideo) {
      navigateToViewVideo(state.originalVideo)
    } else {
      toast('无法取得原片连结')
    }
  }, [navigateToViewVideo, state.originalVideo, toast])

  function handleActorClick(actor) {
    navigateToActor({
      actorId: actor.id,
    })
  }

  function handleProducerClick() {
    navigateToProducer({
      producerId: producer_id,
      producerImage: producer_icon,
      producerName: producer_name,
    })
  }

  return (
    <Wrapper>
      {/* <IconBack onClick={() => closePageModal(uuid)} /> */}

      <Content>
        <div>
          {pageActivated && (
            <StyledVideoPlayer videoInfo={videoInfo} poster={posterImg} skipBigPlayIcon onBack={() => navigate(-1)} />
          )}
        </div>

        {isVipVideoNotVip && <VipLink onClick={openEnableVipModal}>升级VIP会员立享全站高清无限看</VipLink>}
        {isCoinVideoNeverBuy && <PaidLink onClick={openPurchaseVideoModal}>支付{product_coin}金币解锁完整版</PaidLink>}
        <VideoInfo>
          <Text>{title}</Text>
          <Info>
            {producer_name && (
              <InfoItem>
                片商：<InfoItemLink onClick={handleProducerClick}>{producer_name}</InfoItemLink>
              </InfoItem>
            )}
            {isDisplayVideoCode && <InfoItem>番号：{video_code}</InfoItem>}
            {validDisplayActorIds.length > 0 && (
              <InfoItem>
                演员：
                {validDisplayActorIds.map((i) => (
                  <ActorTag onClick={() => handleActorClick(state.actorInfo[i])}>{state.actorInfo[i].name}</ActorTag>
                ))}
              </InfoItem>
            )}
            {ong_video && (
              <InfoItem>
                原片：
                <InfoItemLink onClick={handleOriginalVideoClick}>链結</InfoItemLink>
              </InfoItem>
            )}
          </Info>
          {pageActivated && <Carousel />}
        </VideoInfo>
        {pageActivated && <Recommendation videoInfo={videoInfo} />}
      </Content>
    </Wrapper>
  )
}
