import { useAllComicChapters } from 'hooks/useAllComicChapters'
import { Fragment, useMemo, useState } from 'react'
import ReactModal from 'react-modal'
import { ChapterArea, ChapterItem, CloseButton, SortIcon, SortButton, TitleBar, TitleWrap } from './Styled'

const styles = {
  overlay: {
    zIndex: 100, // higher than page
    backgroundColor: 'rgba(16, 4, 27, 0.5)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'stretch',
    padding: 'calc(env(safe-area-inset-top, 0px) + 1rem) 0 0 0',
  },
  content: {
    position: 'static',
    flexGrow: '1',
    display: 'flex',
    flexDirection: 'column',
    inset: 'unset',
    border: 'none',
    background: 'var(--main-background)',
    padding: 0,
    borderRadius: '5px 5px 0px 0px',
    marginRight: 0,
    color: '#fff',
  },
}

export function Chapters({
  comicId,
  disabled = false,
  isOpen = false,
  onClose = () => {},
  activeChapter = null,
  title = '',
  onOpenChapter = (chapter) => {},
  preloadedList = null,
}) {
  const [sortReversed, setSortReversed] = useState(false)

  const listLive = useAllComicChapters(comicId, !isOpen || preloadedList != null)
  const list = preloadedList ?? listLive

  const listToUse = useMemo(() => {
    if (sortReversed) {
      return list
        .slice(0)
        .map((i, index) => ({ ...i, realIndex: index }))
        .reverse()
    } else {
      return list.slice(0).map((i, index) => ({ ...i, realIndex: index }))
    }
  }, [list, sortReversed])

  return (
    <Fragment>
      {!disabled && (
        <ReactModal isOpen={isOpen} style={styles}>
          <TitleBar>
            <SortButton onClick={() => setSortReversed((v) => !v)}>
              <SortIcon reversed={sortReversed} />
              {sortReversed ? '倒序' : '正序'}
            </SortButton>
            <TitleWrap>{title}</TitleWrap>
            <CloseButton onClick={onClose} />
          </TitleBar>
          <ChapterArea>
            {listToUse.map((item, index) => (
              <ChapterItem
                key={item.id}
                active={
                  activeChapter === null || Number(activeChapter) === 0
                    ? item.realIndex === 0
                    : item.id === activeChapter
                }
                onClick={() => onOpenChapter(item)}
              >
                {item.prefix_name + (item.title ? ` - ${item.title}` : '')}
              </ChapterItem>
            ))}
          </ChapterArea>
        </ReactModal>
      )}
    </Fragment>
  )
}
