// import Success from '../component/Success'
// import Fail from '../component/Fail'
import Info from '../component/Info'

export default function useAlertComponent(type) {
  switch (type) {
    // case 'success':
    //   return Success
    // case 'fail':
    //   return Fail
    case 'info':
      return Info
    default:
      return Info
  }
}
