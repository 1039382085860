import { useCallback, useMemo } from 'react'
import { DisabledCover, GameName, IconWrapper, Wrapper } from './styled'

export default function GameIcon({ id, name, icon, onClick, disabled = false }) {
  const url = useMemo(() => `${icon}`, [icon])
  const baseUrl = process.env.PUBLIC_URL

  const clickAction = useCallback(() => {
    if (!disabled) onClick()
  }, [disabled, onClick])

  return (
    <Wrapper onClick={clickAction}>
      <IconWrapper>
        <img src={url} alt="" onError={(e) => (e.target.src = `${baseUrl}/game/icon-placeholder.svg`)} />
        {disabled && <DisabledCover />}
      </IconWrapper>
      <GameName>{name}</GameName>
    </Wrapper>
  )
}
