import { PrimaryButton } from 'commonStyledComponents'
import styled from 'styled-components'

export const Wrapper = styled.div`
  height: 100%;
  font-size: 14px;
  padding: 10px 8px;
`

export const TextCenter = styled.div`
  text-align: center;
  margin-top: 0.2rem;
`

export const SubmitButton = styled(PrimaryButton)`
  width: 4rem;
`

export const FormErrorText = styled.div`
  width: 4rem;
  margin: 0rem auto;
  color: var(--danger-color);
`
