import core from 'crypto-js/core'
import AesJs from 'crypto-js/aes'
import Utf8Js from 'crypto-js/enc-utf8'
import Base64Js from 'crypto-js/enc-base64'
import cryptoJs from 'crypto-js/crypto-js'
import { unpackTar } from './tarball'

function arrayBufferToBase64(buffer) {
  var binary = ''
  var bytes = new Uint8Array(buffer)
  var len = bytes.byteLength
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return window.btoa(binary)
}

/**
 * @type {typeof core.lib.WordArray}
 */
let WordArray
/**
 * @type {(buf: ArrayBuffer) => core.lib.WordArray}
 */
let arrayBufferToWordArray
/**
 * @type {(wa: core.lib.WordArray) => ArrayBuffer}
 */
let wordArrayToArrayBuffer

if (process.env.NODE_ENV === 'development') {
  WordArray = core.lib.WordArray
  arrayBufferToWordArray = function arrayBufferToWordArray(buffer) {
    const byteLength = Int32Array.BYTES_PER_ELEMENT
    const alignedSize = ~~(buffer.byteLength / byteLength)
    const remainingByte = buffer.byteLength - alignedSize * byteLength
    const uint32View = new Int32Array(buffer, 0, alignedSize)
    const uint8View = new Uint8Array(buffer)
    const needReverse = new Uint8Array(new Uint32Array([1]).buffer)[0] === 1

    let target
    if (!needReverse) {
      target = Array.from(uint32View)
    } else {
      target = []
      for (let i = 0; i < alignedSize; i++) {
        target[i] =
          (uint8View[i * byteLength] << 24) |
          (uint8View[i * byteLength + 1] << 16) |
          (uint8View[i * byteLength + 2] << 8) |
          uint8View[i * byteLength + 3]
      }
    }
    if (remainingByte > 0) {
      const offset = alignedSize * byteLength
      const num = (uint8View[offset] << 24) | (uint8View[offset + 1] << 16) | (uint8View[offset + 2] << 8)
      target.push(num)
    }
    const result = WordArray.create(target, buffer.byteLength)
    return result
  }

  wordArrayToArrayBuffer = function wordArrayToArrayBuffer(wordArray) {
    const buffer = new ArrayBuffer(wordArray.sigBytes)
    const uint8View = new Uint8Array(buffer)

    for (let i = 0; i < uint8View.length; i++) {
      uint8View[i] = (wordArray.words[~~(i / 4)] >> (24 - (i % 4) * 8)) & 0xff
    }

    return buffer
  }
}

export const aesCbcBase64 = async (str) => {
  const fixedKey = 'uuypgrfrgbnmjuik'
  const fixedIv = [0x00, 0x01, 0x05, 0x03, 0x04, 0x05, 0x09, 0x07, 0x08, 0x09, 0x0a, 0x0b, 0x0c, 0x0d, 0x0e, 0x0f]
  if (process.env.NODE_ENV === 'development' && typeof crypto.subtle === 'undefined') {
    // ase polyfill
    const resWork = AesJs.encrypt(str, Utf8Js.parse(fixedKey), {
      mode: cryptoJs.mode.CBC,
      iv: arrayBufferToWordArray(new Uint8Array(fixedIv).buffer),
      padding: cryptoJs.pad.Pkcs7,
    })
    const base64str = resWork.ciphertext.toString(Base64Js)

    const unAes = AesJs.decrypt(
      {
        ciphertext: Base64Js.parse(base64str),
      },
      Utf8Js.parse(fixedKey),
      {
        mode: cryptoJs.mode.CBC,
        iv: arrayBufferToWordArray(new Uint8Array(fixedIv).buffer),
        padding: cryptoJs.pad.Pkcs7,
      }
    )
    const unAesStr = Utf8Js.stringify(unAes)
    console.log(unAes, unAesStr, unAesStr === str)
    return base64str
  }

  const enc = new TextEncoder()
  const mappedKey = await window.crypto.subtle.importKey(
    'raw',
    enc.encode(fixedKey).buffer,
    {
      name: 'AES-CBC',
    },
    true,
    ['encrypt']
  )
  const key = await crypto.subtle.encrypt(
    {
      name: 'aes-cbc',
      iv: new Uint8Array(fixedIv),
    },
    mappedKey,
    enc.encode(str).buffer
  )

  const base64str = arrayBufferToBase64(key)
  return base64str
}

/**
 * @param {ArrayBuffer} arrayBuffer
 * @param {string} key
 * @param {number[]} iv
 */
const unAesCbc = async (arrayBuffer, key, iv) => {
  const enc = new TextEncoder()
  const mappedKey = await window.crypto.subtle.importKey(
    'raw',
    enc.encode(key).buffer,
    {
      name: 'AES-CBC',
    },
    true,
    ['decrypt']
  )

  const result = window.crypto.subtle.decrypt(
    {
      name: 'AES-CBC',
      iv: new Uint8Array(iv),
    },
    mappedKey,
    arrayBuffer
  )

  return result
}
/**
 * @param {ArrayBuffer} arrayBuffer
 * @param {string} key
 * @param {number[]} iv
 */
export const unpackAesImages = async (arrayBuffer, key, iv) => {
  if (process.env.NODE_ENV === 'development' && typeof crypto.subtle === 'undefined') {
    const result = AesJs.decrypt(
      {
        ciphertext: arrayBufferToWordArray(arrayBuffer),
      },
      Utf8Js.parse(key),
      {
        mode: cryptoJs.mode.CBC,
        iv: arrayBufferToWordArray(new Uint8Array(iv).buffer),
      },
      Utf8Js.parse(key)
    )
    // console.log(result)

    const decrypted = wordArrayToArrayBuffer(result)
    const files = unpackTar(decrypted)
    return files
  }
  // console.log(file)
  const decrypted = await unAesCbc(arrayBuffer, key, iv)
  // console.log(decrypted)
  const files = unpackTar(decrypted)
  return files
}

/**
 * @param {ArrayBuffer} arrayBuffer
 * @param {string} key
 * @param {number[]} iv
 */
export const decryptArrayBuffer = async (arrayBuffer, key, iv) => {
  if (process.env.NODE_ENV === 'development' && typeof crypto.subtle === 'undefined') {
    const result = AesJs.decrypt(
      {
        ciphertext: arrayBufferToWordArray(arrayBuffer),
      },
      Utf8Js.parse(key),
      {
        mode: cryptoJs.mode.CBC,
        iv: arrayBufferToWordArray(new Uint8Array(iv).buffer),
      },
      Utf8Js.parse(key)
    )
    // console.log(result)

    const decrypted = wordArrayToArrayBuffer(result)
    return decrypted
  }
  // console.log(file)
  const decrypted = await unAesCbc(arrayBuffer, key, iv)
  // console.log(decrypted)
  return decrypted
}
