import { createSlice } from '@reduxjs/toolkit'
import { AdvertisementType } from 'constant/advertisement'
import { formatDateTime } from 'utils/timeFormat'

const initialState = {
  name: 'Nova',
  hero: {},
  /**
   * 開屏廣告內容
   * @type {null | { item: import('../../../types/api').SchemaOholoMaterialShow, img: string }}
   */
  currentSplashAd: null,
  /** 顯示開屏廣告需要的資料已讀取完成 */
  isReadyToShowSplashAd: false,
  /** App 必需的資料是否已讀取完成 */
  isReadyToEnter: false,
  /** 是否看過廣告 */
  isViewedAdvertisement: false,
  /** 是否正在顯示啟動頁 */
  isShowingLaunchSplash: true,
  /** 是否顯示維護頁 */
  isShowingMaintenancePage: false,
  /** 維護訊息 */
  maintenanceMessageInfo: {
    end: '',
    message: '',
  },
  isShowingFullPageError: false,
  fullPageError: null,
  /** 完整路徑資料，除錯目的 */
  fullOrigins: {
    domain_original: [],
    name_original: [],
    domain_preview: [],
    domain_picture: [],
    domain_comic: [],
    domain_novel: [],
  },
  /** 影片正式路徑 */
  officialOrigin: '',
  /** full origin list */
  /** @type {{ name: string, domain: string }[]} */
  officialOriginList: [
    /*
    {
      name: 'domainName',
      domain: 'do.main'
    }
    */
  ],
  /** 影片預覽路徑 */
  previewOrigin: '',
  /** base64 圖片路徑 */
  pictureOrigin: '',
  /** */
  comicOrigin: '',
  /** */
  novelOrigin: '',
  /** CMS Nav資訊 */
  navigationContent: [],
  /** 標籤列表 */
  tagList: [],
  /** 片商列表 */
  actorList: [],
  /** 地區列表 */
  regionList: [],
  /** 視頻/短視頻主題 */
  topicList: [],
  /** 公告列表 */
  announcementList: [],
  /** 廣告資訊 */
  adInfo: {
    [AdvertisementType.Entrance]: [],
    [AdvertisementType.NavBanner]: [],
    [AdvertisementType.AreaBottom]: [],
    [AdvertisementType.ViewMore]: [],
    [AdvertisementType.AreaCentral]: [],
    [AdvertisementType.ViewVideoBanner]: [],
    [AdvertisementType.Profile]: [],
    [AdvertisementType.Announcement]: [],
    [AdvertisementType.AppCenterBanner]: [],
    [AdvertisementType.AppCenterIcon]: [],
    [AdvertisementType.Category]: [],
  },
  /** 站台資訊 */
  station: {
    chinese_name: '',
    cover_key: '',
    id: 0,
    is_free: false,
    is_video_manager: false,
    is_video_pick_mode: false,
    mode: '',
    name: '',
  },
  routeDetectMessage: '加载中',
}

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    initApp: (state, action) => {
      state = {
        ...initialState,
      }
    },
    updateCurrentSplashAd: (state, action) => {
      state.currentSplashAd = action.payload
    },
    updateReadyToShowSplashAd: (state, action) => {
      state.isReadyToShowSplashAd = action.payload
    },
    updateReadyToEnter: (state, action) => {
      state.isReadyToEnter = action.payload
    },
    updateAppName: (state, action) => {
      state.name = action.payload
    },
    updateHero: (state, action) => {
      state.hero = action.payload
    },
    updateNavigationContent: (state, action) => {
      state.navigationContent = action.payload
    },
    updateTagList: (state, action) => {
      state.tagList = action.payload
    },
    updateRegionList: (state, action) => {
      state.regionList = action.payload
    },
    updateTopicList: (state, action) => {
      state.topicList = action.payload
    },
    updateProducerList: (state, action) => {
      state.producerList = action.payload
    },
    updateViewedAdvertisement: (state, action) => {
      state.isViewedAdvertisement = action.payload
    },
    updateFullOrigins: (state, action) => {
      state.fullOrigins = action.payload
    },
    updatePreviewOrigin: (state, action) => {
      state.previewOrigin = action.payload
    },
    updateOfficialOrigin: (state, action) => {
      state.officialOrigin = action.payload
    },
    updateOfficialOriginList: (state, action) => {
      state.officialOriginList = action.payload
    },
    updatePictureOrigin: (state, action) => {
      state.pictureOrigin = action.payload
    },
    updateComicOrigin: (state, action) => {
      state.comicOrigin = action.payload
    },
    updateNovelOrigin: (state, action) => {
      state.novelOrigin = action.payload
    },
    updateAdInfo: (state, action) => {
      state.adInfo = action.payload
    },
    updateAnnouncementList: (state, action) => {
      state.announcementList = action.payload
    },
    updateStation: (state, action) => {
      state.station = action.payload
    },
    updateRouteDetectMessage: (state, action) => {
      state.routeDetectMessage = action.payload
    },
    updateIsShowingLaunchSplash: (state, action) => {
      state.isShowingLaunchSplash = action.payload
    },
    updateMaintenanceSplash: (state, action) => {
      state.isShowingMaintenancePage = action.payload.visible
      state.maintenanceMessageInfo.end = formatDateTime(action.payload.end) || ''
      state.maintenanceMessageInfo.message = action.payload.message
    },
    updateFullPageError: (state, action) => {
      state.isShowingFullPageError = action.payload.visible
      state.fullPageError = action.payload.error
    },
  },
})

export const { actions } = slice
export default slice.reducer
