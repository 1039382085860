import { PASSWORD_MIN_LENGTH, PASSWORD_MAX_LENGTH } from 'constant/common'
import { Wrapper, ErrorMessage, TextField, TextFieldBox } from '../../Styled'
import { IconAcct } from './Styled'
import { useCallback, useRef } from 'react'

export default function UserNameFieldBox({ handleChange, error, ...otherAttrs }) {
  const inputRef = useRef(null)
  const handleFocus = useCallback(() => {
    try {
      inputRef.current?.focus()
    } catch {}
  }, [inputRef])

  return (
    <Wrapper>
      <TextFieldBox>
        <IconAcct onClick={handleFocus} />

        <TextField
          {...otherAttrs}
          ref={inputRef}
          min={PASSWORD_MIN_LENGTH}
          max={PASSWORD_MAX_LENGTH}
          type="text"
          onBlur={handleChange}
        />
      </TextFieldBox>

      <ErrorMessage>{error}</ErrorMessage>
    </Wrapper>
  )
}
