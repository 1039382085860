export const TRUE = 1
export const FALSE = 2

export const ENABLED = 1
export const DISABLED = 2

export const USERNAME_MIN_LENGTH = 6
export const USERNAME_MAX_LENGTH = 10
export const PASSWORD_MIN_LENGTH = 6
export const PASSWORD_MAX_LENGTH = 10

export const TAG_VIDEO = 1
export const TAG_COMIC = 2

export const VideoTypeEnum = {
  LongVideo: 1,
  ShortVideo: 2,
  Comic: 3,
}

export const NAV_TYPE_NORMAL = 1
export const NAV_TYPE_GAME = 2
export const NAV_TYPE_DISCOVER = 3
export const NAV_TYPE_ACCOUNT = 4
export const NAV_TYPE_APPLICATIONS = 5

export const NAV_ALIGN_LEFT = 1
export const NAV_ALIGN_CENTER = 2
export const NAV_ALIGN_RIGHT = 3
