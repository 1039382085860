/** 視頻的觀看要求 */
export const videoPaymentType = {
  FREE: 1,
  COIN: 2,
  VIP: 3,
}

export const videoLikedState = {
  TRUE: 1,
  FALSE: 2,
}
export const videoFavoriteState = {
  TRUE: 1,
  FALSE: 2,
}
