import { useEffect, useState, useCallback } from 'react'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import actions from 'redux/action'

import { TimerDigit, TimerWrapper } from './Styled'
import { selectIsReadyToEnter } from 'redux/selector/app'
import { useEvent } from 'hooks/useEvent'

export default function Timer({ duration = 5, isAutoIntoLobby = false, onLateLoading = (status) => {} }) {
  const lateLoadingEvent = useEvent(onLateLoading)

  const dispatch = useDispatch()
  const [counter, setCounter] = useState(0)
  const [isTimeout, setIsTimeout] = useState(false)
  const isReadyToEnter = useSelector(selectIsReadyToEnter)

  const [autoEnterLater, setAutoEnterLater] = useState(false)

  const onIntoLobby = useCallback(() => {
    if (isTimeout) {
      if (isReadyToEnter) {
        dispatch(actions.updateViewedAdvertisement(true))
      } else {
        lateLoadingEvent(true)
        setAutoEnterLater(true)
      }
    }
  }, [dispatch, isReadyToEnter, isTimeout, lateLoadingEvent])

  useEffect(() => {
    const id = setInterval(() => {
      setCounter((c) => c + 1)
    }, 1000)

    return () => {
      clearInterval(id)
    }
  }, [])

  // do not allow user to enter if app somehow not finish loading
  const remaining = Math.max(0, duration - counter)

  const lateLoading = !isReadyToEnter && duration - counter <= 0

  useEffect(() => {
    if (lateLoading) {
      // lateLoadingEvent(true)
    } else {
      lateLoadingEvent(false)
    }
  }, [lateLoading, lateLoadingEvent])

  useEffect(() => {
    if (remaining <= 0) {
      setIsTimeout(true)
    }
  }, [remaining])

  useEffect(() => {
    if ((isAutoIntoLobby || autoEnterLater) && isReadyToEnter && remaining === 0) {
      onIntoLobby()
    }
  }, [autoEnterLater, isAutoIntoLobby, isReadyToEnter, onIntoLobby, remaining])

  return (
    <TimerWrapper className={classnames({ isTimeout })} onClick={onIntoLobby}>
      {!isTimeout ? (
        <>
          广告
          {/* 固定寬度以免文字不一樣寬 */}
          <TimerDigit>{remaining}</TimerDigit>
        </>
      ) : (
        '进入'
      )}
    </TimerWrapper>
  )
}
