import Base64Image from 'component/Base64Image'
import styled from 'styled-components'

export const StyledBase64Image = styled(Base64Image)`
  flex: 1 1 auto;
`
export const ImageWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  overflow: hidden;
`
export const Image = styled.img`
  flex: 1 1 auto;
  min-width: 0;
  object-fit: cover;
`
