import { HeaderWrap } from 'commonStyledComponents'
import { NestedPageWrapper, Wrapper } from './Styled'
import { AppOutlet } from 'app-layered-layout/AppProvider'
import Tabs from 'component/Tabs'
import { useAppLocation } from 'app-layered-layout/useAppLocation'
import { useAppNavigate } from 'app-layered-layout/useAppNavigate'
import { TabsType } from 'component/Tabs/Styled'
import { HOME_VIDEO } from 'constant/routers'
import { Navigate } from 'react-router-dom'

const options = [
  {
    id: '/explore/search',
    name: '搜寻',
  },
  {
    id: '/explore/filter',
    name: '推荐',
  },
  {
    id: '/explore/category',
    name: '发现',
  },
]

const HomeVideo = () => {
  const location = useAppLocation()
  const navigate = useAppNavigate()
  const onChange = (p) => {
    navigate(p, { replace: true })
  }

  if (location.pathname === HOME_VIDEO) {
    return <Navigate to={'/explore/filter'} state={location.state} replace />
  }

  return (
    <Wrapper>
      <HeaderWrap>
        <Tabs options={options} value={location.pathname} onChange={onChange} type={TabsType.Underline} />
      </HeaderWrap>
      <NestedPageWrapper>
        <AppOutlet />
      </NestedPageWrapper>
    </Wrapper>
  )
}

export default HomeVideo
