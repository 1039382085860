import { ColoredIcon } from 'component/ColoredIcon'
import styled from 'styled-components'
import MutedIconRaw from './videojs-component/volumn-muted.svg'

export const VideoWrapper = styled.div`
  position: relative;
  height: 5.68rem;

  .video-js .vjs-big-play-button {
    border-radius: 50%;
    width: 2.293rem;
    height: 2.293rem;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    background-image: url(${process.env.PUBLIC_URL}/icon/video-star.png);
    background-size: contain;
    border: none;
    background-color: transparent;
  }

  .video-js .vjs-big-play-button .vjs-icon-placeholder:before {
    display: none;
  }

  .video-js .vjs-control-bar {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
  }
`

export const PromptMessage = styled.div`
  position: absolute;
  bottom: 0.71rem;
  right: 0.71rem;
  font-size: 0.358rem;
  border-radius: 2.56rem;
  padding: 0.1rem 0.41rem;
  line-height: 150%;
  background: var(--page-view-video--preview-button-background);
  color: var(--page-view-video--preview-button-color);
  font-weight: 400;
`

export const PromptMessageSkipArrow = styled(ColoredIcon).attrs({
  src: `${process.env.PUBLIC_URL}/icon/arrow-black-right.svg`,
})`
  margin-left: 0.1rem;
  vertical-align: text-bottom;
  width: 0.41rem;
  height: 0.41rem;
`

export const ColorText = styled.span`
  color: #f8e71c;
`

export const VideoEndedWrapper = styled.div`
  display: none;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.666rem;
  backdrop-filter: blur(5px);
  z-index: 1;

  &.active {
    display: flex;
  }
`

export const Message = styled.span`
  color: white;
  font-size: 0.373rem;
  font-weight: bold;
`

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 0.533rem;
`

const Button = styled.button`
  font-size: 0.358rem;
  border-radius: 2.56rem;
  font-weight: 400;
  padding: 0.2rem 0.41rem;
`

export const ButtonPurchase = styled(Button)`
  width: 5.15rem;
  background: var(--page-view-video--preview-button-background);
  color: var(--page-view-video--preview-button-color);
`
export const IconBack = styled.button`
  position: absolute;
  z-index: 999;
  top: -0.1rem;
  left: -0.2rem;
  width: 1.23rem;
  height: 1.23rem;
  background-image: url(${process.env.PUBLIC_URL}/icon/arrow-white.svg);
  background-size: 33% 33%;
  background-repeat: no-repeat;
  background-position: center;
  filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.25));
`
export const MutedOverlay = styled.button`
  display: none;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  border: 0;
  background: transparent;
  padding: 0;

  &.active {
    display: block;
  }
`

export const UnmuteButton = styled.div`
  position: absolute;
  left: 0.2rem;
  top: 1.1rem;
  background: white;
  color: #2e2e2e;
  font-size: 0.35897rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 0.53846rem */
  letter-spacing: 0.01026rem;
  display: flex;
  align-items: center;
  gap: 0.1rem;
  padding: 0.1rem 0.21rem;
  border-radius: 0.20513rem;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.16);
`

export const MutedIcon = styled(ColoredIcon).attrs({ src: MutedIconRaw })`
  width: 0.76923rem;
  height: 0.76923rem;
`
