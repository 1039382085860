import styled, { css } from 'styled-components'
import InfiniteScroll from 'react-infinite-scroller'
import AreaWrapper from 'component/AreaWrapper'

export const EmptyWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const PageWrapper = styled.div`
  flex: 1;
  overflow: auto;
`

export const StatusBar = styled.div`
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  height: calc(var(--status-bar-height) + env(safe-area-inset-top, 0px));
  line-height: var(--status-bar-height);
  padding-top: env(safe-area-inset-top, 0px);
  color: var(--page-view-comic-statusbar-color);
  text-align: center;
  font-size: 0.48rem;
  font-weight: 700;
`

export const ModalContentWrapper = styled.div`
  padding-top: calc(var(--status-bar-height) + env(safe-area-inset-top, 0px));
`

export const PrimaryButton = styled.button`
  background: var(--button-primary-background);
  color: var(--button-primary-color);
  border-radius: 2.5rem;
  padding: 0.2rem 0.4rem;
  &[disabled] {
    background: var(--button-disabled-background);
  }
`

export const FullWidthPrimaryButton = styled(PrimaryButton)`
  width: 100%;
`

export const TextError = styled.span`
  color: var(--danger-color);
`

export const PageWrapperNonScroll = styled.div`
  flex: 1 1 auto;
  min-width: 0;
  min-height: 0;
  /* overflow: auto; */
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
`

const HeaderPadder = styled.div`
  height: calc(env(safe-area-inset-top, 0px) - 0.21rem);
  min-height: 0;
`

const HeaderWrapRaw = styled.div`
  flex: 0 0 auto;
  padding: 0 0 0.21rem;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 0.21rem;
  background: var(--page-header-background);
  color: var(--page-header-color);
  --component-tabs--color: currentColor;
`

export const HeaderWrap = ({ children, ...props }) => {
  return (
    <HeaderWrapRaw {...props}>
      <HeaderPadder />
      {children}
    </HeaderWrapRaw>
  )
}

export const PageScrollWrap = styled.div`
  flex: 1 1 auto;
  min-height: 0;
  will-change: scroll-position;
  overflow-y: auto;
  ${(props) =>
    !props.noPadding
      ? css`
          padding: var(--cms-grid-row-gap) 0;
        `
      : css``}
`

const gridWrapperStyle = css`
  display: flex;
  flex-wrap: wrap;
  gap: var(--cms-grid-row-gap, 0.186rem) var(--cms-grid-column-gap, 0.186rem);
  padding: 0 var(--cms-grid-column-gap, 0.186rem);
  width: 100%;
`

export const CmsGridWrapper = styled.div`
  ${gridWrapperStyle}
`

export const InfiniteScrollCmsGridWrapper = styled(InfiniteScroll)`
  ${gridWrapperStyle}
`

export const InfiniteScrollWrapper = styled(InfiniteScroll)``

export const AreaPanelCmsGridWrapper = styled(AreaWrapper)`
  display: flex;
  flex-wrap: wrap;
  gap: var(--cms-grid-row-gap, 0.186rem) var(--cms-grid-column-gap, 0.186rem);
  padding-left: var(--cms-grid-column-gap, 0.186rem);
  padding-right: var(--cms-grid-column-gap, 0.186rem);
`
