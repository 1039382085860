export function compareVersion(a, b) {
  const pattern = /^\d+\.\d+\.\d+/
  const v1 = pattern.exec(a)?.[0]
  const v2 = pattern.exec(b)?.[0]
  if (!v1) {
    console.warn('a is unknown format version: ', a)
    return 'unknown'
  }
  if (!v2) {
    console.warn('b is unknown format version: ', b)
    return 'unknown'
  }
  const aArray = v1.split('.').map((m) => parseInt(m))
  const bArray = v2.split('.').map((m) => parseInt(m))

  for (let i = 0; i < 3; i++) {
    const aEle = aArray[i]
    const bEle = bArray[i]
    if (aEle < bEle) {
      return 'less' // a less then b
    }
    if (aEle > bEle) {
      return 'greater' // a greater then b
    }
  }
  return 'equal' // a equal to b
}
