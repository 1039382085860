//
import useSecTagList from './hooks/useSecTagList'
//
import HeaderBox from 'component/PageModalHeaderBox'
import VideoBox from 'component/VideoBox'
import EmptyResult from 'component/EmptyResult'
//
import { useMemo, useState } from 'react'
import { TabsType } from 'component/Tabs/Styled'
import {
  AreaPanelCmsGridWrapper,
  HeaderWrap,
  InfiniteScrollWrapper,
  PageScrollWrap,
  PageWrapperNonScroll,
} from 'commonStyledComponents'
import Tabs from 'component/Tabs'
import { useParamsOfPage } from 'hooks/useNavigateTo'

export default function SecTagList() {
  const [sortIndex, setSortIndex] = useState(0)
  const { headerTitle, params } = useParamsOfPage('video-tag')
  const sortOptions = useMemo(
    () => [
      {
        name: '最新',
        params: {
          sortby: 'on_shelf_at',
        },
      },
      {
        name: '最热',
        params: {
          sortby: 'hot',
        },
      },
    ],
    []
  )

  const { state, handleFetchVideoList } = useSecTagList({
    ...params,
    ...sortOptions[sortIndex].params,
  })

  return (
    <PageWrapperNonScroll>
      <HeaderWrap>
        <HeaderBox headerTitle={headerTitle} />

        <Tabs
          type={TabsType.Underline}
          options={sortOptions.map((option, index) => ({ id: String(index), name: option.name }))}
          value={String(sortIndex)}
          onChange={(indexS) => setSortIndex(Number(indexS))}
        />
      </HeaderWrap>
      {!state?.list?.length && !state.hasMore ? (
        <EmptyResult />
      ) : (
        <PageScrollWrap>
          <InfiniteScrollWrapper
            pageStart={0}
            loadMore={handleFetchVideoList}
            hasMore={state?.hasMore}
            // loader={<div className="loader" key={0}>Loading ...</div>}
            useWindow={false}
          >
            {state?.list?.length > 0 && (
              <AreaPanelCmsGridWrapper>
                {state?.list?.map((item, i) => {
                  return <VideoBox key={i} videoInfo={item} />
                })}
              </AreaPanelCmsGridWrapper>
            )}
          </InfiniteScrollWrapper>
        </PageScrollWrap>
      )}
    </PageWrapperNonScroll>
  )
}
