import { GAME_MESSAGE } from 'constant/game'
import { compareVersion } from 'utils/versionUtil'
import { selectUserInfo } from 'redux/selector/user'
import { useSelector } from 'react-redux'
import { useCallback, useMemo } from 'react'
import { CONFIRM_MODAL_TYPE } from 'component/Modal/ConfirmModal'
import useConfirmModal from './useConfirmModal'
import { getLandingDomains } from 'api'
export function useGameBanCheck(userStatus) {
  const userInfo = useSelector(selectUserInfo)
  const { openConfirm } = useConfirmModal()
  const compareResult = compareVersion(userInfo.register_version, process.env.REACT_APP_MOST_MINI_GAME_ACCESS_VERSION)
  const userCanEnter = !(userStatus.isExist === true && userStatus.status === false) && compareResult !== 'less'

  const blockMessage = compareResult === 'less' ? GAME_MESSAGE.VERSION_TOO_LOW : GAME_MESSAGE.BAN_NOTICE

  const landingUrl = useMemo(() => {
    if (process.env.REACT_APP_LANDING_PAGE_HOST) return 'https://' + process.env.REACT_APP_LANDING_PAGE_HOST
  }, [])

  const validateUser = useCallback(() => {
    if (!userCanEnter) {
      if (compareResult === 'less') {
        openConfirm({
          message: GAME_MESSAGE.VERSION_TOO_LOW,
          type: landingUrl ? CONFIRM_MODAL_TYPE.OK_CANCEL_CUSTOM : CONFIRM_MODAL_TYPE.OK,
          confirmText: '前往官网',
          cancelText: '关闭',
          onConfirm: async () => {
            const sub = window.open('', '_blank')
            try {
              const landing = await getLandingDomains()
              const links = landing.data.map((i) => i.domain_name)
              const selected = links[~~(Math.random() * links.length)]
              if (selected) sub.location.replace(selected)
              else sub.location.replace(landingUrl)
            } catch {
              sub.location.replace(landingUrl)
            }
          },
        })
      } else {
        openConfirm({
          message: GAME_MESSAGE.BAN_NOTICE,
          type: CONFIRM_MODAL_TYPE.OK,
        })
      }
    }
    return userCanEnter
  }, [compareResult, landingUrl, openConfirm, userCanEnter])

  return {
    userCanEnter,
    blockMessage,
    compareResult,
    validateUser,
  }
}
