import axios from 'axios'
import globalStore from 'redux/store'
import { ApiError, ERROR_TYPE } from 'utils/apiError'
import { decryptArrayBuffer } from 'utils/cryoto'
import { getAPI } from 'utils/settings'

const decoder = new TextDecoder('utf8', { fatal: true })
const sloppyDecoder = new TextDecoder('utf8', { fatal: false })

axios.interceptors.response.use(
  async function (response) {
    if (
      (response.config.originalResponseType === 'json' || response.config.originalResponseType === 'text') &&
      response.data
    ) {
      let buffer = response.data
      if (response.headers.encode === '1' || response.headers['content-type'] === 'application/json+encrypted') {
        // remap data here
        try {
          buffer = await decryptArrayBuffer(
            buffer,
            'jkoig9frvbnmjiuy',
            [0x00, 0x01, 0x05, 0x03, 0x04, 0x05, 0x09, 0x07, 0x08, 0x09, 0x0a, 0x0b, 0x0c, 0x0d, 0x0e, 0x0f]
          )
        } catch (err) {
          console.error(err)
        }
      }
      try {
        const text = decoder.decode(buffer)
        response.data = text
        if (response.config.originalResponseType === 'json') {
          try {
            const json = JSON.parse(text)
            response.data = json
            if (process.env.NODE_ENV === 'development' && response.headers.encode === '1') {
              const params = new URLSearchParams(
                Object.entries(response.config.params ?? {}).filter(([k, v]) => v != null)
              ).toString()
              console.groupCollapsed(response.config.url + (params ? `?${params}` : ''))
              console.group('Params')
              console.table(response.config.params)
              console.groupEnd()
              console.group('Response')
              console.log(response.data)
              console.groupEnd()
              console.groupEnd()
            }
          } catch (err) {
            if (process.env.NODE_ENV === 'development') {
              console.warn('bad json', text)
            }
          }
        }
      } catch (err) {
        const trashText = sloppyDecoder.decode(buffer)
        if (process.env.NODE_ENV === 'development') {
          console.warn('bad text', trashText)
        }
        response.data = trashText
      }
    }

    if (response?.data?.error) {
      console.error(response?.data?.error?.message)
      if (response?.data?.error.code === 16888) {
        return Promise.reject(
          new ApiError(
            response.data.error.message,
            ERROR_TYPE.MAINTENANCE,
            response.data.error.start,
            response.data.error.end
          )
        )
      }
      if (response?.data?.error.code === 9996) {
        // ban
        return Promise.reject(new ApiError(response.data.error.message, ERROR_TYPE.USER_FORBIDDEN))
      }
      return Promise.reject(response?.data?.error)
    }

    if (response?.data?.page_result && response?.data?.data === null) {
      console.error('BAD API RESPONSE, paged result with non array data field', response)
      response.data.data = []
    }

    return Promise.resolve(response?.data)
  },
  function (error) {
    return Promise.reject(error)
  }
)

axios.interceptors.request.use(
  function (config) {
    if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_DEV_ENABLE_PROXY) {
      const url = config.url
      const baseUrl = config.baseURL
      const parsed = new URL(url.replace(/^\//, ''), baseUrl ? baseUrl.replace(/\/?$/, '/') : 'http://invalid.host/')
      const newPath = `/server_proxy/${parsed.host}${parsed.pathname}${parsed.search}`
      return {
        ...config,
        url: newPath,
        baseURL: undefined,
      }
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

const validateQueryString = function (method, data) {
  return method === 'GET' ? data : null
}

const validateRequestBody = function (method, data) {
  return method !== 'GET' ? data : null
}

const validateStatus = function (status) {
  return status === 200 || status === 400 || status === 401 || status === 500
}

const setConfig = function (config) {
  const tokenInfo = globalStore.getState()?.user?.tokenInfo
  const authorization = tokenInfo.token_type?.concat(` ${tokenInfo.access_token}`)

  const baseUrl =
    process.env.NODE_ENV === 'production' ? new URL(getAPI(), window.location.origin).toString() : getAPI()

  const {
    baseURL = baseUrl,
    url,
    method = 'GET',
    data = {},
    headers = {},
    mimeType = '',
    timeout = 1000 * 120,
    responseType = 'json', // 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream'
    signal,
  } = config

  return {
    headers: {
      ...headers,
      AuthDog: authorization,
    },
    mimeType,
    responseType: 'arraybuffer',
    originalResponseType: responseType,
    url,
    method,
    baseURL,
    timeout,
    params: validateQueryString(method, data),
    data: validateRequestBody(method, data),
    validateStatus,
    signal,
  }
}

export const fetchApi = function (config) {
  const customizedConfig = setConfig(config)
  return axios(customizedConfig)
}
