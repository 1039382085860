import { useEffect, useState } from 'react'
//
import { FullWidthPrimaryButton, TextError } from 'commonStyledComponents'
import { postGamePaySecurity } from 'api/index'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { Wrapper, FormRow, Input } from './Styled'

export default function CreateWithdrawAccount({ onSuccess }) {
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const { values, errors, handleChange, handleBlur, handleSubmit, isValid, dirty, touched } = useFormik({
    initialValues: {
      legalName: '',
      bankName: '',
      cardCode: '',
      branchName: '',
    },
    // validateOnChange: false,
    validationSchema: Yup.object().shape({
      legalName: Yup.string().required('请输入帐户持有者名称'),
      bankName: Yup.string().required('请输入银行名称'),
      cardCode: Yup.string().required('请输入银行卡号'),
      branchName: Yup.string().required('请输入支行名称'),
    }),
    onSubmit: async () => {
      if (loading) return
      setLoading(true)
      try {
        await submit()
      } finally {
        setLoading(false)
      }
    },
  })

  const submit = async () => {
    const payload = {
      account: values.cardCode,
      bankName: values.bankName,
      branchName: values.branchName,
      legalName: values.legalName,
      remittanceType: 1, // bank
    }
    try {
      setErrorMessage('')
      await postGamePaySecurity(payload)
      onSuccess()
    } catch (ex) {
      console.warn(ex)
      setErrorMessage(ex.message)
    }
  }

  useEffect(() => {}, [])

  return (
    <Wrapper>
      <FormRow>
        <span>真实姓名</span>
        <Input name="legalName" value={values.legalName} onInput={handleChange} onBlur={handleBlur}></Input>
      </FormRow>
      <TextError>{touched.legalName && errors.legalName}</TextError>
      <FormRow>
        <span>银行名称</span>
        <Input name="bankName" value={values.bankName} onInput={handleChange} onBlur={handleBlur}></Input>
      </FormRow>
      <TextError>{touched.bankName && errors.bankName}</TextError>
      <FormRow>
        <span>银行卡号</span>
        <Input name="cardCode" value={values.cardCode} onInput={handleChange} onBlur={handleBlur}></Input>
      </FormRow>
      <TextError>{touched.cardCode && errors.cardCode}</TextError>
      <FormRow>
        <span>开户支行</span>
        <Input name="branchName" value={values.branchName} onInput={handleChange} onBlur={handleBlur}></Input>
      </FormRow>
      <TextError>{touched.branchName && errors.branchName}</TextError>
      <TextError>{errorMessage}</TextError>
      <FullWidthPrimaryButton type="submit" onClick={handleSubmit} disabled={!isValid || !dirty}>
        确定送出
      </FullWidthPrimaryButton>
    </Wrapper>
  )
}
