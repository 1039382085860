import ReactGlobalModal from 'react-modal'
import { useSelector } from 'react-redux'

import isFunction from 'lodash/isFunction'

import { selectGlobalModalData } from 'redux/selector/modal'

import { useModalStyles } from './hooks/useModalStyles'

export default function GlobalModal() {
  const { styles } = useModalStyles()
  const { children: ChildrenComponent, props } = useSelector(selectGlobalModalData)

  const isOpen = isFunction(ChildrenComponent)

  return (
    <>
      {isOpen ? (
        <ReactGlobalModal
          contentLabel="Global Modal"
          isOpen={isOpen}
          // onAfterOpen={afterOpenModal}
          style={styles}
          shouldCloseOnOverlayClick={false}
          // onRequestClose={closeModal}
        >
          <ChildrenComponent {...props} />
        </ReactGlobalModal>
      ) : (
        <></>
      )}
    </>
  )
}

export { ReactGlobalModal }
