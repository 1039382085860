import useHintModal from 'hooks/useHintModal'

import ShareCard from '../component/ShareCard'

export default function useOpenModal() {
  const { openHintModal } = useHintModal()

  const openShareCard = (url) => {
    openHintModal({
      children: ShareCard,
      props: { url },
    })
  }

  return {
    openShareCard,
  }
}
