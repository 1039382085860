import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { selectStation } from 'redux/selector/app'

import { useNavigationLocalData } from './hooks/useNavigationLocalData'

import { Wrapper, TagA, Nav, NavIcon, NavAesIcon, NavText } from './Styled'

/** 元件 - Bottom Navigation */
export default function Navigation() {
  const location = useLocation()
  const { navContent, handleNavChange } = useNavigationLocalData()
  const station = useSelector(selectStation)

  return (
    <Wrapper>
      {navContent?.map(({ name, imageName, navLink, dynamicIconActive, dynamicIconInactive, search, loose }, i) => {
        const isNavActive = loose
          ? location?.pathname.startsWith(navLink)
          : location?.pathname === navLink && location.search === search

        return (
          <TagA key={i} to={navLink + search} onClick={() => handleNavChange(i)} replace>
            <Nav>
              {!dynamicIconActive ? (
                <NavIcon active={isNavActive} imageName={imageName} />
              ) : (
                <NavAesIcon
                  source={process.env.REACT_APP_NAV_IMAGE_SOURCE}
                  decryptKey={station.cover_key}
                  src={isNavActive ? dynamicIconActive : dynamicIconInactive}
                />
              )}
              <NavText active={isNavActive}>{name}</NavText>
            </Nav>
          </TagA>
        )
      })}
    </Wrapper>
  )
}
