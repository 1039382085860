import { JumbotronWrap } from './styled'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination } from 'swiper/modules'
import 'swiper/css'
import { useCallback } from 'react'

export default function Jumbotron({ list }) {
  const handleClick = useCallback((item) => {
    if (item.url) {
      window.open(item.url, '_blank')
    }
  }, [])

  return (
    <JumbotronWrap>
      <Swiper
        style={{ height: '100%' }}
        loop={true}
        autoplay={{ delay: 2500 }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination]}
      >
        {list?.map((item, i) => {
          return (
            <SwiperSlide key={i} onClick={() => handleClick(item)}>
              <img src={item.photoUrl} alt=""></img>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </JumbotronWrap>
  )
}
