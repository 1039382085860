import { useCallback, useRef } from 'react'
import { useImmer } from 'use-immer'

import { fetchComicListByWords, fetchShortListByWords, fetchVideoListByWords } from 'api'
import { useNavigateTo, useParamsOfPage } from 'hooks/useNavigateTo'
import { useContentTab } from 'hooks/useContentTab'

const SEARCH_APIS = {
  video: fetchVideoListByWords,
  short: fetchShortListByWords,
  comic: fetchComicListByWords,
}

export default function useViaSpecific() {
  const { in: urlMode, keyword: searchTitle } = useParamsOfPage('explore/search')
  const { navigateToSearchViaSpecific } = useNavigateTo()

  const { tabs } = useContentTab()

  const currentMode = urlMode ?? tabs[0]?.id

  const [state, updateState] = useImmer({
    current: null,

    isLoading: false,

    nextPage: 1,
    hasMore: true,
    list: [],

    page_result: { current: null, pageSize: null, total: null },
  })

  const handleFetchVideoList = useCallback(() => {
    if (state.isLoading || !state.hasMore) return

    activeRequests.current?.abort()
    const con = (activeRequests.current = new AbortController())

    const data = {
      current: state.nextPage,
      title: searchTitle,
      signal: con.signal,
    }

    updateState((draft) => {
      draft.isLoading = true
    })

    SEARCH_APIS[currentMode](data).then((response) => {
      if (activeRequests.current === con) {
        activeRequests.current = null
      }

      updateState((draft) => {
        draft.list = draft?.list?.concat(response?.data)
        draft.hasMore = response?.page_result?.total > response?.page_result?.current * response?.page_result?.pageSize
        draft.nextPage = response?.page_result?.current + 1
        draft.page_result = response.page_result

        draft.isLoading = false
      })
    })
  }, [currentMode, searchTitle, state.hasMore, state.isLoading, state.nextPage, updateState])

  const activeRequests = useRef()

  const onSearch = useCallback(
    (text, mode = currentMode) => {
      if (text) {
        if (searchTitle) {
          navigateToSearchViaSpecific(
            {
              in: mode,
              keyword: text,
            },
            { replace: true }
          )
        } else {
          navigateToSearchViaSpecific(
            {
              in: mode,
              keyword: text,
            },
            { replace: true }
          )
        }

        updateState((draft) => {
          draft.nextPage = 1
          draft.hasMore = true
          draft.list = []

          draft.isLoading = true
        })

        activeRequests.current?.abort()
        const con = (activeRequests.current = new AbortController())

        SEARCH_APIS[mode]({
          current: 1,
          title: text,
          signal: con.signal,
        }).then((response) => {
          if (activeRequests.current === con) {
            activeRequests.current = null
          }
          updateState((draft) => {
            draft.list = draft?.list?.concat(response?.data)
            draft.hasMore =
              response?.page_result?.total > response?.page_result?.current * response?.page_result?.pageSize
            draft.nextPage = response?.page_result?.current + 1
            draft.page_result = response.page_result

            draft.isLoading = false
          })
        })
      }
    },
    [currentMode, navigateToSearchViaSpecific, searchTitle, updateState]
  )

  const onHistoryClick = onSearch

  const onCancel = useCallback(() => {
    navigateToSearchViaSpecific(
      {
        in: currentMode,
        keyword: undefined,
      },
      { replace: true }
    )
  }, [currentMode, navigateToSearchViaSpecific])

  const onChangeMode = useCallback(
    (mode) => {
      if (searchTitle) {
        onSearch(searchTitle, mode)
      } else {
        navigateToSearchViaSpecific(
          {
            in: mode,
            keyword: undefined,
          },
          { replace: true }
        )
      }
    },
    [navigateToSearchViaSpecific, onSearch, searchTitle]
  )

  return {
    state,
    updateState,
    onSearch,
    onCancel,
    mode: currentMode,
    searchTitle,
    onChangeMode,
    onHistoryClick,
    handleFetchVideoList,
  }
}
