import { fetchComicChapters } from 'api'
import { useEffect } from 'react'
import { useImmer } from 'use-immer'

export const useAllComicChapters = (comicId, disabled) => {
  const apiParams = {
    comic_id: comicId,
  }

  const [state, updateState] = useImmer(() => ({
    currentOptions: apiParams,
    list: [],
    nextPage: 1,
    hasMore: true,
    page_result: {},
  }))

  useEffect(() => {
    const fetcherController = new AbortController()

    if (disabled) {
      return
    }

    if (!state.hasMore) {
      return
    }

    const data = {
      signal: fetcherController.signal,
      current: state.nextPage,
      pageSize: 100,
      ...state.currentOptions,
    }

    fetchComicChapters(data).then((response) => {
      updateState((draft) => {
        draft.list = draft.list.concat(response?.data)
        draft.hasMore = response?.page_result?.total > response?.page_result?.current * response?.page_result?.pageSize
        draft.nextPage = response?.page_result?.current + 1
        draft.page_result = response.page_result
      })
    })

    return () => {
      fetcherController.abort()
    }
  }, [disabled, state.currentOptions, state.hasMore, state.nextPage, updateState])

  return state.list
}
