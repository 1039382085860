import { useEffect, useState } from 'react'

import { fetchVideoList, fetchVideoTotalQty } from 'api'

import VideoBox from 'component/VideoBox'

import { Wrapper, Title } from './Styled'
import { CmsGridWrapper } from 'commonStyledComponents'
import shuffle from 'lodash/shuffle'

const QUANTITY_TO_SHOW = 20

export default function Recommendation({ videoInfo }) {
  const currentVideo = videoInfo.id
  const [randomVideoList, setRandomVideoList] = useState([])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    fetchVideoTotalQty({ signal }).then((res) => {
      const count = res.page_result.total
      const page = Math.floor(((count - QUANTITY_TO_SHOW) / QUANTITY_TO_SHOW) * Math.random()) + 1

      fetchVideoList({ signal, current: page, pageSize: QUANTITY_TO_SHOW }).then((res) => {
        const result = shuffle(res.data ?? [])

        setRandomVideoList(result)
      })
    })

    return () => {
      controller.abort()
    }
  }, [])

  const filteredVideos = randomVideoList.filter((i) => i.id !== currentVideo)

  if (filteredVideos.length === 0) return null

  return (
    <Wrapper>
      <Title hasDecoration text="为你推荐" />

      <CmsGridWrapper>
        {filteredVideos?.map((item, i) => (
          <VideoBox key={i} videoInfo={item} />
        ))}
      </CmsGridWrapper>
    </Wrapper>
  )
}
