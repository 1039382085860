import lottie from 'lottie-web'
import { useEffect, useRef } from 'react'
import animationData from './loading.json'
import { Wrapper } from './styled'

export default function Loading({ lockLandScape, ...props }) {
  const ref = useRef()
  const animationInst = useRef()

  useEffect(() => {
    if (ref.current) {
      const animation = lottie.loadAnimation({
        container: ref.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData,
      })

      animationInst.current = animation

      return () => {
        animation.destroy()
        animationInst.current = null
      }
    }
    // IT IS INTENTIONAL TO MISS active HERE, DO NOT ADD
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animationData])

  return (
    <Wrapper lockLandScape={lockLandScape}>
      <div ref={ref}></div>
      {props.children}
    </Wrapper>
  )
}
