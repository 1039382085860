import type videojs from 'video.js'
import type Component from 'video.js/dist/types/component'
import './vjs-end-card.css'

type VideoJs = typeof videojs

let installed = false

export interface EndCard extends Component {
  setPaymentType(hasPayment: boolean, text: string): void
}

export const addEndCard = (videojs: VideoJs): undefined => {
  if (installed) {
    return
  }

  installed = true

  const Component = videojs.getComponent('Component')

  const getRootShim = (card: EndCard) => {
    return (card as any).root as HTMLDivElement
  }
  const setRootShim = (card: EndCard, button: HTMLDivElement) => {
    return ((card as any).root = button)
  }
  const getButtonShim = (card: EndCard) => {
    return (card as any).button as HTMLButtonElement
  }
  const setButtonShim = (card: EndCard, button: HTMLButtonElement) => {
    return ((card as any).button = button)
  }

  class EndCard extends Component implements EndCard {
    createEl() {
      const button = videojs.dom.createEl(
        'button',
        {
          className: 'vjs-end-card--button',
        },
        {},
        []
      ) as HTMLButtonElement

      const root = videojs.dom.createEl(
        'div',
        {
          // Prefixing classes of elements within a player with "vjs-"
          // is a convention used in Video.js.
          className: 'vjs-end-card',
        },
        {},
        [button]
      ) as HTMLDivElement

      setRootShim(this, root)
      setButtonShim(this, button)

      button.addEventListener('click', () => {
        this.trigger('button-click')
      })

      return root
    }

    setPaymentType(hasPayment: boolean, text: string) {
      if (!hasPayment) {
        getRootShim(this).classList.remove('vjs-end-card--active')
        getButtonShim(this).textContent = text
      } else {
        getRootShim(this).classList.add('vjs-end-card--active')
        getButtonShim(this).textContent = text
      }
    }
  }

  videojs.registerComponent('EndCard', EndCard)
}
