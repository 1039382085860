import { forwardRef } from 'react'
import styled, { keyframes } from 'styled-components'

export const PlayerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: stretch;
  justify-content: stretch;

  &.loading video-js {
    opacity: 0;
  }

  .pre-render video {
    transform: translateX(-100%);
  }
`

export const PlayerLoadingIndicator = styled.div`
  .loading & {
    display: block;
  }
  display: none;
  height: 0.1rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`

const move = keyframes`
  from {
    left: calc(0% - 128px);
  }
  to {
    left: 100%;
  }
`

export const PlayerLoadingIndicatorInner = styled.div`
  height: 2px;
  width: 128px;
  position: absolute;
  top: 0;
  left: 0;
  animation: ${move} 1s linear infinite;
  background: white;
`

export const StyledVideoJs = styled(
  forwardRef(({ children, className, ...props }, ref) => {
    return (
      <video-js {...props} class={className} ref={ref}>
        {children}
      </video-js>
    )
  })
)`
  min-width: 0;
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;
  width: 100%;
  background: transparent;

  video {
    background: black;
  }

  /* > video {
    object-fit: cover;
  } */

  /* safari trigger waiting when speed change */
  &.vjs-waiting.pre-render {
    opacity: 0;
    pointer-events: none;
  }

  .vjs-control-bar {
    transform: translateY(calc(50% - 1px));
    opacity: 1 !important;
    pointer-events: all !important;
    background: transparent;
    z-index: 3;
  }

  .vjs-progress-holder {
    margin: 0 !important;
    background: transparent;
  }
  .vjs-load-progress {
    opacity: 0;
  }
  .vjs-play-progress {
    background-color: white;
  }
  .vjs-play-progress::before {
    color: white;
  }
  .vjs-poster {
    background-color: transparent;
  }

  &.vjs-user-inactive .vjs-play-progress::before {
    color: transparent;
  }
`
