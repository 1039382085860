import styled, { keyframes } from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  padding: 0.125rem;
  gap: 5px;
  align-items: center;
  img {
    width: 0.45rem;
    height: 0.45rem;
    object-fit: cover;
  }
`

export const Animation = keyframes`
    0%{
      transform: translateX(100%);

    }
    100%{
      transform: translateX(-100%);
    }
`

export const MarqueeText = styled.div`
  position: relative;
  flex-grow: 1;
  height: 0.45rem;
  white-space: nowrap;
  line-height: 0.45rem;
  overflow: hidden;

  .marquee-text {
    position: absolute;
    min-width: 100%;
    overflow: visible;
  }
  span {
    display: inline-block;
    overflow: auto;
    padding: 0 5px;
  }
  @keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
`
