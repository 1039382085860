import Base64Image from 'component/Base64Image'
import styled from 'styled-components'

export const AdImage = styled(Base64Image).attrs({
  placeholder: `${process.env.PUBLIC_URL}/loading/square-loading.svg`,
  source: 'picture',
  aspectRatio: 374 / 94,
})`
  width: 100%;
  border-radius: 0.2564rem;
`
