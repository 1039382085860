import { animated, useSpring, easings } from '@react-spring/web'
import ReactHintModal from 'react-modal'
import { useSelector } from 'react-redux'

import isFunction from 'lodash/isFunction'

import { selectHintModalData } from 'redux/selector/modal'

import useHintModal from 'hooks/useHintModal'

import { useModalStyles } from './hooks/useModalStyles'

export default function HintModal() {
  const { styles } = useModalStyles()
  const { closeHintModal } = useHintModal()
  const { children: ChildrenComponent, props } = useSelector(selectHintModalData)

  const isOpen = isFunction(ChildrenComponent)

  const springStyles = useSpring({
    config: { duration: 150, easing: easings.easeInCubic },
    width: '100%',
    height: '100%',
    opacity: isOpen ? 1 : 0.2,
    scale: isOpen ? 1 : 0.6,
  })

  return (
    <>
      {isOpen ? (
        <ReactHintModal
          contentLabel="Hint Modal"
          isOpen={isOpen}
          // onAfterOpen={afterOpenModal}
          style={styles}
          shouldCloseOnOverlayClick={true}
          onRequestClose={closeHintModal}
        >
          <animated.div style={springStyles}>
            <ChildrenComponent {...props} />
          </animated.div>
        </ReactHintModal>
      ) : (
        <></>
      )}
    </>
  )
}

export { ReactHintModal }
