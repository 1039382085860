import { ReactComponent as ArrayR } from './assets/arrow_r.svg'
import styled from 'styled-components'

export const Wrapper = styled.button`
  display: inline-flex;
  align-items: center;
  font-size: 0.3589rem;
  gap: 0.1rem;
  color: var(--component-button-search-more--color);
`

export const TitleMoreText = styled.div``

export const TitleMoreArrow = styled(ArrayR)`
  height: 0.41rem;
  width: 0.41rem;
`
