import { useMemo, useEffect, useState, useCallback } from 'react'
//
import { HeaderWrap, PageScrollWrap, PageWrapperNonScroll, TextError } from 'commonStyledComponents'
import {
  fetchGameBalanceAll,
  fetchGameParameters,
  fetchGamePaySecurity,
  postGamePayWithdraw,
  postTransferV2,
} from 'api/index'
import useConfirmModal from 'hooks/useConfirmModal'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { CardWrapper, Input, IconInputWrapper, Wrapper, RightAlignHintText, TransferShortcut } from './Styled'
import RefreshButton from 'page/GameLobby/component/RefreshButton'

import CreateWithdrawAccount from './CreateWithdrawAccount'
import WithdrawCode from './WithdrawCode'

import { FullWidthPrimaryButton } from 'commonStyledComponents'
import { formatAmount } from 'utils/amountFormat'
import PasswordInput from './components/PasswordInput'
import PageModalHeaderBox from 'component/PageModalHeaderBox'
import { useAppNavigate } from 'app-layered-layout/useAppNavigate'
import { IconDollars } from 'page/GameDeposit/Styled'
import PageLoading from 'component/Loading/PageLoading'
import { useToastAlert } from 'hooks/useToastAlert'
import { useNavigateTo } from 'hooks/useNavigateTo'
import { CONFIRM_MODAL_TYPE } from 'component/Modal/ConfirmModal'

const WITHDRAW_TAB = {
  PIN_SETTING: 1,
  CREATE_ACCOUNT: 2,
  WITHDRAW: 3,
  EMPTY: 0,
}

export default function GameWithdraw() {
  const navigate = useAppNavigate()
  const [tab, setTab] = useState(WITHDRAW_TAB.EMPTY)
  const { openConfirm } = useConfirmModal()
  const [loading, setLoading] = useState(false)
  const [withdrawSetting, setWithdrawSetting] = useState({
    min: 1,
  })
  const { navigateToGameTransfer } = useNavigateTo()

  const { values, errors, handleChange, handleSubmit, handleBlur, isValid, touched } = useFormik({
    initialValues: {
      amount: '',
      pin: '',
    },
    // validateOnChange: false,
    validationSchema: Yup.object().shape({
      amount: Yup.number().required('请输入取款金额').min(withdrawSetting.min, '取款金额应大于${min}'),
      pin: Yup.string().required('请输入密码'),
    }),
    onSubmit: async () => {
      await submitWithdraw()
    },
  })

  const [accountList, setAccountList] = useState([])
  const [balance, setBalance] = useState(0)
  const [errorMessage, setErrorMessage] = useState('')
  const { toast } = useToastAlert()

  const cardInfoList = useMemo(() => {
    return accountList.map((m, i) => (
      <CardWrapper key={i}>
        <div className="card-title">{m.bankName}</div>
        <div className="card-holder">{m.legalName}</div>
        <div className="card-code">{m.account}</div>
      </CardWrapper>
    ))
  }, [accountList])

  async function submitWithdraw() {
    const payload = {
      amount: values.amount.toString(),
      pin: values.pin,
      remittanceType: 1,
    }
    setErrorMessage('')
    if (loading) {
      toast('请勿重复提交')
      return
    }
    try {
      setLoading(true)
      // 取回餘額須先行轉回平台
      await postTransferV2({
        currency: 1,
        tpCode: '',
      })
      await postGamePayWithdraw(payload)
      // 訊息顯示時間拉長
      openConfirm({
        message: '申请提交成功',
        type: CONFIRM_MODAL_TYPE.OK,
      })
      navigate(-1)
    } catch (ex) {
      if (ex && ex.message) {
        setErrorMessage(ex.message)
        toast(ex.message)
      } else {
        toast('发生错误')
      }
      console.warn(ex)
    } finally {
      setLoading(false)
    }
  }

  const handleRefreshBalance = useCallback(async () => {
    try {
      const data = await fetchGameBalanceAll()
      setBalance(data.total)
    } catch (ex) {
      console.warn(ex)
      ex?.message && toast(ex?.message)
    }
  }, [toast])

  const handleDoTransfer = useCallback(async () => {
    try {
      await postTransferV2({ currency: 1 })
    } catch (ex) {
      console.warn(ex)
      ex?.message && toast(ex?.message)
    }
  }, [toast])

  function handleCreateAccountSuccess() {
    toast('设定成功')
    handleLoadInfo()
  }

  function handlePinSettingSuccess() {
    toast('设定成功')
    handleLoadInfo()
  }

  // 提款檢查順序 設定PIN碼 > 新增帳戶 > 取款頁
  const handleLoadInfo = useCallback(async () => {
    try {
      handleRefreshBalance()
      const info = await fetchGamePaySecurity()
      if (!info.payment_pin) {
        // boolean
        setTab(WITHDRAW_TAB.PIN_SETTING)
      } else if (!info.data || !info.data.length) {
        // array
        setTab(WITHDRAW_TAB.CREATE_ACCOUNT)
      } else {
        setTab(WITHDRAW_TAB.WITHDRAW)
      }
      setAccountList(info.data)

      const params = await fetchGameParameters().catch((ex) => ex?.message && toast(ex?.message))
      if (params) {
        setWithdrawSetting({
          min: params.WITHDRAWAL_LOWER_LIMIT,
        })
      }
    } catch (ex) {
      ex?.message && toast(ex?.message)
    }
  }, [handleRefreshBalance, toast])

  const handlePageMount = useCallback(async () => {
    /**
     * action sequence of api call after page mounted
     * 1. transfer
     * 2. get balance
     * 3. get withdraw account
     */
    await handleDoTransfer()
    await handleLoadInfo()
  }, [handleDoTransfer, handleLoadInfo])

  function toGameTransfer() {
    navigateToGameTransfer()
  }

  function handleClose({ close }) {
    let message = '确定放弃填写？'
    openConfirm({
      title: '',
      message: message,
      onConfirm: () => {
        close()
      },
    })
  }

  useEffect(() => {
    handlePageMount()
  }, [handlePageMount])

  let title = ''
  let page = ''
  if (tab === WITHDRAW_TAB.CREATE_ACCOUNT) {
    title = '新增取款帐户'
    page = <CreateWithdrawAccount onSuccess={handleCreateAccountSuccess}></CreateWithdrawAccount>
  } else if (tab === WITHDRAW_TAB.PIN_SETTING) {
    title = '设置取款密码'
    page = <WithdrawCode onSuccess={handlePinSettingSuccess}></WithdrawCode>
  } else if (tab === WITHDRAW_TAB.WITHDRAW) {
    title = '取款'
    page = (
      <Wrapper>
        <div>{cardInfoList}</div>
        <div>
          余额｜￥{formatAmount(balance)}
          <RefreshButton
            img={`${process.env.PUBLIC_URL}/icon/refresh.svg`}
            onClick={handleRefreshBalance}
          ></RefreshButton>
        </div>
        <IconInputWrapper>
          <IconDollars className="input-icon" />
          <Input
            name="amount"
            type="number"
            value={values.amount}
            placeholder="请输入取款金额"
            onChange={handleChange}
            onBlur={handleBlur}
          ></Input>
        </IconInputWrapper>
        <RightAlignHintText>提现最小金额 {formatAmount(withdrawSetting.min)}</RightAlignHintText>
        <TextError>{touched.amount && errors.amount}</TextError>
        <PasswordInput name="pin" value={values.pin} onChange={handleChange} onBlur={handleBlur}></PasswordInput>
        <TextError>{touched.pin && errors.pin}</TextError>
        <TextError>{errorMessage}</TextError>
        <FullWidthPrimaryButton type="submit" onClick={handleSubmit} disabled={!isValid}>
          提交申请
        </FullWidthPrimaryButton>
      </Wrapper>
    )
  } else {
    title = '取款'
    page = (
      <Wrapper>
        <PageLoading></PageLoading>
      </Wrapper>
    )
  }

  return (
    <PageWrapperNonScroll>
      <HeaderWrap>
        <PageModalHeaderBox headerTitle={title} onClose={handleClose} />
        {tab === WITHDRAW_TAB.WITHDRAW && <TransferShortcut onClick={toGameTransfer}>额度转换</TransferShortcut>}
      </HeaderWrap>
      <PageScrollWrap>{page}</PageScrollWrap>
    </PageWrapperNonScroll>
  )
}
