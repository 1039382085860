import { SelectModal } from 'component/Modal/SelectModal'
import { useCallback, useRef } from 'react'
import useHintModal from './useHintModal'

/**
 * @typedef {Object} Option
 * @property {string} text
 * @property {() => void} onSelect
 */

export default function useSelectModal() {
  const { openHintModal, closeHintModal } = useHintModal()

  const currentCancel = useRef()
  const currentOptions = useRef()

  const onCancelProxy = useCallback(() => {
    closeHintModal()
    currentCancel.current?.()
  }, [closeHintModal])

  const onConfirmProxy = useCallback(
    (index) => {
      closeHintModal()
      currentOptions.current?.[index]?.onSelect?.()
    },
    [closeHintModal]
  )

  const openSelect = useCallback(
    ({ title, message, options = /** @type {Option[]} */ ([]), onCancel, initialIndex = -1 }) => {
      currentCancel.current = onCancel
      currentOptions.current = options
      openHintModal({
        children: SelectModal,
        props: {
          title,
          message,
          options,
          onConfirm: onConfirmProxy,
          onCancel: onCancelProxy,
          initialIndex,
        },
      })
    },
    [onCancelProxy, onConfirmProxy, openHintModal]
  )

  return { openSelect }
}
