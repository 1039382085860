import { HeaderWrap, InfiniteScrollCmsGridWrapper, PageScrollWrap } from 'commonStyledComponents'
import { Header, Wrapper } from './Styled'
import { fetchAdList } from 'api'
import { AdvertisementType } from 'constant/advertisement'
import ItemLink from './component/ItemLink'
import { useImmer } from 'use-immer'
import PageLoading from 'component/Loading/PageLoading'
import EmptyResult from 'component/EmptyResult'

const HomePage = () => {
  const [state, updateState] = useImmer({
    nextPage: 1,
    isLoading: true,
    hasMore: true,
    list: [],

    page_result: { current: null, pageSize: null, total: null },
  })

  const handleFetchVideoList = () => {
    if (state.current === state.page_result.current || !state.hasMore) return

    const data = {
      current: state.nextPage,
      siteType: AdvertisementType.AppCenterIcon,
    }

    fetchAdList(data).then((response) => {
      updateState((draft) => {
        draft.list = draft?.list?.concat(response?.data)
        draft.hasMore = response?.page_result?.total > response?.page_result?.current * response?.page_result?.pageSize
        draft.nextPage = response?.page_result?.current + 1
        draft.page_result = response.page_result

        draft.isLoading = false
      })
    })
  }
  return (
    <Wrapper>
      <HeaderWrap>
        <Header>站长推荐</Header>
      </HeaderWrap>
      <PageScrollWrap>
        {state.isLoading && state.list.length === 0 && <PageLoading />}
        {!state.isLoading && state.list.length === 0 && <EmptyResult />}
        <InfiniteScrollCmsGridWrapper
          pageStart={0}
          loadMore={handleFetchVideoList}
          hasMore={state?.hasMore}
          // loader={<div className="loader" key={0}>Loading ...</div>}
          useWindow={false}
        >
          {state?.list?.map((item, i) => (
            <ItemLink key={i} {...item} />
          ))}
        </InfiniteScrollCmsGridWrapper>
      </PageScrollWrap>
    </Wrapper>
  )
}

export default HomePage
