import { ColoredIcon } from 'component/ColoredIcon'
import styled from 'styled-components'

export const HeaderBox = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.51rem;
  font-weight: bold;
  width: 100%;
  height: 0.66rem;
  line-height: 0.66rem;
  touch-action: none;
`

export const BackButton = styled.div`
  position: absolute;
  z-index: 10;
  left: var(--cms-grid-column-gap);
  top: 50%;
  transform: translateY(-50%);

  &::after {
    content: '';
    position: absolute;
    display: block;
    left: var(--button-live-zone-padding);
    right: var(--button-live-zone-padding);
    bottom: var(--button-live-zone-padding);
    top: var(--button-live-zone-padding);
  }
`

export const IconBack = styled(ColoredIcon).attrs({
  src: `${process.env.PUBLIC_URL}/icon/arrow.svg`,
})`
  height: 0.41rem;
  width: 0.41rem;
`
