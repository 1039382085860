import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { AdvertisementType } from 'constant/advertisement'
import { selectAdInfo } from 'redux/selector/app'
import RecommendedItem from './component/RecommendedItem'
import { Wrapper, ItemsWrapper, StyledAreaTitle } from './Styled'

export default function OfficialRecommendation({ pageUuid }) {
  const adInfo = useSelector(selectAdInfo)

  /** 官方推薦廣告位 */
  const adItems = useMemo(() => {
    return adInfo?.[AdvertisementType.OfficialRecommendation]
  }, [adInfo])

  if (!adItems?.length) return null

  return (
    <Wrapper>
      <StyledAreaTitle text="官方推荐" hasDecoration />

      <ItemsWrapper>
        {adItems?.map((item) => (
          <RecommendedItem key={item?.id} pageUuid={pageUuid} {...item} />
        ))}
      </ItemsWrapper>
    </Wrapper>
  )
}
