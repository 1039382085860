import styled from 'styled-components'
import ReactModal from 'react-modal'

export const Wrapper = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  width: 7.253rem;
  padding: 0.41rem;
  background-color: #ffffff;
  border-radius: 0.373rem;
  transform: translate(-50%, -50%);
  outline: none;
`

export const ReactModalWrapper = styled(ReactModal)`
  position: absolute;
  left: 50%;
  top: 50%;
  &.rotate-90 {
    transform: translate(-50%, -50%) rotate(90deg);
  }
  &.rotate-180 {
    transform: translate(-50%, -50%) rotate(180deg);
  }
  &.rotate-270 {
    transform: translate(-50%, -50%) rotate(270deg);
  }
`
