import { HeaderWrap } from 'commonStyledComponents'
import styled from 'styled-components'

export const Wrapper = styled.div``

export const PromotionPlaceholder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-image: url(${process.env.PUBLIC_URL}/game/bg-promotion-placeholder.png);
  background-size: cover;
`

export const PageHeaderWrapper = styled(HeaderWrap)`
  color: #fff;
  z-index: 1;
  background: none;
`
