import Base64Image from 'component/Base64Image'
import styled from 'styled-components'

export const Wrapper = styled.div`
  --columns: 5;
  width: calc(calc(100% - var(--cms-grid-column-gap) * calc(var(--columns) - 1)) / var(--columns));
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  color: #ffffff;
`

export const Icon = styled(Base64Image).attrs({ aspectRatio: 1 })`
  border-radius: 0.2564rem;
`

export const Name = styled.div`
  margin-top: 7px;
`
