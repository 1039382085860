import { useMemo, useEffect, useState, useCallback } from 'react'
//
import { HeaderWrap, PageWrapperNonScroll } from 'commonStyledComponents'
import { fetchGameBalanceAll, postTransferV2 } from 'api/index'

import { Button, PlatformItem, PlatformItemBalance, TotalCard, TotalCardButtonRow, Wrapper } from './Styled'
import RefreshButton from 'page/GameLobby/component/RefreshButton'

import HeaderBox from 'component/PageModalHeaderBox'
import { PageScrollWrap } from 'commonStyledComponents'
import { useToastAlert } from 'hooks/useToastAlert'
import Loading from 'page/GameLobby/component/Loading'
import { formatAmount } from 'utils/amountFormat'
import { combinedStorage } from 'utils/combinedStorage'

const COOL_DOWN_MS = 10000

const LAST_TRANSFER_TIME_VARIABLE = 'ltt'

export default function GameTransfer() {
  // 頁面資料
  const [balanceAll, setBalanceAll] = useState({})
  const [submitLoading, setSubmitLoading] = useState(false)

  const [coolDownStart, setCoolDownStart] = useState(() => {
    const old = combinedStorage.getItem(LAST_TRANSFER_TIME_VARIABLE)
    if (old) {
      try {
        const parsed = JSON.parse(old)
        if (typeof parsed !== 'number') {
          throw new Error('bad data')
        }
        return parsed
      } catch (err) {
        return null
      }
    }
  })
  const [now, setNow] = useState(Date.now())

  const { toast } = useToastAlert()

  // --動態計算資料--

  // add extra 999 ms so it will actually show '10'
  const shouldTick = coolDownStart != null && now < coolDownStart + COOL_DOWN_MS + 999
  const timeRemainingMs = coolDownStart == null ? 0 : coolDownStart + COOL_DOWN_MS + 999 - now
  const timeRemaining = ~~(timeRemainingMs / 1000)
  const disabled = timeRemainingMs > 0

  useEffect(() => {
    if (shouldTick) {
      let id
      const onTick = () => {
        setNow(Date.now())
        id = requestAnimationFrame(onTick)
      }
      id = requestAnimationFrame(onTick)
      return () => cancelAnimationFrame(id)
    }
  }, [shouldTick])

  const startCoolDown = useCallback(() => {
    combinedStorage.setItem(LAST_TRANSFER_TIME_VARIABLE, JSON.stringify(Date.now()))
    setNow(Date.now())
    setCoolDownStart(Date.now())
  }, [])

  const handleTransfer = useCallback(
    async (tpCode) => {
      startCoolDown()
      try {
        setSubmitLoading(true)
        await postTransferV2({ currency: 1, tpCode })
        await handleRefreshBalance()
      } catch (ex) {
        ex?.message && toast(ex?.message)
      }
      setSubmitLoading(false)
    },
    [startCoolDown, toast]
  )

  const handleTransferAll = useCallback(async () => {
    startCoolDown()
    try {
      setSubmitLoading(true)
      await postTransferV2({ currency: 1, tpCode: '' })
      await handleRefreshBalance()
    } catch (ex) {
      ex?.message && toast(ex?.message)
    }
    setSubmitLoading(false)
  }, [startCoolDown, toast])

  const platformList = useMemo(() => {
    return balanceAll?.list?.map((m, i) =>
      i === 0 ? (
        <TotalCard key={i}>
          <div>
            帐户余额
            <RefreshButton
              img={`${process.env.PUBLIC_URL}/icon/refresh.svg`}
              onClick={handleRefreshBalance}
              buttonBackground={false}
            ></RefreshButton>
          </div>
          <div className="total-balance">{formatAmount(m.balance)}</div>
          <TotalCardButtonRow>
            <Button onClick={handleTransferAll} disabled={disabled}>
              一键取回 {disabled ? `(${timeRemaining})` : ''}
            </Button>
          </TotalCardButtonRow>
        </TotalCard>
      ) : (
        <PlatformItem key={i}>
          <div>{m.name}</div>
          <PlatformItemBalance>
            <div>{formatAmount(m.balance)}</div>
            <Button onClick={() => handleTransfer(m.tpCode)} disabled={disabled}>
              取回余额 {disabled ? `(${timeRemaining})` : ''}
            </Button>
          </PlatformItemBalance>
        </PlatformItem>
      )
    )
  }, [balanceAll?.list, disabled, handleTransfer, handleTransferAll, timeRemaining])

  async function handleRefreshBalance() {
    const data = await fetchGameBalanceAll()
    setBalanceAll(data)
  }

  useEffect(() => {
    handleRefreshBalance()
  }, [])

  return (
    <PageWrapperNonScroll>
      <HeaderWrap>
        <HeaderBox headerTitle={'额度转换'} />
      </HeaderWrap>

      <PageScrollWrap>
        <Wrapper>{platformList}</Wrapper>
        {submitLoading && <Loading active={true}>请稍候</Loading>}
      </PageScrollWrap>
    </PageWrapperNonScroll>
  )
}
