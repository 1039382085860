import { useImmer } from 'use-immer'
import InfiniteScroll from 'react-infinite-scroller'
import { AdvertisementType } from 'constant/advertisement'

import { fetchAdList } from 'api'

import EmptyResult from 'component/EmptyResult'
import Carousel from './component/Carousel'
import ItemLink from './component/ItemLink'
import OfficialRecommendation from './component/OfficialRecommendation'
import PopularGames from './component/PopularGames'

import { Wrapper, StyledAreaTitle, OuterWrapper } from './Styled'
import PageModalHeaderBox from 'component/PageModalHeaderBox'
import { HeaderWrap } from 'commonStyledComponents'
import { URL_QUERY_DISABLE_LAYOUT } from 'constant/routes'
import { useAppLocationQuery } from 'app-layered-layout/useAppLocationQuery'

export default function AppCenter() {
  const query = useAppLocationQuery()
  const isPopup = query[URL_QUERY_DISABLE_LAYOUT] != null

  const [state, updateState] = useImmer({
    nextPage: 1,
    isLoading: true,
    hasMore: true,
    list: [],

    page_result: { current: null, pageSize: null, total: null },
  })

  const handleFetchVideoList = () => {
    if (state.current === state.page_result.current || !state.hasMore) return

    const data = {
      current: state.nextPage,
      siteType: AdvertisementType.AppCenterIcon,
    }

    fetchAdList(data).then((response) => {
      updateState((draft) => {
        draft.list = draft?.list?.concat(response?.data)
        draft.hasMore = response?.page_result?.total > response?.page_result?.current * response?.page_result?.pageSize
        draft.nextPage = response?.page_result?.current + 1
        draft.page_result = response.page_result

        draft.isLoading = false
      })
    })
  }

  return (
    <OuterWrapper isPopup={isPopup}>
      {isPopup && (
        <HeaderWrap>
          <PageModalHeaderBox headerTitle="应用中心" />
        </HeaderWrap>
      )}
      <Wrapper isPopup={isPopup}>
        <Carousel />

        {/* 官方推薦 */}
        <OfficialRecommendation />

        {/* 熱門遊戲 */}
        <PopularGames />

        <StyledAreaTitle text="热门应用" hasDecoration />
        {!state?.list?.length && !state.isLoading ? (
          <EmptyResult />
        ) : (
          <div>
            <InfiniteScroll
              style={{
                display: 'flex',
                // 一行五個
                gap: '16px calc((100% - (1.7948rem * 5)) / (5 - 1))',
                flexWrap: 'wrap',
                marginBottom: '0.641rem',
                padding: '0 var(--cms-grid-column-gap)',
              }}
              pageStart={0}
              loadMore={handleFetchVideoList}
              hasMore={state?.hasMore}
              // loader={<div className="loader" key={0}>Loading ...</div>}
              useWindow={false}
            >
              {state?.list?.map((item, i) => (
                <ItemLink key={i} {...item} />
              ))}
            </InfiniteScroll>
          </div>
        )}
      </Wrapper>
    </OuterWrapper>
  )
}
