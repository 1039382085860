import { useMemo, useRef } from 'react'
import { PromoteFlag, Wrapper } from './styled'
import { GAME_DEPOSIT_CHANNEL_PROMOTE_FLAG } from 'constant/game'
import { checkDebugSecret } from 'page/SystemConfig'

export default function MethodButton({ data, active, onClick, ...props }) {
  const method = useMemo(() => data, [data])
  const methodHidden = method?.hidden
  const isPromote = useMemo(() => data.label?.find((m) => m === GAME_DEPOSIT_CHANNEL_PROMOTE_FLAG.PROMOTED), [data])
  const isHot = useMemo(() => data.label?.find((m) => m === GAME_DEPOSIT_CHANNEL_PROMOTE_FLAG.HOT), [data])
  const debugInfo = useRef(localStorage.getItem('DEBUG'))
  let parsed = []

  if (debugInfo.current) {
    try {
      const temp = JSON.parse(debugInfo.current)
      if (Array.isArray(temp) && temp.every((i) => typeof i === 'string')) {
        if (checkDebugSecret(temp[0])) {
          parsed = temp.slice(1)
        }
      }
    } catch (err) {}
  }

  const shouldShow = methodHidden !== true || parsed.includes('PAYMENT')

  return method && shouldShow ? (
    <Wrapper onClick={onClick} className={active ? 'active' : ''} props={props}>
      {method.icon ? (
        <img className="button-icon" src={method.icon} alt={method.name} />
      ) : (
        <span className="button-icon" />
      )}

      {method.name}
      {isPromote ? <PromoteFlag>优惠</PromoteFlag> : isHot && <PromoteFlag>热门</PromoteFlag>}
    </Wrapper>
  ) : (
    <></>
  )
}
