import { createContext, useCallback, useContext, useMemo, useState } from 'react'

const SharedDirtyWorkContext = createContext({
  addData: (type, items) => {},
  mapData: (type, items) => {},
})

export const DirtyWorkDataProvider = ({ children }) => {
  const [data, setData] = useState(() => /** @type {{ [type: string]: { [id: string]: any } }} */ ({}))

  const addData = useCallback((type, items) => {
    setData((oldValue) => {
      const oldRecord = oldValue[type] ?? {}
      const newRecord = { ...oldRecord }
      for (const item of items) {
        newRecord[item.id] = item
      }
      return { ...oldValue, [type]: newRecord }
    })
  }, [])

  const mapData = useCallback(
    (type, items) => {
      if (data[type] == null) {
        return items
      }

      const record = data[type]

      return items.map((i) => {
        if (record[i.id]) {
          return record[i.id]
        }
        return i
      })
    },
    [data]
  )

  const value = useMemo(
    () => ({
      addData,
      mapData,
    }),
    [addData, mapData]
  )

  return <SharedDirtyWorkContext.Provider value={value}>{children}</SharedDirtyWorkContext.Provider>
}

export const useSharedCache = () => {
  const { addData, mapData } = useContext(SharedDirtyWorkContext)

  const addDirtyData = addData

  const useMappedData = (type, items) => {
    return useMemo(() => {
      return mapData(type, items)
      // FUCK eslint, ctx really changes and cause update!
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapData, items, type])
  }

  return { addDirtyData, useMappedData }
}
