import { useCallback } from 'react'
import { useAlert, positions } from 'react-alert'

export function useToastAlert() {
  const alert = useAlert()
  const toast = useCallback(
    (text, params) => {
      alert.info(text, {
        position: positions.MIDDLE,
        offset: '300px',
        timeout: 2000,
        ...params,
      })
    },
    [alert]
  )

  return {
    toast: toast,
  }
}
