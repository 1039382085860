import styled from 'styled-components'

export const Label = styled.div`
  position: absolute;
  right: 0.2rem;
  bottom: calc(0.2rem + env(safe-area-inset-bottom));
  font-size: 0.2rem;
  color: white;
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.5));
`
