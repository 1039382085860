import styled, { keyframes } from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100%;
  width: 100%;
  background: #000000;
  font-size: 0.32rem;
  font-weight: bold;
`

const move = keyframes`
  from {
    left: calc(1rem / 39 * -140)
  }
  to {
    left: calc(1rem / 39 * 200)
  }
`

export const LoadingIndicator = styled.div`
  margin-top: 0.5rem;
  width: calc(1rem / 39 * 200);
  height: calc(1rem / 39 * 2);
  background-image: url(${process.env.PUBLIC_URL}/video-loading/loading-back.svg);
  background-size: 100% 100%;
  position: relative;
  overflow: hidden;
`

export const LoadingIndicatorInner = styled.div`
  width: calc(1rem / 39 * 140);
  height: calc(1rem / 39 * 2);
  background-image: url(${process.env.PUBLIC_URL}/video-loading/loading-front.svg);
  background-size: 100% 100%;
  animation: ${move} 1s linear infinite;
  position: absolute;
  top: 0;
`

export const LoadingHintText = styled.div`
  margin-top: 0.5rem;
  font-size: 0.3rem;
  color: white;
`
