import { fetchAddCollectList, fetchAddLikeList, fetchRemoveCollectList, fetchRemoveLikeList, fetchShortList } from 'api'
import { AdvertisementType } from 'constant/advertisement'
import { FALSE, TRUE } from 'constant/common'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectAdInfo } from 'redux/selector/app'
import { useImmer } from 'use-immer'
import PlaceHolderPage from './components/PlaceHolderPage'
import PlayerPage from './components/PlayerPage'
import PromotionPage from './components/PromotionPage'
import { VirtualPages } from './components/VirtualPage'
import { IconBack, Wrapper } from './Styled'
import useAlertWorksActionResult from 'hooks/useAlertWorksActionResult'
import { useMutex } from 'hooks/useMutex'
import { useSharedCache } from 'hooks/useDirtyWorkData'
import { useNavigateTo, useParamsOfPage } from 'hooks/useNavigateTo'
import { usePageActivated } from 'app-layered-layout/usePageActivated'
import { useAppNavigate } from 'app-layered-layout/useAppNavigate'
import { flushSync } from 'react-dom'
import { RESET_SHORT_FAVOURITE } from 'redux/constant/shortFavourite'
import { RESET_SHORT_COLLECTION } from 'redux/constant/shortCollection'
import { combinedStorage } from 'utils/combinedStorage'
import Tutorial from './components/Tutorial'

const AD_INTERVAL = 5
const TUTORIAL_SKIP_VARIABLE = 'viewedShortTutorialAt'

export default function ViewShort() {
  const dispatch = useDispatch()

  const { navigateToViewShort } = useNavigateTo()
  const navigate = useAppNavigate()
  const {
    videoInfo: videoInfoInitial,
    videoList: videoListInitial,
    currentIndex = 0,
    posterImg,
  } = useParamsOfPage('short')

  const pageActivated = usePageActivated()
  const updateIndex = (index) => {
    navigateToViewShort(
      {
        videoInfo: videoInfoInitial,
        videoList: videoListInitial,
        currentIndex: index,
        posterImg,
      },
      { replace: true }
    )
  }

  const videoIndexInitial = videoListInitial.findIndex((i) => i.id === videoInfoInitial.id)
  const { addDirtyData, useMappedData } = useSharedCache()
  const videoList = useMappedData('short', videoListInitial)

  const adViewShort = useSelector(selectAdInfo)[AdvertisementType.ViewShort] ?? []

  const [imageCache, patchImageCache] = useImmer({
    [videoInfoInitial.id]: {
      preloaded: posterImg != null,
      src: posterImg,
    },
  })

  // tutorial
  const [skipTutorial] = useState(() => {
    return combinedStorage.getItem(TUTORIAL_SKIP_VARIABLE) === process.env.REACT_APP_VERSION
  })

  const setTutorialSkip = () => {
    combinedStorage.setItem(TUTORIAL_SKIP_VARIABLE, process.env.REACT_APP_VERSION)
  }

  const updateImageCache = (id, base64Image) => {
    if (!base64Image) return
    patchImageCache((arg) => {
      if (arg[id] == null) {
        arg[id] = {
          preloaded: false,
          src: base64Image,
        }
      }
    })
  }

  const commitImageCache = () => {
    patchImageCache((arg) => {
      for (const k of Object.keys(arg)) {
        if (arg[k].src) {
          arg[k].preloaded = true
        }
      }
    })
  }

  /**
   *
   * @param {number} originalIndex
   * @param {number} adCount
   * @returns {{ type: 'short' | 'ad', index: number }}
   */
  const mapIndex = (originalIndex, adCount) => {
    if (adCount === 0) {
      return {
        type: 'short',
        index: originalIndex % videoList.length,
      }
    }

    /**
     *  -7  -6  -5  -4  -3  -2  -1  +0 +1 +2 +3 +4 +5 +6  7  8  9 10  11
     * [a-2]s-5 s-4 s-3 s-2 s-1[a-1]s0 s1 s2 s3 s4[a0]s5 s6 s7 s8 s9 [a1]
     */

    const segmentLength = AD_INTERVAL + 1
    let isAd = false
    let adIndex = 0
    let shortIndex = 0
    if (originalIndex >= 0) {
      isAd = (originalIndex + 1) % segmentLength === 0
      shortIndex = originalIndex - ~~(originalIndex / segmentLength)
      adIndex = ~~(originalIndex / segmentLength)
    } else {
      isAd = -(originalIndex + 1) % segmentLength === 0
      shortIndex = originalIndex - Math.floor(originalIndex / segmentLength)
      adIndex = Math.floor(originalIndex / segmentLength)
    }

    if (isAd) {
      return {
        type: 'ad',
        index: adIndex,
      }
    } else {
      return {
        type: 'short',
        index: shortIndex,
      }
    }
  }
  const mod = (a, b) => ((a % b) + b) % b

  /**
   * @param {*} originalIndex
   * @param {*} baseOffset
   * @param {*} shorts
   * @param {*} ads
   * @returns {{type: 'ad'} | {type: 'short', item: import("../../../types/api").SchemaShortVideoShow}}
   */
  const selectPage = (originalIndex, baseOffset, shorts, ads) => {
    const index = mapIndex(originalIndex, ads.length)
    if (index.type === 'short') {
      return {
        type: 'short',
        item: shorts[mod(index.index + baseOffset, videoList.length)],
      }
    } else {
      return {
        type: 'ad',
        item: ads[mod(index.index, adViewShort.length)],
      }
    }
  }

  // a proper mod that handles negative value

  const [videoBase] = useState(videoIndexInitial)
  const prevPage = selectPage(currentIndex - 1, videoBase, videoList, adViewShort)
  const currentPage = selectPage(currentIndex, videoBase, videoList, adViewShort)
  const nextPage = selectPage(currentIndex + 1, videoBase, videoList, adViewShort)

  useEffect(() => {
    if (!pageActivated) {
      return
    }

    if (currentPage.type !== 'short') {
      return
    }

    const controller = new AbortController()
    const signal = controller.signal
    async function run() {
      const id = currentPage.item.id
      const newShortData = (await fetchShortList({ signal, id })).data
      addDirtyData('short', newShortData)
    }

    run().catch((err) => {})

    return () => {
      controller.abort()
    }
  }, [addDirtyData, currentPage.item.id, currentPage.type, pageActivated])

  const MIN_STAY_TIME = 500

  useEffect(() => {
    if (currentPage.type === 'ad') {
      return
    }

    const id = currentPage.item.id

    let waitingForLeave = false

    const waitId = setTimeout(() => {
      waitingForLeave = true
    }, MIN_STAY_TIME)

    return async () => {
      clearTimeout(waitId)
      if (waitingForLeave) {
        const newShortData = (await fetchShortList({ id })).data
        addDirtyData('short', newShortData)
      }
    }
  }, [addDirtyData, currentPage.item.id, currentPage.type])

  const virtualPages = useRef()

  const onNext = () => {
    const newIndex = currentIndex + 1
    flushSync(() => {
      updateIndex(newIndex)
      commitImageCache()
    })
  }
  const onPrev = () => {
    const newIndex = currentIndex - 1
    flushSync(() => {
      updateIndex(newIndex)
      commitImageCache()
    })
  }

  useLayoutEffect(() => {
    virtualPages.current.resetPageOffset()
  }, [currentIndex])

  const alerts = useAlertWorksActionResult()

  const { wrap: wrapAddLike } = useMutex()
  const handleAddLikeList = wrapAddLike(async (video) => {
    const fetchUpdateApi = video.like === TRUE ? fetchRemoveLikeList : fetchAddLikeList

    await fetchUpdateApi({ video_id: video?.id })

    // const newShortData = (await fetchShortList({ id: video?.id })).data

    /**
     * @type {import('../../../types/api').SchemaShortVideoShow}
     */
    const oldShortData = currentPage.item

    let newState =
      oldShortData.like === TRUE
        ? {
            like: FALSE,
            like_count: Math.max(oldShortData.like_count - 1, 0),
          }
        : {
            like: TRUE,
            like_count: oldShortData.like_count + 1,
          }

    const newShortData = {
      ...oldShortData,
      ...newState,
    }

    addDirtyData('short', [newShortData])

    dispatch({ type: RESET_SHORT_FAVOURITE })
    dispatch({ type: RESET_SHORT_COLLECTION })
  })

  const { wrap: wrapAddCollection } = useMutex()
  const handleAddCollectList = wrapAddCollection(async (video) => {
    const fetchUpdateApi = video.favorite === TRUE ? fetchRemoveCollectList : fetchAddCollectList

    await fetchUpdateApi({ video_id: video?.id })

    /**
     * @type {import('../../../types/api').SchemaShortVideoShow}
     */
    const oldShortData = currentPage.item

    let newState =
      oldShortData.favorite === TRUE
        ? {
            favorite: FALSE,
            favorite_count: Math.max(oldShortData.favorite_count - 1, 0),
          }
        : {
            favorite: TRUE,
            favorite_count: oldShortData.favorite_count + 1,
          }

    const newShortData = {
      ...oldShortData,
      ...newState,
    }

    addDirtyData('short', [newShortData])

    dispatch({ type: RESET_SHORT_FAVOURITE })
    dispatch({ type: RESET_SHORT_COLLECTION })

    if (video.favorite === FALSE) {
      alerts.alertSaved()
    }
  })

  const preloadedImages = [
    ...(prevPage.type === 'short' && imageCache[prevPage.item.id]?.src
      ? [
          {
            key: prevPage.item.coverphoto_v,
            value: imageCache[prevPage.item.id].src,
          },
        ]
      : []),
    ...(nextPage.type === 'short' && imageCache[nextPage.item.id]?.src
      ? [
          {
            key: nextPage.item.coverphoto_v,
            value: imageCache[nextPage.item.id].src,
          },
        ]
      : []),
  ]

  return (
    <Wrapper>
      <VirtualPages ref={virtualPages} onNext={onNext} onPrev={onPrev}>
        {{
          prev:
            prevPage.type === 'short' ? (
              <PlaceHolderPage
                videoInfo={prevPage.item}
                posterSrc={imageCache[prevPage.item.id]?.preloaded && imageCache[prevPage.item.id].src}
                onBase64ImageLoad={(src) =>
                  updateImageCache(prevPage.item.id, src ? `data:image/jpeg;base64, ${src}` : null)
                }
              />
            ) : (
              <PromotionPage
                promoteInfo={prevPage.item}
                posterSrc={imageCache[prevPage.item.id]?.preloaded && imageCache[prevPage.item.id].src}
                onBase64ImageLoad={(src) =>
                  updateImageCache(prevPage.item.id, src ? `data:image/jpeg;base64, ${src}` : null)
                }
              />
            ),
          current: pageActivated ? (
            currentPage.type === 'short' ? (
              <PlayerPage
                mode="video"
                videoInfo={currentPage.item}
                posterSrc={imageCache[currentPage.item.id]?.preloaded && imageCache[currentPage.item.id].src}
                onBase64ImageLoad={(src) =>
                  updateImageCache(currentPage.item.id, src ? `data:image/jpeg;base64, ${src}` : null)
                }
                onLike={() => handleAddLikeList(currentPage.item)}
                onSave={() => handleAddCollectList(currentPage.item)}
                preloadedImages={preloadedImages}
              />
            ) : (
              <PlayerPage
                mode="promotion"
                promoteInfo={currentPage.item}
                posterSrc={imageCache[currentPage.item.id]?.preloaded && imageCache[currentPage.item.id].src}
                onBase64ImageLoad={(src) =>
                  updateImageCache(currentPage.item.id, src ? `data:image/jpeg;base64, ${src}` : null)
                }
                preloadedImages={preloadedImages}
              />
            )
          ) : (
            <PlaceHolderPage
              videoInfo={currentPage.item}
              posterSrc={imageCache[currentPage.item.id]?.preloaded && imageCache[currentPage.item.id].src}
              onBase64ImageLoad={(src) =>
                updateImageCache(currentPage.item.id, src ? `data:image/jpeg;base64, ${src}` : null)
              }
            />
          ),
          next:
            nextPage.type === 'short' ? (
              <PlaceHolderPage
                videoInfo={nextPage.item}
                posterSrc={imageCache[nextPage.item.id]?.preloaded && imageCache[nextPage.item.id].src}
                onBase64ImageLoad={(src) =>
                  updateImageCache(nextPage.item.id, src ? `data:image/jpeg;base64, ${src}` : null)
                }
              />
            ) : (
              <PromotionPage
                promoteInfo={nextPage.item}
                posterSrc={imageCache[nextPage.item.id]?.preloaded && imageCache[nextPage.item.id].src}
                onBase64ImageLoad={(src) =>
                  updateImageCache(nextPage.item.id, src ? `data:image/jpeg;base64, ${src}` : null)
                }
              />
            ),
        }}
      </VirtualPages>
      <IconBack
        onClick={() => {
          navigate(-1)
        }}
      />
      <Tutorial isOpen={!skipTutorial} onFinished={setTutorialSkip} />
    </Wrapper>
  )
}
