import lottie from 'lottie-web'
import { useEffect, useRef } from 'react'
import { AnimationIconWrap } from './Styled'

const AnimatedIcon = ({ animationData, active = false, ...props }) => {
  const ref = useRef()
  const animationInst = useRef()
  const initialRun = useRef(true)

  useEffect(() => {
    if (ref.current) {
      const animation = lottie.loadAnimation({
        container: ref.current,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData,
        initialSegment: active ? [animationData.op + 1, animationData.op + 1] : null,
      })

      animationInst.current = animation

      return () => {
        animation.destroy()
        animationInst.current = null
      }
    }
    // IT IS INTENTIONAL TO MISS active HERE, DO NOT ADD
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animationData])

  useEffect(() => {
    if (!animationInst.current) {
      return
    }

    if (initialRun.current) {
      initialRun.current = false
      return
    }

    const inst = animationInst.current

    if (active) {
      inst.play()
    } else {
      inst.setSegment(0, animationData.op + 1)
      inst.goToAndStop(0)
    }
  }, [active, animationData.op])

  return <AnimationIconWrap {...props} ref={ref} />
}

export default AnimatedIcon
