import AreaWrapper from 'component/AreaWrapper'
import styled from 'styled-components'

export const SlideContent = styled.div`
  height: 100%;
  overflow-y: auto;
`

export const AreaPanelWrapper = styled(AreaWrapper)`
  min-height: 2rem;
`

export const LoadingWrap = styled.div`
  position: absolute;
  inset: 0;
  z-index: 1;
  padding: 1rem 0;
`
