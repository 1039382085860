import { Location, RouteObject, matchRoutes, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from 'react'
import { routes as routeMap } from "../router";
import { ReactElement } from "react";
import { useMemo } from "react";
import { AppProvider } from "./AppProvider";
import { useModalStyles } from "component/Modal/PageModal/hooks/useModalStyles";
import ReactPageModal from "react-modal";
import { HISTORY_STATE_DROP_BROWSING_STACKS } from "constant/routes";

const MAX_PRESERVE_STACK = 5
const PLACEHOLDER = <div />

export function AppLayout() {
    const { styles } = useModalStyles();

    const currentLocation = useLocation()
    const currentLiveHistoryIndex: number | undefined = currentLocation.state?.index

    const routes = useMemo(() => matchRoutes(routeMap, currentLocation) ?? [], [currentLocation])
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
    const selfIndex = routes?.findIndex(i => (i.route.element as ReactElement).type === AppLayout) ?? 0

    const currentLiveRoute = routes?.[selfIndex + 1]!.route.element as ReactElement

    const maxHistoryIndex = useRef<number>()
    const lastRenderedIndex = useRef<number>()

    if (maxHistoryIndex.current == null) {
        if (currentLiveHistoryIndex != null) {
            maxHistoryIndex.current = currentLiveHistoryIndex
        }
    }

    interface StackFrame {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        state: { [k: string]: any },
        location: ReturnType<typeof useLocation>,
        routes: NonNullable<ReturnType<typeof matchRoutes<RouteObject>>>,
        key: string
        component: ReactElement
    }

    const [pageStack, setPageStack] = useState<StackFrame[]>()
    const renderedStack = useRef<StackFrame[]>()
    const navigate = useNavigate();

    const lastRanLocation = useRef<Location>()
    useEffect(() => {
        if (lastRanLocation.current === currentLocation) {
            return
        }
        lastRanLocation.current = currentLocation

        const liveHistoryIndex = window.history.state?.usr?.index

        // FIXME
        if (liveHistoryIndex != null && liveHistoryIndex !== currentLiveHistoryIndex) {
            console.error('REACT ROUTER CORRUPTED')
        }

        if (liveHistoryIndex == null) {
            if (maxHistoryIndex.current != null) {
                navigate({ ...currentLocation }, { replace: true, state: { ...currentLocation.state, index: maxHistoryIndex.current + 1 } })
                console.log('set maxHistoryIndex to ' + (maxHistoryIndex.current + 1), currentLocation.state)
                maxHistoryIndex.current += 1
            } else {
                navigate({ ...currentLocation }, { replace: true, state: { ...currentLocation.state, index: 1 } })
                // navigate(0)
                console.log('set maxHistoryIndex to 1')
                maxHistoryIndex.current = 1
            }
            return
        }

        if (maxHistoryIndex.current != null && liveHistoryIndex > maxHistoryIndex.current) {
            maxHistoryIndex.current = liveHistoryIndex
        }

        if (lastRenderedIndex.current == null || liveHistoryIndex === lastRenderedIndex.current) {
            if (renderedStack.current == null) {
                console.log(`== init == ${liveHistoryIndex}`)
                const stack = [
                    {
                        state: currentLocation.state,
                        component: currentLiveRoute,
                        routes: routes.slice(selfIndex + 1),
                        location: currentLocation,
                        key: String(liveHistoryIndex)
                    }
                ]
                setPageStack(stack)
                renderedStack.current = stack
            } else {
                console.log(`== repl == ${liveHistoryIndex}`)
                const patchedStack = renderedStack.current.slice(0, renderedStack.current.length - 1).concat([
                    {
                        state: currentLocation.state,
                        component: currentLiveRoute,
                        routes: routes.slice(selfIndex + 1),
                        location: currentLocation,
                        key: String(liveHistoryIndex)
                    }
                ])
                if (currentLocation.state[HISTORY_STATE_DROP_BROWSING_STACKS]) {
                    const sliced = [
                        patchedStack[patchedStack.length - 1]
                    ]
                    setPageStack(sliced)
                    renderedStack.current = sliced
                } else {
                    setPageStack(patchedStack)
                    renderedStack.current = patchedStack
                }
            }
        } else if (liveHistoryIndex > lastRenderedIndex.current) {
            console.log(`== next == ${lastRenderedIndex.current} -> ${liveHistoryIndex}`)
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const patchedStack = renderedStack.current!.concat([
                {
                    state: currentLocation.state,
                    component: currentLiveRoute,
                    routes: routes.slice(selfIndex + 1),
                    location: currentLocation,
                    key: String(liveHistoryIndex)
                }
            ])
            if (currentLocation.state[HISTORY_STATE_DROP_BROWSING_STACKS]) {
                const sliced = [
                    patchedStack[patchedStack.length - 1]
                ]
                setPageStack(sliced)
                renderedStack.current = sliced
            } else {
                setPageStack(patchedStack)
                renderedStack.current = patchedStack
            }
        } else {
            console.log(`== prev == ${lastRenderedIndex.current} -> ${liveHistoryIndex}`)
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            let targetIndex = renderedStack.current!.findIndex(i => i.key === String(liveHistoryIndex))
            let targetNotFound = false

            let sliceIndex = -1

            if (targetIndex !== -1) {
                sliceIndex = targetIndex + 1
            } else  {
                targetNotFound = true
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                sliceIndex = renderedStack.current!.findIndex(i => i.key > String(liveHistoryIndex))
            }

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const patchedStack = renderedStack.current!.slice(0, sliceIndex)

            if (targetNotFound) {
                patchedStack.push({
                    state: currentLocation.state,
                    component: currentLiveRoute,
                    routes: routes.slice(selfIndex + 1),
                    location: currentLocation,
                    key: String(liveHistoryIndex)
                })
            }

            if (patchedStack.length === 0) {
                const initialStack = [
                    {
                        state: currentLocation.state,
                        component: currentLiveRoute,
                        routes: routes.slice(selfIndex + 1),
                        location: currentLocation,
                        key: String(liveHistoryIndex)
                    }
                ]
                setPageStack(initialStack)
                renderedStack.current = initialStack
                lastRenderedIndex.current = liveHistoryIndex
                return
            }

            setPageStack(patchedStack)
            renderedStack.current = patchedStack
        }
        lastRenderedIndex.current = liveHistoryIndex
    }, [currentLiveHistoryIndex, currentLiveRoute, currentLocation, navigate, routes, selfIndex])

    return <>
        {
            pageStack?.map((frame, index, arr) => {
                if (index === 0) {
                    return <AppProvider
                        key={index}
                        active={index === arr.length - 1}
                        routes={frame.routes}
                        location={frame.location}
                    >
                        {frame.component}
                    </AppProvider>
                } else {
                    return <ReactPageModal
                        key={index}
                        contentLabel="Page Modal"
                        style={styles}
                        shouldCloseOnOverlayClick={false}
                        isOpen
                    >
                        <AppProvider
                            active={index === arr.length - 1}
                            routes={frame.routes}
                            location={frame.location}
                        >
                            {arr.length - index >= MAX_PRESERVE_STACK ? PLACEHOLDER : frame.component}
                        </AppProvider>
                    </ReactPageModal>
                }
            })
        }
    </>
}