import styled from 'styled-components'

export const IconAcct = styled.i`
  cursor: pointer;
  user-select: none;
  width: 0.5128rem;
  height: 0.5128rem;
  background: url(${process.env.PUBLIC_URL}/icon/account.svg) no-repeat center;
  background-size: contain;
  margin-right: 0.1025rem;
`
