import { useEffect, useState, Fragment, useRef, useLayoutEffect, useMemo } from 'react'
import { useImmer } from 'use-immer'
import InfiniteScroll from 'react-infinite-scroller'
import { useSelector } from 'react-redux'
import { selectAdInfo, selectRegionList } from 'redux/selector/app'

import { fetchActorInfoList } from 'api'

import StaticItem from '../Statictem'
import { TitleBox } from '../../Styled'
import { Wrapper, RegionBox, ScrollWrap } from './Styled'
import { AdvertisementType } from 'constant/advertisement'
import AdItem from 'page/ViewMore/component/AdItem'
import EmptyResult from 'component/EmptyResult'
import { TabsType } from 'component/Tabs/Styled'
import { isEmpty } from 'lodash'
import { ENABLED } from 'constant/common'

const REGION_USE_TALL_ACTOR = 2

export default function ContentActor() {
  const rawRegionList = useSelector(selectRegionList)
  const regionList = useMemo(() => {
    // big first
    return rawRegionList.filter((i) => i.actor_status === ENABLED).sort((i, j) => j.actor_seqno - i.actor_seqno)
  }, [rawRegionList])

  const [activeRegionInfo, setActiveRegionInfo] = useState(null)
  const [listInfo, updateListInfo] = useImmer({
    nextPage: 1,
    isLoading: true,
    hasMore: false,
    list: [],
    page_result: { current: null, pageSize: null, total: null },
  })

  const hasNoContent = listInfo.list.length === 0 && !listInfo.isLoading

  const onRegionItemClick = (itemInfo) => {
    setActiveRegionInfo(itemInfo)
  }

  const currentFetching = useRef({
    region: null,
    page: null,
  })

  const handleFetchActorList = () => {
    if (
      (currentFetching.current.id === activeRegionInfo?.id && currentFetching.current.page === listInfo.nextPage) ||
      !listInfo.hasMore
    ) {
      return
    }

    currentFetching.current = {
      id: activeRegionInfo?.id,
      page: listInfo.nextPage,
    }

    const data = {
      current: listInfo.nextPage,
      region_id: activeRegionInfo?.id,
      discover_page: 1,
      pageSize: 30,
      status: 1, //僅顯示啟用狀態
    }

    fetchActorInfoList(data).then((response) => {
      updateListInfo((draft) => {
        draft.list = draft?.list?.concat(response?.data)
        draft.hasMore = response?.page_result?.total > response?.page_result?.current * response?.page_result?.pageSize
        draft.nextPage = response?.page_result?.current + 1
        draft.page_result = response.page_result

        draft.isLoading = false
      })
    })
  }

  useEffect(() => {
    setActiveRegionInfo(regionList[0])
  }, [regionList])

  useLayoutEffect(() => {
    updateListInfo((draft) => {
      draft.list = []
      draft.nextPage = 1
      draft.isLoading = true
      draft.hasMore = true
      draft.page_result = { current: null, pageSize: null, total: null }
    })
  }, [activeRegionInfo, updateListInfo])

  const adInfo = useSelector(selectAdInfo)
  const adCategory = adInfo?.[AdvertisementType.Category] || []

  return (
    <Wrapper>
      {/* 只顯示至多 3 個地區 */}
      <RegionBox
        type={TabsType.Underline}
        options={regionList.slice(0, 3).map((r, index) => ({
          id: String(r.id),
          name: r.name,
        }))}
        value={String(activeRegionInfo?.id)}
        onChange={(id) => onRegionItemClick(regionList.find((r) => String(r.id) === id))}
      />

      {hasNoContent && <EmptyResult style={{ height: 'calc(100% - 2rem)' }} />}
      <ScrollWrap>
        <InfiniteScroll
          style={{ display: 'flex', gap: 'var(--category-grid-gap)', flexWrap: 'wrap' }}
          pageStart={0}
          loadMore={handleFetchActorList}
          hasMore={listInfo?.hasMore}
          // loader={<div className="loader" key={0}>Loading ...</div>}
          useWindow={false}
        >
          {listInfo?.list?.map((item, i) => {
            const needToShowAd = i >= 6 && (i - 6 + 1) % 12 === 0 && adCategory.length > 0
            const adIndex = ~~((i - 6 - 11) / 12)
            const wrappedIndex = adIndex % adCategory.length
            const useTallActor = i >= 6 && activeRegionInfo?.type === REGION_USE_TALL_ACTOR
            return (
              <Fragment key={i}>
                {/** 前六個顯示推薦演員 */}
                {i === 0 && <TitleBox>推荐演员</TitleBox>}
                {i === 6 && <TitleBox>全部演员</TitleBox>}

                <StaticItem
                  tall={useTallActor}
                  key={item.id}
                  name={item.name}
                  icon_path={item.icon_path}
                  params={{
                    actor_ids: item.id,
                  }}
                  placeholder={useTallActor ? `/loading/discover-actor-tall.svg` : `/loading/discover-actor.svg`}
                  showName
                />
                {needToShowAd && !isEmpty(adCategory[wrappedIndex]) && <AdItem {...adCategory[wrappedIndex]} />}
              </Fragment>
            )
          })}
        </InfiniteScroll>
      </ScrollWrap>
    </Wrapper>
  )
}
