/**
 * @typedef {Object} Option
 * @property {string} text
 */

import { useCallback, useState } from 'react'
import {
  ActionBox,
  ActionCancel,
  ActionConfirm,
  Message,
  Option,
  OptionStatus,
  OptionText,
  Title,
  Wrapper,
} from './Styled'

export function SelectModal({
  title = '',
  message = '',
  options = /** @type {Option[]} */ ([]),
  onCancel = () => {},
  onConfirm = (key) => {},
  initialIndex = -1,
} = {}) {
  const [draftOption, setDraftOption] = useState(initialIndex)
  const onConfirmWrapped = useCallback(() => {
    if (draftOption >= 0) {
      onConfirm(draftOption)
    }
  }, [draftOption, onConfirm])

  return (
    <Wrapper>
      <Title>{title}</Title>
      <Message>{message}</Message>
      {options.map((op, index) => (
        <Option key={index} onClick={() => setDraftOption(index)}>
          <OptionText>{op.text}</OptionText>
          <OptionStatus active={draftOption === index} />
        </Option>
      ))}
      <ActionBox>
        <ActionCancel onClick={onCancel}>取消</ActionCancel>
        <ActionConfirm onClick={onConfirmWrapped}>确认</ActionConfirm>
      </ActionBox>
    </Wrapper>
  )
}
