import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  background-color: rgba(255, 255, 255, 1);
  color: black;
  /* width: 300px; */
  color: 100px;
  border-radius: 0.373rem;
  overflow: hidden;
`

export const CameraWrapper = styled.div`
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.41rem;
  border: 1px dashed #777;

  ${(props) =>
    props.placeholder
      ? css`
          height: 250px;
        `
      : ''}
  ${(props) =>
    props.detached
      ? css`
          position: fixed !important;
          left: 0;
          top: 0;
          visibility: hidden;
        `
      : ''}
`

export const ToggleButton = styled.label`
  width: 100%;
  padding: 1em;
  background: #eee;
  display: block;
  text-align: center;

  ${(props) =>
    props.hidden
      ? css`
          display: none;
        `
      : ''}
`

export const HiddenInput = styled.input`
  display: none;
`
