import styled from 'styled-components'

export const SwiperWrapper = styled.div`
  margin-top: 0.41rem;
  overflow: hidden;
  border-radius: 0.25rem;

  .swiper-container {
    position: relative;
  }
  .swiper-container > .swiper-slide__content {
    position: absolute;
    top: 0;
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 200ms linear;
  }
  .swiper-slide__content {
    height: 300px;
  }

  // pagination
  .swiper-pagination {
    bottom: 0;

    .swiper-pagination-bullet {
      background: rgba(255, 255, 255, 0.8);

      &.swiper-pagination-bullet-active {
        background: #ffffff;
      }
    }
  }

  .swiper-scrollbar {
    display: none;
  }
`
