import { useMemo, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import classnames from 'classnames'
import { useImmer } from 'use-immer'
import { selectTagList, selectProducerList } from 'redux/selector/app'

import ContentActor from './component/ContentActor'
//
import { TRUE, FALSE } from 'constant/common'
import { PageWrapper } from 'commonStyledComponents'
//
import SecTagPromoteItem from './component/SecTagPromoteItem'
//
import {
  Wrapper,
  HeaderTitle,
  ContentWrapper,
  CategoryItemWrapper,
  CategoryItem,
  CategoryContentWrapper,
  SecTagItem,
  // TitleBox,
  Dividing,
} from './Styled'
import ContentProducer from './component/ContentProducer'
import EmptyResult from 'component/EmptyResult'
import { useNavigateTo } from 'hooks/useNavigateTo'

const CODE_PRODUCER = 'producer'
const CODE_ACTOR = 'actor'
const staticTopTagList = [
  { name: '厂商', id: CODE_PRODUCER, code: CODE_PRODUCER, isStatic: true },
  { name: '演员', id: CODE_ACTOR, code: CODE_ACTOR, isStatic: true },
]

export default function AppCenter() {
  const { navigateToSecTagList } = useNavigateTo()
  /** 所有廠商 */
  const producerList = useSelector(selectProducerList)?.data
  /** 所有標籤 */
  const tagList = useSelector(selectTagList)?.data
  const [state, updateState] = useImmer({
    /** 目前選擇的一級標籤 */
    currentTopTagInfo: {},
    /** 目前選擇一級標籤下 推薦 的二級標籤 */
    currentSecPromoteList: [],
    /** 目前選擇一級標籤下 非推薦 的二級標籤 */
    currentSecList: [],
  })

  const hasNoContent = (state.currentSecPromoteList?.length ?? 0) === 0 && (state.currentSecList?.length ?? 0) === 0

  /** 一級標籤清單 */
  const topTagList = useMemo(() => tagList?.filter((tag) => tag.level === 1), [tagList])

  /** 二級標籤清單 */
  const secTagList = useMemo(() => tagList?.filter((tag) => tag.level === 2), [tagList])

  useEffect(() => {
    updateState((draft) => void (draft.currentTopTagInfo = staticTopTagList[0]))
  }, [updateState])

  useEffect(() => {
    const categoryInfo = state.currentTopTagInfo

    if (categoryInfo.isStatic) {
      // don't do anything here
      let currentSecPromoteList = []
      let currentSecList = []

      updateState((draft) => {
        draft.currentSecPromoteList = currentSecPromoteList
        draft.currentSecList = currentSecList
      })
    } else {
      const currentSecPromoteList = secTagList?.filter(
        (info) => categoryInfo.id === info.parent_id && info.promote === TRUE
      )
      const currentSecList = secTagList?.filter((info) => categoryInfo.id === info.parent_id && info.promote === FALSE)

      updateState((draft) => {
        draft.currentSecPromoteList = currentSecPromoteList
        draft.currentSecList = currentSecList
      })
    }
  }, [state.currentTopTagInfo, updateState, secTagList, producerList])

  const handleCategoryItemChange = useCallback(
    (categoryInfo) => {
      updateState((draft) => {
        draft.currentTopTagInfo = { ...categoryInfo }
        draft.currentSecPromoteList = []
        draft.currentSecList = []
      })
    },
    [updateState]
  )

  const handleSecTagClick = useCallback(
    (info) => {
      navigateToSecTagList({
        headerTitle: info.name,
        params: {
          tag_ids: info.id,
        },
      })
    },
    [navigateToSecTagList]
  )

  return (
    <PageWrapper>
      <Wrapper>
        <ContentWrapper>
          <CategoryItemWrapper>
            {staticTopTagList?.map((categoryInfo, i) => (
              <CategoryItem
                key={categoryInfo?.id}
                className={classnames({
                  active: state?.currentTopTagInfo?.id === categoryInfo.id,
                })}
                onClick={() => handleCategoryItemChange(categoryInfo)}
              >
                {categoryInfo.name}
              </CategoryItem>
            ))}

            {topTagList?.map((categoryInfo, i) => (
              <CategoryItem
                key={categoryInfo?.id}
                className={classnames({
                  active: state?.currentTopTagInfo?.id === categoryInfo.id,
                })}
                onClick={() => handleCategoryItemChange(categoryInfo)}
              >
                {categoryInfo.name}
              </CategoryItem>
            ))}
          </CategoryItemWrapper>

          <CategoryContentWrapper>
            {/* 片商列表 */}
            {state?.currentTopTagInfo?.id === CODE_PRODUCER && <ContentProducer />}

            {/* 演員列表 */}
            {state?.currentTopTagInfo?.id === CODE_ACTOR && <ContentActor />}

            {/* 二級標籤 */}
            {![CODE_PRODUCER, CODE_ACTOR].includes(state?.currentTopTagInfo?.id) && (
              <>
                {/* <TitleBox>{`#${state.currentTopTagInfo.name}`}</TitleBox> */}
                {/* 推薦 的二級標籤 */}
                {state.currentSecPromoteList?.map((info, i) => (
                  <SecTagPromoteItem key={info.id} {...info} />
                ))}

                {/* 分隔區 */}
                {(state.currentSecPromoteList?.length ?? 0) > 0 && <Dividing />}

                {/* 非推薦 的二級標籤 */}
                {state.currentSecList?.map((info, i) => (
                  <SecTagItem key={info.id} onClick={() => handleSecTagClick(info)}>
                    {info?.name}
                  </SecTagItem>
                ))}

                {hasNoContent && <EmptyResult style={{ height: 'calc(100% - 1rem)' }} />}
              </>
            )}
          </CategoryContentWrapper>
        </ContentWrapper>
      </Wrapper>
    </PageWrapper>
  )
}
