import { useNavigateTo } from 'hooks/useNavigateTo'

export default function useOpenModal({ videoInfo }) {
  const { navigateToEnableVip, navigateToWallet } = useNavigateTo()
  const openEnableVipModal = () => {
    navigateToEnableVip({ fromVideo: true })
  }

  const openDepositModal = () => {
    navigateToWallet({})
  }

  return {
    openEnableVipModal,
    openDepositModal,
  }
}
