// @ts-check
import { forwardRef, useImperativeHandle, useMemo, useRef } from 'react'
import { useImmer } from 'use-immer'
import { VirtualPageElement, VirtualPagesElement, VirtualPagesScrollerElement } from './Styled'

const PAGE_PADDING_TOP = 'env(safe-area-inset-top, 0px)'
const PAGE_PADDING_BOTTOM = 'env(safe-area-inset-bottom, 0px)'

const PAGE_GAP = CSS.supports('width', 'max(0px,0px)')
  ? 'max(var(--page-padding-top), var(--page-padding-bottom))'
  : 'calc(var(--page-padding-top), var(--page-padding-bottom))'

const BASE_OFFSET = CSS.supports('width', 'max(0px,0px)') ? `max(0px, calc(${PAGE_GAP} - ${PAGE_PADDING_TOP}))` : '0px'

/**
 * [---------] [--------]
 * [page top ] [        ]
 * [---------] [        ]
 * [page     ] [ screen ]
 * [         ] [        ]
 * [         ] [        ]
 * [---------] [        ]
 * [page gap ] [        ] [--------]
 * [         ] [        ] [        ]
 * [---------] [--------] [        ]
 * [page     ]            [ screen ]
 * [         ]            [        ]
 * [         ]            [        ]
 * [---------]            [        ]
 * [page bot ]            [        ]
 * [         ]            [        ]
 * [---------]            [--------]
 */

/**
 * @typedef { 'INITIAL' | 'HOLD' | 'MOVE' | 'ANIMATE' | 'LOCKED' } State
 */

/**
 * @typedef { [x: number, y: number] } Position
 */

const MIN_MOVE_DISTANCE = 20
const TIME_WINDOW_MS = 500
const MIN_SPEED = 100
const ANIMATE_TIMEOUT = 500

const C = {
  '+': (...args) => `calc(${args.join(' + ')})`,
  '-': (a, b) => `calc(${a} - ${b})`,
  '*': (a, b) => `calc(${a} * ${b})`,
  '/': (a, b) => `calc(${a} / ${b})`,
}

export const VirtualPages = forwardRef(
  (
    /**
  @type {{
    onNext?: () => void,
    onPrev?: () => void,
    children: {
      prev?: JSX.Element,
      current: JSX.Element
      next?: JSX.Element
    }
  }}
*/ { onNext, onPrev, children },
    ref
  ) => {
    const containerRef = useRef(/** @type {HTMLDivElement | null} */ (null))
    const scrollerRef = useRef(/** @type {HTMLDivElement | null} */ (null))

    const [pageState, setPageState] = useImmer({
      /** @type {State} */
      state: 'INITIAL',
      /** @type {[x: number, y: number]} */
      initialPosition: [0, 0],
      /** -1 ~ 1 */
      pageIndex: 0,
      /** px (number)*/
      pageOffset: 0,
      /**
       * @type {[y: number, timeMs: number][]}
       */
      trace: [],
      capturedPointer: -1,
      animateDeadline: 0,
    })

    const pageWrapClassName = useMemo(() => {
      if (pageState.state === 'MOVE') {
        return 'short-video-virtual-page virtual-page-animating'
      }
      return 'short-video-virtual-page'
    }, [pageState])

    const resetPageOffset = () => {
      setPageState((arg) => {
        arg.state = 'INITIAL'
        arg.initialPosition = [0, 0]
        arg.pageIndex = 0
        arg.pageOffset = 0
        arg.trace = []
      })
    }

    useImperativeHandle(ref, () => ({ resetPageOffset }))

    const prevPage = children.prev
    const currentPage = children.current ?? <VirtualPage />
    const nextPage = children.next

    const totalPage = (prevPage ? 1 : 0) + 1 + (nextPage ? 1 : 0)
    // const initialTranslate = prevPage != null ? 1 : 0
    const PAGE_HEIGHT_TO_BASE = C['-'](C['-']('100%', PAGE_PADDING_TOP), PAGE_PADDING_BOTTOM)
    const SCROLLER_HEIGHT = C['+'](
      PAGE_PADDING_TOP,
      C['*'](PAGE_HEIGHT_TO_BASE, totalPage),
      C['*'](PAGE_GAP, totalPage - 1),
      PAGE_PADDING_BOTTOM
    )
    const PAGE_HEIGHT_TO_SCROLLER = C['/'](
      C['-'](C['-'](C['-']('100%', PAGE_PADDING_TOP), PAGE_PADDING_BOTTOM), C['*'](PAGE_GAP, totalPage - 1)),
      totalPage
    )
    const BASE_POSITION = prevPage == null ? '0px' : C['+'](PAGE_PADDING_TOP, PAGE_HEIGHT_TO_SCROLLER, BASE_OFFSET)
    const TRANSFORM = `translateY(${C['*'](
      C['+'](
        BASE_POSITION,
        C['*'](C['+'](PAGE_HEIGHT_TO_SCROLLER, PAGE_GAP), pageState.pageIndex),
        pageState.pageOffset + 'px'
      ),
      '-1'
    )})`

    const transform = TRANSFORM
    const scrollerHeight = SCROLLER_HEIGHT
    const pageHeight = PAGE_HEIGHT_TO_SCROLLER

    /**
     * @param {import('react').SyntheticEvent<HTMLDivElement, PointerEvent>} ev
     */
    const onPointerDown = (ev) => {
      // console.log('PointerDown', ev.nativeEvent.pointerId, pageState.state)
      /**
       * @type {Position}
       */
      const currentPos = [ev.nativeEvent.clientX, ev.nativeEvent.clientY]

      // prevent animate on user are pressing in player control bar
      let el = /** @type {HTMLElement | null} */ (ev.target)
      while (el) {
        if (el.className.includes('block-virtual-page')) {
          return
        }
        el = el.parentElement
      }

      if (pageState.state === 'INITIAL') {
        setPageState((arg) => {
          arg.state = 'HOLD'
          arg.initialPosition = currentPos
        })
      }
      // force update if missed for whatever reason
      if (pageState.state === 'HOLD') {
        setPageState((arg) => {
          arg.state = 'HOLD'
          arg.initialPosition = currentPos
        })
      }

      if (pageState.state === 'ANIMATE' && pageState.animateDeadline < Date.now()) {
        // force cancel animation
        scrollerRef.current?.classList.remove('has-transition')
        setPageState((arg) => {
          arg.state = 'HOLD'
          arg.initialPosition = currentPos
        })
      }
    }

    /**
     * @param {import('react').SyntheticEvent<HTMLDivElement, PointerEvent>} ev
     */
    const onPointerMove = (ev) => {
      // console.log('PointerMove', ev.nativeEvent.pointerId, pageState.state)
      /**
       * @type {Position}
       */
      const currentPos = [ev.nativeEvent.clientX, ev.nativeEvent.clientY]
      const initialPos = pageState.initialPosition

      if (
        /** @type {HTMLElement} */ (ev.target).querySelector(':scope .vjs-seek-speed-up')
        // && pageState.state === 'HOLD'
      ) {
        // 已經進行影片快轉，取消上下滑動行為
        setPageState((arg) => {
          arg.state = 'INITIAL'
        })
        return
      }

      if (pageState.state === 'HOLD') {
        const captured = containerRef.current?.hasPointerCapture(ev.nativeEvent.pointerId)
        if (Math.abs(currentPos[1] - initialPos[1]) > MIN_MOVE_DISTANCE) {
          containerRef.current?.setPointerCapture(ev.nativeEvent.pointerId)
          setPageState((arg) => {
            arg.state = 'MOVE'
            arg.initialPosition = currentPos
            arg.trace.push([currentPos[1], Date.now()])
            arg.capturedPointer = ev.nativeEvent.pointerId
          })
        }
      }

      if (pageState.state === 'MOVE') {
        const offset = -(currentPos[1] - initialPos[1])

        const now = Date.now()

        setPageState((arg) => {
          arg.pageOffset = offset
          arg.trace.push([currentPos[1], Date.now()])
          arg.trace = arg.trace.filter((i) => now - i[1] < TIME_WINDOW_MS)
        })
      }
    }

    const onPointerCancel = (ev) => {
      // console.log('PointerCancel', ev.nativeEvent.pointerId, pageState.state)
      if (pageState.state === 'HOLD') {
        setPageState((arg) => {
          arg.state = 'INITIAL'
          arg.pageIndex = 0
          arg.pageOffset = 0
          arg.trace = []
        })
      }
      if (pageState.state === 'MOVE') {
        containerRef.current?.releasePointerCapture(pageState.capturedPointer)
        scrollerRef.current?.classList.add('has-transition')
        setPageState((arg) => {
          arg.state = 'ANIMATE'
          arg.pageIndex = 0
          arg.pageOffset = 0
          arg.trace = []
        })
      }
    }

    /**
     * @param {import('react').SyntheticEvent<HTMLDivElement, PointerEvent>} ev
     */
    const onPointerUp = (ev) => {
      // console.log('PointerUp', ev.nativeEvent.pointerId, pageState.state)
      if (pageState.state === 'HOLD') {
        setPageState((arg) => {
          arg.state = 'INITIAL'
          arg.pageIndex = 0
          arg.pageOffset = 0
          arg.trace = []
        })
      }
      if (pageState.state === 'MOVE') {
        const height = containerRef.current?.getBoundingClientRect().height ?? 0
        containerRef.current?.releasePointerCapture(pageState.capturedPointer)
        if (Math.abs(pageState.pageOffset) > height * 0.5) {
          scrollerRef.current?.classList.add('has-transition')
          setPageState((arg) => {
            arg.state = 'ANIMATE'
            arg.pageIndex = pageState.pageOffset < 0 ? -1 : 1
            arg.pageOffset = 0
            arg.trace = []
          })

          return
        }
        // check speed
        const now = Date.now()
        const trace = pageState.trace.filter((i) => now - i[1] < TIME_WINDOW_MS)
        const start = trace[0]
        const end = trace[trace.length - 1]
        const speed = start == null || end == null ? 0 : (end[0] - start[0]) / ((end[1] - start[1]) / 1000)

        if (Math.abs(speed) > MIN_SPEED) {
          scrollerRef.current?.classList.add('has-transition')
          setPageState((arg) => {
            arg.state = 'ANIMATE'
            arg.pageIndex = speed > 0 ? -1 : 1
            arg.pageOffset = 0
            arg.trace = []
            arg.animateDeadline = Date.now() + ANIMATE_TIMEOUT
          })
          return
        }

        // else, just cancel
        if (pageState.pageOffset !== 0) {
          scrollerRef.current?.classList.add('has-transition')
          setPageState((arg) => {
            arg.state = 'ANIMATE'
            arg.pageIndex = 0
            arg.pageOffset = 0
            arg.trace = []
            arg.animateDeadline = Date.now() + ANIMATE_TIMEOUT
          })
        } else {
          setPageState((arg) => {
            arg.state = 'INITIAL'
            arg.pageIndex = 0
            arg.pageOffset = 0
            arg.trace = []
          })
        }
      }
    }

    /**
     * @param {import('react').SyntheticEvent<HTMLDivElement, TransitionEvent>} ev
     */
    const onTransitionEnd = (ev) => {
      // console.log('onTransitionEnd', pageState.state)
      if (pageState.state === 'ANIMATE') {
        scrollerRef.current?.classList.remove('has-transition')
        if (pageState.pageIndex === 0) {
          resetPageOffset()
        } else {
          setPageState((arg) => {
            arg.state = 'LOCKED'
          })
          if (pageState.pageIndex > 0) {
            onNext?.()
          }
          if (pageState.pageIndex < 0) {
            onPrev?.()
          }
        }
      }
    }

    return (
      <VirtualPagesElement
        ref={containerRef}
        onPointerDown={onPointerDown}
        onPointerMove={onPointerMove}
        onPointerUp={onPointerUp}
        onPointerCancel={onPointerCancel}
        onTransitionEnd={onTransitionEnd}
        className={pageWrapClassName}
        style={
          /** @type {import('react').CSSProperties} */ ({
            '--scroller-height': scrollerHeight,
            '--page-height': pageHeight,
            '--page-gep': PAGE_GAP,
            '--page-padding-top': PAGE_PADDING_TOP,
            '--page-padding-bottom': PAGE_PADDING_BOTTOM,
          })
        }
      >
        {/** wrapper */}
        <VirtualPagesScrollerElement
          ref={scrollerRef}
          style={{
            transform,
          }}
        >
          {nextPage ? <div style={{ height: PAGE_PADDING_TOP }} /> : null}
          {prevPage ? <prevPage.type {...prevPage.props} /> : null}
          {prevPage ? <div style={{ height: PAGE_GAP }} /> : <div style={{ height: PAGE_PADDING_TOP }} />}
          {currentPage}
          {nextPage ? <div style={{ height: PAGE_GAP }} /> : <div style={{ height: PAGE_PADDING_BOTTOM }} />}
          {nextPage}
          {nextPage ? <div style={{ height: PAGE_PADDING_BOTTOM }} /> : null}
        </VirtualPagesScrollerElement>
      </VirtualPagesElement>
    )
  }
)

export const VirtualPage = ({ children = null, ...props }) => {
  return <VirtualPageElement {...props}>{children}</VirtualPageElement>
}
