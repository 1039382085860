import { createSlice } from '@reduxjs/toolkit'
import { combinedStorage } from 'utils/combinedStorage'
import { TOKEN_KEY } from 'redux/constant/user'

const INVITE_CODE_KEY = 'invited_by'

const initialState = {
  userInfo: {
    name: '',
    id: '',
  },
  /** Token 資訊 */
  tokenInfo: {},
  /** 代理邀請碼 */
  inviteCode: localStorage.getItem(INVITE_CODE_KEY) ?? null,
}

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUser: (state, action) => {
      state.userInfo = action.payload
    },
    clearUser: (state, action) => {
      state.userInfo = initialState.userInfo
      localStorage.removeItem(TOKEN_KEY)
    },
    updateTokenInfo: (state, action) => {
      state.tokenInfo = action.payload
      localStorage.setItem(TOKEN_KEY, JSON.stringify(action.payload))
    },
    updateInviteCode: (state, action) => {
      combinedStorage.setItem(INVITE_CODE_KEY, action.payload)
      state.inviteCode = action.payload
    },
  },
})

export const { actions } = slice
export default slice.reducer
