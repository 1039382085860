import styled from 'styled-components'

export const ListBox = styled.div`
  padding: var(--cms-grid-row-gap) 0 calc(var(--cms-grid-row-gap) + env(safe-area-inset-bottom, 0));
`

export const ItemWrapper = styled.div`
  --columns: 4;
  width: calc(calc(100% - var(--cms-grid-column-gap, 0.093rem) * calc(var(--columns) - 1)) / var(--columns));
  display: flex;
  flex-direction: column;
  align-items: stretch;
`
