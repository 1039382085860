import { useMemo } from 'react'
import { PromoteFlag, RecommendFlag, Wrapper } from './styled'

export default function ChannelButton({ data, active, onClick, ...props }) {
  const hasDiscount = useMemo(() => data.discountRatio > 0, [data])
  return (
    <Wrapper onClick={onClick} className={active ? 'active' : ''} props={props}>
      <span className="button-text">{data.name}</span>
      {hasDiscount && <PromoteFlag>送{data.discountRatio}%</PromoteFlag>}
      {data.isRecommend && <RecommendFlag>推</RecommendFlag>}
    </Wrapper>
  )
}
