import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--component-modal-alert-color);
  font-size: 0.373rem;
  font-weight: 400;
  padding: 0.205rem 0.41rem;
  // just make it big enough
  border-radius: 10rem;
  pointer-events: all;
  background: var(--component-modal-alert-background);
`
