import { useMemo, useState } from 'react'
import { Wrapper } from './styled'
import { ColoredIcon } from 'component/ColoredIcon'
import classNames from 'classnames'

export default function RefreshButton({ img, onClick, buttonBackground = true }) {
  const [processing, setProcessing] = useState(false)

  function handleClick() {
    onClick()
    setProcessing(true)
    setTimeout(() => setProcessing(false), 1000)
  }

  const style = useMemo(() => {
    return {
      background: buttonBackground ? 'var(--page-game--balance-bar-button-background)' : '',
    }
  }, [buttonBackground])

  return (
    <Wrapper onClick={handleClick} style={style}>
      <ColoredIcon
        src={img}
        alt=""
        className={classNames(processing ? 'loading' : '', buttonBackground ? 'white' : '')}
      />
    </Wrapper>
  )
}
