import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  /** 提示 Modal 資料 */
  hintModalData: {
    children: null,
    props: null,
  },
  /** 全局 Modal 資料 */
  globalModalData: {
    children: null,
    props: null,
  },
}

const slice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openHintModal: (state, action) => {
      state.hintModalData = action.payload
    },
    closeHintModal: (state, action) => {
      state.hintModalData = initialState.hintModalData
    },
    openGlobalModal: (state, action) => {
      state.globalModalData = action.payload
    },
    closeGlobalModal: (state, action) => {
      state.globalModalData = initialState.globalModalData
    },
  },
})

export const { actions } = slice
export default slice.reducer
