import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  font-size: 0.358rem;

  --category-grid-gap: 0.1rem;
  --category-content-side-padding: 0.15rem;
`

export const HeaderTitle = styled.div`
  padding-top: env(safe-area-inset-top);
  font-weight: bold;
  font-size: 0.5128rem;
  line-height: 1.54rem;
  box-sizing: content-box;
  height: 1.54rem;
  color: var(--page-header-color);
  background: var(--page-header-background);
  align-self: stretch;
  text-align: center;
`

export const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  gap: 0 var(--category-grid-gap);
  overflow: hidden;
  padding: 0.2rem 0.2rem;
`

export const CategoryItemWrapper = styled.div`
  width: 2.31rem;
  background: var(--page-category-sidebar-background);
  overflow: hidden auto;
`

export const CategoryItem = styled.button`
  width: 100%;
  height: 45px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.active {
    background: var(--page-category-sidebar-active-background);
    color: var(--page-category-sidebar-active-color);
  }
`

export const CategoryContentWrapper = styled.div`
  padding: var(--category-grid-gap) var(--category-content-side-padding);
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: var(--category-grid-gap);
  background: var(--page-category-main-background);
  overflow: hidden auto;
`

export const SecTagItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  // gap為4px, 因為一行三個, 會有兩個 gap , 所以是 8px/3
  width: calc((100% / 3) - (calc(var(--category-grid-gap) * 2) / 3));
  height: 0.94rem;
  background: var(--page-category-tag-background);
  color: var(--page-category-tag-color);
  border-radius: 0.15rem;
  overflow: hidden;
  white-space: nowrap;
  font-size: 0.358rem;
`

export const TitleBox = styled.div`
  width: 100%;
  margin: 10px 0 3px 0;
  color: var(--main-color);
`

export const Dividing = styled.div`
  width: 100%;
`
