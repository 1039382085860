import styled from 'styled-components'
import { CmsGridWrapper, PageWrapper } from 'commonStyledComponents'

export const Wrapper = styled(PageWrapper)`
  display: flex;
  flex-direction: column;
`

export const ContentWrapper = styled.div`
  /** 這邊是用在 Modal, 所以使用 height: 100% */
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`

export const Controller = styled.div`
  flex-shrink: 0;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 var(--cms-grid-column-gap);
`

export const SearchBox = styled.div`
  display: flex;
  flex: 1;
`

export const IconBackWrapper = styled.button`
  position: relative;
  display: flex;
  padding: 0.2051rem;
  box-sizing: content-box;
  color: var(--page-header-color);
  &::after {
    content: '';
    position: absolute;
    display: block;
    left: var(--button-live-zone-padding);
    right: var(--button-live-zone-padding);
    bottom: var(--button-live-zone-padding);
    top: var(--button-live-zone-padding);
  }
`

export const Container = styled.div`
  overflow: auto;
  padding-bottom: var(--cms-grid-column-gap);
`

export const StyledCmsGridWrapper = styled(CmsGridWrapper)`
  padding-top: var(--cms-grid-row-gap);
  padding-bottom: var(--cms-grid-row-gap);
`

export const InlineHeaderWrap = styled.div`
  flex: 0 0 auto;
  padding: 0.21rem 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 0.21rem;
  background: var(--page-header-background);
  color: var(--page-header-color);
  --component-tabs--color: currentColor;
`
