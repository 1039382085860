import { useRef, useMemo, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { FETCH_USER_INFO } from 'redux/constant/user'

import { useAlert, positions } from 'react-alert'
import { fetchFindPreviousAccount } from 'api'

import HeaderBox from 'component/PageModalHeaderBox'

import useOpenModal from './hooks/useOpenModal'

import { Content, Item, ArrowRight } from './Styled'

import md5 from 'md5'
import { HeaderWrap, PageScrollWrap, PageWrapperNonScroll } from 'commonStyledComponents'
import IDCardScanner from './component/IDCardScanner'
import { useNavigateTo } from 'hooks/useNavigateTo'
import { useAppNavigate } from 'app-layered-layout/useAppNavigate'

export const checkDebugSecret = (str) => {
  return md5('SSSSEEECCCRRREEETTT' + (str ?? '')) === '725ea846597e362511f6bae1d8d068db'
}

export default function SystemConfig() {
  const dispatch = useDispatch()
  const { openIDCard } = useOpenModal()
  const navigate = useAppNavigate()
  const { navigateToDebugPage } = useNavigateTo()
  const [scannerShowing, setScannerShowing] = useState()

  const handleFindPreviousAccount = useCallback(() => {
    setScannerShowing(true)
  }, [])

  const alert = useAlert()

  const onScan = (decodedText) => {
    // success, use decodedText
    console.log(decodedText)
    fetchFindPreviousAccount({ code: decodedText })
      .then(() => {
        // 重新使用deviceId獲取用戶資料
        setScannerShowing(false)
        alert.info('欢迎回来', {
          position: positions.MIDDLE,
          offset: '300px',
          timeout: 1500,
        })
        dispatch({ type: FETCH_USER_INFO })
        setTimeout(() => {
          navigate('/', { replace: true, dropStack: true })
        }, 1500)
      })
      .catch((e) => {
        console.log(e)
        if (e.message) {
          alert.info('无法找回会员ID，' + e.message, {
            position: positions.MIDDLE,
            offset: '300px',
            timeout: 1500,
          })
        } else {
          alert.info('无法找回会员ID，请联系客服' + e.message, {
            position: positions.MIDDLE,
            offset: '300px',
            timeout: 1500,
          })
        }
        // openHintModal({
        //   children: FindAccountFailed,
        //   props: {},
        // });
      })
  }
  const onScanError = () => {
    // openHintModal({
    //   children: FindAccountFailed,
    //   props: {},
    // });

    alert.info('辨识失败，这似乎不是正确的身份卡', {
      position: positions.MIDDLE,
      offset: '300px',
      timeout: 1500,
    })
  }

  const items = useMemo(
    () => [
      {
        label: '帐号凭证',
        callback: openIDCard,
      },
      {
        label: '找回帐号',
        callback: handleFindPreviousAccount,
      },
    ],
    [handleFindPreviousAccount, openIDCard]
  )

  const clickCount = useRef(0)

  function onClickVersion() {
    clickCount.current++
    if (clickCount.current > 10) {
      clickCount.current = 0
      const input = prompt()
      if (checkDebugSecret(input)) {
        navigateToDebugPage({
          secret: input,
        })
      }
    }
  }

  return (
    <PageWrapperNonScroll>
      <HeaderWrap>
        <HeaderBox headerTitle={'设置页面'} />
      </HeaderWrap>
      <PageScrollWrap noPadding>
        <Content>
          {items?.map((item, i) => {
            return (
              <Item key={i} onClick={item.callback}>
                {item.label}
                <ArrowRight />
              </Item>
            )
          })}

          <Item onClick={onClickVersion}>
            当前版本号码
            <div>{process.env.REACT_APP_VERSION}</div>
          </Item>

          {scannerShowing && (
            <IDCardScanner
              qrCodeSuccessCallback={onScan}
              qrCodeErrorCallback={onScanError}
              onClose={() => setScannerShowing(false)}
            />
          )}
        </Content>
      </PageScrollWrap>
    </PageWrapperNonScroll>
  )
}
