import { CmsGridWrapper } from 'commonStyledComponents'
import AreaTitle from 'component/AreaTitle'
import styled from 'styled-components'

export const Container = styled(CmsGridWrapper)`
  margin: var(--cms-grid-row-gap) 0;
`

export const Title = styled(AreaTitle).attrs({ hasDecoration: true })`
  margin: var(--cms-grid-row-gap) 0;
`
