import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 1.7948rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  color: #ffffff;
`

export const Icon = styled.div.attrs((props) => {
  const imageInfo = props?.base64Img && {
    backgroundImage: `url(data:image/jpeg;base64,${props?.base64Img})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  }

  return {
    style: {
      ...imageInfo,
    },
  }
})`
  width: 100%;
  height: 1.7948rem;
  border-radius: 0.2564rem;
  background: url(${process.env.PUBLIC_URL}/loading/square-loading.svg) no-repeat center;
  background-size: cover;
  transition: background 0.2s ease-in;
`

export const Name = styled.div`
  margin-top: 7px;
`
