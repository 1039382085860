import AnimatedIcon from 'component/AnimatedIcon'
import styled from 'styled-components'

export const Wrapper = styled.div`
  min-width: 0;
  flex: 1 1 auto;
  align-self: stretch;
  overflow: auto;
  background: var(--page-view-comic-chapter--background);
  color: var(--page-view-comic-chapter--color);
`

export const BottomHint = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.4rem;
  line-height: 0.6rem;
`
export const Header = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: calc(env(safe-area-inset-top) + 0.75rem);
  padding-top: env(safe-area-inset-top);
  background: var(--page-view-comic-chapter--header-footer-background);
  color: var(--page-view-comic-chapter--header-footer-color);
  transition: all 0.5s;
  transform: translateY(0%);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.4rem;

  &.hidden {
    transform: translateY(-100%);
  }
`

export const HeaderClose = styled.button`
  position: absolute;
  top: calc(50% + calc(env(safe-area-inset-top, 0px) / 2));
  transform: translateY(-50%);
  left: 0;
  width: 0.8rem;
  height: 0.8rem;
  background-size: 50% 50%;
  background-image: url('${process.env.PUBLIC_URL}/comic-icons/chapter_close.svg');
  background-repeat: no-repeat;
  background-position: center;
  border: 0;
  padding: 0;
`

export const Footer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: calc(env(safe-area-inset-bottom) + 2.38rem);
  background: var(--page-view-comic-chapter--header-footer-background);
  color: var(--page-view-comic-chapter--header-footer-color);
  transition: all 0.5s;
  transform: translateY(0%);

  &.hidden {
    transform: translateY(100%);
  }

  display: flex;
  justify-content: space-between;
  align-items: stretch;
`
export const FooterButton = styled.button`
  min-width: 0;
  width: 1.69rem;
  padding-top: 0.4rem;
  padding-bottom: calc(0.4rem + env(safe-area-inset-bottom, 0));
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  font-size: 0.35rem;
  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }
`

export const FooterButtonIcon = styled.img`
  width: 0.8rem;
  height: 0.8rem;
`

export const FooterButtonIconChapter = styled(FooterButtonIcon).attrs({
  src: `${process.env.PUBLIC_URL}/comic-icons/chapters.svg`,
})``
export const FooterButtonIconChapterPrev = styled(FooterButtonIcon).attrs({
  src: `${process.env.PUBLIC_URL}/comic-icons/chapter_prev.svg`,
})``

export const FooterButtonIconChapterNext = styled(FooterButtonIcon).attrs({
  src: `${process.env.PUBLIC_URL}/comic-icons/chapter_next.svg`,
})``

export const StyledAnimatedIcon = styled(AnimatedIcon)`
  height: 1rem;
  width: 1rem;
  margin: -0.15rem !important;
`
