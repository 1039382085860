import { Wrapper, BalanceWrapper, ShortcutWrapper, ShortcutButton, Balance, Button, CopyButton, UidBox } from './styled'
import RefreshButton from '../RefreshButton'
import { formatAmount } from 'utils/amountFormat'
import { useCallback } from 'react'
// import { useAlert } from 'react-alert'
import { useToastAlert } from 'hooks/useToastAlert'

export default function BalanceBar({
  uid,
  balance,
  onRefresh,
  toGameDeposit,
  toGameWithdraw,
  toGamePromotion,
  toService,
}) {
  // const uidRef = useRef(null)
  const { toast } = useToastAlert()

  const handleCopyUid = useCallback(async () => {
    if (uid && navigator.clipboard) {
      await navigator.clipboard.writeText(uid)
      toast('已复制ID至剪贴簿')
    }
  }, [toast, uid])

  return (
    <Wrapper>
      <BalanceWrapper>
        <div>
          ID:{uid}
          <CopyButton onClick={handleCopyUid}>
            <img src={`${process.env.PUBLIC_URL}/icon/copy.png`} alt="复制"></img>
          </CopyButton>
          {/* <UidBox defaultValue={uid} ref={uidRef}></UidBox> */}
        </div>
        <Balance>
          <span>￥{formatAmount(balance)}</span>
          <RefreshButton img={`${process.env.PUBLIC_URL}/icon/refresh.svg`} onClick={onRefresh}></RefreshButton>
        </Balance>
      </BalanceWrapper>
      <ShortcutWrapper>
        <ShortcutButton onClick={toGameDeposit}>
          <img src={`${process.env.PUBLIC_URL}/game/icon-save.png`} alt="存款"></img>
          <span>存款</span>
        </ShortcutButton>
        <ShortcutButton onClick={toGameWithdraw}>
          <img src={`${process.env.PUBLIC_URL}/game/icon-withdraw.png`} alt="取款"></img>
          <span>取款</span>
        </ShortcutButton>
        <ShortcutButton onClick={toGamePromotion}>
          <img src={`${process.env.PUBLIC_URL}/game/icon-bonus.png`} alt="优惠"></img>
          <span>优惠</span>
        </ShortcutButton>
        <ShortcutButton onClick={toService}>
          <img src={`${process.env.PUBLIC_URL}/game/icon-service.png`} alt="客服"></img>
          <span>客服</span>
        </ShortcutButton>
      </ShortcutWrapper>
    </Wrapper>
  )
}
