import styled from 'styled-components'

export const LogoInfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 0.3rem;
  margin: 0.5rem 0;
`

export const Branding = styled.div`
  background: url('${process.env.PUBLIC_URL}/profile-icon/about-branding.svg');
  background-size: cover;
  background-repeat: no-repeat;
  width: 2.05128rem;
  height: 2.05128rem;
`

export const Version = styled.div`
  color: white;
  font-size: 0.35897rem;
  line-height: 150%;
`
export const Name = styled.span`
  font-weight: 700;
`
export const Desc = styled.div`
  color: white;
  font-size: 0.35897rem;
  line-height: 150%;
  font-weight: 700;
  background: linear-gradient(136.12deg, #ffcef4 25.88%, #ff3f7a 79.45%);
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  background-clip: text !important;
  text-fill-color: transparent !important;
`

export const Detail = styled.div`
  padding: 0 0.4rem;
`

export const DetailTitle = styled.h2`
  color: var(--system-white, #fff);
  font-size: 0.35897rem;
  font-family: Noto Sans SC;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.00974rem;
  margin: 0.62rem 0 0.21rem;

  padding-left: 0.31rem;
  position: relative;

  ::after {
    position: absolute;
    left: 0.1rem;
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    background: white;
    content: '';
    display: block;
    width: 0.15rem;
    height: 0.15rem;
  }
`

export const DetailText = styled.p`
  color: var(--system-gray-ec, #ececec);
  /* Regular/Body Text */
  font-size: 0.35897rem;
  font-family: Noto Sans SC;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.01026rem;
`
