import axios from 'axios'
import UAParser from 'ua-parser-js'
import { fetchApi } from './config'
import globalStore from 'redux/store'
import actions from 'redux/action'
import { aesCbcBase64 } from 'utils/cryoto'

import { TRUE, FALSE, ENABLED } from 'constant/common'
import { COMIC_CHAPTER_STATE_ON } from 'constant/comic'
import { combinedStorage } from 'utils/combinedStorage'

const HAD_LOGON_KEY = 'lo'

// 遊客身份登入
export async function fetchTouristLogin({ visitorId, inviteCode } = {}) {
  const superSecretHeader = await aesCbcBase64(`${visitorId}&cookie`)

  if (process.env.NODE_ENV === 'development') {
    console.log(`${visitorId}&cookie`, superSecretHeader)
  }

  const parser = new UAParser(navigator.userAgent)
  const parserResults = parser.getResult()

  let isFirst = combinedStorage.getItem(HAD_LOGON_KEY) == null

  const params = {
    url: '/member/establish',
    data: {
      device_id: visitorId,
      device_type: 'cookie',
      invite_code: inviteCode || process.env.REACT_APP_DEFAULT_INVITE_CODE,
      os_version: parserResults.os.name + ' ' + parserResults.os.version,
      phone_device: parserResults.device.vendor + ' ' + parserResults.device.model,
      version: process.env.REACT_APP_VERSION,
      is_first: isFirst ? TRUE : FALSE,
    },
    method: 'POST',
    headers: {
      Conection: superSecretHeader,
    },
  }

  // the content does not matter
  combinedStorage.setItem(HAD_LOGON_KEY, ~~(Math.random() * 100000))
  return fetchApi(params)
}

// 刷新Token
export function fetchRefreshToken() {
  const params = {
    url: '/member/refresh-token',
    data: {},
    method: 'POST',
  }
  return fetchApi(params)
}

// 獲取手機驗證碼
export function fetchVerificationCode({ phone } = {}) {
  const params = {
    url: '/member/phone',
    data: {
      phone,
    },
    method: 'POST',
  }
  return fetchApi(params)
}

// 註冊帳號
export function fetchRegister({ user_name, password, phone_code } = {}) {
  const params = {
    url: 'member/register',
    data: {
      user_name,
      password,
      phone_code,
    },
    method: 'POST',
  }
  return fetchApi(params)
}

// 取得帳號Qrcode
export function fetchDeviceQrcode() {
  const params = {
    url: 'member/bind_dev',
    data: {},
    method: 'GET',
  }
  return fetchApi(params)
}

// 找回帳號
export function fetchFindPreviousAccount({ code } = {}) {
  const params = {
    url: 'member/bind_dev',
    data: {
      code,
    },
    method: 'POST',
  }
  return fetchApi(params)
}

// 獲取用戶資料
export function fetchUserInfo({ signal } = {}) {
  const params = {
    url: '/member/me',
    data: {},
    method: 'GET',
    signal,
  }
  return fetchApi(params)
}

// 帳戶登入
export function fetchLogin({ user_name, password } = {}) {
  const params = {
    url: '/member/login',
    data: {
      user_name,
      password,
    },
    method: 'POST',
  }
  return fetchApi(params)
}

// 帳戶登出
export function fetchLogout(data) {
  globalStore.dispatch(actions.clearUser())

  const params = {
    url: '/api/Admin/Logout',
    data,
    method: 'POST',
  }

  return fetchApi(params)
}

// THIS IS FIXED TO 1 due to legacy reason
const LEGACY_SITE_ID = '1'

// 獲取Content Management System Information 資訊
export function fetchNavigationContent() {
  const params = {
    url: process.env.REACT_APP_HAS_COMIC
      ? '/cms/publish/result_v6'
      : process.env.REACT_APP_HAS_SHORT
      ? '/cms/publish/result_v4'
      : '/cms/publish/result_v2',
    data: {
      site_id: LEGACY_SITE_ID,
    },
    method: 'GET',
  }

  return fetchApi(params)
}

// 獲取影片列表
export function fetchVideoList({
  signal,
  id,
  category_ids,
  actor_ids,
  region_id,
  region_ids,
  producer_id,
  producer_ids,
  tag_ids,
  sortby,
  video_code,
  payment_type,
  current = 1,
  pageSize = 500,
  /** 是否要拿到片商的數量詳情, 預設為 False */
  detail = FALSE,
} = {}) {
  const params = {
    url: '/video',
    data: {
      current,
      pageSize,
      state: 2, // (1:未上架 2:上架 3:下架)

      /** 是否要拿到片商的數量詳情? 要拿到的話要帶 Id */
      id,
      detail,
      category_ids,
      actor_ids,
      region_id,
      region_ids,
      producer_id,
      producer_ids,
      tag_ids,
      sortby,

      video_code,
      /** 是否指定查番號(1:使用 2:不使用) */
      ...(video_code ? { is_specify_video_code: 1 } : {}),

      /** 付費類型(0:不限, 1:免費, 2:金幣, 3:VIP) */
      payment_type,
    },
    signal,
  }

  return fetchApi(params)
}

// 獲取影片金鑰
export function fetchVideoKey({ id } = {}) {
  const params = {
    url: `/video/key/${id}`,
    data: {},
  }

  return fetchApi(params)
}

// 購買金幣影片
export function fetchBuyVideo({ video_id } = {}) {
  const params = {
    url: `/member_video/buy_video`,
    data: {
      video_id,
    },
    method: 'POST',
  }

  return fetchApi(params)
}

// 購買金幣影片
export function fetchBuyShort({ video_id } = {}) {
  const params = {
    url: `/member_video/buy_video`,
    data: {
      video_id,
    },
    method: 'POST',
  }

  return fetchApi(params)
}

// 獲取標籤列表
export function fetchVideoTagList() {
  const params = {
    url: '/video/tag',
    data: {
      current: 1,
      pageSize: 500,
      // DO NOT CHANGE: 播放詳情頁面的 tag 含已關閉標籤
      // status: ENABLED
    },
  }

  return fetchApi(params)
}

// 獲取標籤列表
export function fetchVideoTagListWithParam({ type, promote, signal }) {
  const params = {
    signal,
    url: '/video/tag',
    data: {
      current: 1,
      pageSize: 500,
      promote,
      type,
    },
  }

  return fetchApi(params)
}

// 獲取短視頻標籤
export function fetchVideoTopicList({ promote, signal }) {
  const params = {
    signal,
    url: '/video/topic',
    data: {
      current: 1,
      pageSize: 500,
      promote,
    },
  }
  return fetchApi(params)
}

// 獲取片商列表
export function fetchProducerList({ id } = {}) {
  const params = {
    url: '/video/producer',
    data: {
      current: 1,
      pageSize: 500,
    },
  }
  if (id) params.data.id = id

  return fetchApi(params)
}

// 獲取演員列表
export function fetchActorList() {
  const params = {
    url: '/video/actor',
    data: {
      current: 1,
      pageSize: 500,
    },
  }

  return fetchApi(params)
}

// 獲取演員資訊列表
export function fetchActorInfoList({ signal, ids, region_id, discover_page, current = 1, pageSize = 30 } = {}) {
  const params = {
    url: '/video/actor',
    data: {
      ids,
      region_id,
      discover_page,
      current,
      pageSize,
    },
    signal,
  }

  return fetchApi(params)
}

// 獲取片商資訊列表
export function fetchProducerInfoList({ id, current = 1, pageSize = 30 } = {}) {
  const params = {
    url: '/video/producer',
    data: {
      id,
      current,
      pageSize,
    },
  }

  return fetchApi(params)
}

// 讀取Bin檔
// export function fetchBinFile({ url }) {
//   return new Promise((resolve, reject) => {
//     var request = new XMLHttpRequest();
//     request.open("GET", url);
//     request.onload = function () {
//       if (request.status === 200) {
//         resolve(request.response);
//       } else {
//         reject(new Error(request));
//       }
//     };
//     request.send();
//   });
// }

// 讀取Bin檔
export function fetchBinFile({ url, responseType, signal } = {}) {
  const params = {
    responseType,
    signal,
  }

  return axios.get(url, params)
}

// 獲取商品列表
export function fetchProductList({ current = 1, pageSize = 100, product_type, signal } = {}) {
  const params = {
    url: '/product',
    data: {
      current,
      pageSize,
      product_type, // (1:金幣 2:VIP)
      status: ENABLED,
    },
    signal,
  }

  return fetchApi(params)
}

// 購買指定商品
export function fetchPurchaseProduct({ payTypeId, productId } = {}) {
  const params = {
    url: '/member_order',
    data: {
      pay_type_id: payTypeId, // 支付渠道ID
      product_id: productId, // 商品ID
      return_url: '', // 完成訂單跳轉地址
    },
    method: 'POST',
  }

  return fetchApi(params)
}

// 獲取支付方式(支付寶/微信/銀聯 etc.)
export function fetchPayTypeList({ current = 1, pageSize = 500 } = {}) {
  const params = {
    url: '/pay/type',
    data: {
      current,
      pageSize,
      status: ENABLED,
    },
  }

  return fetchApi(params)
}

// 購買指定商品
export async function fetchRegionList() {
  const params = {
    url: '/video/region',
    data: {
      current: 1,
      pageSize: 500,
    },
  }

  return /** @type {import('../../types/api').SchemaVideoRegion} */ ((await fetchApi(params)).data)
}

// 獲取瀏覽紀錄列表
export function fetchVideoHistoryList({ signal, current = 1, pageSize = 30 } = {}) {
  const params = {
    url: '/video',
    data: {
      current,
      watch_history: TRUE,
      pageSize,
    },
    signal,
  }

  return fetchApi(params)
}

// 獲取我的最愛列表
export function fetchVideoFavouriteList({ signal, current = 1, pageSize = 30 } = {}) {
  const params = {
    url: '/video',
    data: {
      current,
      like_history: TRUE,
      pageSize,
    },
    signal,
  }

  return fetchApi(params)
}

// 獲取我的收藏列表
export function fetchVideoCollectionList({ signal, current = 1, pageSize = 30 } = {}) {
  const params = {
    url: '/video',
    data: {
      current,
      favorite_history: TRUE,
      pageSize,
    },
    signal,
  }

  return fetchApi(params)
}

export function fetchCountViewVideo({ video_id } = {}) {
  const params = {
    url: '/member_video/watch_video',
    data: {
      video_id,
    },
    method: 'POST',
  }

  return fetchApi(params)
}

export function fetchAvailableOrigin() {
  const params = {
    url: '/system/domain',
    data: {},
  }

  return fetchApi(params)
}

// 獲取我的購買列表(影片消費紀錄)
export function fetchVideoBuyList({ signal, current = 1, pageSize = 30 } = {}) {
  const params = {
    url: '/video',
    data: {
      current,
      buy_history: TRUE,
      pageSize,
    },
    signal,
  }

  return fetchApi(params)
}

// 依照關鍵字搜尋影片
export function fetchVideoListByWords({ signal, title, current = 1, pageSize = 30 } = {}) {
  const params = {
    url: '/video',
    data: {
      title,
      current,
      pageSize,
    },
    signal,
  }

  return fetchApi(params)
}

// 依照條件搜尋獲取影片的總數量
export function fetchVideoTotalQty({
  signal,
  category_ids,
  actor_ids,
  region_id,
  region_ids,
  producer_id,
  producer_ids,
  tag_ids,
  payment_type,
} = {}) {
  const params = {
    url: '/video',
    data: {
      only_count: TRUE,
      category_ids,
      actor_ids,
      region_id,
      region_ids,
      producer_id,
      producer_ids,
      tag_ids,
      payment_type,
    },
    signal,
  }

  return fetchApi(params)
}

// 加入喜歡
export function fetchAddLikeList({ signal, video_id } = {}) {
  const params = {
    url: '/member_video/like',
    method: 'POST',
    data: {
      video_id,
    },
    signal,
  }

  return fetchApi(params)
}

// 刪除喜歡
export function fetchRemoveLikeList({ signal, video_id } = {}) {
  const params = {
    url: `/member_video/like/${video_id}`,
    method: 'DELETE',
    signal,
  }

  return fetchApi(params)
}

// 加入收藏
export function fetchAddCollectList({ signal, video_id } = {}) {
  const params = {
    url: '/member_video/favorite',
    method: 'POST',
    data: {
      video_id,
    },
    signal,
  }

  return fetchApi(params)
}

// 刪除收藏
export function fetchRemoveCollectList({ signal, video_id } = {}) {
  const params = {
    url: `/member_video/favorite/${video_id}`,
    method: 'DELETE',
    signal,
  }

  return fetchApi(params)
}

// 應用中心/廣告API
export function fetchAdList({
  signal,
  siteType = null,
  start_status = 1, // 開始狀態(1:開始 2:未開始)
  expire_status = 1, // 過期狀態(1:未到期 2:到期)
  current = 1,
  pageSize = 500,
} = {}) {
  const params = {
    url: '/oholo/oholo_material/v2',
    method: 'GET',
    data: {
      site_type: siteType,
      start_status,
      expire_status,
      current,
      pageSize,
    },
    signal,
  }

  return fetchApi(params)
}

// 廣告點擊事件
export function fetchPostAdEvent({ date, material_id, material_name, oholo_site_id, oholoer_id }) {
  // material_id = isNaN(material_id) ? material_id : Number(material_id)
  // oholo_site_id = isNaN(oholo_site_id) ? oholo_site_id : Number(oholo_site_id)
  const params = {
    url: '/event/oholo',
    method: 'POST',
    data: {
      data: [
        {
          date,
          material_id: Number(material_id),
          material_name,
          oholo_site_id: Number(oholo_site_id),
          oholo_site_name: '',
          oholoer_id: Number(oholoer_id),
          count: 1,
        },
      ],
    },
  }

  return fetchApi(params)
}

export function fetchAnnouncement({ signal } = {}) {
  const params = {
    url: '/announce',
    method: 'GET',
    data: {
      status: ENABLED,
      only_count: FALSE, // 查詢僅回總數 (1:是 2:否)
      device_type: 'cookie',
      current: 1,
      pageSize: 500,
    },
    signal,
  }

  return fetchApi(params)
}

// 獲取short列表
export function fetchShortList({
  signal,
  id,
  category_ids,
  actor_ids,
  region_id,
  region_ids,
  producer_id,
  tag_ids,
  sortby,
  topic_ids,
  payment_type,
  current = 1,
  uploader_id,
  pageSize = 500,
  /** 是否要拿到片商的數量詳情, 預設為 False */
  detail = FALSE,
} = {}) {
  const params = {
    url: '/video/short',
    data: {
      current,
      pageSize,
      state: 2, // (1:未上架 2:上架 3:下架)

      /** 是否要拿到片商的數量詳情? 要拿到的話要帶 Id */
      id,
      detail,
      category_ids,
      actor_ids,
      region_id,
      region_ids,
      producer_id,
      tag_ids,
      sortby,
      topic_ids,
      /** 付費類型(0:不限, 1:免費, 2:金幣, 3:VIP) */
      payment_type,
      uploader_id,
    },
    signal,
  }

  return fetchApi(params)
}

// 依照條件搜尋獲取short的總數量
export function fetchShortTotalQty({
  signal,
  category_ids,
  actor_ids,
  region_id,
  region_ids,
  producer_id,
  tag_ids,
  payment_type,
  uploader_id,
} = {}) {
  const params = {
    url: '/video/short',
    data: {
      only_count: TRUE,
      category_ids,
      actor_ids,
      region_id,
      region_ids,
      producer_id,
      tag_ids,
      payment_type,
      uploader_id,
    },
    signal,
  }

  return fetchApi(params)
}

// 獲取短視頻我的最愛列表
export function fetchShortFavouriteList({ signal, current = 1, pageSize = 30 } = {}) {
  const params = {
    url: '/video/short',
    data: {
      current,
      like_history: TRUE,
      pageSize,
    },
    signal,
  }

  return fetchApi(params)
}

// 獲取短視頻我的收藏列表
export function fetchShortCollectionList({ signal, current = 1, pageSize = 30 } = {}) {
  const params = {
    url: '/video/short',
    data: {
      current,
      favorite_history: TRUE,
      pageSize,
    },
    signal,
  }

  return fetchApi(params)
}

// 獲取我的購買列表(短片消費紀錄)
export function fetchShortBuyList({ signal, current = 1, pageSize = 30 } = {}) {
  const params = {
    url: '/video/short',
    data: {
      current,
      buy_history: TRUE,
      pageSize,
    },
    signal,
  }

  return fetchApi(params)
}

// 獲取上傳者資料
export function fetchUploaderList({ id, name, signal, current = 1, pageSize = 30 } = {}) {
  const params = {
    url: '/video/uploader',
    data: {
      current,
      pageSize,
      id,
      name,
      status: ENABLED,
    },
    signal,
  }

  return fetchApi(params)
}

// 獲取落地頁清單
export function getLandingDomains({ signal, current = 1, pageSize = 5, user_name_eq } = {}) {
  const params = {
    url: '/statistics/landing_domain',
    data: {
      user_name_eq,
      current,
      pageSize,
      status: ENABLED,
    },
    signal,
  }

  return fetchApi(params)
}

/**
 * @typedef {Object} Station
 * @property {string} chinese_name
 * @property {string} cover_key
 * @property {number} id
 * @property {boolean} is_free
 * @property {boolean} is_video_manager
 * @property {boolean} is_video_pick_mode
 * @property {string} mode
 * @property {string} name
 */

// 取得站台資訊
export function fetchStation() {
  const params = {
    url: '/system/station',
    data: {},
  }
  /** @type {Promise<Station>}*/
  const result = fetchApi(params)
  return result
}

// 獲取漫畫列表
export function fetchComicList({
  signal,
  id,
  comic_code,
  region,
  region_ids,
  category_ids,
  serial,
  tag_ids,
  sortby,
  payment_type,
  current = 1,
  pageSize = 500,
  /** 是否要拿到片商的數量詳情, 預設為 False */
  detail = FALSE,
} = {}) {
  const params = {
    url: '/comic',
    data: {
      current,
      pageSize,
      state: 2, // (1:未上架 2:上架 3:下架)

      /** 是否要拿到片商的數量詳情? 要拿到的話要帶 Id */
      id,
      detail,
      comic_code,
      region,
      region_ids,
      category_ids,
      serial,
      tag_ids,
      sortby,
      /** 付費類型(0:不限, 1:免費, 2:金幣, 3:VIP) */
      payment_type,
    },
    signal,
  }

  return fetchApi(params)
}

// 依照條件搜尋獲取comic的總數量
export function fetchComicTotalQty({
  signal,
  comic_code,
  region,
  region_ids,
  category_ids,
  serial,
  tag_ids,
  payment_type,
  uploader_id,
} = {}) {
  const params = {
    url: '/comic',
    data: {
      only_count: TRUE,
      comic_code,
      region,
      region_ids,
      category_ids,
      serial,
      tag_ids,
      payment_type,
      uploader_id,
    },
    signal,
  }

  return fetchApi(params)
}

export function fetchComicChapters({
  signal,
  comic_id,
  comic_title,
  id,
  is_sync_oss,
  only_count,
  title,
  current = 1,
  pageSize = 30,
} = {}) {
  const params = {
    url: '/comic/chapter',
    data: {
      current,
      pageSize,

      comic_id,
      comic_title,
      id,
      is_sync_oss,
      only_count,
      title,
      state: COMIC_CHAPTER_STATE_ON,
    },
    signal,
  }

  return fetchApi(params)
}
export function fetchComicViewChapter({ signal, chapter_id } = {}) {
  const params = {
    url: '/comic/chapter/view',
    data: {
      chapter_id,
    },
    method: 'POST',
    signal,
  }

  return fetchApi(params)
}

// 加入漫畫收藏
export function fetchAddComicCollectList({ signal, comic_id } = {}) {
  const params = {
    url: '/member_comic/favorite',
    method: 'POST',
    data: {
      comic_id,
    },
    signal,
  }

  return fetchApi(params)
}

// 刪除漫畫收藏
export function fetchRemoveComicCollectList({ signal, comic_id } = {}) {
  const params = {
    url: `/member_comic/favorite/${comic_id}`,
    method: 'DELETE',
    signal,
  }

  return fetchApi(params)
}

// 取得漫畫解密密鑰
export function fetchComicChapterKey({ signal, id } = {}) {
  const params = {
    url: `/comic/chapter/key/${id}`,
    method: 'POST',
    signal,
  }

  return /** @type {Promise<{key: string}>} */ (fetchApi(params))
}

// 獲取漫畫我的收藏列表
export function fetchComicCollectionList({ signal, current = 1, pageSize = 30 } = {}) {
  const params = {
    url: '/comic',
    data: {
      current,
      favorite_history: TRUE,
      pageSize,
    },
    signal,
  }

  return fetchApi(params)
}

// 獲取我的購買列表(漫畫消費紀錄)
export function fetchComicBuyList({ signal, current = 1, pageSize = 30 } = {}) {
  const params = {
    url: '/comic',
    data: {
      current,
      buy_history: TRUE,
      pageSize,
    },
    signal,
  }

  return fetchApi(params)
}

// 獲取瀏覽紀錄列表
export function fetchShortHistoryList({ signal, current = 1, pageSize = 30 } = {}) {
  const params = {
    url: '/video/short',
    data: {
      current,
      watch_history: TRUE,
      pageSize,
    },
    signal,
  }

  return fetchApi(params)
}

// 獲取瀏覽紀錄列表
export function fetchComicHistoryList({ signal, current = 1, pageSize = 30 } = {}) {
  const params = {
    url: '/comic',
    data: {
      current,
      view_history: TRUE,
      pageSize,
    },
    signal,
  }

  return fetchApi(params)
}

// GAME
export function fetchGameBalance() {
  const params = {
    url: '/game/balance',
    data: {},
  }
  return fetchApi(params)
}

/**
 * 取得所有遊戲商餘額
 * @returns
 */
export function fetchGameBalanceAll() {
  const params = {
    url: '/game/balance/all',
    data: {},
  }
  return fetchApi(params)
}

export function fetchGameBalanceThirdGame() {
  const params = {
    url: '/game/balance/third_game',
  }
  return fetchApi(params)
}

/**
 * 遊戲列表，取得瓦力遊戲列表
 * @param {{ current: number, pageSize: number, tpCode: string }} param
 * @returns
 */
export function fetchGameList({ current, pageSize, tpCode }) {
  const params = {
    url: '/game/list',
    data: {
      current,
      pageSize,
      tp_code: tpCode,
    },
  }

  return fetchApi(params)
}

/**
 * 新遊戲列表，取得目前所有遊戲商遊戲列表
 * @param {{ current: number, pageSize: number }} param
 * @returns
 */
export function fetchGameListV1({ current, pageSize }) {
  const params = {
    url: '/game/list_v1',
    data: {
      current,
      pageSize,
    },
  }

  return fetchApi(params)
}

export function fetchGameMarquee() {
  const params = {
    url: '/game/marquee_banner',
  }
  return fetchApi(params)
}

export function fetchGameHall() {
  const params = {
    url: '/game/hall',
  }
  return fetchApi(params)
}

export function fetchGameDepositChannel({ deviceType } = { deviceType: 1 }) {
  const params = {
    url: '/game/pay/deposit_channel',
    data: {
      device_type: deviceType,
    },
  }

  return fetchApi(params)
}

export function fetchGamePaymentTypeList() {
  const params = {
    url: '/game/pay/payment_type_list',
    data: {},
  }

  return fetchApi(params)
}

export function fetchGameDepositChannelV4({ code }) {
  const params = {
    url: '/game/pay/deposit_channel_v4',
    data: {
      payment_type_code: code,
    },
  }

  return fetchApi(params)
}

export function fetchGameDepositOrder({ amount, name, paymentChannelId }) {
  const params = {
    url: '/game/pay/order',
    data: {
      amount,
      name,
      payment_channel_id: paymentChannelId,
    },
  }

  return fetchApi(params)
}

/**
 * 取款
 * @param {*} param
 * @returns
 */
export function fetchGameWithdrawOrder({ dateBegin, dateEnd, limit, page, remittanceType, status }) {
  const params = {
    url: '/game/pay/order_withdrawal',
    data: {
      dateBegin,
      dateEnd,
      limit,
      page,
      remittanceType,
      status,
    },
  }

  return fetchApi(params)
}

export function fetchGamePaySecurity() {
  const params = {
    url: '/game/pay/security_v2',
    data: {},
  }

  return fetchApi(params)
}

export function fetchGameParameters() {
  const params = {
    url: '/game/parameter_config',
    data: {},
  }

  return fetchApi(params)
}

export function fetchGameUserStatus() {
  const params = {
    url: '/game/user/status',
    data: {},
  }

  return fetchApi(params)
}

// GAME UPDATE
/**
 * 進入遊戲
 * @param {*}
 * @returns {{order_id: number, urls: string[]}}
 */
export function postGame({ gameId, gameType, tpCode }) {
  // 僅tpCode與gameType皆有值才會帶入
  const payload =
    gameType && tpCode
      ? {
          game_id: gameId,
          game_type: gameType,
          tp_code: tpCode,
        }
      : { game_id: gameId }
  const params = {
    url: '/game/huangiu',
    data: payload,
    method: 'POST',
    headers: {},
  }
  return fetchApi(params)
}

/**
 * 建立存款單
 * @param {*}
 * @returns {{paymentId: string, paymentLink: string}}
 */
export function postGamePayOrder({ amount, name, account, bank, paymentChannelId }) {
  const params = {
    url: '/game/pay/order',
    data: {
      amount: amount,
      name: name,
      account: account,
      bank: bank,
      payment_channel_id: paymentChannelId,
    },
    method: 'POST',
    headers: {},
  }
  return fetchApi(params)
}

/**
 *
 * @param {*}
 * @returns {{data: boolean}}
 */
export function postGamePayWithdraw({ amount, pin, remittanceType }) {
  const params = {
    url: '/game/pay/order_withdrawal',
    data: {
      amount: amount,
      pin: pin,
      remittance_type: remittanceType,
    },
    method: 'POST',
    headers: {},
  }
  return fetchApi(params)
}

export function patchGamePayPin({ pin }) {
  const params = {
    url: '/game/pay/pin',
    data: {
      pin: pin,
    },
    method: 'PATCH',
    headers: {},
  }
  return fetchApi(params)
}

export function postGamePinCheck({ pin }) {
  const params = {
    url: '/game/pay/pin_check',
    data: {
      pin: pin,
    },
    method: 'POST',
    headers: {},
  }
  return fetchApi(params)
}

export function postGamePaySecurity({ account, bankName, branchName, legalName, remittanceType }) {
  //remittance type: 1: bank, 2: usdt
  const params = {
    url: '/game/pay/security',
    data: {
      account: account,
      bank_name: bankName,
      branchName: branchName,
      legal_name: legalName,
      remittance_type: remittanceType,
    },
    method: 'POST',
    headers: {},
  }
  return fetchApi(params)
}

/**
 * 平台餘額轉換
 * @param {{ currency: 1 }} param
 * @returns
 */
export function postTransfer({ currency }) {
  const params = {
    url: '/game/pay/transfer',
    data: {
      currency: currency,
    },
    method: 'POST',
    headers: {},
  }
  return fetchApi(params)
}

/**
 * 平台餘額轉換
 * @param {{ currency: 1, tpCode: string }} param
 * @returns
 */
export function postTransferV2({ currency, tpCode }) {
  const params = {
    url: '/game/pay/transfer_v2',
    data: {
      currency: currency,
      tp_code: tpCode,
    },
    method: 'POST',
    headers: {},
  }
  return fetchApi(params)
}

/**
 * 取得USDT匯率
 * @returns {{code: string, data: object}}
 */
export function fetchUsdtExchangeRate() {
  const params = {
    url: '/game/pay/usdt_exchange_rate',
    data: {},
    method: 'POST',
  }
  return fetchApi(params)
}

/**
 * 取得公司入款渠道詳情
 * @param {{channelId: number}}
 * @returns {{code: string, data: object}}
 */
export function fetchGameDepositChannelDetail({ channelId }) {
  const params = {
    url: '/game/pay/channel_details',
    data: {
      channel_id: channelId,
    },
  }
  return fetchApi(params)
}

/**
 * 建立公司入款單
 * @param {{amount: string, channelId: number, remark: string}}
 * @returns
 */
export function postGameCompanyDeposit({ amount, channelId, remark }) {
  const params = {
    url: '/game/company/order_deposit',
    data: {
      amount,
      channel_Id: channelId,
      remark,
    },
    method: 'POST',
  }
  return fetchApi(params)
}

// 依照關鍵字搜尋短視頻
export function fetchShortListByWords({ signal, title, current = 1, pageSize = 30 } = {}) {
  const params = {
    url: '/video/short',
    data: {
      title,
      current,
      pageSize,
    },
    signal,
  }

  return fetchApi(params)
}

// 依照關鍵字搜尋漫畫
export function fetchComicListByWords({ signal, title, current = 1, pageSize = 30 } = {}) {
  const params = {
    url: '/comic',
    data: {
      title,
      current,
      pageSize,
    },
    signal,
  }

  return fetchApi(params)
}

export function fetchCooperation({ signal } = {}) {
  const params = {
    url: '/cooperation',
    method: 'GET',
    data: {
      pageSize: 500,
      status: ENABLED,
      siteID: 2, // APP
    },
    signal,
  }

  return /** @type { Promise<{ data: import('../../types/api').SchemaCooperation[] }> } */ (fetchApi(params))
}

/**
 * 取得遊戲平台列表
 * v2回傳設定檔設置的列表
 * @param {*}
 * @returns
 */
export function fetchGamePlatformList({ signal } = {}) {
  const params = {
    url: '/game/platform_list_v2',
    method: 'GET',
    data: {},
    signal,
  }

  return /** @type { Promise<{ count: Number, data: { name: string, tp_code: string }[] }> } */ (fetchApi(params))
}
