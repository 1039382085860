import Base64Image from 'component/Base64Image'
import styled from 'styled-components'

export const AdImage = styled(Base64Image).attrs({
  aspectRatio: 900 / 240,
  source: 'picture',
  placeholder: `/loading/square-loading.svg`,
})`
  width: 100%;
  border-radius: 0.2564rem;
`
