import { useState, useEffect, useRef, useCallback } from 'react'
import { useImmer } from 'use-immer'
import InfiniteScroll from 'react-infinite-scroller'

import { fetchActorInfoList } from 'api'

import EmptyResult from 'component/EmptyResult'

import { Wrapper, ItemWrapper, ActorIcon, ActorName } from './Styled'
import { useNavigateTo } from 'hooks/useNavigateTo'

/** 當region為falsy，將以actorIds來查詢 */
export default function Region({ region, actorIds, ...props }) {
  const isFirstTimeIntoView = useRef(true)
  const [listInfo, updateListInfo] = useImmer({
    nextPage: 1,
    isLoading: true,
    hasMore: true,
    list: [],
    page_result: { current: null, pageSize: null, total: null },
  })
  const [loading, setLoading] = useState(false)
  const { navigateToActor } = useNavigateTo()

  // to view more
  const onActorItemClick = useCallback(
    (itemInfo) => {
      navigateToActor({
        actorId: itemInfo?.id,
      })
    },
    [navigateToActor]
  )

  const handleFetchActorList = async () => {
    if (loading) return
    if (listInfo.current === listInfo.page_result.current || !listInfo.hasMore) return

    setLoading(true)
    const data = {
      current: listInfo.nextPage,
      pageSize: 30,
      ids: !region ? actorIds?.join(',') : undefined,
      region_id: region ? region : undefined,
    }

    const response = await fetchActorInfoList(data)
    /* data.page
    {
      "total": 703,
      "current": 22,
      "pageSize": 30,
      "base_offset": 0
    }
    */

    updateListInfo((draft) => {
      draft.list = draft?.list?.concat(response?.data)
      draft.hasMore = response?.page_result?.total > response?.page_result?.current * response?.page_result?.pageSize
      draft.nextPage = response?.page_result?.current + 1
      draft.page_result = response.page_result
      draft.isLoading = false
    })
    setLoading(false)
  }

  useEffect(() => {
    if (!isFirstTimeIntoView.current) {
      return
    }
    isFirstTimeIntoView.current = false
    // updateListInfo((draft) => {
    //   draft.list = [];
    //   draft.nextPage = 1;
    //   draft.isLoading = true;
    //   draft.hasMore = true;
    //   draft.page_result = { current: null, pageSize: null, total: null };
    // });
    handleFetchActorList()
    // eslint-disable-next-line
  }, [])

  return (
    <Wrapper>
      {!listInfo?.list?.length && !loading ? (
        <EmptyResult />
      ) : (
        <InfiniteScroll
          style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '0.2rem 0', flexWrap: 'wrap' }}
          pageStart={0}
          loadMore={() => handleFetchActorList()}
          hasMore={listInfo?.hasMore}
          useWindow={false}
        >
          {listInfo?.list?.map((item, i) => {
            return (
              <ItemWrapper key={i} onClick={() => onActorItemClick(item)}>
                <ActorIcon src={item?.icon_path}></ActorIcon>
                <ActorName>{item?.name}</ActorName>
              </ItemWrapper>
            )
          })}
        </InfiniteScroll>
      )}
    </Wrapper>
  )
}
