import EmptyResult from 'component/EmptyResult'
import VideoBox from 'component/VideoBox'
import { useImmer } from 'use-immer'
import { fetchVideoBuyList } from 'api'
import { InfiniteScrollCmsGridWrapper, PageScrollWrap } from 'commonStyledComponents'

const Video = () => {
  const [state, updateState] = useImmer({
    nextPage: 1,
    isLoading: true,
    hasMore: true,
    list: [],

    page_result: { current: null, pageSize: null, total: null },
  })

  const handleFetchVideoList = async () => {
    if (state.current === state.page_result.current || !state.hasMore) return

    const data = {
      current: state.nextPage,
    }

    try {
      const response = await fetchVideoBuyList(data)
      updateState((draft) => {
        draft.list = draft?.list?.concat(response?.data)
        draft.hasMore = response?.page_result?.total > response?.page_result?.current * response?.page_result?.pageSize
        draft.nextPage = response?.page_result?.current + 1
        draft.page_result = response.page_result

        draft.isLoading = false
      })
    } catch (error) {
      updateState((draft) => {
        draft.isLoading = false
      })
    }
  }

  return !state?.list?.length && !state.isLoading ? (
    <EmptyResult />
  ) : (
    <PageScrollWrap>
      <InfiniteScrollCmsGridWrapper
        pageStart={0}
        loadMore={handleFetchVideoList}
        hasMore={state?.hasMore}
        // loader={<div className="loader" key={0}>Loading ...</div>}
        useWindow={false}
      >
        {state?.list?.map((item, i) => (
          <VideoBox key={i} videoInfo={item}>
            {i}
          </VideoBox>
        ))}
      </InfiniteScrollCmsGridWrapper>
    </PageScrollWrap>
  )
}

export default Video
