import { PageScrollWrap } from 'commonStyledComponents'
import styled from 'styled-components'

export const StyledPageScrollWrap = styled(PageScrollWrap)`
  font-size: 0.48rem;
`

export const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  padding: 10px 0 50px 0;
`

export const BtnSubmit = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8.8rem;
  height: 0.923rem;
  margin-top: 8px;
  background: var(--page-login-register--submit-background);
  color: var(--page-login-register--submit-color);
  border-radius: 2.5rem;
`

export const BtnSwitchLogin = styled.button`
  color: #f9f9f9;
  position: relative;
  padding-left: 12px;

  &:before {
    position: absolute;
    left: 4.5px;
    content: ' | ';
    color: #aaaaaa;
    height: 100%;
    width: 1px;
  }
`

export const BottomText = styled.div`
  margin-top: 0.92rem;
  color: #aaaaaa;
  letter-spacing: 0.36px;
  font-size: 0.32rem;
`
