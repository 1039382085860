import { useNavigateTo } from 'hooks/useNavigateTo'
import { useCallback } from 'react'

export const useOpenComicChapter = () => {
  const { navigateToViewComicChapter } = useNavigateTo()
  const openComicChapter = useCallback(
    (comicId, chapterInfo) => {
      navigateToViewComicChapter({
        comicId,
        chapterInfo,
      })
    },
    [navigateToViewComicChapter]
  )

  return {
    openComicChapter,
  }
}
