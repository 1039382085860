import styled from 'styled-components'

export const AnimationIconWrap = styled.div`
  margin: -0.15rem;
  position: relative;
  overflow: hidden;
  img,
  svg {
  }
`
