import { useAppLocation } from 'app-layered-layout/useAppLocation'
import { createContext, useContext, useEffect, useMemo, useRef } from 'react'

let resolve
const analyticsReadyPromise = new Promise((resolve_) => {
  resolve = resolve_
})

/**
 * @type {Promise<ReportSystem>[]}
 */
const loadingReportSystems = []

/**
 * @typedef {{
 *   SerialNumber: number
 *   Event: string
 *   Trigger: string
 *   Parameters: string
 *   Value: string
 * }} LogEntry
 */

/**
 * @typedef {{
 *   report: (log: LogEntry) => void
 *   setUser: (user: String) => void
 *   onReady: (location: *) => void
 *   recordPV: (location: *) => void
 * }} ReportSystem
 */

/**
 * @param {Promise<ReportSystem>} reportSystemPromise
 */
const addReportSystem = (reportSystemPromise) => {
  loadingReportSystems.push(
    Promise.resolve(reportSystemPromise).catch((err) => {
      console.error(err)
      return {
        report: () => {},
        setUser: () => {},
        onReady: () => {},
        recordPV: () => {},
      }
    })
  )
}

export const setAnalyticUser = (user) => {
  if (process.env.NODE_ENV === 'development') {
    console.log('[analytics] set user ' + user)
  }
  loadingReportSystems.forEach((p) =>
    p.then((sys) => {
      sys.setUser(user)
    })
  )
}

/**
 * @param {LogEntry} log
 */
export const report = (log) => {
  if (process.env.NODE_ENV === 'development') {
    console.log('[analytics] report', log)
  }

  loadingReportSystems.forEach((p) =>
    p.then((sys) => {
      sys.report(log)
    })
  )
}

const flatten = (obj) => {
  const list = []
  if (obj == null || typeof obj !== 'object') {
    return list
  }
  for (let [k, v] of Object.entries(obj)) {
    if (v != null) {
      if (typeof v === 'object') {
        const subList = flatten(v)
        list.push(...subList.map(([subK, v]) => [`${k}.${subK}`, v]))
      } else {
        list.push([k, v])
      }
    }
  }
  return list
}

/**
 * @param {*} location react router location
 */
export const recordPV = async (location) => {
  if (process.env.NODE_ENV === 'development') {
    console.log('[analytics] recordPV ' + window.location.href, location, flatten({ state: location.state }))
  }

  await getAnalyticReady()
  // record new location here
  loadingReportSystems.forEach((p) =>
    p.then((sys) => {
      sys.recordPV(location)
    })
  )
}
export const useAnalyticsPV = () => {
  // record page view here
  const location = useAppLocation()
  const lastRecorded = useRef()

  useEffect(() => {
    if (lastRecorded.current === location.pathname + location.search + location.hash) {
      return
    }

    lastRecorded.current = location.pathname + location.search + location.hash
    recordPV(location)
  }, [location])
}

export const getAnalyticReady = () => {
  return analyticsReadyPromise
}

export const setAnalyticReady = () => {
  if (process.env.NODE_ENV === 'development') {
    console.log('[analytics] ready')
  }
  resolve()
  loadingReportSystems.forEach((p) =>
    p.then((sys) => {
      sys.onReady()
    })
  )
}

export const useAnalyticsReport = () => {}

if (process.env.REACT_APP_TENCENT_APM_KEY) {
  addReportSystem(
    import('aegis-web-sdk').then(({ default: Aegis }) => {
      const aegis = new Aegis({
        id: process.env.REACT_APP_TENCENT_APM_KEY, // 上报 id
        // uin: 'xxx', // 用户唯一 ID（可选）
        reportApiSpeed: true, // 接口测速
        reportAssetSpeed: true, // 静态资源测速
        spa: true, // spa 应用页面跳转的时候开启 pv 计算
        hostUrl: 'https://rumt-zh.com',
        version: process.env.REACT_APP_VERSION,
      })

      /**
       * @type {ReportSystem}
       */
      const system = {
        setUser: (user) => {
          aegis.setConfig({
            uin: user,
          })
        },
        report: (log) => {
          aegis.reportEvent({
            name: log.Trigger,
            ext1: log.Event,
            ext2: log.Parameters,
            ext3: log.Value,
          })
        },
        onReady: () => {},
        recordPV: (location) => {},
      }

      return system
    })
  )
}

if (process.env.REACT_APP_APLUS_ID && process.env.REACT_APP_APLUS_KEY) {
  addReportSystem(
    Promise.resolve({
      setUser: (user) => {
        try {
          // 此处文档以uuid作为示例
          const { aplus_queue } = window
          aplus_queue.push({
            // 设置idtype
            action: 'aplus.setMetaInfo',
            arguments: ['aplus-idtype', 'uuid'], //取值参考见附表1
          })

          aplus_queue.push({
            //设置userid
            action: 'aplus.setMetaInfo',
            // uuid一般是一个32位的随机字符串，当前value仅作为示例参考
            arguments: ['uuid', user],
          })
        } catch (err) {
          console.error(err)
        }
      },
      report: (log) => {},
      onReady: () => {
        try {
          const { aplus_queue } = window
          aplus_queue.push({
            action: 'aplus.setMetaInfo',
            arguments: ['_hold', 'START'],
          })
        } catch (err) {
          console.error(err)
        }
      },
      recordPV: (location) => {
        try {
          const { aplus_queue } = window
          aplus_queue.push({
            action: 'aplus.sendPV',
            arguments: [
              {
                is_auto: false,
                pageUrl: location.pathname + location.search + location.hash,
                title: window.document.title,
              },
            ], // 此处上报的数据暂时在后台没有展示
          })
        } catch (err) {
          console.error(err)
        }
      },
    })
  )
}

const workSectionContext = createContext(
  /** @type {{
  SerialNumber: number
  Event: string
  Trigger: string
} | null} */ (null)
)

export const WorkSectionProvider = ({ SerialNumber, Event, Trigger, children }) => {
  const value = useMemo(
    () => ({
      SerialNumber,
      Event,
      Trigger,
    }),
    [Event, SerialNumber, Trigger]
  )
  return <workSectionContext.Provider value={value}>{children}</workSectionContext.Provider>
}

export const useCurrentWorkSection = () => {
  const ctx = useContext(workSectionContext)
  return ctx
}
