import styled, { keyframes } from 'styled-components'

export const Animation = keyframes`
from{
    transform: rotate(0turn);
}
to{
    transform: rotate(1turn);
}
`

export const Wrapper = styled.button`
  display: inline-block;
  width: 0.48rem;
  height: 0.48rem;
  vertical-align: middle;
  margin: 0 4px;
  border: none;
  border-radius: 50%;
  padding: 0.05rem;
  .loading {
    animation: ${Animation} 1s linear 0s infinite;
  }
  i {
    width: 100%;
    height: 100%;
  }
  .white {
    color: white;
  }
`
