import styled, { keyframes } from 'styled-components'

const loading = keyframes`
  to { background-position: -3900px; }
`

export const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
`

export const Image = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  height: 300px;
  width: 300px;
  background: url(${process.env.PUBLIC_URL}/loading/loading-sprites.png);
  background-repeat: no-repeat;
  animation: ${loading} 1.2s steps(13) infinite;
  transform: translate(-50%, -50%);
`
