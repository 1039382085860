import { Wrapper } from './Styled'

export default function NoteBox({ title = '温馨提醒', text, background }) {
  return (
    <Wrapper background={background}>
      <h3>{title}</h3>
      <ol>
        {text.map((m, i) => (
          <li key={i}>{m}</li>
        ))}
      </ol>
    </Wrapper>
  )
}
