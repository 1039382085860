import { fetchShortList, fetchUploaderList } from 'api'
import { useEffect, useMemo, useRef } from 'react'
import { useImmer } from 'use-immer'
import InfiniteScroll from 'react-infinite-scroller'
import {
  Header,
  IconBack,
  OverlayTitle,
  StyledBase64Img,
  TotalShortObserverArea,
  TotalShorts,
  Uploader,
  UploaderImage,
  UploaderMeta,
  UploaderMetaName,
  UploaderMetaValue,
  UploaderWrapper,
  Wrapper,
} from './Styled'
import VideoBoxVertical from 'component/VideoBoxVertical'
import { useIntersectionObserver } from 'hooks/useIntersectionObserver'
import { useParamsOfPage } from 'hooks/useNavigateTo'
import { useAppNavigate } from 'app-layered-layout/useAppNavigate'
import { catchPromiseCancel } from 'utils/catchPromiseCancel'

const ViewUploader = () => {
  const { uploaderId } = useParamsOfPage('uploader')
  const navigate = useAppNavigate()

  const { ref, containerRef, ratio } = useIntersectionObserver({
    threshold: Array.from({ length: 19 }).map((_, index) => (index + 1) / 19),
    defaultVisible: true,
  })

  const expandRange = (current, [rangeMin, rangeMax], [toMin, toMax]) => {
    const expanded = ((current - rangeMin) / (rangeMax - rangeMin)) * (toMax - toMin) + toMin
    const capped = Math.min(toMax, Math.max(toMin, expanded))
    return capped
  }

  const mappedRatio = expandRange(ratio, [1, 0.95], [0, 1])

  const [uploaderData, updateUploaderData] = useImmer({
    cover_path: '',
    created_at: '',
    description: '',
    fans_count: 0,
    favorite_count: 0,
    icon_path: '',
    id: '0',
    like_count: 0,
    name: '',
    status: 0,
    updated_at: '',
    video_num: 0,
  })

  useEffect(() => {
    const controller = new AbortController()
    const data = {
      id: uploaderId,
      signal: controller.signal,
    }
    fetchUploaderList(data)
      .then((response) => {
        if (response.data.length > 0) {
          updateUploaderData((draft) => {
            Object.assign(draft, response.data[0])
          })
        }
      })
      .catch(catchPromiseCancel)

    return () => {
      controller.abort()
    }
  }, [updateUploaderData, uploaderId])

  const style = useMemo(
    () => ({
      display: 'flex',
      gap: 'var(--cms-grid-row-gap, 0.093rem) var(--cms-grid-column-gap, 0.093rem)',
      flexWrap: 'wrap',
    }),
    []
  )

  const [uploaderShorts, updateUploaderShorts] = useImmer({
    nextPage: 1,
    isLoading: true,
    hasMore: true,
    list: [],

    page_result: { current: null, pageSize: null, total: null },
  })

  const fetching = useRef(false)

  const handleFetchVideoList = () => {
    if (uploaderShorts.current === uploaderShorts.page_result.current || !uploaderShorts.hasMore) return

    if (fetching.current) return
    fetching.current = true

    const data = {
      current: uploaderShorts.nextPage,
      pageSize: 30,
      uploader_id: uploaderId,
    }

    fetchShortList(data)
      .then((response) => {
        updateUploaderShorts((draft) => {
          draft.list = draft?.list?.concat(response?.data)
          draft.hasMore =
            response?.page_result?.total > response?.page_result?.current * response?.page_result?.pageSize
          draft.nextPage = response?.page_result?.current + 1
          draft.page_result = response.page_result

          draft.isLoading = false
        })
      })
      .finally(() => {
        fetching.current = false
      })
  }

  const shortInfo = useMemo(() => {
    return {
      list: uploaderShorts.list,
    }
  }, [uploaderShorts.list])

  return (
    <>
      <Wrapper ref={containerRef}>
        <Header>
          <UploaderWrapper>
            <Uploader>{uploaderData.name}</Uploader>
            <UploaderMeta>
              <UploaderMetaValue>{uploaderData.like_count}</UploaderMetaValue>
              <UploaderMetaName>获赞</UploaderMetaName>
              <UploaderMetaValue>{uploaderData.favorite_count}</UploaderMetaValue>
              <UploaderMetaName>被收藏</UploaderMetaName>
            </UploaderMeta>
          </UploaderWrapper>
          <UploaderImage>
            <StyledBase64Img
              noLazy
              src={uploaderData.icon_path}
              imageStyle={{
                objectPosition: 'top',
              }}
            ></StyledBase64Img>
          </UploaderImage>
          <StyledBase64Img
            src={uploaderData.cover_path}
            placeholder={`/short-background/uploader-background.png`}
            aspectRatio={null}
          ></StyledBase64Img>
        </Header>
        <TotalShorts>
          她的作品 {uploaderShorts.page_result.total}
          <TotalShortObserverArea ref={ref} />
        </TotalShorts>
        <InfiniteScroll
          style={style}
          pageStart={0}
          loadMore={handleFetchVideoList}
          hasMore={uploaderShorts?.hasMore}
          // loader={<div className="loader" key={0}>Loading ...</div>}
          useWindow={false}
        >
          {uploaderShorts?.list?.map((item, i) => {
            return <VideoBoxVertical noTitle columns={3} videoInfo={item} shortInfo={shortInfo} />
          })}
        </InfiniteScroll>
      </Wrapper>
      <OverlayTitle style={{ opacity: mappedRatio }}>{uploaderData.name}</OverlayTitle>
      <IconBack onClick={() => navigate(-1)}></IconBack>
    </>
  )
}

export default ViewUploader
