import styled from 'styled-components'

export const Image = styled.img`
  border-radius: 0.25rem;
  width: 100%;
  height: 2.393rem;
  background: url(${process.env.PUBLIC_URL}/loading/square-loading.svg) no-repeat center;
  background-size: cover;
  transition: background 0.2s ease-in;
`
