import { ColoredIcon } from 'component/ColoredIcon'
import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 100vw;
  font-size: 0.35rem;
  padding: 0 0.2rem;
  gap: 0.25rem;
`

export const SectionTitle = styled.h2`
  font-size: 0.4rem;
  font-weight: bold;
  padding: 0.2rem 0 0.2rem 0;
`

export const SectionPlaceholder = styled.div`
  padding: 0.75rem 0;
  text-align: center;
`

export const Button = styled.button`
  min-width: 2rem;
  padding: 0.4rem 0.1rem;
  background: var(--page-game--select-button-background);
  color: var(--page-game--select-button-color);
  border: 1px solid var(--page-game--select-button-background);
  border-radius: 0.25rem;
  &.active {
    border: 1px solid var(--page-game--select-button-active-color);
  }
`

export const ThreeButtonRow = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.2rem;
  flex-wrap: wrap;
  width: 100%;
`

export const FourButtonRow = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.2rem;
  flex-wrap: wrap;
  width: 100%;
  gap: 0.2rem;
`

export const BalanceWrapper = styled.div`
  display: grid;
  grid-template-rows: 60% auto;
  align-items: center;
  background: url(${process.env.PUBLIC_URL}/account/bg-balance.png);
  background-size: cover;
  color: var(--page-game-deposit--balance-color);
  height: 1.6rem;
  border-radius: 0.2rem;
  padding: 0.3rem 0.4rem;

  .card-title {
    font-size: 0.4rem;
    font-weight: 700;
    margin-bottom: 0.1rem;
  }
`

export const Input = styled.input`
  background: var(--input-background);
  border-radius: 0.25rem;
  padding: 0.25rem;
  width: 100%;
  font-size: 0.3rem;
  &:hover,
  &:focus {
    background: var(--input-focus-background);
  }
`

export const IconInput = styled(Input)`
  padding-left: 0.75rem;
`

export const IconInputWrapper = styled.div`
  position: relative;
  width: 100%;
  color: var(--input-color);
  ::placeholder {
    color: var(--input-placeholder-color);
  }
`

export const RightAlignHintText = styled.div`
  text-align: right;
  font-size: 0.25rem;
  color: var(--page-game--hint-text-color);
`

export const Icon = styled(ColoredIcon)`
  position: absolute;
  left: 0.2rem;
  height: 100%;
  width: 0.4rem;
`

export const IconDollars = styled(Icon).attrs({
  src: `${process.env.PUBLIC_URL}/account/icon-dollars.svg`,
})``

export const IconUser = styled(Icon).attrs({
  src: `${process.env.PUBLIC_URL}/account/icon-user.svg`,
})``

export const ErrorText = styled.div`
  color: var(--danger-color);
  font-size: 0.25rem;
`
