import { getLandingDomains } from 'api'
import { addListener, launch } from 'devtools-detector'

export function load() {
  console.log = () => {}
  console.warn = () => {}
  console.error = () => {}
  const clear = console.clear.bind(console)

  const dropProps = (o) => {
    for (const k of Object.keys(o)) {
      delete o[k]
    }
  }

  function killPage() {
    // 清空畫面
    document.querySelector(':root').remove()
    const placeholder = document.createElement('div')

    const shadow = placeholder.attachShadow({ mode: 'closed' })
    const text = document.createElement('div')
    text.textContent = 'Hey yay!'
    shadow.appendChild(text)

    function blocker() {
      // 洗掉 call stack 讓 devtool 更難找到觸發位置
      const fillStack = (cb, count = 2000) => {
        if (count > 1000) {
          return Promise.resolve().then(() => fillStack(cb, count - 1))
        } else if (count > 0) {
          return fillStack(cb, count - 1)
        } else {
          return cb()
        }
      }
      requestAnimationFrame(async function d() {
        fillStack(() => {
          debugger
          requestAnimationFrame(d)
        })
      })
    }
    // 讓 call stack 起點變成不是在原本的代碼內
    const consoleKiller = document.createElement('iframe')
    consoleKiller.srcdoc = `
      <script>
        (${blocker.toString()})()
      </script>
    `
    placeholder.appendChild(consoleKiller)
    document.appendChild(placeholder)
    // 更多的惡作劇，故意清空 prototype 上的東西讓 devtool 裡拿不到 element 的 method
    dropProps(Node.prototype)
    dropProps(Element.prototype)
    dropProps(HTMLElement.prototype)
    dropProps(HTMLIFrameElement.prototype)
  }

  let triggered = false
  addListener((isOpen) => {
    if (isOpen & !triggered) {
      killPage()
      triggered = true
    }
  })

  const loadDevBlocker = () => {
    setInterval(() => {
      clear()
    }, 100)

    launch()
  }

  loadDevBlocker()

  const isIPhone = navigator.userAgent.toLowerCase().indexOf('iphone') >= 0
  const iOSIsInstalled = window.navigator.standalone === true

  if (!isIPhone || !iOSIsInstalled) {
    try {
      const body = document.createElement('body')
      body.style.background = 'black'
      body.style.color = 'white'
      body.style.height = '100vh'
      body.textContent = (isIPhone ? '进行' : '') + (iOSIsInstalled ? '网页' : '') + '重新导向中...'
      document.querySelector('html').replaceChild(body, document.querySelector('body'))
    } catch (err) {}
    async function redirect() {
      const res = await getLandingDomains()
      const links = res.data.map((i) => i.domain_name)
      const selected = links[~~(Math.random() * links.length)]
      setTimeout(() => {
        window.location.replace(selected)
      }, 500)
    }
    redirect()
    throw new Error('stop loading')
  }
}
