import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'

import reducers from 'redux/reducer'
import saga from 'redux/saga'

const sagaMiddleware = createSagaMiddleware()
const middlewares = [sagaMiddleware]

const globalStore = configureStore({
  reducer: reducers,
  middleware: [...middlewares],
  devTools: process.env.NODE_ENV !== 'production',
})

sagaMiddleware.run(saga)

export default globalStore
