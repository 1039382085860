import type videojs from 'video.js'
import './vjs-back-button.css'

type VideoJs = typeof videojs

let installed = false

export const addBackButton = (videojs: VideoJs) => {
  if (installed) {
    return
  }

  installed = true

  const Component = videojs.getComponent('Component')

  class BackButton extends Component {
    createEl() {
      const root = videojs.dom.createEl(
        'div',
        {
          // Prefixing classes of elements within a player with "vjs-"
          // is a convention used in Video.js.
          className: 'vjs-back-button',
        },
        {},
        []
      )

      return root
    }
  }

  videojs.registerComponent('BackButton', BackButton)
}
