import { useLayoutEffect, useMemo, useRef, useState } from 'react'

export const useIntersectionObserver = ({
  defaultVisible = true,
  cb = /** @type {(entries: IntersectionObserverEntry[]) => void} */ (() => {}),
  triggerOnce = false,
  rootMargin,
  rootClassName = '',
  threshold,
} = {}) => {
  const ref = useRef(/** @type {HTMLElement | null} */ (null))
  const containerRef = useRef()
  const [visible, setVisible] = useState(defaultVisible ?? true)
  const currentVisible = useRef(visible)
  useLayoutEffect(() => {
    currentVisible.current = visible
  })

  const [ratio, setRatio] = useState(defaultVisible ?? true ? 1 : 0)
  const [disabled, setDisabled] = useState(false)

  useLayoutEffect(() => {
    if (disabled) {
      return
    }

    /**
     * @param {IntersectionObserverEntry[]} entries
     */
    const observerCb = (entries) => {
      for (const e of entries) {
        if (!triggerOnce || defaultVisible !== e.isIntersecting) {
          setVisible(e.isIntersecting)
        }
        setRatio(e.intersectionRatio)
        if (triggerOnce && currentVisible.current !== e.isIntersecting) {
          setDisabled(true)
        }
      }
      cb(entries)
    }
    if (window.IntersectionObserver) {
      let root
      if (rootClassName !== '') {
        let leaf = ref.current
        while (leaf != null) {
          if (leaf.classList.contains(rootClassName)) {
            root = leaf
            break
          } else {
            leaf = leaf.parentElement
          }
        }
      }
      if (containerRef.current) {
        root = containerRef.current
      }

      const observer = new IntersectionObserver(observerCb, {
        root,
        rootMargin,
        threshold,
      })

      if (ref.current) {
        observer.observe(ref.current)
      }

      return () => {
        observer.disconnect()
      }
    } else {
      console.error('IntersectionObserver not available')
    }
  }, [cb, defaultVisible, disabled, rootClassName, rootMargin, setDisabled, threshold, triggerOnce])

  const result = useMemo(
    () => ({
      ref,
      containerRef,
      visible,
      ratio,
    }),
    [ratio, visible]
  )

  return result
}
