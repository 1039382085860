import styled from 'styled-components'

export const PagePullWrap = styled.div`
  /* background: red; */
  display: flex;
  width: 100%;
  height: 100%;
  height: 67px;
  align-self: stretch;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`
