import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import actions from 'redux/action'
import { selectHero } from 'redux/selector/app'
import isEmpty from 'lodash/isEmpty'

import { INDEX, UNKNOWN } from 'constant/routers'
import { useAppNavigate } from 'app-layered-layout/useAppNavigate'
import { useAppLocation } from 'app-layered-layout/useAppLocation'

export default function useHero() {
  const dispatch = useDispatch()
  const hero = useSelector(selectHero)
  const navigate = useAppNavigate()
  const isHeroHailHydra = !isEmpty(hero)
  const location = useAppLocation()

  useEffect(() => {
    if (isHeroHailHydra) {
      navigate(UNKNOWN, { replace: true })
    } else {
      /** 獲取 URL 中的代理碼 */
      const searchParams = new URLSearchParams(window.location.search)
      const inviteCode = searchParams.get('c')

      if (inviteCode != null) {
        dispatch(actions.updateInviteCode(inviteCode))

        const newUSearch = new URL(location.search, 'http://localhost/')
        newUSearch.searchParams.delete('c')

        navigate({ ...location, search: newUSearch.search }, { replace: true })
      }
    }
    // eslint-disable-next-line
  }, [])
}
