import { useMemo } from 'react'
import isEmpty from 'lodash/isEmpty'
import { useSelector } from 'react-redux'
import { Swiper, SwiperSlide } from 'swiper/react'
import { A11y, Autoplay, Pagination, Thumbs } from 'swiper/modules'
import { selectAdInfo } from 'redux/selector/app'
//
import { AdvertisementType } from 'constant/advertisement'
//
import CarouselItem from './component/CarouselItem'
import { SwiperWrapper } from './Styled'

export default function Carousel({ pageUuid }) {
  const adInfo = useSelector(selectAdInfo)

  const adItems = useMemo(() => {
    return adInfo?.[AdvertisementType.AppCenterBanner]
  }, [adInfo])

  /** 自動切換延遲時間 */
  const delay = 1000 * (adItems?.[0]?.carousel_time || 2.5)

  if (isEmpty(adItems)) return null

  return (
    <SwiperWrapper>
      <Swiper
        modules={[Autoplay, Pagination, A11y, Thumbs]}
        loop={adItems?.length > 1}
        autoplay={{
          delay,
          disableOnInteraction: false,
        }}
        pagination={{ clickable: true }}
        // onSwiper={(swiper) => console.log(swiper)}
        // onSlideChange={() => console.log("slide change")}
      >
        {adItems?.map((info, i) => (
          <SwiperSlide key={i}>
            <CarouselItem pageUuid={pageUuid} {...info} />
          </SwiperSlide>
        ))}
      </Swiper>
    </SwiperWrapper>
  )
}
