import { useEffect, useState, useRef } from 'react'

import { Input, IconInputWrapper, IconEye, IconPassword } from '../../Styled'

export default function PasswordInput({ name, value, onChange, onBlur }) {
  const [visible, setVisible] = useState(false)
  const inputRef = useRef(null)

  function handleToggleVisible() {
    setVisible(!visible)
    inputRef.current.focus()
  }

  useEffect(() => {}, [])

  return (
    <IconInputWrapper>
      <IconPassword className="input-icon" alt="" />
      <IconEye className={visible ? 'visible' : ''} onClick={handleToggleVisible}></IconEye>
      <Input
        ref={inputRef}
        type={visible ? 'text' : 'password'}
        name={name}
        placeholder="请输入取款密码"
        maxLength={4}
        onChange={onChange}
        onBlur={onBlur}
      ></Input>
    </IconInputWrapper>
  )
}
