import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  name: 'timer',
  register: {
    verificationCodeSeconds: 'verificationCodeSeconds',
  },
  tokenTimer: {
    timestamp: 0,
  },
}

const slice = createSlice({
  name: 'timer',
  initialState,
  reducers: {
    updateRegisterVerificationCodeSeconds: (state, action) => {
      state.register.verificationCodeSeconds = action.payload
    },
    updateTokenTimer: (state, action) => {
      state.tokenTimer.timestamp = action.payload
    },
  },
})

export const { actions } = slice
export default slice.reducer
