import { fetchComicCollectionList } from 'api'
import actions from 'redux/action'
import { FETCH_COMIC_COLLECTION, RESET_COMIC_COLLECTION } from 'redux/constant/comicCollection'
import { createListSaga } from 'redux/utils'

const handleCollection = createListSaga(
  'ComicCollection',
  FETCH_COMIC_COLLECTION,
  RESET_COMIC_COLLECTION,
  actions.patchComicCollectionData,
  actions.clearComicCollectionData,
  fetchComicCollectionList
)

export default handleCollection
