import Animation from 'component/Animation/index.js'
import styled from 'styled-components'
import pagePullLoadingStyle from './page-pull-load/style.js'
import pagePullLoadAnimation from './page-pull-load/index.js'

export const PagePullLoadIcon = styled(Animation).attrs({ animationData: pagePullLoadAnimation })`
  ${pagePullLoadingStyle}
`

export default PagePullLoadIcon
