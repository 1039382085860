import { useEffect, useMemo, useRef, useState } from 'react'
import { Wrapper, MarqueeText } from './styled'

export default function Marquee({ list, speed = 100 }) {
  const [mounted, setMounted] = useState(false)
  const [containerWidth, setContainerWidth] = useState(0)
  const [marqueeWidth, setMarqueeWidth] = useState(0)
  const containerRef = useRef(null)
  const marqueeRef = useRef(null)

  const textStyle = useMemo(() => {
    let duration = marqueeWidth < containerWidth ? containerWidth / speed : marqueeWidth / speed
    if (duration < 1) duration = 1
    return { animation: `marquee ${duration}s linear infinite` }
  }, [speed, marqueeWidth, containerWidth])

  useEffect(() => {
    if (!mounted) return

    const calculateWidth = () => {
      if (marqueeRef.current && containerRef.current) {
        setContainerWidth(containerRef.current.getBoundingClientRect().width)
        setMarqueeWidth(marqueeRef.current.getBoundingClientRect().width)
      }
    }

    calculateWidth()
    window.addEventListener('resize', calculateWidth)
    return () => {
      window.removeEventListener('resize', calculateWidth)
    }
  }, [mounted, speed, list])

  useEffect(() => {
    setMounted(true)
  }, [])

  const messages = useMemo(
    () =>
      list
        .map((m) => m.title)
        .filter((m) => m)
        .map((m, i) => <span key={i}>{m}</span>),
    [list]
  )

  return (
    <Wrapper>
      <img src={`${process.env.PUBLIC_URL}/game/icon-broadcast.png`} alt=""></img>
      <MarqueeText ref={containerRef}>
        <div className="marquee-text" style={textStyle} ref={marqueeRef}>
          {messages}
        </div>
      </MarqueeText>
    </Wrapper>
  )
}
