import Base64Image from 'component/Base64Image'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow: hidden;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: inherit;
  width: 100%;
  padding: 0 0.2rem;
`

export const InfoBoxWrapper = styled.div`
  padding: 0.3rem 0.2rem;
`

export const InfoBox = styled.div`
  display: flex;
  align-items: center;
  height: 1.5rem;
  background: url('${process.env.PUBLIC_URL}/vip-icon/icon-member-vip.png');
  background-color: linear-gradient(180deg, #cbbc9b 0%, #ac8851 100%);
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
`

export const Info = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.106rem;
  font-size: 0.25rem;
  letter-spacing: 0.13px;

  > div {
    word-break: break-word;
  }
`

export const Avatar = styled.div`
  width: 1.333rem;
  height: 1.333rem;
  margin-right: 0.56rem;
`

export const Text = styled.div`
  font-size: 0.48rem;
  font-weight: bold;
  margin: 0.56rem 0.533rem 0.16rem 0.533rem;
`

export const VipBox = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  gap: 0.2rem;

  > * {
    user-select: none;
    cursor: pointer;
  }
`

export const VipCard = styled(Base64Image).attrs({
  placeholder: '/loading/square-loading.svg',
  source: 'picture',
})`
  padding-bottom: calc(100% * 108 / 374);
  border-radius: 0.2rem;
  > div {
    // height: 1rem;
  }
`

export const VipTitle = styled.div`
  font-weight: 700;
  font-size: 0.6rem;
  margin-bottom: 0.2rem;
`
