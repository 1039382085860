import { useCallback, useMemo } from 'react'
import { useImmer } from 'use-immer'
import useConfirmModal from 'hooks/useConfirmModal'
import {
  Wrapper,
  FlexBox,
  Line,
  TrashCan,
  HistoryWrapper,
  NeverSearched,
  Expand,
  HistoryItem,
  BtnCancel,
  ExpandIcon,
} from './Styled'
import AreaTitle from 'component/AreaTitle'
import { useSearchHistory } from 'hooks/useSearchHistory'

/** Local Storage: 用戶搜尋紀錄清單的 Key */
const EXPAND_COUNT = 5

export default function HistoryPanel({ onHistoryClick, mode }) {
  const { openConfirm } = useConfirmModal()
  const { remove, clear, get } = useSearchHistory()

  const [state, updateState] = useImmer({
    isControllerMode: false,
    isExpand: false,
  })

  const history = useMemo(() => get(mode), [get, mode])

  const isNeedExpand = !state.isExpand && history?.length > EXPAND_COUNT + 1

  const onExpand = useCallback(() => {
    updateState((draft) => void (draft.isExpand = !draft.isExpand))
  }, [updateState])

  const onControllerModeChange = useCallback(() => {
    if (!history?.length) return

    updateState((draft) => void (draft.isControllerMode = !draft.isControllerMode))
  }, [history?.length, updateState])

  /** empty all history */
  const onEmptyHistory = useCallback(() => {
    openConfirm({
      title: '清空搜寻纪录',
      message: '请问您是否要清除搜寻纪录',
      onCancel: () => {},
      onConfirm: () => {
        clear(mode)
        updateState((draft) => {
          draft.isControllerMode = false
        })
      },
    })
  }, [clear, mode, openConfirm, updateState])

  /** remove history item */
  const onRemoveItem = useCallback(
    (e) => {
      e.stopPropagation()

      openConfirm({
        title: '清空搜寻纪录',
        message: '请问您是否要清除搜寻纪录',
        onCancel: () => {},
        onConfirm: () => {
          const word = e.target.dataset.word
          remove(mode, word)
          if (history.length === 1 && history[0] === word) {
            updateState((draft) => void (draft.isControllerMode = false))
          }
        },
      })
    },
    [history, mode, openConfirm, remove, updateState]
  )

  /** 搜尋無結果 */
  // if (!isLoading && !searchResult?.length && searchWords) return <NullResult />;

  return (
    <Wrapper>
      {/* Panel 上半部 */}
      <AreaTitle text="搜索记录">
        {!state.isControllerMode ? (
          <TrashCan onClick={onControllerModeChange} />
        ) : (
          <FlexBox>
            <div onClick={onEmptyHistory}>清空历史</div>
            <Line>|</Line>
            <div onClick={onControllerModeChange}>完成</div>
          </FlexBox>
        )}
      </AreaTitle>

      {/* Panel 下半部 */}
      <HistoryWrapper isExpand={state.isExpand}>
        {history.length === 0 ? (
          <NeverSearched>您还没有搜索过呦</NeverSearched>
        ) : (
          history.map((item, i) => {
            return (
              <HistoryItem key={i} onClick={() => onHistoryClick(item)}>
                {item}
                {state.isControllerMode && <BtnCancel data-word={item} onClick={onRemoveItem} />}
              </HistoryItem>
            )
          })
        )}
      </HistoryWrapper>

      {isNeedExpand && (
        <Expand onClick={onExpand}>
          展开<ExpandIcon></ExpandIcon>
        </Expand>
      )}
    </Wrapper>
  )
}
