import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  balance: 0,
  userStatus: {
    status: true,
    isExist: undefined,
  },
}

const slice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    updateGameBalance(state, action) {
      state.balance = action.payload
    },
    updateGameUserStatus(state, action) {
      state.userStatus = action.payload
    },
  },
})

export const { actions } = slice
export default slice.reducer
