import styled from 'styled-components'

export const Wrapper = styled.div.attrs((props) => ({
  style: {
    background: props.background !== undefined ? props.background : 'var(--component-note-box--background)',
  },
}))`
  color: var(--component-note-box--color);
  border-radius: 0.25rem;
  padding: 0.3rem 0.12rem;
  font-size: 0.3rem;
  line-height: 0.5rem;
  h3 {
    font-size: 0.35rem;
    font-weight: 700;
  }
  ol {
    list-style: decimal;
    padding: 0.2rem;
    padding-left: 0.5rem;
  }
`
