import { useEffect, useRef, useMemo } from 'react'

import PageTabs, { PageTab } from 'component/PageTabs'
import Region from './component/Region'

import { useParamsOfPage } from 'hooks/useNavigateTo'
import { selectRegionList } from 'redux/selector/app'
import { useSelector } from 'react-redux'
import { ENABLED } from 'constant/common'

/** headerTitle 為傳參因為有用在金剛區, 如果金剛區有名稱設定的話, 則以金剛區的名稱為 Title */
export default function ActorList() {
  const { actorIds, headerTitle = '演员列表' } = useParamsOfPage('actors')

  const rawRegionItems = useSelector(selectRegionList)
  const regionItems = useMemo(() => {
    // big first
    return rawRegionItems.filter((i) => i.actor_status === ENABLED).sort((i, j) => j.actor_seqno - i.actor_seqno)
  }, [rawRegionItems])

  const pageTabRef = useRef()

  // 如果從金剛區的 跳到指定演員進來, 會有actorIds
  const isSpecifiedSearch = !!actorIds

  async function loadPage() {
    if (isSpecifiedSearch) {
      // 如果是從金剛區導向到指定演員
      handleUpdateActiveRegion({ id: 0, index: 0 })
    } else {
      // 如果是從金剛區導向到演員列表
      handleUpdateActiveRegion({ id: regionItems[0].id, index: 0 })
    }
  }

  const handleUpdateActiveRegion = ({ id }) => {
    pageTabRef.current.changeTab(id)
  }

  useEffect(() => {
    loadPage()
    // eslint-disable-next-line
  }, [])

  return (
    <PageTabs ref={pageTabRef} title={headerTitle} type="underline" tabs={[...regionItems]}>
      {regionItems.map((t, i) => (
        <PageTab key={i}>
          <Region region={t.id} actorIds={actorIds} />
        </PageTab>
      ))}
    </PageTabs>
  )
}
