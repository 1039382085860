import useHintModal from 'hooks/useHintModal'

import PurchaseFailed from '../component/PurchaseFailed'
import { useAlert, positions } from 'react-alert'
import { useNavigateTo } from 'hooks/useNavigateTo'

export default function useOpenModal() {
  const { closeHintModal, openHintModal } = useHintModal()
  const alert = useAlert()
  const { navigateToWallet } = useNavigateTo()

  const openDepositModal = () => {
    closeHintModal()
    navigateToWallet({})
  }

  const openPurchaseSuccessModal = () => {
    alert.info('购买成功', {
      position: positions.MIDDLE,
      offset: '300px',
      timeout: 1500,
    })
  }

  const openPurchaseFailedModal = () => {
    openHintModal({
      children: PurchaseFailed,
      props: {},
    })
  }

  return {
    closeHintModal,
    openPurchaseSuccessModal,
    openPurchaseFailedModal,
    openDepositModal,
  }
}
