import styled from 'styled-components'

export const UploaderInfoElement = styled.div`
  position: absolute;
  left: 0.2rem;
  right: 0.2rem;
  bottom: 0.2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  pointer-events: none;
`
export const UploaderName = styled.a`
  font-weight: 700;
  font-size: 0.41rem;
  margin-bottom: 0.1rem;
  pointer-events: auto;

  display: flex;
  align-items: center;
  gap: 0.2rem;
`
export const VideoTitle = styled.a`
  align-self: stretch;
  font-size: 0.35rem;
  line-height: 150%;
  word-break: break-all;
  word-wrap: break-word;
`
export const InfoTag = styled.div`
  border: 0;
  margin: 0;
  background: var(--view-short-promotion-hint-background);
  padding: 0.2rem 0.4rem;
  font-size: 0.25rem;
  border-radius: 0.7rem;
`
