import { fetchShortFavouriteList } from 'api'
import actions from 'redux/action'
import { FETCH_SHORT_FAVOURITE, RESET_SHORT_FAVOURITE } from 'redux/constant/shortFavourite'
import { createListSaga } from 'redux/utils'

const handleFavourite = createListSaga(
  'ShortFavourite',
  FETCH_SHORT_FAVOURITE,
  RESET_SHORT_FAVOURITE,
  actions.patchShortFavouriteData,
  actions.clearShortFavouriteData,
  fetchShortFavouriteList
)

export default handleFavourite
