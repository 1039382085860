import { useEffect, useRef, useCallback } from 'react'
import { useSelector } from 'react-redux'
//
import { selectPictureOrigin } from 'redux/selector/app'
import { fetchBinFile } from 'api'
//
import { imageParser } from 'utils/imageParser'
import { Image } from './Styled'
import { useAdLinkHandler } from 'hooks/useAdLinkHandler'
import { catchPromiseCancel } from 'utils/catchPromiseCancel'

export default function CarouselItem(info) {
  const pictureOrigin = useSelector(selectPictureOrigin)
  const imgRef = useRef(null)
  const handleAdLink = useAdLinkHandler()

  const onAdItemClick = useCallback(() => {
    // if (info?.link) {
    //   fetchAfterAdClickEvent({ material_id: Number(info?.id) })
    // }

    handleAdLink({
      link: info,
      pageUuid: info.pageUuid,
    })
  }, [handleAdLink, info])

  useEffect(() => {
    if (!pictureOrigin || !info.icon_path) return

    const controller = new AbortController()
    const shortcutImagePath = `${pictureOrigin}/${info.icon_path}`

    let url

    fetchBinFile({
      url: shortcutImagePath,
      signal: controller.signal,
    })
      .then((textBase64) => (url = imgRef.current.src = imageParser(textBase64)))
      .catch(catchPromiseCancel)

    return () => {
      if (url) URL.revokeObjectURL(url)
      controller.abort()
    }
  }, [pictureOrigin, info])

  return <Image ref={imgRef} onClick={onAdItemClick} />
}
