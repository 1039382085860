import ReactModal from 'react-modal'
import styled, { keyframes } from 'styled-components'

const tick = keyframes`
 from { 
   stroke-dashoffset: 100;
 }
 to {
   stroke-dashoffset: 0;
 }
`

export const ModalWrapper = styled.div``

export const ReactModalWrapper = styled(ReactModal)``

export const Wrapper = styled.div`
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  width: 5.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.4rem;
  background: var(--page-profile--info-popup-background);
  color: var(--page-profile--info-popup-color);
  font-size: 0.3rem;
  transform: translate(-50%, -50%);
  border-radius: 0.25rem;

  .tick {
    stroke-dasharray: 100;
    stroke-dashoffset: 100;

    &.active {
      stroke-dashoffset: 0;
      animation: ${tick} 0.5s ease-in-out;
    }
  }
`

export const IconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  background: url(${process.env.PUBLIC_URL}/account/icon-payment-validating.svg) center;
  background-size: contain;
  background-repeat: no-repeat;
  padding-top: 1rem;
  font-size: 0.4rem;
`

export const TipText = styled.div`
  padding: 0.2rem;
`
