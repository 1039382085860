import { fetchVideoFavouriteList } from 'api'
import actions from 'redux/action'
import { FETCH_FAVOURITE, RESET_FAVOURITE } from 'redux/constant/favourite'
import { createListSaga } from 'redux/utils'

const handleFavourite = createListSaga(
  'Favourite',
  FETCH_FAVOURITE,
  RESET_FAVOURITE,
  actions.patchFavouriteData,
  actions.clearFavouriteData,
  fetchVideoFavouriteList
)

export default handleFavourite
