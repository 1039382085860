import { formatDate } from './timeFormat'

export const VIP_STATUS = {
  YES: 1,
  NO: 2,
  FOREVER: 3,
}

export function parseVipInfo(user) {
  if (!user) return { title: '-', time: '-' }
  const result = {
    title: '免费会员',
    expireText: '',
    isVip: false,
  }
  if (user.vip === VIP_STATUS.FOREVER) {
    result.title = user.vip_name || 'VIP会员'
    result.expireText = '永久效期'
    result.isVip = true
  } else if (user.vip === VIP_STATUS.YES) {
    result.title = user.vip_name || 'VIP会员'
    result.expireText = formatDate(user.vip_at) + ' 到期'
    result.isVip = true
  } else if (user.vip_at) {
    result.expireText = formatDate(user.vip_at) + ' 已过期'
  } else {
    result.expireText = '您还不是VIP，升级VIP可无限观影'
  }
  return result
}
