import { HeaderWrap, PageScrollWrap, PageWrapperNonScroll } from 'commonStyledComponents'
import PageModalHeaderBox from 'component/PageModalHeaderBox'
import { Branding, Desc, Detail, DetailText, DetailTitle, LogoInfoWrap, Name, Version } from './Styled'
import { useRef } from 'react'
import md5 from 'md5'
import { useNavigateTo } from 'hooks/useNavigateTo'

export const checkDebugSecret = (str) => {
  return md5('SSSSEEECCCRRREEETTT' + (str ?? '')) === '725ea846597e362511f6bae1d8d068db'
}
export const About = () => {
  const { navigateToDebugPage } = useNavigateTo()
  const clickCount = useRef(0)

  function onClickVersion() {
    clickCount.current++
    if (clickCount.current > 10) {
      clickCount.current = 0
      const input = prompt()
      if (checkDebugSecret(input)) {
        navigateToDebugPage({
          secret: input,
        })
      }
    }
  }
  return (
    <PageWrapperNonScroll>
      <HeaderWrap>
        <PageModalHeaderBox headerTitle={'关于'} />
      </HeaderWrap>
      <PageScrollWrap>
        <LogoInfoWrap>
          <Branding />
          <Version onClick={onClickVersion}>
            <Name>欲火视频</Name>
            <br />v{process.env.REACT_APP_VERSION}
          </Version>
          <Desc>
            全网最好的正版APP聚合平台
            <br />
            软件装机必备神器
          </Desc>
        </LogoInfoWrap>
        <Detail>
          <DetailTitle>安全无毒</DetailTitle>
          <DetailText>
            我们承诺欲火视频上架的每一款应用，都经过严格测式和把关后，保证您的手机安全无毒，无任何损害。
          </DetailText>
          <DetailTitle>最全应用</DetailTitle>
          <DetailText>
            欲火视频APP甄选全网正版应用软件，种类覆盖全网所有影视领域，为您提供多款优质正版产品服务，是当下软件最全的正版聚合平台。并且每天还在不断上架最新应用。
          </DetailText>
          <DetailTitle>免责声明</DetailTitle>
          <DetailText>本程序所有数据收集自互联网公共资源，程序本身及后台不存储任何音视频数据。</DetailText>
        </Detail>
      </PageScrollWrap>
    </PageWrapperNonScroll>
  )
}

export default About
