import { Image, ImageWrapper, StyledBase64Image } from './Styled'

const CoverImage = ({ base64SrcSource, onBase64ImageLoad, source, src, ...props }) => {
  return src ? (
    <ImageWrapper {...props}>
      <Image src={src} alt="" />
    </ImageWrapper>
  ) : (
    <StyledBase64Image
      {...props}
      source={source}
      onBase64ImageLoad={onBase64ImageLoad}
      noLazy={true}
      src={base64SrcSource}
    ></StyledBase64Image>
  )
}

export default CoverImage
