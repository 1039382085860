import Base64Image from 'component/Base64Image'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--page-Producer--color);
`

export const ProducerInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0.2rem;
  gap: 0.4rem;
`

export const ProducerIcon = styled(Base64Image).attrs({
  aspectRatio: null,
  placeholder: '/loading/load-actor.svg',
})`
  border-radius: 100%;
  width: 2.5rem;
  height: 2.5rem;
  object-fit: cover;
`

export const ProducerName = styled.div`
  font-size: 0.6rem;
  padding-bottom: 0.2rem;
`

export const ProducerInfo = styled.div`
  font-size: 0.3rem;
  padding: 0.1rem;
`
