import styled from 'styled-components'
import ReactModal from 'react-modal'

export const Wrapper = styled.div`
  outline: none;
`

export const Toggler = styled.button`
  width: 2rem;
  height: 2rem;
  overflow: hidden;
  position: fixed;
  z-index: 100;
  &.rotate-0 {
    right: calc(10px + env(safe-area-inset-right, 0px));
    top: 50%;
  }
  &.rotate-90 {
    bottom: calc(10px + max(env(safe-area-inset-bottom, 0px), env(safe-area-inset-top, 0px)));
    right: 50%;
  }
  &.rotate-180 {
    left: calc(10px + env(safe-area-inset-right, 0px));
    bottom: 50%;
  }
  &.rotate-270 {
    top: calc(10px + max(env(safe-area-inset-bottom, 0px), env(safe-area-inset-top, 0px)));
    left: 50%;
  }
`

export const TogglerIcon = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${process.env.PUBLIC_URL}/game/icon-home.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  &.rotate-90 {
    transform: rotate(90deg);
  }
  &.rotate-180 {
    transform: rotate(180deg);
  }
  &.rotate-270 {
    transform: rotate(270deg);
  }
`

export const ToolbarButton = styled.button`
  font-size: 0.3rem;
  padding: 0.2rem;
  width: 1.8rem;
  img {
    height: 0.75rem;
    width: 0.75rem;
  }
`

export const ReactModalWrapper = styled(ReactModal)`
  position: absolute;
  display: flex;
  justify-content: center;
  color: #fff;
  background: #2e2e2e;
  border-radius: 10px;
  width: 7.5rem;
  height: 2rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  &.rotate-90 {
    transform: translate(-50%, -50%) rotate(90deg);
  }
  &.rotate-180 {
    transform: translate(-50%, -50%) rotate(180deg);
  }
  &.rotate-270 {
    transform: translate(-50%, -50%) rotate(270deg);
  }
`
