import styled, { css, keyframes } from 'styled-components'

const circleFadeDelay = keyframes`
  0%,
  39%,
  100% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }
`

const circleFadeDelayRule = css`
  ${circleFadeDelay} 1.2s infinite ease-in-out both;
`

export const Wrapper = styled.div`
  margin: 2em auto;
  width: 1.067rem;
  height: 1.067rem;
  position: relative;
`

export const Circle = styled.div.attrs((props) => {
  return {
    style: props.rotate && {
      transform: `rotate(${props.rotate}deg)`,
    },
  }
})`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  &:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: #999;
    border-radius: 100%;
    animation: ${circleFadeDelayRule};
    ${(props) => `animation-delay: ${props.delay}s;`}
  }
`
