import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  height: 100%;
  font-size: 0.35rem;
  padding: 0.25rem 0.2rem;
`

export const FormRow = styled.div`
  display: grid;
  grid-template-columns: 80px 1fr;
  align-items: center;
`

export const Input = styled.input`
  background: var(--input-background);
  border-radius: 0.25rem;
  padding: 0.25rem;
  font-size: 0.35rem;
  &:hover,
  &:focus {
    background: var(--input-focus-background);
  }
`
