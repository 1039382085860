import { EncryptedDiv } from 'component/EncryptedElements'
import styled from 'styled-components'

export const FloatPromotionContainer = styled.div`
  position: absolute;
  right: var(--cms-grid-column-gap);
  bottom: var(--cms-grid-row-gap);
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const FloatPromotionClose = styled.div`
  margin-top: var(--cms-grid-row-gap);
  width: 0.76923rem;
  height: 0.76923rem;
  background-image: url('${process.env.PUBLIC_URL}/icon/promotion-float-close.svg');
  background-position: center;
  background-size: contain;
`

export const FloatPromotionImage = styled(EncryptedDiv).attrs({
  backgroundImage: `${process.env.PUBLIC_URL}/icon/promotion-float.enc.gif`,
})`
  width: 2.64103rem;
  height: 2.5641rem;
  background-position: center;
  background-size: contain;
`
