import { fetchComicList } from 'api'
import ComicBox from 'component/ComicBox'
import { useCallback, useEffect, useState } from 'react'
import { Container, Title } from './Styled'
import { useNavigateTo } from 'hooks/useNavigateTo'

export default function Recommendations({ tag_ids }) {
  const [data, setData] = useState(() => [])
  const serializedId = tag_ids.join(',')

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    fetchComicList({
      signal,
      tag_ids: serializedId,
      pageSize: 6,
    })
      .then((res) => {
        setData(res.data)
      })
      .catch((err) => {
        if (process.env.NODE_ENV === 'development') {
          console.info(err)
        }
      })

    return () => {
      controller.abort()
    }
  }, [serializedId])

  const { navigateToViewMore } = useNavigateTo()
  const openViewMore = useCallback(() => {
    navigateToViewMore({
      params: {
        tag_ids: tag_ids.join(','),
      },
      headerTitle: '猜你喜欢',
      type: 'comic',
    })
  }, [navigateToViewMore, tag_ids])

  return (
    <>
      <Title text="猜你喜欢" hasMore onMore={openViewMore} />
      <Container>
        {data.map((item, index) => (
          <ComicBox key={index} columns={3} comicInfo={item} />
        ))}
      </Container>
    </>
  )
}
