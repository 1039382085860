import Free from '../component/Free'
import Currency from '../component/Currency'
import VIP from '../component/VIP'
import { useMemo } from 'react'

const paymentTypeImage = {
  1: Free,
  2: Currency,
  3: VIP,
}

/**
 *
 * @param {import("../../../../types/api").SchemaVideoShow} videoInfo
 * @returns
 */
export const usePaymentTypeImage = (videoInfo) => {
  const Tag = paymentTypeImage[videoInfo.payment_type] ?? Free

  return useMemo(() => <Tag {...videoInfo} />, [videoInfo])
}
