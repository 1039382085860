// import Video from 'video.js'
import lottie from 'lottie-web'
import loadingData from './video_loading.json'

let installed = false
export const addLoadingSpinner = (Video) => {
  if (installed) {
    return
  }

  installed = true
  const Component = Video.getComponent('Component')
  class LoadingSpinner extends Component {
    createEl() {
      var el = Component.prototype.createEl.call(this, 'div', {
        className: 'vjs-custom-spinner',
      })

      const animation = lottie.loadAnimation({
        container: el,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: loadingData,
      })

      this.player().on('dispose', () => {
        animation.destroy()
      })

      return el
    }
  }

  Video.registerComponent('LoadingSpinner', LoadingSpinner)
}
