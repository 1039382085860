import Base64Image from 'component/Base64Image'
import styled from 'styled-components'

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  overflow-y: auto;
  flex-wrap: nowrap;
  background-color: #454444;
  --cms-grid-row-gap: 0.02rem;
  --cms-grid-column-gap: 0.02rem;
  --component-video-box-vertical--border-radius: 0;
  --title-bar-radius: 0.2rem;
  > * {
    flex: 0 0 auto;
  }
`

export const Header = styled.div`
  position: relative;
  z-index: 0;
  box-sizing: content-box;
  height: env(safe-area-inset-top);
  padding-bottom: 38.7%;
`

export const IconBack = styled.button`
  position: absolute;
  z-index: 999;
  top: calc(env(safe-area-inset-top, 0rem) + 0.26rem);
  left: 0.266rem;
  width: 1.333rem;
  height: 1.333rem;
  background-image: url(${process.env.PUBLIC_URL}/icon/arrow-white.svg);
  background-size: 22% 36%;
  background-repeat: no-repeat;
  background-position: left top;
`

export const UploaderWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: calc(calc(var(--title-bar-radius) * -1) - 1px);
  padding-bottom: var(--title-bar-radius);
  z-index: 2;
  background: linear-gradient(360deg, #334142 22.29%, rgba(51, 65, 66, 0) 100%);
  box-sizing: border-box;
  height: 2.25rem;
  padding-top: 0.2rem;
  padding-left: 2.77rem;
  color: white;
`

export const UploaderImage = styled.div`
  position: absolute;
  left: 0.41rem;
  bottom: 0.41rem;
  z-index: 2;
  box-sizing: border-box;
  width: 2.05rem;
  height: 2.05rem;
  border: 0.05rem solid white;
  overflow: hidden;
  border-radius: 50%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const Uploader = styled.div`
  font-size: 0.51rem;
  font-weight: 700;
  line-height: 130%;
  position: relative;

  &::after {
  }
`

export const UploaderMeta = styled.div`
  display: flex;
  align-items: center;
`
export const UploaderMetaValue = styled.div`
  font-size: 0.41rem;
  font-weight: 700;
  line-height: 130%;
  margin-right: 0.1rem;
`

export const UploaderMetaName = styled.div`
  font-weight: 700;
  font-size: 0.308rem;
  line-height: 130%;
  margin-right: 0.2rem;
`
export const StyledBase64Img = styled(Base64Image)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`

export const TotalShorts = styled.div`
  background: black;
  height: 1.01rem;
  position: relative;
  background: black;
  text-align: center;
  font-size: 0.41rem;
  font-weight: 700;
  border-radius: var(--title-bar-radius) var(--title-bar-radius) 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`

export const TotalShortObserverArea = styled.div`
  position: absolute;
  top: calc(env(safe-area-inset-top, 0) * -1);
  left: 0;
  right: 0;
  bottom: env(safe-area-inset-top, 0);
  display: block;
  visibility: hidden;
`
export const OverlayTitle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: black;
  box-sizing: content-box;
  padding-top: env(safe-area-inset-top);
  height: 1.02rem;
  line-height: 1.02rem;
  text-align: center;
  opacity: 0;
  font-size: 0.41rem;
  font-weight: 700;
  color: white;
`
