import styled from 'styled-components'

export default styled.div`
  /* margin-top: env(safe-area-inset-top); */
  flex: 1 1 auto;
  min-height: 0;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  position: relative;
  z-index: 1;
`
