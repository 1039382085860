import { useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useImmer } from 'use-immer'
import SyncLoader from 'react-spinners/SyncLoader'
import isEmpty from 'lodash/isEmpty'

import { selectIsOpenDepositChannelBox } from 'redux/selector/deposit'

import { fetchPayTypeList } from 'api'

import useDepositChannelBox from 'hooks/useDepositChannelBox'

import {
  Wrapper,
  ChannelBox,
  Title,
  LoadingBox,
  ChannelItemBox,
  Item,
  Icon,
  Radio,
  Button,
  TipsBox,
  ProductName,
  ProductPrice,
  IconClose,
} from './Styled'
import useConfirmModal from 'hooks/useConfirmModal'

/** 元件 - 充值渠道選項 */
export default function DepositChannelBox({ selectedDepositItem, onSubmit }) {
  const { closeDepositChannelBox } = useDepositChannelBox()
  const ChannelBoxRef = useRef()
  const isOpenDepositChannelBox = useSelector(selectIsOpenDepositChannelBox)
  const { openConfirm } = useConfirmModal()

  const [depositInfo, updateDepositInfo] = useImmer({
    isLoading: true,
    hasError: false,
    errorMessage: '发生错误',
    list: [],

    selectedChannel: '',
  })

  const onOutsideClose = (event) => {
    const isClickOutsideChannelBox = !ChannelBoxRef.current?.contains(event.target)

    if (isClickOutsideChannelBox) {
      closeDepositChannelBox()
      updateDepositInfo((draft) => void (draft.selectedChannel = ''))
    }
  }

  const handleClose = () => {
    closeDepositChannelBox()
    updateDepositInfo((draft) => void (draft.selectedChannel = ''))
  }

  const onDepositChannelChange = (e) => {
    updateDepositInfo((draft) => void (draft.selectedChannel = e.target.value))
  }

  const handleSubmit = () => {
    onSubmit(depositInfo.selectedChannel)
  }

  useEffect(() => {
    if (!isOpenDepositChannelBox || depositInfo?.list?.length > 0) {
      return
    }

    updateDepositInfo((draft) => {
      draft.isLoading = true
      draft.list = []
    })

    fetchPayTypeList({}) // (1:金幣 2:VIP)
      .then((response) => {
        updateDepositInfo((draft) => {
          draft.isLoading = false
          draft.list = response?.data

          /** 預設使用所有充值方式的第一個 */
          draft.selectedChannel = response?.data[0]?.id
        })
      })
      .catch((err) => {
        openConfirm({
          title: '发生错误',
          message: err?.message || String(err) || '发生错误',
          onConfirm: () => {
            closeDepositChannelBox()
            updateDepositInfo((draft) => void (draft.selectedChannel = ''))
          },
          onCancel: () => {
            closeDepositChannelBox()
            updateDepositInfo((draft) => void (draft.selectedChannel = ''))
          },
        })
      })
  }, [
    closeDepositChannelBox,
    depositInfo?.list?.length,
    isOpenDepositChannelBox,
    openConfirm,
    selectedDepositItem,
    updateDepositInfo,
  ])

  return (
    <>
      {!isEmpty(selectedDepositItem) && isOpenDepositChannelBox && (
        <Wrapper onClick={onOutsideClose}>
          <ChannelBox ref={ChannelBoxRef}>
            <Title>
              <div>请选择支付方式</div>
              <IconClose onClick={handleClose} />
            </Title>

            <TipsBox>
              <span>已选择</span>
              <ProductName>{selectedDepositItem?.name}</ProductName>
              <ProductPrice>¥{selectedDepositItem.price}</ProductPrice>
            </TipsBox>

            <ChannelItemBox onChange={onDepositChannelChange}>
              {depositInfo?.isLoading ? (
                <LoadingBox>
                  <SyncLoader size={'0.32rem'} color={'#35D7B7'} />
                </LoadingBox>
              ) : depositInfo?.hasError ? (
                <div style={{ height: '1rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  {depositInfo?.errorMessage}
                </div>
              ) : (
                depositInfo?.list?.map((item) => {
                  return (
                    <label key={item?.id} htmlFor={item?.id}>
                      <Item>
                        <Icon url={item?.icon} />
                        <span>{item?.name}</span>
                        <Radio
                          defaultChecked={depositInfo.selectedChannel === item?.id}
                          type="radio"
                          name="payTypeId"
                          id={item?.id}
                          value={item?.id}
                        />
                      </Item>
                    </label>
                  )
                })
              )}
            </ChannelItemBox>

            <Button
              disabled={depositInfo.hasError || depositInfo.isLoading || !depositInfo.selectedChannel}
              onClick={handleSubmit}
            >
              确认支付
            </Button>
          </ChannelBox>
        </Wrapper>
      )}
    </>
  )
}
