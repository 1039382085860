import { PageWrapper } from 'commonStyledComponents'
import styled from 'styled-components'

export const Wrapper = styled(PageWrapper)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

export const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.51rem;
  font-weight: bold;
  width: 100%;
  height: 0.66rem;
  line-height: 0.66rem;
  touch-action: none;
`

export const NestedPageWrapper = styled.div`
  height: 0;
  flex: 1 1 auto;
  position: relative;
  display: flex;
`
