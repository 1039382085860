import Tabs from 'component/Tabs'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: auto;
  gap: var(--category-grid-gap);

  ::-webkit-scrollbar {
    display: none;
  }

  img {
    // 演員頭像圖片位置靠上方以顯示頭部
    object-position: top;
  }
`

export const RegionBox = styled(Tabs)`
  flex: 0 0 auto;
  margin: 0 0 0.25rem;
  --component-tabs--active-color: var(--page-category-region-active-color);
`

export const ScrollWrap = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
`
