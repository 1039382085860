import { Fragment, useCallback, useMemo, useRef } from 'react'
import isEmpty from 'lodash/isEmpty'
import { useImmer } from 'use-immer'
import { useSelector } from 'react-redux'
import shuffle from 'lodash/shuffle'
//
import { selectAdInfo } from 'redux/selector/app'
//
import {
  fetchComicList,
  fetchComicTotalQty,
  fetchShortList,
  fetchShortTotalQty,
  fetchVideoList,
  fetchVideoTotalQty,
} from 'api'
//
import { AdvertisementType } from 'constant/advertisement'
//
import HeaderBox from 'component/PageModalHeaderBox'
import VideoBox from 'component/VideoBox'
import VideoBoxVertical from 'component/VideoBoxVertical'
import EmptyResult from 'component/EmptyResult'
import AdItem from './component/AdItem'
//
import ComicBox from 'component/ComicBox'
import {
  AreaPanelCmsGridWrapper,
  HeaderWrap,
  InfiniteScrollWrapper,
  PageScrollWrap,
  PageWrapperNonScroll,
} from 'commonStyledComponents'
import { useParamsOfPage } from 'hooks/useNavigateTo'
import PullToRefresh from 'react-simple-pull-to-refresh'
import PagePullLoad from 'component/Loading/PagePullLoad'

export default function ViewMore() {
  const { type = 'video', params, headerTitle: headerTitleProp, showAd = true } = useParamsOfPage('view-more')

  const adInfo = useSelector(selectAdInfo)
  const adViewMore = useMemo(() => {
    return adInfo?.[AdvertisementType.ViewMore]
  }, [adInfo])
  // 是否需要亂數排序
  const randomSort = useMemo(() => {
    return params.random
  }, [params])

  const [state, updateState] = useImmer({
    nextPage: 1,
    isLoading: true,
    hasMore: true,
    list: [],
    page_result: { current: null, pageSize: null, total: null },
    loadedPages: [], // loaded pages index array
  })
  const loading = useRef(false)

  const headerTitle = headerTitleProp || '看更多'
  const VideoBoxComponent =
    type === 'video'
      ? params?.isVerticalVideoBox
        ? VideoBoxVertical
        : VideoBox
      : type === 'short'
      ? VideoBoxVertical
      : type === 'comic'
      ? ComicBox
      : VideoBox

  const itemPerRow =
    type === 'video'
      ? params?.isVerticalVideoBox
        ? 2
        : params?.isLargeVideoBox
        ? 1
        : 2
      : type === 'short'
      ? 2
      : type === 'comic'
      ? 3
      : 2 // as default

  const fetchVideoAPi =
    type === 'video'
      ? fetchVideoList
      : type === 'short'
      ? fetchShortList
      : type === 'comic'
      ? fetchComicList
      : fetchVideoList

  const fetchTotalApi = useMemo(() => {
    if (type === 'short') return fetchShortTotalQty
    else if (type === 'comic') return fetchComicTotalQty
    else return fetchVideoTotalQty
  }, [type])

  const handleFetchVideoList = useCallback(async () => {
    if (!state.hasMore) return

    const data = {
      // current: state.nextPage,
      pageSize: 30,
      ...params,
    }

    if (loading.current) return
    loading.current = true
    let total = state?.page_result?.total
    if (!total) {
      // update total
      const totalRes = await fetchTotalApi({
        ...params,
      })
      total = totalRes?.page_result?.total || 0
      if (totalRes?.page_result) {
        updateState((draft) => {
          draft.page_result = totalRes?.page_result
        })
      }
    }

    if (total) {
      // random page
      const pages = Math.ceil(total / data.pageSize)
      const numbers = Array.from({ length: pages }, (m, i) => i + 1)
      let next = state.nextPage
      let hasMore = false
      if (randomSort) {
        const filteredNumbers = numbers.filter((m) => !state.loadedPages.includes(m))
        next = filteredNumbers[Math.floor(Math.random() * filteredNumbers.length)]
        hasMore = filteredNumbers.length > 1
      }
      data.current = next
      const response = await fetchVideoAPi(data)
      let items = response?.data || []
      if (randomSort) {
        items = shuffle(items)
      } else {
        hasMore = response?.page_result?.total > response?.page_result?.current * response?.page_result?.pageSize
      }
      updateState((draft) => {
        if (draft.loadedPages.includes(next)) return
        draft.list = draft?.list?.concat(items)
        draft.hasMore = hasMore
        draft.nextPage = response?.page_result?.current + 1
        draft.page_result = response.page_result
        draft.isLoading = false
        draft.loadedPages = [...draft.loadedPages, next]
      })
      loading.current = false
    } else {
      updateState((draft) => {
        draft.list = []
        draft.hasMore = false
        draft.nextPage = 1
        draft.isLoading = false
        draft.loadedPages = []
      })
    }
  }, [fetchTotalApi, fetchVideoAPi, params, randomSort, state, updateState])

  const handleRefresh = useCallback(async () => {
    // load event will be triggered after update state
    updateState((draft) => {
      draft.list = []
      draft.hasMore = true
      draft.nextPage = 1
      draft.page_result = { current: null, pageSize: null, total: null }
      draft.isLoading = true
      draft.loadedPages = []
    })
  }, [updateState])

  const shortInfo = useMemo(() => {
    return {
      list: state.list,
    }
  }, [state.list])

  return (
    <PageWrapperNonScroll>
      <HeaderWrap>
        <HeaderBox headerTitle={headerTitle} />
      </HeaderWrap>
      {!state?.list?.length && !state.isLoading ? (
        <EmptyResult />
      ) : (
        <PageScrollWrap className="lazy-root">
          <PullToRefresh
            onRefresh={handleRefresh}
            pullingContent={<PagePullLoad paused />}
            refreshingContent={<PagePullLoad />}
          >
            <InfiniteScrollWrapper
              pageStart={0}
              loadMore={handleFetchVideoList}
              hasMore={state?.hasMore}
              // loader={<div className="loader" key={0}>Loading ...</div>}
              useWindow={false}
            >
              {state?.list?.length > 0 && (
                <AreaPanelCmsGridWrapper>
                  {state?.list?.map((item, i) => {
                    /** 每四行 放置一個 視頻櫥窗更多廣告位 */
                    const itemsPerAd = itemPerRow * 4
                    const isPutAdItem = showAd ? (i + 1) % itemsPerAd === 0 : false
                    const adIndex = ((i + 1) / itemsPerAd) % adViewMore?.length
                    const adItemInfo = adViewMore?.[adIndex]

                    return (
                      <Fragment key={i}>
                        {type === 'video' && <VideoBoxComponent columns={itemPerRow} videoInfo={item} />}
                        {type === 'short' && (
                          <VideoBoxComponent columns={itemPerRow} videoInfo={item} shortInfo={shortInfo} />
                        )}
                        {type === 'comic' && <VideoBoxComponent columns={itemPerRow} comicInfo={item} />}
                        {isPutAdItem && !isEmpty(adItemInfo) && <AdItem {...adItemInfo} />}
                      </Fragment>
                    )
                  })}
                </AreaPanelCmsGridWrapper>
              )}
            </InfiniteScrollWrapper>
          </PullToRefresh>
        </PageScrollWrap>
      )}
    </PageWrapperNonScroll>
  )
}
