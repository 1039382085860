import { fetchShortCollectionList } from 'api'
import actions from 'redux/action'
import { FETCH_SHORT_COLLECTION, RESET_SHORT_COLLECTION } from 'redux/constant/shortCollection'
import { createListSaga } from 'redux/utils'

const handleCollection = createListSaga(
  'ShortCollection',
  FETCH_SHORT_COLLECTION,
  RESET_SHORT_COLLECTION,
  actions.patchShortCollectionData,
  actions.clearShortCollectionData,
  fetchShortCollectionList
)

export default handleCollection
