import styled from 'styled-components'

export const Wrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  z-index: var(--layer-fullpage-modal);
  background-color: black;
`

export const Item = styled.div.attrs((props) => {
  const imageInfo = props?.base64Img && {
    backgroundImage: `url(data:image/jpeg;base64,${props?.base64Img})`,
    backgroundSize: 'cover',
    backgroundPosition: 'top center',
    backgroundRepeat: 'no-repeat',
  }

  return {
    style: {
      ...imageInfo,
    },
  }
})`
  position: relative;
  height: 100%;
  width: 100%;
`

export const LateLoadingContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.31rem 0.2rem;
  border-radius: 0.25rem;
  gap: 0.2rem;
  background: rgba(0, 0, 0, 0.05);
  color: #2e2e2e;
  font-size: 0.36rem;

  text-shadow: 0 0 8px rgba(255, 255, 255, 0.8);

  img {
    width: 1.3rem;
    height: 1.3rem;
    object-fit: contain;
  }
`
