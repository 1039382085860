import styled from 'styled-components'
import PageLoadingIcon from '../PageLoadingIcon/index.js'

export const OverLay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: var(--main-background);
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledAnimation = PageLoadingIcon
