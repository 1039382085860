import styled from 'styled-components'

export const TouchEffectContainer = styled.div`
  position: relative;
`
export const TouchEffectItem = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  transition: all 1s;
  height: 3.23rem;
  width: 3.89rem;
`
