import Splash from 'component/LaunchSplash'
import { MaintenancePageWrapper, PageContent, PageContentLine, PageFooter, PageTitle } from './Styled'
import React, { useCallback, useMemo } from 'react'
import { FullWidthPrimaryButton } from 'commonStyledComponents'
import { useSelector } from 'react-redux'
import { selectMaintenanceMessageInfo } from 'redux/selector/app'

export default function Maintenance() {
  const info = useSelector(selectMaintenanceMessageInfo)
  const lines = useMemo(
    () => ['为了让您有更好体验', '我们正在更新维护中', '', '预计维护完成时间', info.end, `( ${info.message} ）`],
    [info]
  )

  const version = useMemo(() => {
    return process.env.REACT_APP_VERSION + '.' + process.env.REACT_APP_GIT_SHA
  }, [])

  const handleRetry = useCallback(() => {
    window.location.replace('/')
  }, [])

  return (
    <React.Fragment>
      <Splash></Splash>
      <MaintenancePageWrapper>
        <PageContent>
          <PageTitle>系统维护中，请稍后</PageTitle>
          {lines.map((m) => (
            <PageContentLine>{m}</PageContentLine>
          ))}
        </PageContent>

        <FullWidthPrimaryButton onClick={handleRetry}>重试</FullWidthPrimaryButton>
        <PageFooter>
          <PageContentLine>{version}</PageContentLine>
        </PageFooter>
      </MaintenancePageWrapper>
    </React.Fragment>
  )
}
