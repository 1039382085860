import { useState } from 'react'
import { PageWrapperNonScroll, PageScrollWrap } from 'commonStyledComponents'
import {} from 'api/index'

import { PageHeaderWrapper, PromotionPlaceholder } from './Styled'
import PageModalHeaderBox from 'component/PageModalHeaderBox'

export default function GamePromotion() {
  const [promotionList, setPromotionList] = useState([])

  const content = <PromotionPlaceholder></PromotionPlaceholder>

  return (
    <PageWrapperNonScroll>
      <PageHeaderWrapper>
        <PageModalHeaderBox headerTitle={'优惠活动'} />
      </PageHeaderWrapper>
      <PageScrollWrap>{content}</PageScrollWrap>
    </PageWrapperNonScroll>
  )
}
