import { EncryptedDiv } from 'component/EncryptedElements'
import styled from 'styled-components'

// card
export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Card = styled(EncryptedDiv).attrs({
  backgroundImage: `${process.env.PUBLIC_URL}/share/img-card-bg.enc.png`,
})`
  width: 7.5rem;
  height: 14.3rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`

export const CardContent = styled.div`
  position: absolute;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.2rem;
  align-items: start;
  width: 100%;
  bottom: 0;
  padding: 0.5rem 0.4rem;
`

export const CardText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.2rem;
  text-align: center;
  font-size: 0.4rem;
  font-weight: bold;
  line-height: 130%;
  color: var(--page-share-text-color);
  overflow: hidden;
`

export const CardQrCodeWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: end;
`

export const CardLink = styled.div`
  text-decoration: underline;
  font-size: 0.3rem;
  text-align: left;
  word-break: break-all;
  line-height: 1.2;
`

export const QRCodeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  background-color: #ffffff;
  padding: 0.2rem;
  border-radius: 0.1103rem;
`

export const Link = styled.a``
