import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
// import { Html5Qrcode } from 'html5-qrcode'
import ReactModal from 'react-modal'
import { CameraWrapper, HiddenInput, ToggleButton, Wrapper } from './Style'
import { useAlert, positions } from 'react-alert'

const qrcodeRegionId = 'html5qr-code-camera-region'

// Creates the configuration object for Html5QrcodeScanner.
const createConfig = (props) => {
  let config = {
    qrbox: 248,
  }
  if (props.fps) {
    config.fps = props.fps
  }
  if (props.qrbox) {
    config.qrbox = props.qrbox
  }
  if (props.aspectRatio) {
    config.aspectRatio = props.aspectRatio
  }
  if (props.disableFlip !== undefined) {
    config.disableFlip = props.disableFlip
  }
  return config
}

const modalStyle = {
  overlay: {
    zIndex: 99,
    fontSize: 'calc(100vmin / 390 * 16)',
    backgroundColor: 'rgba(16, 4, 27, 0.5)',
  },
  content: {
    top: '50%',
    left: '50%',
    bottom: 'initial',
    right: 'initial',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    border: 'initial',
  },
}

export default function IDCardScanner(props) {
  const [modalInitiated, setModalInitiated] = useState()
  const [cameraStarted, setCameraStarted] = useState(false)
  const cameraWrapperEl = useRef(/** @type {HTMLDivElement | null} */ (null))
  const alert = useAlert()

  const [qrCodeApp, setQrCodeApp] = useState(/** @type {Html5Qrcode} */ (null))

  const [qrCodeAppId, setQrCodeAppId] = useState(0)

  useEffect(() => {
    if (!modalInitiated) return

    let newQrCodeApp
    let aborted = false

    async function load() {
      const { Html5Qrcode } = await import('html5-qrcode')
      if (aborted) return
      newQrCodeApp = new Html5Qrcode(qrcodeRegionId)
      setQrCodeApp(newQrCodeApp)
    }
    load()

    return () => {
      aborted = true
      try {
        newQrCodeApp
          ?.stop()
          .then((ignore) => {
            // QR Code scanning is stopped.
          })
          .catch((err) => {
            // Stop failed, handle it.
          })
      } catch (err) {
        // ignore
      }
    }
  }, [modalInitiated, qrCodeAppId])

  // checking camera error
  useEffect(() => {
    if (!cameraStarted) {
      return
    }

    const MAX_ERROR_CHECK = 3
    let errorCount = 0

    const id = setInterval(() => {
      const el = cameraWrapperEl.current?.querySelector('video')

      if (el && !el.paused && el.currentTime === 0) {
        errorCount++
      } else {
        errorCount = 0
      }

      if (errorCount > MAX_ERROR_CHECK) {
        clearInterval(id)

        try {
          qrCodeApp
            ?.stop()
            .then(
              () => {
                setCameraStarted(false)
              },
              (err) => {
                setCameraStarted(false)
                // Stop failed, handle it.
                console.error(err)
                // we are unable to stop it normally, abort the instance
                setQrCodeAppId((i) => i + 1)
              }
            )
            .then(() => {
              alert.info('系统相机异常，请重启手机或是使用相片上传', {
                position: positions.MIDDLE,
                offset: '300px',
                timeout: 1500,
              })
            })
        } catch (err) {}
      }
    }, 500)

    return () => {
      clearInterval(id)
    }
  }, [alert, cameraStarted, qrCodeApp])

  const qrCodeHandler = useRef(props.qrCodeSuccessCallback)

  useLayoutEffect(() => {
    qrCodeHandler.current = props.qrCodeSuccessCallback
  })

  const onQrCodeSuccess = useCallback((...args) => {
    qrCodeHandler.current(...args)
  }, [])

  const qrCodeErrorHandler = useRef(props.qrCodeErrorCallback)

  useLayoutEffect(() => {
    qrCodeErrorHandler.current = props.qrCodeErrorCallback
  })
  const onQrCodeError = useCallback((...args) => {
    qrCodeErrorHandler.current(...args)
  }, [])

  const enableCamera = async () => {
    const config = createConfig(props)

    try {
      await qrCodeApp.stop()
    } catch (err) {}

    qrCodeApp.start({ facingMode: 'environment' }, config, onQrCodeSuccess).then(() => {
      setCameraStarted(true)
    })
  }

  /**
   * @param {import("react").FormEvent<HTMLInputElement>} ev
   */
  const handleDropFile = async (ev) => {
    const file = ev.currentTarget.files[0]
    // FIXME: for some reason, scan will fail if qrcode is perfectly grid aligned on safari.
    // so we rotate it slightly and try again
    const scanWithRotation = async (file, degree = 0) => {
      if (degree === 0) {
        return qrCodeApp.scanFile(file, true)
      }

      const bitmap = await createImageBitmap(file)
      const canvas = document.createElement('canvas')
      canvas.width = bitmap.width
      canvas.height = bitmap.height
      const ctx2d = canvas.getContext('2d')
      ctx2d.rotate((Math.PI / 180) * degree)
      ctx2d.drawImage(bitmap, 0, 0, bitmap.width, bitmap.height, 0, 0, bitmap.width, bitmap.height)

      const pngBlob = await new Promise((resolve) => canvas.toBlob(resolve, 'png'))
      const pngFile = new File([pngBlob], 'test.png')
      return qrCodeApp.scanFile(pngFile, true)
    }
    if (file) {
      try {
        const t = await scanWithRotation(file, 0)
        onQrCodeSuccess(t)
        return
      } catch (err) {}
      try {
        const t = await scanWithRotation(file, 1)
        onQrCodeSuccess(t)
        return
      } catch (err) {}
      try {
        const t = await scanWithRotation(file, -1)
        onQrCodeSuccess(t)
        return
      } catch (err) {
        onQrCodeError(err)
      }
    }
  }

  const onCloseCamera = useCallback(() => {
    qrCodeApp
      ?.stop()
      .then((ignore) => {
        // QR Code scanning is stopped.
      })
      .catch((err) => {
        // Stop failed, handle it.
      })
      .then(() => {
        setCameraStarted(false)
      })
  }, [qrCodeApp])

  return (
    <ReactModal isOpen onAfterOpen={() => setModalInitiated(true)} style={modalStyle} onRequestClose={props.onClose}>
      <Wrapper>
        <CameraWrapper placeholder detached={cameraStarted} onClick={enableCamera}>
          点击启用相机
        </CameraWrapper>
        <CameraWrapper ref={cameraWrapperEl} detached={!cameraStarted} id={qrcodeRegionId}></CameraWrapper>
        <ToggleButton hidden={!cameraStarted} onClick={onCloseCamera}>
          关闭相机
        </ToggleButton>
        <ToggleButton hidden={cameraStarted}>
          或是，上传相片
          <HiddenInput type="file" accept="image/*" onChange={handleDropFile} />
        </ToggleButton>
      </Wrapper>
      {/* <div id={qrcodeRegionId} /> */}
    </ReactModal>
  )
}
