import EmptyResult from 'component/EmptyResult'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FETCH_SHORT_FAVOURITE, RESET_SHORT_FAVOURITE } from 'redux/constant/shortFavourite'
import { selectShortFavouriteData } from 'redux/selector/shortFavourite'
import ShortVideoBox from 'component/ShortVideoBox'
import { InfiniteScrollCmsGridWrapper } from 'commonStyledComponents'
import { ShortWrap } from './Styled'

const Short = () => {
  const dispatch = useDispatch()
  const videoShortFavourites = useSelector(selectShortFavouriteData)

  const runOnce = useRef(false)
  const handleFetchVideoList = useCallback(() => {
    if (!runOnce.current) {
      runOnce.current = true
      dispatch({ type: RESET_SHORT_FAVOURITE })
    }

    dispatch({ type: FETCH_SHORT_FAVOURITE })
  }, [dispatch])

  const shortInfo = useMemo(
    () => ({
      list: videoShortFavourites?.list ?? [],
      updateList: () => dispatch({ type: RESET_SHORT_FAVOURITE }),
    }),
    [dispatch, videoShortFavourites]
  )

  useEffect(() => {
    dispatch({ type: RESET_SHORT_FAVOURITE })
    dispatch({ type: FETCH_SHORT_FAVOURITE })
  }, [dispatch])

  return !videoShortFavourites?.list?.length && !videoShortFavourites.isLoading ? (
    <EmptyResult />
  ) : (
    <ShortWrap>
      <InfiniteScrollCmsGridWrapper
        pageStart={0}
        loadMore={handleFetchVideoList}
        hasMore={videoShortFavourites?.hasMore}
        // loader={<div className="loader" key={0}>Loading ...</div>}
        useWindow={false}
      >
        {videoShortFavourites?.list?.map((item, i) => (
          <ShortVideoBox key={i} videoInfo={item} columns={3} shortInfo={shortInfo} noTitle>
            {i}
          </ShortVideoBox>
        ))}
      </InfiniteScrollCmsGridWrapper>
    </ShortWrap>
  )
}

export default Short
