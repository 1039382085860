import { ColoredIcon } from 'component/ColoredIcon'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 100vw;
  font-size: 0.35rem;
  padding: 0.2rem;
  gap: 0.25rem;
`

export const Section = styled.div`
  background: var(--page-game-deposit--section-background);
  border-radius: 0.25rem;
  padding: 0.4rem 0.2rem;
  font-size: 0.35rem;
`

export const SectionTitle = styled.h2`
  font-size: 0.4rem;
  font-weight: bold;
  padding: 0rem 0 0.3rem 0;
`

export const FieldRow = styled.div`
  display: grid;
  grid-template-columns: 2rem 1fr;
  gap: 0.2rem;
  align-items: center;
`

export const AccountInfoRow = styled.div`
  display: grid;
  grid-template-columns: 2rem 1fr auto;
  gap: 0.2rem;
  margin-bottom: 0.2rem;
`

export const CopyButton = styled(ColoredIcon).attrs({
  src: `${process.env.PUBLIC_URL}/icon/copy.png`,
})`
  width: 0.5rem;
  height: 0.5rem;
`

export const ErrorHintText = styled.div`
  color: var(--danger-color);
  text-align: right;
  padding-top: 0.1rem;
`
