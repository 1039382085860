import { useNavigationLocalData } from 'component/Navigation/hooks/useNavigationLocalData'
import { INTERNAL_TYPE_GAME, INTERNAL_TYPE_NAV, LINK_TYPE_INTERNAL } from 'constant/advertisement'
import { useSelector } from 'react-redux'
import { selectNavigationContent } from 'redux/selector/app'
import { useCallback } from 'react'
import { useAppNavigate } from 'app-layered-layout/useAppNavigate'
import { useAppLocationQuery } from 'app-layered-layout/useAppLocationQuery'
import { URL_QUERY_DISABLE_LAYOUT } from 'constant/routes'
import { useOpenGamePopup } from './useOpenGamePopup'
import { selectGameUserStatus } from 'redux/selector/game'
import { formatDate } from 'utils/timeFormat'
import { fetchPostAdEvent } from 'api'
import { useGameBanCheck } from './useGameBanCheck'

const getPopStateEvent = () => {
  return new Promise((resolve) => {
    /**
     * @param {PopStateEvent} ev
     */
    const handler = (ev) => {
      resolve()
      window.removeEventListener('popstate', handler)
    }

    window.addEventListener('popstate', handler)
  })
}

/**
 * @typedef {{ link: import("../../types/api").SchemaOholoMaterialShow, pageUuid?: string }} DataItemLink
 */

export const useAdLinkHandler = () => {
  const navigationContent = useSelector(selectNavigationContent)
  const navigate = useAppNavigate()
  const localData = useNavigationLocalData()
  const query = useAppLocationQuery()
  const isPopup = query[URL_QUERY_DISABLE_LAYOUT] != null
  const openGamePopup = useOpenGamePopup()
  const gameUserStatus = useSelector(selectGameUserStatus)
  const { userCanEnter } = useGameBanCheck(gameUserStatus)

  return useCallback(
    (
      /** @type {DataItemLink} */ {
        link: {
          link_type,
          internal_type,
          internal_id,
          link,
          game_platform,
          game_type_id,
          direction,
          id,
          name,
          site_id,
          oholoer_id,
        },
      }
    ) => {
      fetchPostAdEvent({
        date: formatDate(new Date()),
        material_id: id,
        material_name: name,
        oholo_site_id: site_id,
        oholoer_id,
      })
      if (link_type !== LINK_TYPE_INTERNAL) {
        if (link !== '') {
          window.open(link, '_blank')
        }
      } else {
        switch (internal_type) {
          case INTERNAL_TYPE_GAME:
            if (!userCanEnter) {
              // user banned, redirect to game lobby
              navigate('/game')
              return
            }
            openGamePopup({
              id: internal_id,
              ...(game_platform
                ? {
                    gameType: game_type_id,
                    tpCode: game_platform,
                    direction: direction,
                  }
                : {}),
            })
            break
          case INTERNAL_TYPE_NAV:
          default:
            const index = navigationContent.findIndex((i) => i.id === internal_id)

            if (index < 0) {
              return
            }

            const target = localData.navContent[index]

            if (isPopup) {
              const popState = getPopStateEvent()
              navigate(-1)
              popState.then(() => {
                navigate(target.navLink + target.search, { replace: true, forceReplaceNonCurrentPage: true })
              })
            } else {
              navigate(target.navLink + target.search, { replace: true, forceReplaceNonCurrentPage: true })
            }
        }
      }
    },
    [isPopup, localData.navContent, navigate, navigationContent, openGamePopup, userCanEnter]
  )
}
