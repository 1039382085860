import { ColoredIcon } from 'component/ColoredIcon'
import styled, { keyframes } from 'styled-components'

const driveUp = keyframes`
 from { transform: translate3d(0, 100%, 0) }
 to { transform: translate3d(0, 0, 0) }
`

export const Wrapper = styled.div`
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(16, 4, 27, 0.5);
  font-size: 0.426rem;
  color: #000000;
`

export const ChannelBox = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0.8rem 1rem;
  background-color: #fff;
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
  animation: ${driveUp} 0.2s ease-out;
`

export const Title = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  font-size: 0.48rem;
  font-weight: bold;
  letter-spacing: 0.2px;
  color: #000;
  text-align: left;
`

export const ChannelItemBox = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 0.1rem;
  padding-bottom: 0.5rem;
`

export const Item = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  letter-spacing: 0.27px;
  user-select: none;
  cursor: pointer;
  padding: 0.266rem 0;
  border-bottom: solid 0.05rem var(--page-profile--item-color);

  > span {
    flex: 1;
    text-align: left;
  }
`

export const Icon = styled.div.attrs((props) => {
  return {
    style: {
      backgroundImage: `url(data:image/jpeg;base64,${props.url})`,
    },
  }
})`
  width: 0.746rem;
  height: 0.746rem;
  margin-right: 0.453rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`

export const Radio = styled.input`
  width: 0.4rem;
  height: 0.4rem;
  cursor: pointer;
`

export const Button = styled.button`
  width: 100%;
  padding: 0.2rem 0;
  font-size: 0.35rem;
  border-radius: 0.5rem;
  color: var(--button-primary-color);
  letter-spacing: 0.2px;
  background: var(--button-primary-background);
`

export const LoadingBox = styled.div`
  padding-top: 0.266rem;
  height: 2.133rem;
`

export const TipsBox = styled.div`
  padding: 0.6rem 0;
  display: grid;
  grid-template-columns: auto auto 1fr;
  letter-spacing: 1.5px;
  font-weight: bold;
  font-size: 0.45rem;
`

export const ProductName = styled.span`
  font-size: 0.45rem;
  margin: 0 15px 0 5px;
`

export const ProductPrice = styled.span`
  color: var(--theme-color);
  font-weight: bold;
  text-align: right;
`

export const IconClose = styled(ColoredIcon).attrs({
  src: `${process.env.PUBLIC_URL}/icon/close.svg`,
})`
  height: 0.6rem;
  width: 0.6rem;
`
