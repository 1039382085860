import type videojs from 'video.js'
import type Component from 'video.js/dist/types/component'
import type VideoJsPlayer from 'video.js/dist/types/player'
import './vjs-title-bar.css'

type VideoJs = typeof videojs

interface VideoJsTitleBarPluginOptions {
  title: string
}

export interface ITitleBar extends Component {
  setTitle(title: string): void
}

let installed = false

export const addTitleBar = (videojs: VideoJs) => {
  if (installed) {
    return
  }

  installed = true

  const Component = videojs.getComponent('Component')

  // How the fuck cra still not support declare class field in 2023???
  const getRootShim = (title: TitleBar): Element => {
    return (title as any).root
  }
  // How the fuck cra still not support declare class field in 2023???
  const setRootShim = (title: TitleBar, root: Element): void => {
    ;(title as any).root = root
  }

  class TitleBar extends Component implements ITitleBar {
    static defaultOptions: VideoJsTitleBarPluginOptions = {
      title: '',
    }

    private titleBarOptions: VideoJsTitleBarPluginOptions

    // declare private root: Element

    constructor(player: VideoJsPlayer) {
      super(player)
      this.titleBarOptions = Object.assign({}, TitleBar.defaultOptions)

      this.el().textContent = this.titleBarOptions.title
    }

    createEl() {
      const root = videojs.dom.createEl(
        'div',
        {
          // Prefixing classes of elements within a player with "vjs-"
          // is a convention used in Video.js.
          className: 'vjs-custom-title-bar',
        },
        {},
        []
      )

      setRootShim(this, root)

      return root
    }

    setTitle(title: string) {
      videojs.dom.emptyEl(getRootShim(this))
      getRootShim(this).textContent = title
    }
  }

  videojs.registerComponent('TitleBar', TitleBar)
}
