import { useCallback, useEffect, useMemo, useState } from 'react'
//
import { fetchGameDepositChannelDetail, fetchUsdtExchangeRate, postGameCompanyDeposit } from 'api/index'

import { Wrapper, Section, FieldRow, AddressRow, CopyButton, CopyInfoRow, ErrorHintText } from './Styled'

import { FullWidthPrimaryButton, HeaderWrap, PageWrapperNonScroll, PageScrollWrap } from 'commonStyledComponents'
import { formatAmount } from 'utils/amountFormat'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Input } from '../Styled'
import NoteBox from 'component/NoteBox'
import PageModalHeaderBox from 'component/PageModalHeaderBox'
import { useParamsOfPage } from 'hooks/useNavigateTo'
import { useAppNavigate } from 'app-layered-layout/useAppNavigate'
import { useToastAlert } from 'hooks/useToastAlert'

export default function UsdtExchange() {
  const { toast } = useToastAlert()
  const { payment_channel_id, amount } = useParamsOfPage('game-deposit/usdt-exchange')
  const navigate = useAppNavigate()

  // 頁面資料
  const [exchangeInfo, setExchangeInfo] = useState({
    rate: 0, // 1 USDT = ? RMB
  })
  const [accountInfo, setAccountInfo] = useState(null)
  const [infoLoading, setInfoLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const estimatedAmount = useMemo(() => amount * exchangeInfo.rate, [amount, exchangeInfo])
  const MAX_LEN = 5

  const handleCopy = useCallback(
    async (text) => {
      if (text && navigator.clipboard) {
        await navigator.clipboard.writeText(text)
        toast('已复制到剪贴簿')
      }
    },
    [toast]
  )

  const addressRow = useMemo(
    () =>
      accountInfo ? (
        <AddressRow>
          <div>TRC-20地址</div>
          <CopyInfoRow>
            {accountInfo.receiptAccount}
            <CopyButton onClick={() => handleCopy(accountInfo.receiptAccount)}></CopyButton>
          </CopyInfoRow>
        </AddressRow>
      ) : (
        <>{infoLoading ? '载入中' : '无资料'}</>
      ),
    [accountInfo, handleCopy, infoLoading]
  )

  const { values, errors, handleChange, handleSubmit, handleBlur, isValid, dirty, touched } = useFormik({
    initialValues: {
      remark: '',
    },
    // validateOnChange: false,
    validationSchema: Yup.object().shape({
      remark: Yup.string()
        .required('请输入SHA值')
        .test('maxlen', `请输入正确的哈希值末${MAX_LEN}码`, (v) => v?.toString().length == MAX_LEN),
    }),
    onSubmit: async () => {
      await submitDeposit()
    },
  })

  const loadChannel = useCallback(async () => {
    setInfoLoading(true)
    try {
      const detail = await fetchGameDepositChannelDetail({
        channelId: payment_channel_id,
      })
      if (detail.data) {
        setAccountInfo(detail.data)
      } else {
        toast('暂无帐户信息')
      }
    } catch {
      toast('无法取得帐户信息')
    }

    setInfoLoading(false)
  }, [payment_channel_id, toast])

  const loadExchangeRate = useCallback(async () => {
    try {
      const rateInfo = await fetchUsdtExchangeRate()
      setExchangeInfo({
        rate: rateInfo.data.buyPrice,
        time: rateInfo.data.time,
      })
    } catch {
      toast('无法取得汇率')
    }
  }, [toast])

  async function submitDeposit() {
    if (submitLoading) {
      toast('请勿重复提交')
      return
    }
    const payload = {
      channelId: payment_channel_id,
      remark: values.remark.toString(),
      amount,
    }
    try {
      setSubmitLoading(true)
      await postGameCompanyDeposit(payload)
      toast('提交成功')
      navigate(-2)
    } catch (ex) {
      console.warn(ex)
      if (ex && ex.message) toast('存款失败')
    } finally {
      setSubmitLoading(false)
    }
  }

  useEffect(() => {
    loadChannel()
    loadExchangeRate()
  }, [loadChannel, loadExchangeRate])

  return (
    <PageWrapperNonScroll>
      <HeaderWrap>
        <PageModalHeaderBox headerTitle={'USDT'} />
      </HeaderWrap>
      <PageScrollWrap>
        <Wrapper>
          <Section>
            <FieldRow>
              <span>转帐金额</span>
              <span>{formatAmount(amount)} USDT</span>
            </FieldRow>
            <FieldRow>
              <span>汇率</span>
              <span>{formatAmount(exchangeInfo.rate)}</span>
            </FieldRow>
            <FieldRow>
              <span>转帐金额</span>
              <span>¥ {formatAmount(estimatedAmount)}元</span>
            </FieldRow>
          </Section>
          <Section>{addressRow}</Section>
          <Section>
            <FieldRow>
              <span>转帐备注</span>
              <Input
                placeholder="请输入转帐哈希值末5码"
                name="remark"
                type="text"
                maxLength={MAX_LEN}
                value={values.remark}
                onBlur={handleBlur}
                onChange={handleChange}
              ></Input>
            </FieldRow>
            <ErrorHintText>{touched.remark && errors.remark}</ErrorHintText>
          </Section>
          <NoteBox
            background="none"
            text={['请务必提供真实资讯，以供核对转帐资料。', '请再汇款备注写入真实姓名及相关资讯，以加速充值流程。']}
          ></NoteBox>
          <FullWidthPrimaryButton type="submit" onClick={handleSubmit} disabled={!isValid || !dirty}>
            送出
          </FullWidthPrimaryButton>
        </Wrapper>
      </PageScrollWrap>
    </PageWrapperNonScroll>
  )
}
