import { useEffect, useState } from 'react'
import { useImmer } from 'use-immer'

import { fetchProducerList, fetchVideoList } from 'api'

import { Wrapper, ProducerIcon, ProducerName, ProducerInfo, ProducerInfoWrapper } from './Styled'
import { HeaderWrap, InfiniteScrollCmsGridWrapper, PageScrollWrap, PageWrapperNonScroll } from 'commonStyledComponents'
import PageModalHeaderBox from 'component/PageModalHeaderBox'
import VideoBox from 'component/VideoBox'
import { Fragment } from 'react'
import EmptyResult from 'component/EmptyResult'
import { useParamsOfPage } from 'hooks/useNavigateTo'

/** 演員頁 */
export default function Producer() {
  const { producerId, producerImage, producerName, producerImageSource } = useParamsOfPage('producer')
  const [listInfo, updateListInfo] = useImmer({
    nextPage: 1,
    isLoading: true,
    hasMore: true,
    list: [],
    page_result: { current: null, pageSize: null, total: null },
  })
  const [producerInfo, setProducerInfo] = useState(null)
  const [total, setTotal] = useState(null)
  const [loading, setLoading] = useState(false)

  async function loadInfo() {
    const producer = await fetchProducerList({
      id: producerId,
    })
    if (producer?.data?.length) {
      setProducerInfo(producer.data[0])
    } else {
      console.warn('producer not found: ' + producerId)
    }
  }

  async function handleLoadMore() {
    if (loading) return
    if (listInfo.current === listInfo.page_result.current || !listInfo.hasMore) return

    setLoading(true)
    const data = {
      current: listInfo.nextPage,
      pageSize: 30,
      producer_ids: producerId,
    }

    const response = await fetchVideoList(data)
    updateListInfo((draft) => {
      draft.list = draft?.list?.concat(response?.data)
      draft.hasMore = response?.page_result?.total > response?.page_result?.current * response?.page_result?.pageSize
      draft.nextPage = response?.page_result?.current + 1
      draft.page_result = response.page_result
      draft.isLoading = false
    })
    setTotal(response.page_result.total)
    setLoading(false)
  }

  useEffect(() => {
    loadInfo()
  }, [])

  return (
    <PageWrapperNonScroll>
      <HeaderWrap>
        <PageModalHeaderBox headerTitle="" />
      </HeaderWrap>
      <PageScrollWrap>
        <Wrapper>
          <ProducerInfoWrapper>
            <ProducerIcon src={producerInfo?.icon_path}></ProducerIcon>
            <ProducerInfo>
              <ProducerName>{producerInfo?.name}</ProducerName>
              <div>作品数量 {total}</div>
              {/* {info.name} */}
            </ProducerInfo>
          </ProducerInfoWrapper>
          <InfiniteScrollCmsGridWrapper
            style={{}}
            pageStart={0}
            loadMore={() => handleLoadMore()}
            hasMore={listInfo?.hasMore}
            useWindow={false}
          >
            {listInfo?.list?.map((item, i) => {
              return (
                <Fragment key={i}>
                  <VideoBox videoInfo={item} />
                </Fragment>
              )
            })}
            {!listInfo?.list?.length && !listInfo.isLoading && <EmptyResult />}
          </InfiniteScrollCmsGridWrapper>
        </Wrapper>
      </PageScrollWrap>
    </PageWrapperNonScroll>
  )
}
