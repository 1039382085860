import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'

import BeatLoader from 'react-spinners/BeatLoader'

import { UserNameFieldBox, PasswordFieldBox } from 'component/TextFieldBox'

import {
  ContentBox,
  Wrapper,
  Title,
  HintText,
  WrapperMask,
  ErrorText,
  BottomText,
  BtnSwitchLogin,
  IconClose,
  ButtonSubmit,
} from './Styled'

import { PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH, USERNAME_MAX_LENGTH, USERNAME_MIN_LENGTH } from 'constant/common'
import { useToastAlert } from 'hooks/useToastAlert'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { fetchLogin, fetchRegister } from 'api'
import { FETCH_USER_INFO } from 'redux/constant/user'
import useConfirmModal from 'hooks/useConfirmModal'

const MODE = {
  REGISETER: 0,
  LOGIN: 1,
}

/** 元件 - 充值渠道選項 */
export default function LoginPopup({ onClose, onFinish }) {
  const [mode, setMode] = useState(MODE.REGISETER)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [registerErrorMessage, setRegisterErrorMessage] = useState('')
  const [loginErrorMessage, setLoginErrorMessage] = useState('')
  const { toast } = useToastAlert()
  const { openConfirm } = useConfirmModal()
  const dispatch = useDispatch()

  const {
    errors: loginErrors,
    handleChange: handleLoginChange,
    handleSubmit: handleLoginSubmit,
  } = useFormik({
    initialValues: {
      user_name: '',
      password: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      user_name: Yup.string().required('请输入帐号'),
      password: Yup.string().required('请输入密码'),
    }),
    onSubmit: (param) => {
      handleLogin(param)
    },
  })

  const {
    errors: registerErrors,
    handleChange: handleRegisterChange,
    handleSubmit: handleRegisterSubmit,
  } = useFormik({
    initialValues: {
      user_name: '',
      password: '',
      confirm_password: '',
    },
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      user_name: Yup.string()
        .required('请输入帐号')
        .min(USERNAME_MIN_LENGTH, `帐号需要大于${USERNAME_MIN_LENGTH}个字元`)
        .max(USERNAME_MAX_LENGTH, `帐号需要小于${USERNAME_MAX_LENGTH}个字元`),
      password: Yup.string()
        .required('请输入密码')
        .min(PASSWORD_MIN_LENGTH, `密码需要大于${PASSWORD_MIN_LENGTH}个字元`),
      confirm_password: Yup.string()
        .required('请再次确认密码')
        .oneOf([Yup.ref('password'), null], '密码与确认密码需一致'),
    }),
    onSubmit: (param) => {
      handleRegister(param)
    },
  })

  const handleFinish = useCallback(() => {
    onFinish()
  }, [onFinish])

  const handleRegister = useCallback(
    async (params) => {
      setSubmitLoading(true)
      setRegisterErrorMessage('')

      try {
        await fetchRegister(params)
        // 重新使用deviceId獲取用戶資料
        dispatch({ type: FETCH_USER_INFO })

        // 顯示成功訊息
        toast('注册成功')
        handleFinish()
      } catch (error) {
        // toast('获取帐户资料失败', error?.message)
        toast(error?.message || '注册失败')
        setSubmitLoading(false)
        // setRegisterErrorMessage(error?.message || '')
      } finally {
        setSubmitLoading(false)
      }
    },
    [dispatch, toast, handleFinish]
  )

  const handleLogin = useCallback(
    async (param) => {
      setSubmitLoading(true)
      setLoginErrorMessage('')
      try {
        const data = {
          user_name: param.user_name,
          password: param.password,
        }
        await fetchLogin(data)
        dispatch({ type: FETCH_USER_INFO })
        toast('登入成功')
        handleFinish()
      } catch (error) {
        toast(error?.message || '登入失败')
        setSubmitLoading(false)
        // setLoginErrorMessage(error?.message || '')
      } finally {
        setSubmitLoading(false)
      }
    },
    [dispatch, handleFinish, toast]
  )

  const switchMode = useCallback(
    (mode) => {
      if (mode === MODE.LOGIN) {
        openConfirm({
          title: ' ',
          message: '登录其他账号后存款将不会合并于同一账号，确认是否仍要前往登录吗？',
          onConfirm: () => {
            setMode(mode)
          },
        })
      } else {
        setMode(mode)
      }
      setLoginErrorMessage('')
      setRegisterErrorMessage('')
    },
    [openConfirm]
  )

  return (
    <WrapperMask>
      <Wrapper>
        {mode === MODE.REGISETER ? (
          <ContentBox key={MODE.REGISETER}>
            <Title>
              注册会员
              <IconClose onClick={onClose} />
            </Title>
            <UserNameFieldBox
              name="user_name"
              placeholder={`请输入${PASSWORD_MIN_LENGTH}-${PASSWORD_MAX_LENGTH}位英文字母+数字的组合`}
              error={registerErrors.user_name}
              handleChange={handleRegisterChange}
            />

            <PasswordFieldBox
              name="password"
              placeholder={`请输入${PASSWORD_MIN_LENGTH}-${PASSWORD_MAX_LENGTH}位英文字母+数字的组合`}
              error={registerErrors.password}
              handleChange={handleRegisterChange}
            />

            <PasswordFieldBox
              name="confirm_password"
              placeholder={'再次输入密码'}
              error={registerErrors.confirm_password}
              handleChange={handleRegisterChange}
              isConfirmPassword
            />

            <ErrorText>{registerErrorMessage}</ErrorText>
            <ButtonSubmit type="submit" disabled={submitLoading} onClick={handleRegisterSubmit}>
              {submitLoading ? <BeatLoader size={12} margin={2} color={'#da9dc8'} /> : '立即注册'}
            </ButtonSubmit>

            <BottomText>
              <span>已经有帐号</span>
              <BtnSwitchLogin onClick={() => switchMode(MODE.LOGIN)}>去登入</BtnSwitchLogin>
            </BottomText>
            <HintText>为维护您的权益，充值前请先注册会员哦</HintText>
          </ContentBox>
        ) : (
          <ContentBox key={MODE.LOGIN}>
            <Title>
              登入会员
              <IconClose onClick={onClose} />
            </Title>
            <UserNameFieldBox
              name="user_name"
              placeholder="输入帐号"
              error={loginErrors.user_name}
              handleChange={handleLoginChange}
            />

            <PasswordFieldBox
              name="password"
              placeholder={'输入密码'}
              error={loginErrors.password}
              handleChange={handleLoginChange}
            />

            <ErrorText>{loginErrorMessage}</ErrorText>
            <ButtonSubmit type="submit" disabled={submitLoading} onClick={handleLoginSubmit}>
              {submitLoading ? <BeatLoader size={12} margin={2} color={'#da9dc8'} /> : '登入'}
            </ButtonSubmit>

            <BottomText>
              <span>没有帐号</span>
              <BtnSwitchLogin onClick={() => switchMode(MODE.REGISETER)}>去注册</BtnSwitchLogin>
            </BottomText>
            <HintText>
              提醒您，登录其他账号前请先前往个人中心
              <br />
              保存身份卡至本地相册，避免额度丢失哦
            </HintText>
          </ContentBox>
        )}
      </Wrapper>
    </WrapperMask>
  )
}
