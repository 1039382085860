import { useEffect, useMemo, useRef, useCallback } from 'react'

import { QRCodeWrapper, CardWrapper, CardText, CardContent, CardLink, Card, CardQrCodeWrapper, Link } from './Styled'
import QRCode from 'qrcode.react'
import { BeatLoader } from 'react-spinners'

export default function SharePage({ url }) {
  const cardRef = useRef(null)
  const downloadLinkRef = useRef(null)

  const link = useMemo(() => {
    return url ? url : ''
  }, [url])

  const handleDownload = useCallback(async function () {
    try {
      const card = cardRef.current
      const link = downloadLinkRef.current
      const html2canvas = (await import('html2canvas')).default
      const canvas = await html2canvas(card, { backgroundColor: null, scale: 2 })
      const imageName = 'share_card'
      const src = canvas.toDataURL()
      link.href = src
      link.download = `${imageName}.png`
      link.click()
      // toast('保存成功')
    } catch (ex) {
      console.warn('download image error', ex)
    }
  }, [])

  useEffect(() => {
    setTimeout(() => {
      handleDownload()
    }, 1000)
  }, [handleDownload])

  return (
    <CardWrapper>
      <Card ref={cardRef}>
        <CardContent>
          <CardQrCodeWrapper>
            <QRCodeWrapper>
              {!!link ? (
                <QRCode id={'qrcode'} style={{ width: '2.64rem', height: '2.64rem' }} value={link} level={'H'} />
              ) : (
                <BeatLoader size={10} />
              )}
            </QRCodeWrapper>
          </CardQrCodeWrapper>
          <CardText>
            <div>
              扫描二维码
              <br />
              下载APP立即观看
            </div>
            <CardLink>{link}</CardLink>
          </CardText>
        </CardContent>
      </Card>
      <Link target="_blank" ref={downloadLinkRef} />
    </CardWrapper>
  )
}
