export const timeFormat = (video_length) => {
  const before = video_length?.split(':').reverse()
  const after = []
  for (let i = 0; i < 3; i++) {
    const sliceTime = Math.ceil(before[i]) ? Math.ceil(before[i])?.toString() : '00'
    after.push(!!sliceTime?.length ? (sliceTime?.length >= 2 ? sliceTime : sliceTime.padStart(2, '0')) : '00')
  }
  return after?.reverse().join(':')
}

export const formatDate = (text) => {
  const date = new Date(text)
  if (isNaN(date)) return '-'
  return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate()}`
}

export const formatDateTime = (text) => {
  const date = new Date(text)
  if (isNaN(date)) return '-'
  return (
    `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate()} ` +
    `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date
      .getSeconds()
      .toString()
      .padStart(2, '0')}`
  )
}
