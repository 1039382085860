import styled from 'styled-components'

export const UploaderInfoElement = styled.div`
  position: absolute;
  left: 0.2rem;
  right: 0.2rem;
  bottom: 0.2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  pointer-events: none;
  // 避免被 player control layer 覆蓋導致無法點擊
  z-index: 10;
  opacity: 1;
  transition: opacity 0.5s;

  // 加速或者移動進度條時隱藏用戶資訊欄位
  .video-flag-speed-up &,
  .video-flag-seeking & {
    opacity: 0;
  }
`
export const Topics = styled.div`
  color: var(--white, #fff);
  /* Bold/Body Text */
  font-size: 0.35897rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 0.53846rem */
  letter-spacing: 0.01026rem;

  margin-bottom: 0.26rem;
  padding: 0.05rem 0;
`

export const TopicTextWrapper = styled.span`
  background: rgba(0, 0, 0, 0.2);
  padding: 0.05rem 0;

  > span {
    white-space: nowrap;
    display: inline-block;
    margin-left: 0.1rem;
    margin-right: 0.1rem;
    pointer-events: auto;
  }
`

export const UploaderName = styled.a`
  font-weight: 700;
  font-size: 0.41rem;
  margin-bottom: 0.1rem;
  pointer-events: auto;
`
export const VideoTitle = styled.a`
  align-self: stretch;
  font-size: 0.35rem;
  line-height: 150%;
  word-break: break-all;
  word-wrap: break-word;
`
