import { PrimaryButton } from 'commonStyledComponents'
import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 100vw;
  font-size: 0.35rem;
  padding: 0 0.2rem;
  gap: 0.25rem;
`

export const TotalCard = styled.div`
  position: relative;
  padding-top: 0.2rem;
  color: var(--page-game--has-background-color);
  background: url(${process.env.PUBLIC_URL}/account/bg-creditcard.png);
  background-size: cover;
  height: 2.8rem;
  border-radius: 0.25rem;
  overflow: hidden;
  font-size: 0.4rem;

  > div {
    padding: 0.1rem 0.4rem;
  }

  .total-balance {
    font-size: 0.6rem;
    font-weight: 700;
  }
`

export const TotalCardButtonRow = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  bottom: 0;
  width: 100%;
  background: var(--page-game--withdraw-card-bottom-background);
`

export const PlatformItem = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  border: var(--page-game-transfer--border);
  font-size: 0.4rem;
  padding: 0.4rem;
  border-radius: 0.125rem;
`

export const PlatformItemBalance = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: end;
`

export const Button = styled(PrimaryButton)`
  min-width: 2rem;
  padding: 0.1rem 0.2rem;
  font-size: 0.35rem;
  &[disabled] {
    color: white;
    background: linear-gradient(180deg, #ededed -0.1%, #b5b5b5 37.44%, #999999 72.37%, #737373 100%);
  }
`
