import { takeLatest, fork, call, put, select, all, join } from 'redux-saga/effects'

import actions from 'redux/action'
import { INIT_APP, RACE_AVAILABLE_ORIGIN } from 'redux/constant/app'
import {
  fetchNavigationContent,
  fetchVideoTagList,
  fetchProducerList,
  fetchAvailableOrigin,
  fetchAdList,
  fetchRegionList,
  fetchAnnouncement,
  fetchBinFile,
  fetchStation,
  fetchVideoTopicList,
  fetchGameUserStatus,
} from 'api'

import { AdvertisementType } from 'constant/advertisement'
import { getStationId } from 'utils/settings'
import { fetchUser, getVisitorId } from './user'
import { URL_QUERY_SKIP_LOGIN_TOKEN } from 'constant/routes'
import { setAnalyticReady, setAnalyticUser } from 'hooks/useAnalytics'
import { ApiError } from 'utils/apiError'
import { processURL } from 'utils/debugUtil'
import { selectCurrentSplashAd, selectPictureOrigin } from 'redux/selector/app'
import { AnnouncementType } from 'constant/announcement'

const sleep = (timeMs) => {
  return new Promise((resolve) => {
    setTimeout(resolve, timeMs)
  })
}

const BASE_WAIT_TIME_MS = 5000
const TIME_SCALER = 1.5
const MAX_WAIT_TIME = 30000
const WAIT_TIME_RANGE = 3000
const MAX_RETRY_ATTEMPTS = 3
const ROUTE_ERROR_RETRY_MESSAGES = ['验证站点数据中...', '连线中...']
const DATA_ERROR_RETRY_MESSAGES = ['获取站点资料中...', '连线中...']

export const CACHED_AD_ITEM_KEY = 'CAIK'

export const mapToUserError = (e) => {
  let userError = e
  if (e && /network error/i.test(String(e.message))) {
    userError = Object.assign(new Error('请检查手机网络设置'), { title: '网络异常' })
    if (process.env.NODE_ENV === 'development') {
      console.log('mapped error', e)
    }
  }
  return userError
}

function* runWithRetry(generator) {
  let hasError = false
  let retryDataTime = 0

  let waitTime = BASE_WAIT_TIME_MS

  do {
    try {
      const result = yield* generator()
      hasError = false
      return result
    } catch (err) {
      hasError = true
      if (ApiError.isMaintenanceError(err)) {
        console.log('System is in maintenance, aborting')
        // do not capture MaintenanceError
        yield put(actions.updateRouteDetectMessage(''))
        yield put(actions.updateIsShowingLaunchSplash(false))
        yield put(actions.updateMaintenanceSplash({ visible: true, end: err.end, message: err.message }))
        //
        throw err
      }
      if (ApiError.isForbidden(err)) {
        // do not capture MaintenanceError
        yield put(actions.updateRouteDetectMessage(''))
        yield put(actions.updateIsShowingLaunchSplash(false))
        yield put(actions.updateFullPageError({ visible: true, error: err }))
        //
        throw err
      }

      yield put(
        actions.updateRouteDetectMessage(DATA_ERROR_RETRY_MESSAGES[retryDataTime % DATA_ERROR_RETRY_MESSAGES.length])
      )

      if (retryDataTime >= MAX_RETRY_ATTEMPTS) {
        // 超過最大重試上線
        yield put(actions.updateRouteDetectMessage(''))
        yield put(actions.updateIsShowingLaunchSplash(false))
        yield put(actions.updateFullPageError({ visible: true, error: mapToUserError(err) }))
        //
        throw err
      }

      yield sleep(waitTime + (Math.random() * 2 - 1) * WAIT_TIME_RANGE)
      waitTime = Math.min(waitTime * TIME_SCALER, MAX_WAIT_TIME)
      retryDataTime++
    }
  } while (hasError)
}

function* fakeInit(tokenJSON) {
  const visitorId = yield* getVisitorId()
  setAnalyticUser(visitorId)
  yield put(actions.updateTokenInfo(JSON.parse(tokenJSON)))
  yield put(actions.updateReadyToShowSplashAd(true))
  yield put(actions.updateReadyToEnter(true))
  yield put(actions.updateViewedAdvertisement(true))
  yield put(actions.updateIsShowingLaunchSplash(false))
  // make game page accessible
  yield put(actions.updateUser({ id: 'user_id_for_game' }))
}

function* init() {
  yield console.log('App init')

  // FIXME: workaround to open new page
  const url = new URL(window.location.href)
  if (url.searchParams.get(URL_QUERY_SKIP_LOGIN_TOKEN) != null) {
    yield* fakeInit(url.searchParams.get(URL_QUERY_SKIP_LOGIN_TOKEN))
    setAnalyticReady()
    return
  }

  if (process.env.REACT_APP_USE_STARTUP_CACHE) {
    yield fork(loadCachedAd)
  }

  let station
  try {
    yield put(actions.updateMaintenanceSplash({ visible: false }))
    yield* runWithRetry(function* () {
      let currentStation = (station = yield call(fetchStation))
      let waitTime = BASE_WAIT_TIME_MS
      let retryTime = 0

      while (String(currentStation.id) !== getStationId()) {
        console.warn('API ERROR, bad site id')
        yield put(
          actions.updateRouteDetectMessage(ROUTE_ERROR_RETRY_MESSAGES[retryTime % ROUTE_ERROR_RETRY_MESSAGES.length])
        )
        // 避免上線後變成瞬間自我 ddos
        yield sleep(waitTime + (Math.random() * 2 - 1) * WAIT_TIME_RANGE)
        currentStation = yield call(fetchStation)
        waitTime = Math.min(waitTime * TIME_SCALER, MAX_WAIT_TIME)
        retryTime++
      }
    })

    // 站台資訊
    yield put(actions.updateStation(station))

    yield* runWithRetry(function* () {
      // 自動使用遊客身份登入, 獲取代理碼
      const inviteCode = yield select((state) => state?.user?.inviteCode)
      yield* fetchUser({ inviteCode })
    })

    const checkDomainTask = yield fork(function* () {
      yield* runWithRetry(function* () {
        // 獲取圖片/預覽/高清用網址
        yield call(getAvailableOrigin)
      })
    })

    const loadAdTask = yield fork(loadAd, checkDomainTask)

    // Non critical resource, just allow it to fail and don't retry
    yield fork(getInformation)

    yield* runWithRetry(function* () {
      // 獲取所有 Content Management System 資訊
      const navContent = yield call(fetchNavigationContent)

      // Content Management System 資訊
      yield put(actions.updateNavigationContent(navContent))
    })

    yield join(loadAdTask)
    yield join(checkDomainTask)

    yield put(actions.updateReadyToEnter(true))

    yield* runWithRetry(function* () {
      try {
        // 獲取遊戲用戶狀態
        const gameUserStatus = yield call(fetchGameUserStatus)
        yield put(actions.updateGameUserStatus(gameUserStatus))
      } catch (ex) {
        console.warn('get game user status error')
      }
    })

    setAnalyticReady()
  } catch (e) {
    yield console.error('App init Error', e)
    yield put(actions.updateReadyToShowSplashAd(false))
    yield put(actions.updateHero(mapToUserError(e)))
  }
}

function processAdList(adList) {
  const adInfo = {}

  Object.values(AdvertisementType).forEach((adType) => {
    const adContent = adList?.filter((info) => info.site_type === adType)
    adInfo[adType] = adContent
  })

  return adInfo
}

function processAnnouncementList(adInfo, announcementList) {
  const adList = adInfo?.[AdvertisementType.Announcement]
  return [
    ...announcementList.map((item) => ({ type: AnnouncementType.System, item })),
    ...adList.map((item) => ({ type: AnnouncementType.Ad, item })),
  ]
}

function* getAppName() {
  yield console.log('getAppName')
}

function* getInformation() {
  try {
    const [
      // 獲取地區列表
      regionList,
      // 獲取標籤列表
      tagList,
      // 獲取短視頻主題列表
      topicList,
      // 獲取片商列表
      producerList,
    ] = yield all([
      call(fetchRegionList),
      call(fetchVideoTagList),
      call(fetchVideoTopicList, {}),
      call(fetchProducerList),
    ])
    yield put(actions.updateRegionList(regionList))
    yield put(actions.updateTagList(tagList))
    yield put(actions.updateTopicList(topicList.data ?? []))
    yield put(actions.updateProducerList(producerList))
  } catch (error) {
    yield console.error('getInformation Error')
  }
}

function* getAvailableOrigin() {
  // 獲取可用的 location origin
  const { domain_original, domain_preview, domain_picture, domain_comic, domain_novel, name_original } = yield call(
    fetchAvailableOrigin
  )

  yield put(
    actions.updateFullOrigins({
      domain_original,
      name_original,
      domain_preview,
      domain_picture,
      domain_comic,
      domain_novel,
    })
  )

  const mappedOrigins = name_original
    .map((name, index) => {
      if (!/^https?:\/\//.test(domain_original[index])) {
        return null
      }
      try {
        return {
          name,
          domain: new URL(domain_original[index]).origin,
        }
      } catch (err) {
        console.warn(err)
        return null
      }
    })
    .filter((i) => i != null)

  const filterInvalidDomains = (arr) => {
    return arr.filter((i) => /^https?:\/\//.test(i))
  }

  yield put(actions.updateOfficialOriginList(mappedOrigins))

  /** 更新預覽影片使用origin */
  const fastestPreviewOrigin = yield Promise.race([
    // 任意線路成功就使用該線路
    Promise.any(
      filterInvalidDomains(domain_preview).map((origin) =>
        Promise.all([origin, fetchBinFile({ url: `${origin}/speed` })])
      )
    ).catch((err) => {
      // 替換錯誤訊息
      throw Object.assign(new Error('请检查网络设置'), { title: '网络异常' })
    }),
    // 如果延遲異常久就 timeout
    new Promise((_, reject) => setTimeout(reject, 60000)),
  ])

  yield put(actions.updatePreviewOrigin(fastestPreviewOrigin[0]))

  /** 更新高清影片使用origin */
  const fastestOfficialOrigin = yield Promise.race([
    Promise.any(
      mappedOrigins.map(({ name, domain }) => Promise.all([fetch(processURL(`${domain}/speed`)), `${domain}/speed`]))
    ).catch((err) => {
      throw Object.assign(new Error('请检查网络设置'), { title: '网络异常' })
    }),
    // 如果延遲異常久就 timeout
    new Promise((_, reject) => setTimeout(reject, 60000)),
  ])

  const officialUrlInfo = new URL(fastestOfficialOrigin[1])
  yield put(actions.updateOfficialOrigin(officialUrlInfo.origin))

  /** domain_picture 沒有測速 */
  yield put(actions.updatePictureOrigin(`${filterInvalidDomains(domain_picture)[0]}`))

  /** domain_comic 沒有測速 */
  yield put(actions.updateComicOrigin(`${filterInvalidDomains(domain_comic)[0]}`))

  /** domain_novel 沒有測速 */
  yield put(actions.updateNovelOrigin(`${filterInvalidDomains(domain_novel)[0]}`))
}

function* loadCachedAd() {
  const data = localStorage.getItem(CACHED_AD_ITEM_KEY)
  try {
    if (!data) return
    const parsed = JSON.parse(data)
    yield put(actions.updateCurrentSplashAd(parsed))
    yield put(actions.updateReadyToShowSplashAd(true))
    // hide start up splash immediately
    yield put(actions.updateIsShowingLaunchSplash(false))
    console.log(parsed, 'using cached promo')
  } catch (err) {}
}

function* loadAd(checkDomainTask) {
  // 獲取所有公告列表 和 所有廣告列表 和 Content Management System 資訊
  const [announcementList, adList] = yield all([
    call(() => fetchAnnouncement().catch(() => ({ data: [], failed: true }))),
    call(() => fetchAdList().catch(() => ({ data: [], failed: true }))),
  ])

  // 更新廣告資訊
  const adInfo = processAdList(adList.data)
  // 更新系統公告 和 廣告公告資訊
  const announcement = processAnnouncementList(adInfo, announcementList?.data)
  yield put(actions.updateAnnouncementList(announcement))
  yield put(actions.updateAdInfo(adInfo))

  yield join(checkDomainTask)

  // 讀取圖片
  const splashAds = adInfo[AdvertisementType.Entrance]
  /**
   * @type {import('../../../types/api').SchemaOholoMaterialShow}
   */
  const selectedSplashAd = splashAds[Math.floor(splashAds.length * Math.random())]

  const pictureOrigin = yield select(selectPictureOrigin)

  if (!adList.failed) {
    if (process.env.NODE_ENV === 'development') {
      console.log('Selected splash ad', selectedSplashAd)
    }
    if (selectedSplashAd != null) {
      try {
        const shortcutImagePath = `${pictureOrigin}/${selectedSplashAd.icon_path}`
        const img = yield call(fetchBinFile, {
          url: shortcutImagePath,
        })

        const data = { item: selectedSplashAd, img }

        if (process.env.NODE_ENV === 'development') {
          console.log('Ad content fetched, updating cache')
        }

        try {
          localStorage.setItem(CACHED_AD_ITEM_KEY, JSON.stringify(data))
        } catch {
          if (process.env.NODE_ENV === 'development') {
            console.warn('unable to set ad cache')
          }
          // remove old cache
          localStorage.removeItem(CACHED_AD_ITEM_KEY)
        }

        const currentSplashAd = yield select(selectCurrentSplashAd)

        if (currentSplashAd == null) {
          if (process.env.NODE_ENV === 'development') {
            console.log("Using latest ad because there isn't any current displaying.")
          }
          yield put(actions.updateCurrentSplashAd(data))
          yield put(actions.updateReadyToShowSplashAd(true))
        }
      } catch (err) {
        if (process.env.NODE_ENV === 'development') {
          console.warn('Bad ad content, skipping update')
        }
        // just let it fail
      }
    } else {
      // actually don't have promo
      localStorage.removeItem(CACHED_AD_ITEM_KEY)
    }
  } else {
    if (process.env.NODE_ENV === 'development') {
      console.warn('Failed to fetch ad list, skipping update splash ad cache')
    }
  }

  const currentShowingAd = yield select(selectCurrentSplashAd)

  // 所有資料皆已獲取, 進入頁面
  if (currentShowingAd == null) {
    yield put(actions.updateReadyToShowSplashAd(true))
    yield put(actions.updateViewedAdvertisement(true))
  }
}

export default function* watchApp() {
  yield takeLatest(INIT_APP, init)
  yield takeLatest(actions.initApp, getAppName)
  yield takeLatest(RACE_AVAILABLE_ORIGIN, getAvailableOrigin)
}
