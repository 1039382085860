import { HISTORY_STATE_DROP_BROWSING_STACKS } from 'constant/routes'
import { useNavigate, useLocation, NavigateOptions, To } from 'react-router-dom'
import { usePageActivated } from './usePageActivated'
import { useCallback, useInsertionEffect, useRef } from 'react'

interface AppNavigateOptions extends NavigateOptions {
  dropStack?: boolean
  forceReplaceNonCurrentPage?: boolean
}

export function useAppNavigate() {
  const navigate = useNavigate()

  const currentLocation = useLocation()
  const currentLocationRef = useRef(currentLocation)
  useInsertionEffect(() => {
    currentLocationRef.current = currentLocation
  })

  const pageActivated = usePageActivated()
  const pageActivatedRef = useRef(pageActivated)
  useInsertionEffect(() => {
    pageActivatedRef.current = pageActivated
  })

  return useCallback(
    (target: To | number, originalOpts: AppNavigateOptions = {}) => {
      if (!pageActivatedRef.current && originalOpts?.replace && originalOpts.forceReplaceNonCurrentPage !== true) {
        console.error('trying to replace history when it is not current active page')
        return
      }

      if (typeof target === 'number') {
        return navigate(target)
      } else {
        let opts: AppNavigateOptions
        if (originalOpts.replace) {
          opts = {
            ...originalOpts,
            state: {
              ...currentLocationRef.current.state,
              ...(originalOpts.state ? originalOpts.state : {}),
              ...(originalOpts.dropStack ? { [HISTORY_STATE_DROP_BROWSING_STACKS]: true } : {}),
            },
          }
        } else {
          opts = {
            ...originalOpts,
            state: {
              ...(originalOpts.state ? originalOpts.state : {}),
              ...(originalOpts.dropStack ? { [HISTORY_STATE_DROP_BROWSING_STACKS]: true } : {}),
            },
            replace: false,
          }
        }

        navigate(target, opts)
      }
    },
    [navigate]
  )
}
