import styled from 'styled-components'

export const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  --px: calc(1rem / 39);

  &.visible {
    display: flex;
  }
`

export const Group = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(var(--px) * 308);
  height: calc(var(--px) * 460);
  align-items: stretch;
`

export const TopImage = styled.div`
  flex: 0 0 auto;
  padding-bottom: calc(100% / 308 * 100);
  background: url(${process.env.PUBLIC_URL}/announcement/system-type-top.jpg) center no-repeat;
  background-size: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  /** workaround image gap caused by browser layout rounding error */
  transform: translateY(1px);
`

export const ContentWrapper = styled.div`
  flex: 1 1 auto;
  min-height: 0;

  background: #ffffff;
  padding: 0 16px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: stretch;
`

export const Content = styled.div`
  flex: 1 1 auto;
  min-height: 0;

  margin-top: calc(var(--px) * 10);
  font-weight: 400;
  font-size: calc(var(--px) * 14);
  letter-spacing: calc(var(--px) * 0.38);
  color: #787878;
  word-break: break-word;
  overflow: hidden auto;
  line-height: 1.3;

  h1 {
    font-size: calc(var(--px) * 40);
  }

  h2 {
    font-size: calc(var(--px) * 34);
  }

  h3 {
    font-size: calc(var(--px) * 28);
  }

  h4 {
    font-size: calc(var(--px) * 24);
  }

  h5 {
    font-size: calc(var(--px) * 20);
  }

  h6 {
    font-size: calc(var(--px) * 16);
  }

  a {
    color: var(--link-color);
    text-decoration: underline;
  }
`

export const Button = styled.button`
  width: 100%;
  margin: calc(var(--px) * 16) 0;
  padding: calc(var(--px) * 8) 0;
  font-weight: 400;
  font-size: calc(var(--px) * 14);
  line-height: 130%;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: calc(var(--px) * 0.05);
  color: #ffffff;
  overflow: scroll;
  border-radius: 1rem;
`

export const BtnLeft = styled(Button)`
  background: var(--component-announcement-systemitem--left-background);
  color: var(--component-announcement-systemitem--left-color);
`

export const BtnRight = styled(Button)`
  background: var(--component-announcement-systemitem--right-background);
  color: var(--component-announcement-systemitem--right-color);
`

export const ButtonWrap = styled.div`
  flex: 0 0 auto;
  display: flex;
  gap: 0.2rem;
`
