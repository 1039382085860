import Base64Image from 'component/Base64Image'
import styled from 'styled-components'

export const Wrapper = styled.div`
  --columns: ${(props) => props.columns ?? 2};
  width: ${(props) =>
    props?.width != null
      ? props.width
      : 'calc(calc(100% - var(--cms-grid-column-gap, 0.093rem) * calc(var(--columns) - 1)) / var(--columns))'};

  overflow: hidden;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  > * {
    user-select: none;
  }

  > :global(.lazyload-wrapper) {
    display: contents;
  }
`

export const ImageWrapper = styled.div`
  border-radius: var(--component-video-box-vertical--border-radius);
  /* transform: translate3d(0, 0, 0); */
  position: relative;
  overflow: hidden;
`

export const StyledBase64Image = styled(Base64Image)``

export const VideoName = styled.div`
  padding: 0.1rem 0 0;
  font-size: ${(props) => props.fontSize || '0.35897rem'};
  line-height: 150%; /* 0.53846rem */
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: ${(props) => props.textAlign};

  [data-slide-stack-inactive] & {
    opacity: 0;
  }
`
