import { useAppNavigate } from "app-layered-layout/useAppNavigate"
import { useMemo } from "react"
import { SchemaComicChapterShow, SchemaComicShow, SchemaShortVideoShow, SchemaVideoQueryParam, SchemaVideoShow } from "../../types/api"
import ViewMore from "page/ViewMore"
import { useAppLocation } from "app-layered-layout/useAppLocation"
import ViewVideo from "page/ViewVideo"
import ViewShort from "page/ViewShort"
import ViewComic from "page/ViewComic"
import ViewComicChapter from "page/ViewComicChapter"
import { NavigateOptions } from "react-router-dom"
import ViewUploader from "page/ViewUploader"
import ViaCategory from "page/SearchVideo/component/ViaCategory"
import ViaSpecific from "page/SearchVideo/component/ViaSpecific"
import PurchaseHistory from "page/PurchaseHistory"
import BrowseCollection from "page/BrowseCollection"
import BrowseFavourite from "page/BrowseFavourite"
import BrowseHistory from "page/BrowseHistory"
import BusinessRelationship from "page/BusinessRelationship"
import { APP_CENTER } from "constant/routers"
import { URL_QUERY_DISABLE_LAYOUT } from "constant/routes"
import Login from "page/Login"
import Register from "page/Register"
import SystemConfig from "page/SystemConfig"
import Wallet from "page/Wallet"
import EnableVip from "page/EnableVip"
import SecTagList from "page/SecTagList"
import ProducerList from "page/ProducerList"
import ActorList from "page/ActorList"
import Game from "page/Game"
import GameDeposit from "page/GameDeposit"
import GameWithdraw from "page/GameWithdraw"
import GamePromotion from "page/GameLobby/GamePromotion"
import BankTransfer from "page/GameDeposit/BankTransfer"
import UsdtExchange from "page/GameDeposit/UsdtExchange"
import DebugOnly from "page/DebugOnly"
import Actor from "page/Actor"
import Producer from "page/Producer"
import About from "page/About"
import GameTransfer from "page/GameTransfer"
import ZQBTransfer from "page/GameDeposit/ZQBTransfer"
import SharePage from "page/SharePage"
import Test from "page/Test"

interface LocationDefinition<State extends Record<string, any>, Query extends Record<string, any>> {
  state: State,
  query: Query
}

interface PartialLocationDefinition<State extends Record<string, any>, Query extends Record<string, any>> {
  state?: State,
  query: Query
}

interface MetaData {
  hasNav?: boolean,
  isVideo?: boolean,
}

interface RouteDefinition<Name extends string, Param, State extends Record<string, any>, Query extends Record<string, any>, ParsedLocation> {
  component: JSX.Element,
  name: Name,
  paramToLocation: (param: Param) => LocationDefinition<State, Query>,
  locationToParam: (location: PartialLocationDefinition<State, Query>) => ParsedLocation,
  meta: MetaData
}

const createRouteDefinition = <Name extends string, Param, State extends Record<string, any>, Query extends Record<string, any>, ParsedLocation>(
  component: JSX.Element,
  name: Name,
  paramToLocation: (param: Param) => LocationDefinition<State, Query>,
  locationToParam: (location: PartialLocationDefinition<State, Query>) => ParsedLocation,
  meta: MetaData = {}
): RouteDefinition<Name, Param, State, Query, ParsedLocation> => {
  return {
    component,
    name,
    paramToLocation,
    locationToParam,
    meta
  }
}

type MapToNavFunction<T extends RouteDefinition<string, any, any, any, any>[]> = {
  [key in T[number]['name']]: (param: ReturnType<(T[number] & { name: key })['locationToParam']>, opt?: NavigateOptions) => void
}

const useNavFunctions = <T extends RouteDefinition<string, any, any, any, any>[]>(definitions: T) => {
  const navigate = useAppNavigate()
  const memoedDict = useMemo(() => {
    const dict: MapToNavFunction<typeof definitions> = {} as any

    for (const item of definitions) {
      dict[item.name] = (param, opt?: NavigateOptions) => {
        const loc = item.paramToLocation(param as never)
        const query = loc.query
        const queryEntries = Object.entries(query).filter(i => i[1] != null)
        const search = queryEntries.length === 0 ? '' : '?' + queryEntries.map(e => e[0] + '=' + encodeURIComponent(e[1] as any)).join('&')
        const addedState = opt?.state ?? {}
        navigate('/' + item.name + search, {
          ...opt,
          state: {
            ...addedState,
            ...(loc.state ?? {})
          }
        })
      }
    }

    return dict
  }, [definitions, navigate])
  return memoedDict
}
type RouteTypes = (typeof namedRouteDefinitions)[number]
type RouteNames = RouteTypes['name']
type RouteDict = {
  [k in RouteNames]: RouteTypes & { name: k }
}
type GetTypeFromName<T extends keyof RouteDict> =
  RouteDict[T] extends { 'locationToParam': infer U }
    ? U extends (...args: any[]) => any ? ReturnType<U> : never
    : never

export const useParamsOfPage = <T extends RouteTypes['name']>(name: T):
  GetTypeFromName<typeof name> => {
  const location = useAppLocation()
  const memoedLoc = useMemo(() => {
    const mapper = namedRouteDefinitions.find(i => i.name === name)!.locationToParam
    const url = new URL(location.search, 'http://localhost/')
    const params = [...url.searchParams]
    const paramObject = Object.fromEntries(params)
    const loc = mapper({
      query: paramObject as any,
      state: location.state
    })
    return loc
  }, [location.search, location.state, name])
  return memoedLoc as any
}

export const namedRouteDefinitions = [
  createRouteDefinition(
    <ViewMore />,
    'view-more',
    ({
      params,
      headerTitle,
      type,
      showAd
    }: {
      params: SchemaVideoQueryParam,
      headerTitle: string,
      type: 'video' | 'short' | 'comic' | 'novel',
      showAd: boolean
    }) => ({
      query: {},
      state: {
        params,
        headerTitle,
        type,
        showAd
      }
    } as const),
    (loc) => loc.state
  ),
  createRouteDefinition(
    <ViewVideo />,
    'watch',
    (info:  SchemaVideoShow & { posterImg?: string }) => {
      return {
        query: {
          id: info.id!
        },
        state: {
          videoInfo: info
        }
      }
    },
    (loc) => {
      return loc.state?.videoInfo ?? { id: loc.query.id }
    }
  ),
  createRouteDefinition(
    <ViewShort />,
    'short',
    (info: {
      videoInfo: SchemaShortVideoShow,
      videoList: SchemaShortVideoShow[],
      currentIndex?: number,
      posterImg?: string
    }) => {
      return {
        query: {
          id: info.videoInfo.id!
        },
        state: {
          videoInfo: info.videoInfo,
          videoList: info.videoList,
          posterImg: info.posterImg,
          currentIndex: info.currentIndex
        }
      }
    },
    (loc) => {
      const info = loc.state?.videoInfo ?? { id: loc.query.id }
      return {
        videoInfo: info,
        videoList: loc.state?.videoList ?? [info],
        posterImg: loc.state?.posterImg,
        currentIndex: loc.state?.currentIndex
      }
    }
  ),
  createRouteDefinition(
    <ViewComic />,
    'comic',
    (info: {
      posterImg?: string,
      comicInfo: SchemaComicShow
    }) => {
      return {
        query: {
          id: info.comicInfo.id
        },
        state: {
          posterImg: info.posterImg,
          comicInfo: info.comicInfo
        }
      }
    },
    (loc) => {
      return {
        posterImg: loc.state?.posterImg,
        comicInfo: loc.state?.comicInfo ?? { id: loc.query.id }
      }
    }
  ),
  createRouteDefinition(
    <ViewComicChapter />,
    'comic-chapter',
    (info: {
      chapterInfo: SchemaComicChapterShow,
      comicId: number,
    }) => ({
      query: {
        id: info.chapterInfo.id,
        comic: info.comicId
      },
      state: {
        chapterInfo: info.chapterInfo,
      }
    }),
    (loc) => {
      return {
        chapterInfo: loc.state?.chapterInfo ?? { id: loc.query.id },
        comicId: loc.query.comic
      }
    }
  ),
  createRouteDefinition(
    <ViewUploader />,
    'uploader',
    (info: {
      uploaderId: string
    }) => ({
      query: {
        id: info.uploaderId
      },
      state: {}
    }),
    (loc) => ({
      uploaderId: loc.query.id
    })
  ),
  createRouteDefinition(
    <ViaCategory />,
    'explore/filter',
    (info: {
      sort?: string,
      payment?: string,
      region?: string,
      producer?: string
    }) => ({
      query: {
        sort: info.sort,
        payment: info.payment,
        region: info.region,
        producer: info.producer
      },
      state: {}
    }),
    (loc) => {
      return {
        sort: loc.query.sort,
        payment: loc.query.payment,
        region: loc.query.region,
        producer: loc.query.producer
      }
    },
    {
      isVideo: true
    }
  ),
  createRouteDefinition(
    <ViaSpecific />,
    'explore/search',
    (info: {
      in?: string,
      keyword?: string
    }) => ({
      query: {
        in: info.in,
        keyword: info.keyword,
      },
      state: {}
    }),
    (loc) => {
      return {
        in: loc.query.in,
        keyword: loc.query.keyword,
      }
    },
    {
      isVideo: true
    }
  ),
  createRouteDefinition(
    <PurchaseHistory />,
    'purchase-history',
    (info: {}) => ({
      query: {},
      state: {}
    }),
    (loc) => {
      return {}
    }
  ),
  createRouteDefinition(
    <BrowseCollection />,
    'my-collection',
    (info: {}) => ({
      query: {},
      state: {}
    }),
    (loc) => {
      return {}
    }
  ),
  createRouteDefinition(
    <BrowseCollection />,
    'my-collection',
    (info: {}) => ({
      query: {},
      state: {}
    }),
    (loc) => {
      return {}
    }
  ),
  createRouteDefinition(
    <BrowseFavourite />,
    'my-favorite',
    (info: {}) => ({
      query: {},
      state: {}
    }),
    (loc) => {
      return {}
    }
  ),
  createRouteDefinition(
    <BrowseHistory />,
    'my-history',
    (info: {}) => ({
      query: {},
      state: {}
    }),
    (loc) => {
      return {}
    }
  ),
  createRouteDefinition(
    <BusinessRelationship />,
    'business',
    (info: {}) => ({
      query: {},
      state: {}
    }),
    (loc) => {
      return {}
    }
  ),
  createRouteDefinition(
    <Login />,
    'login',
    (info: {}) => ({
      query: {},
      state: {}
    }),
    (loc) => {
      return {}
    }
  ),
  createRouteDefinition(
    <Register />,
    'register',
    (info: {}) => ({
      query: {},
      state: {}
    }),
    (loc) => {
      return {}
    }
  ),
  createRouteDefinition(
    <SystemConfig />,
    'settings',
    (info: {}) => ({
      query: {},
      state: {}
    }),
    (loc) => {
      return {}
    }
  ),
  createRouteDefinition(
    <Wallet />,
    'wallet',
    (info: {}) => ({
      query: {},
      state: {}
    }),
    (loc) => {
      return {}
    }
  ),
  createRouteDefinition(
    <EnableVip />,
    'enable-vip',
    (info: {
      fromVideo?: boolean
    }) => ({
      query: {
        fromVideo: info.fromVideo ? '' : undefined
      },
      state: {}
    }),
    (loc) => {
      return {
        fromVideo: loc.query.fromVideo != null
      }
    }
  ),
  createRouteDefinition(
    <SecTagList />,
    'video-tag',
    (info: {
      params: Record<any, string>,
      headerTitle: string
    }) => ({
      query: {},
      state: {
        params: info.params,
        headerTitle: info.headerTitle
      }
    }),
    (loc) => {
      return {
        params: loc.state!.params,
        headerTitle: loc.state!.headerTitle
      }
    }
  ),
  createRouteDefinition(
    <ProducerList />,
    'producers',
    (info: {
      producerIds?: string[] | undefined
      headerTitle?: string
    }) => ({
      query: {
        title: info.headerTitle,
        ids: info.producerIds?.join(',')
      },
      state: {}
    }),
    (loc) => {
      return {
        producerIds: loc.query.ids?.split(',') ?? [],
        headerTitle: loc.query.title
      }
    }
  ),
  createRouteDefinition(
    <Actor />,
    'actor',
    (info: {
      actorId: string,
    }) => ({
      query: {
        id: info.actorId
      },
      state: {}
    }),
    (loc) => {
      return {
        actorId: loc.query.id,
      }
    }
  ),
  createRouteDefinition(
    <Producer />,
    'producer',
    (info: {
      producerId: string,
      producerImage: string,
      producerName: string
      producerImageSource?: string
    }) => ({
      query: {
        id: info.producerId,
        image: info.producerImage,
        name: info.producerName,
        source: info.producerImageSource
      },
      state: {}
    }),
    (loc) => {
      return {
        producerId: loc.query.id,
        producerImage: loc.query.image,
        producerName: loc.query.name,
        producerImageSource: loc.query.source,
      }
    }
  ),
  createRouteDefinition(
    <ActorList />,
    'actors',
    (info: {
      actorIds?: string[],
      headerTitle?: string
    }) => ({
      query: {
        ids: info.actorIds?.join(','),
        headerTitle: info.headerTitle
      },
      state: {}
    }),
    (loc) => {
      return {
        actorIds: loc.query.ids?.split(','),
        headerTitle: loc.query.headerTitle
      }
    }
  ),
  createRouteDefinition(
    <Game />,
    'game-now',
    (info: {
      id: string,
      gameType: string,
      tpCode: string,
      direction: number
    }) => ({
      query: {
        id: info.id,
        gameType: info.gameType,
        tpCode: info.tpCode,
        direction: info.direction,
      },
      state: {}
    }),
    (loc) => {
      return {
        id: loc.query.id,
        gameType: loc.query.gameType,
        tpCode: loc.query.tpCode,
        direction: loc.query.direction
      }
    }
  ),
  createRouteDefinition(
    <GameDeposit />,
    'game-deposit',
    (info: {
      fromGame?: number,
      fromGameType?: number,
      fromGameTpCode?: string,
      fromGameDirection: number
    }) => ({
      query: {
        from: info.fromGame,
        fromType: info.fromGameType,
        fromCode: info.fromGameTpCode,
        fromDirection: info.fromGameDirection
      },
      state: {}
    }),
    (loc) => ({
      fromGame: loc.query.from,
      fromGameType: loc.query.fromType,
      fromGameTpCode: loc.query.fromCode,
      fromGameDirection: loc.query.fromDirection
    })
  ),
  createRouteDefinition(
    <BankTransfer />,
    'game-deposit/bank-transfer',
    (info: {
      payment_channel_id: string,
      amount: string
    }) => ({
      query: {},
      state: {
        payment_channel_id: info.payment_channel_id,
        amount: info.amount
      }
    }),
    (loc) => ({
      payment_channel_id: loc.state!.payment_channel_id,
      amount: loc.state!.amount
    })
  ),
  createRouteDefinition(
    <UsdtExchange />,
    'game-deposit/usdt-exchange',
    (info: {
      payment_channel_id: string,
      amount: string
    }) => ({
      query: {},
      state: {
        payment_channel_id: info.payment_channel_id,
        amount: info.amount
      }
    }),
    (loc) => ({
      payment_channel_id: loc.state!.payment_channel_id,
      amount: loc.state!.amount
    })
  ),
  createRouteDefinition(
    <ZQBTransfer />,
    'game-deposit/zqb-transfer',
    (info: {
      payment_channel_id: string,
      amount: string
    }) => ({
      query: {},
      state: {
        payment_channel_id: info.payment_channel_id,
        amount: info.amount
      }
    }),
    (loc) => ({
      payment_channel_id: loc.state!.payment_channel_id,
      amount: loc.state!.amount
    })
  ),
  createRouteDefinition(
    <GameWithdraw />,
    'game-withdraw',
    () => ({
      query: {},
      state: {}
    }),
    (loc) => ({})
  ),
  createRouteDefinition(
    <GamePromotion />,
    'game-promotion',
    () => ({
      query: {},
      state: {}
    }),
    (loc) => ({})
  ),
  createRouteDefinition(
    <GameTransfer />,
    'game-transfer',
    () => ({
      query: {},
      state: {}
    }),
    (loc) => ({})
  ),
  createRouteDefinition(
    <DebugOnly />,
    'yOu-CAN_t-s66-rne',
    (info: {
      secret: string
    }) => ({
      query: {},
      state: {
        s: info.secret
      }
    }),
    (loc) => ({
      secret: loc.state?.s
    })
  ),
  createRouteDefinition(
    <SharePage />,
    'share',
    (info: {}) => ({
      query: {},
      state: {}
    }),
    (loc) => {
      return {}
    }
  ),
  createRouteDefinition(
    <About />,
    'about',
    (info: {}) => ({
      query: {},
      state: {}
    }),
    (loc) => {
      return {}
    }
  ),
  ...(process.env.NODE_ENV === 'development' ? [
    createRouteDefinition(
      <Test />,
      'test',
      (info: {})=>({
        query: {
          
        },
        state: {
          
        }
      }),
      ()=>{
        return {
          
        }
      }
    ),
  ] : [])
]

export const useNavigateTo = () => {
  const navFunctions = useNavFunctions(namedRouteDefinitions)
  const navigate = useAppNavigate()


  return useMemo(() => {
    const navigateToViewMore = navFunctions['view-more']
    const navigateToViewVideo = navFunctions['watch']
    const navigateToViewShort = navFunctions['short']
    const navigateToViewComic = navFunctions['comic']
    const navigateToViewComicChapter = navFunctions['comic-chapter']
    const navigateToViewUploader = navFunctions['uploader']
    const navigateToSearchViaCategory = navFunctions['explore/filter']
    const navigateToSearchViaSpecific = navFunctions['explore/search']
    const navigateToPurchaseHistory = navFunctions['purchase-history']
    const navigateToBrowseCollection = navFunctions['my-collection']
    const navigateToBrowseFavorite = navFunctions['my-favorite']
    const navigateToBrowseHistory = navFunctions['my-history']
    const navigateToBusinessRelationship = navFunctions['business']
    const navigateToAppCenter = (info: { asPage: boolean }, opt?: NavigateOptions) => {
      navigate(APP_CENTER + (info.asPage ? `?${URL_QUERY_DISABLE_LAYOUT}=` : ''), opt)
    }
    const navigateToLogin = navFunctions['login']
    const navigateToRegister = navFunctions['register']
    const navigateToSystemConfig = navFunctions['settings']
    const navigateToWallet = navFunctions['wallet']
    const navigateToEnableVip = navFunctions['enable-vip']
    const navigateToSecTagList= navFunctions['video-tag']
    const navigateToProducerList = navFunctions['producers']
    const navigateToProducer = navFunctions['producer']
    const navigateToActor = navFunctions['actor']
    const navigateToActorList = navFunctions['actors']
    const navigateToGame = navFunctions['game-now']
    const navigateToGameDeposit = navFunctions['game-deposit']
    const navigateToGameDepositBankTransfer = navFunctions['game-deposit/bank-transfer']
    const navigateToGameDepositUsdtExchange = navFunctions['game-deposit/usdt-exchange']
    const navigateToGameDepositZqbTransfer = navFunctions['game-deposit/zqb-transfer']
    const navigateToGameWithdraw = navFunctions['game-withdraw']
    const navigateToGamePromotion = navFunctions['game-promotion']
    const navigateToGameTransfer = navFunctions['game-transfer']
    const navigateToDebugPage = navFunctions['yOu-CAN_t-s66-rne']
    const navigateToShare = navFunctions['share']
    const navigateToAbout = navFunctions['about']

    return {
      navigateToViewMore,
      navigateToViewVideo,
      navigateToViewShort,
      navigateToViewComic,
      navigateToViewComicChapter,
      navigateToViewUploader,
      navigateToSearchViaCategory,
      navigateToSearchViaSpecific,
      navigateToPurchaseHistory,
      navigateToBrowseCollection,
      navigateToBrowseFavorite,
      navigateToBrowseHistory,
      navigateToBusinessRelationship,
      navigateToAppCenter,
      navigateToLogin,
      navigateToRegister,
      navigateToSystemConfig,
      navigateToWallet,
      navigateToEnableVip,
      navigateToSecTagList,
      navigateToProducer,
      navigateToProducerList,
      navigateToActor,
      navigateToActorList,
      navigateToGame,
      navigateToGameDeposit,
      navigateToGameDepositBankTransfer,
      navigateToGameDepositUsdtExchange,
      navigateToGameDepositZqbTransfer,
      navigateToGameWithdraw,
      navigateToGamePromotion,
      navigateToGameTransfer,
      navigateToDebugPage,
      navigateToShare,
      navigateToAbout,
    }
  }, [navFunctions, navigate])
}