import { Wrapper, ButtonWrapper, CancelButton, ConfirmButton, CoverImage } from './Styled'

export default function BigConfirmModal({ cover, onConfirm, confirmText = '确定', onCancel, cancelText = '不，谢谢' }) {
  return (
    <Wrapper>
      <CoverImage src={cover} />
      <ButtonWrapper>
        <ConfirmButton onClick={onConfirm}>{confirmText}</ConfirmButton>
        <CancelButton onClick={onCancel}>{cancelText}</CancelButton>
      </ButtonWrapper>
    </Wrapper>
  )
}
