import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import actions from 'redux/action'

export default function useHintModal() {
  const dispatch = useDispatch()

  const openHintModal = useCallback(
    (ChildrenComponent) => {
      dispatch(actions.openHintModal(ChildrenComponent))
    },
    [dispatch]
  )

  const closeHintModal = useCallback(() => {
    dispatch(actions.closeHintModal())
  }, [dispatch])

  const instance = useMemo(() => {
    return {
      openHintModal,
      closeHintModal,
    }
  }, [closeHintModal, openHintModal])

  return instance
}
