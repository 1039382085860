import { Wrapper, Title, Message, FatMessage, ButtonWrapper, CancelButton, ConfirmButton } from './Styled'

export const CONFIRM_MODAL_TYPE = {
  OK_CANCEL: 1,
  OK: 2,
  OK_CANCEL_CUSTOM: 3,
}

export default function ConfirmModal({
  title,
  message,
  onConfirm,
  onCancel,
  type = CONFIRM_MODAL_TYPE.OK_CANCEL,
  confirmText = '确定',
  cancelText = '取消',
}) {
  return (
    <Wrapper>
      {title ? (
        <>
          <Title>{title}</Title>
          <Message>{message}</Message>
        </>
      ) : (
        <>
          <FatMessage>{message}</FatMessage>
        </>
      )}
      {type === CONFIRM_MODAL_TYPE.OK ? (
        <ButtonWrapper>
          <ConfirmButton onClick={onConfirm}>确定</ConfirmButton>
        </ButtonWrapper>
      ) : type === CONFIRM_MODAL_TYPE.OK_CANCEL_CUSTOM ? (
        <ButtonWrapper>
          <CancelButton onClick={onCancel}>{cancelText}</CancelButton>
          <ConfirmButton onClick={onConfirm}>{confirmText}</ConfirmButton>
        </ButtonWrapper>
      ) : (
        <ButtonWrapper>
          <CancelButton onClick={onCancel}>取消</CancelButton>
          <ConfirmButton onClick={onConfirm}>确定</ConfirmButton>
        </ButtonWrapper>
      )}
    </Wrapper>
  )
}
