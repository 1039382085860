import Base64Image from 'component/Base64Image'
import styled from 'styled-components'

export const Image = styled(Base64Image).attrs({
  aspectRatio: 900 / 240,
  source: 'picture',
  placeholder: `/loading/square-loading.svg`,
  allowUnload: false,
})`
  /* width: 100%; */
  margin: 0 var(--cms-grid-column-gap);
  border-radius: 0.25rem;
`
