import { useState, useEffect, useRef, useMemo } from 'react'
import { useImmer } from 'use-immer'

import { fetchActorInfoList, fetchVideoList } from 'api'

import { Wrapper, ActorIcon, ActorName, ActorInfo, ActorInfoWrapper, Description, DescriptionRow } from './Styled'
import { HeaderWrap, InfiniteScrollCmsGridWrapper, PageScrollWrap, PageWrapperNonScroll } from 'commonStyledComponents'
import PageModalHeaderBox from 'component/PageModalHeaderBox'
import VideoBox from 'component/VideoBox'
import { Fragment } from 'react'
import EmptyResult from 'component/EmptyResult'
import { useParamsOfPage } from 'hooks/useNavigateTo'
import { useSelector } from 'react-redux'
import { selectRegionList } from 'redux/selector/app'
import { ENABLED } from 'constant/common'

/** 演員頁 */
export default function Actor() {
  const { actorId } = useParamsOfPage('actor')
  const isFirstTimeIntoView = useRef(true)
  const [info, setInfo] = useState({})
  const [listInfo, updateListInfo] = useImmer({
    nextPage: 1,
    isLoading: true,
    hasMore: true,
    list: [],
    page_result: { current: null, pageSize: null, total: null },
  })
  const regionList = useSelector(selectRegionList)
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const regionName = useMemo(() => {
    const target = regionList.find((m) => m.id === info.region_id && m.status === ENABLED)
    return target ? target.name : '-'
  }, [regionList, info])

  async function loadInfo() {
    const info = await fetchActorInfoList({
      current: 1,
      pageSize: 1,
      ids: actorId,
    })
    if (!info.data.length) {
      console.warn('no actor')
    } else {
      setInfo(info.data[0])
    }
  }

  async function handleLoadMore() {
    if (loading) return
    if (listInfo.current === listInfo.page_result.current || !listInfo.hasMore) return

    setLoading(true)
    const data = {
      current: listInfo.nextPage,
      pageSize: 30,
      actor_ids: actorId,
    }

    const response = await fetchVideoList(data)
    updateListInfo((draft) => {
      draft.list = draft?.list?.concat(response?.data)
      draft.hasMore = response?.page_result?.total > response?.page_result?.current * response?.page_result?.pageSize
      draft.nextPage = response?.page_result?.current + 1
      draft.page_result = response.page_result
      draft.isLoading = false
    })
    setTotal(response.page_result.total)
    setLoading(false)
  }

  useEffect(() => {
    if (!isFirstTimeIntoView.current) {
      return
    }
    isFirstTimeIntoView.current = false
    loadInfo()
    // eslint-disable-next-line
  }, [])

  return (
    <PageWrapperNonScroll>
      <HeaderWrap>
        <PageModalHeaderBox headerTitle="" />
      </HeaderWrap>
      <PageScrollWrap>
        <Wrapper>
          <ActorInfoWrapper>
            <ActorIcon src={info.icon_path}></ActorIcon>
            <ActorInfo>
              <ActorName>{info.name}</ActorName>
              <div>
                {regionName} | 作品数量 {total}
              </div>
              {/* {info.name} */}
            </ActorInfo>
          </ActorInfoWrapper>
          <Description>
            {info.cup && (
              <DescriptionRow>
                <span>罩杯：</span>
                <span>{info.cup}</span>
              </DescriptionRow>
            )}
            <DescriptionRow>{info.description}</DescriptionRow>
          </Description>
          <InfiniteScrollCmsGridWrapper
            style={{}}
            pageStart={0}
            loadMore={() => handleLoadMore()}
            hasMore={listInfo?.hasMore}
            useWindow={false}
          >
            {listInfo?.list?.map((item, i) => {
              return (
                <Fragment key={i}>
                  <VideoBox videoInfo={item} />
                </Fragment>
              )
            })}
            {!listInfo?.list?.length && <EmptyResult />}
          </InfiniteScrollCmsGridWrapper>
        </Wrapper>
      </PageScrollWrap>
    </PageWrapperNonScroll>
  )
}
