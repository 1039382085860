import useHintModal from 'hooks/useHintModal'

import IDCard from '../component/IDCard'

export default function useOpenModal() {
  const { openHintModal } = useHintModal()

  const openIDCard = () => {
    openHintModal({
      children: IDCard,
      props: {},
    })
  }

  return {
    openIDCard,
  }
}
