import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const TextFieldBox = styled.div`
  position: relative;
  display: inline-flex;
  width: 8.8rem;
  padding: 0.08rem 0.3705rem 0.08rem 0.2051rem;
  background-color: var(--input-background);
  color: var(--input-color);
  align-items: center;
  border-radius: 0.256rem;
  overflow: hidden;
  ::placeholder {
    color: var(--input-placeholder-color);
  }
`

export const TextField = styled.input`
  font-family: 'Noto Sans SC';
  font-style: normal;
  width: 100%;
  height: 0.906rem;
  font-size: 0.3589rem;
  font-weight: 400;

  &::placeholder {
    color: #aaaaaa;
    font-size: 0.3589rem;
  }
`

export const ErrorMessage = styled.span`
  font-size: 0.266rem;
  font-weight: normal;
  width: 8.8rem;
  color: var(--danger-color);
  padding: 4px 0 10px 0;
  text-align: left;
`
