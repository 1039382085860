import { fetchPostAdEvent } from 'api'
import CoverImage from '../CoverImage'
import InfoWrapper from '../InfoWrapper'
import PromotionInfo from '../PromotionInfo'
import { StyledVirtualPage } from './Styled'
import { formatDate } from 'utils/timeFormat'
import { useCallback } from 'react'

const PromotionPage = ({ promoteInfo, onBase64ImageLoad, posterSrc = null }) => {
  const onClick = useCallback(() => {
    if (promoteInfo) {
      fetchPostAdEvent({
        date: formatDate(new Date()),
        material_id: promoteInfo.id,
        material_name: promoteInfo.name,
        oholo_site_id: promoteInfo.site_id,
        oholoer_id: promoteInfo.oholoer_id,
      })
      window.open(promoteInfo?.link, '_blank')
    }
  }, [promoteInfo])

  return (
    <StyledVirtualPage>
      <InfoWrapper onClick={onClick}>
        <CoverImage
          src={posterSrc}
          onBase64ImageLoad={onBase64ImageLoad}
          base64SrcSource={promoteInfo.icon_path}
          source={'picture'}
          style={{ pointerEvents: 'none' }}
        />
        <PromotionInfo promoter="@官方推荐" description={promoteInfo.name} link={promoteInfo.link} />
      </InfoWrapper>
    </StyledVirtualPage>
  )
}

export default PromotionPage
