import styled from 'styled-components'

export const Wrapper = styled.button`
  width: 100%;
  overflow: hidden;
`

export const IconWrapper = styled.div`
  /* height: 2.1rem; */
  padding-bottom: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 0.375rem;

  img {
    position: absolute;
    display: inline-block;
    object-fit: cover;
    /* width: 2.1rem; */
    /* height: 2.1rem; */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

export const GameName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.35rem;
`

export const DisabledCover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 101%;
  height: 101%;
  background-image: url('${process.env.PUBLIC_URL}/game/icon-unavailable.svg');
  background-size: cover;
`
