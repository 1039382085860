import Animation from 'component/Animation'
import { EncryptedDiv } from 'component/EncryptedElements'
import styled from 'styled-components'

export const Wrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  z-index: var(--layer-fullpage-modal);
  overflow: hidden;
`

export const SplashImage = styled(EncryptedDiv).attrs({
  backgroundImage: `${process.env.PUBLIC_URL}/splash/boot-background.enc.png`,
})`
  position: relative;
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  transform: scale(1.18);
`

export const SplashText = styled.div`
  position: absolute;
  width: 9.29085rem;
  height: 4.71013rem;
  left: 50%;
  transform: translateX(-50%);
  bottom: calc(1.5rem + env(safe-area-inset-bottom));
  background-image: url(${process.env.PUBLIC_URL}/splash/boot-mid.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`

export const LoadingBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 0.2rem 0.73rem;
`

export const Text = styled.div`
  margin-top: 0.307rem;
  font-weight: 400;
  font-size: 0.358rem;
  line-height: 150%;
  color: #ffffff;
  text-align: center;
`

export const StyledAnimation = styled(Animation)`
  width: 1.84rem;
  height: 1.84rem;
  margin: 0 auto;
`
