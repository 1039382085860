import EmptyResult from 'component/EmptyResult'
import { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FETCH_COLLECTION, RESET_COLLECTION } from 'redux/constant/collection'
import { selectCollectionData } from 'redux/selector/collection'
import VideoBox from 'component/VideoBox'
import { AreaPanelCmsGridWrapper, InfiniteScrollWrapper, PageScrollWrap } from 'commonStyledComponents'

const Video = () => {
  const dispatch = useDispatch()
  const videoCollections = useSelector(selectCollectionData)

  const runOnce = useRef(false)
  const handleFetchVideoList = useCallback(() => {
    if (!runOnce.current) {
      runOnce.current = true
      dispatch({ type: RESET_COLLECTION })
    }

    dispatch({ type: FETCH_COLLECTION })
  }, [dispatch])

  useEffect(() => {
    dispatch({ type: RESET_COLLECTION })
    dispatch({ type: FETCH_COLLECTION })
  }, [dispatch])

  return !videoCollections?.list?.length && !videoCollections.isLoading ? (
    <EmptyResult />
  ) : (
    <PageScrollWrap>
      <InfiniteScrollWrapper
        pageStart={0}
        loadMore={handleFetchVideoList}
        hasMore={videoCollections?.hasMore}
        // loader={<div className="loader" key={0}>Loading ...</div>}
        useWindow={false}
      >
        {videoCollections?.list?.length > 0 && (
          <AreaPanelCmsGridWrapper>
            {videoCollections?.list?.map((item, i) => (
              <VideoBox key={i} videoInfo={item}>
                {i}
              </VideoBox>
            ))}
          </AreaPanelCmsGridWrapper>
        )}
      </InfiniteScrollWrapper>
    </PageScrollWrap>
  )
}

export default Video
