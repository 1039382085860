import styled, { css } from 'styled-components'
import AesImage from 'component/AesImage'
import { AppNavLink } from 'app-layered-layout/AppNavLink'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  min-height: 1.41rem;
  font-size: 0.32rem;
  z-index: 1;
  background: var(--page-footer-background);
  color: var(--page-footer-color);
  box-shadow: var(--page-footer-box-shadow);
  border-top: var(--page-footer-border-top);
`

export const TagA = styled(AppNavLink)`
  z-index: 2;
  width: 20%;
  flex: 1 1 auto;
`

const commonIconStyle = css`
  width: 0.5384rem;
  height: 0.5384rem;
`

export const NavIcon = styled.i`
  ${commonIconStyle}

  background: url(${({ imageName }) => `${process.env.PUBLIC_URL}${imageName}.svg`})
    no-repeat center center;
  background-size: contain;

  ${(props) =>
    props.active
      ? css`
          background: url(${({ imageName }) => `${process.env.PUBLIC_URL}${imageName}_active.svg`}) no-repeat center
            center;
          background-size: contain;
        `
      : ''}
`

export const NavAesIcon = styled(AesImage).attrs({ aspectRatio: 1, hasPlaceholder: false, cached: true })`
  ${commonIconStyle}
`

export const Nav = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 0.106rem;
  width: 100%;
  padding-top: 0.2307rem;
  padding-bottom: env(safe-area-inset-bottom, 0.2307rem);
  height: 100%;
  cursor: pointer;
`

export const NavText = styled.div`
  align-self: stretch;
  text-align: center;
  ${({ active }) => (active ? `background: var(--component-navigation--active-color);` : 'background: currentColor;')}
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  background-clip: text !important;
  text-fill-color: transparent !important;
`
