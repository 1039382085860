import { StyledIcon } from './Styled'

export const ColoredIcon = ({
  src,
  color = null,
  contentSize = null,
  contentWidth = null,
  contentHeight = null,
  ...props
}) => {
  return <StyledIcon {...props} src={src} color={color} contentWidth={contentWidth} contentHeight={contentHeight} />
}
