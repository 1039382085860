import { InfoTag, UploaderInfoElement, UploaderName, VideoTitle } from './Styled'

const PromotionInfo = ({ promoter = '', description = '', link = '', ...props }) => {
  return (
    <UploaderInfoElement {...props}>
      <UploaderName href={link} target="_blank">
        {promoter}
        <InfoTag>查看详情</InfoTag>
      </UploaderName>
      <VideoTitle>{description}</VideoTitle>
    </UploaderInfoElement>
  )
}

export default PromotionInfo
