import { processURL } from 'utils/debugUtil'
import { getAPI } from 'utils/settings'
/**
 * 取得m3u8 key
 * @returns {string} m3u8 key data url
 */
export async function fetchKey(videoId, tokenInfo) {
  // get the real api base
  const fetchKeyApi = new URL(getAPI(), window.location.origin).toString()

  // resolve to full url
  return new URL(
    // replace to proxy url in dev env
    processURL(fetchKeyApi.concat(`/video/ios/key/${videoId}?value=${tokenInfo.access_token}`)),
    window.location.origin
  ).toString()
}

/**
 * 處理m3u8內容
 * @returns {string} m3u8 data url
 */
export async function fetchM3U8Url(videoPath, videoId, tokenInfo, signal) {
  const contentPath = videoPath.slice(0, videoPath.lastIndexOf('/'))

  return await fetch(processURL(videoPath), { signal })
    .then((response) => response.text())
    .then(async (data) => {
      if (data.includes('<')) throw new Error('Invalid m3u8')
      const keyUrl = await fetchKey(videoId, tokenInfo)
      const m3u8Lines = data.trim().split('\n')
      const lineMap = {}
      const keyLine = m3u8Lines.find((l) => l.startsWith('#EXT-X-KEY:'))
      if (keyLine) {
        const params = keyLine.split(',').slice(1)
        const uriParam = params.find((l) => l.startsWith('URI='))
        const replacedKeyLine = keyLine.replace(uriParam, `URI=${JSON.stringify(keyUrl)}`)
        lineMap[keyLine] = replacedKeyLine
      }
      const mappedLines = m3u8Lines.map((line) => {
        if (!line.startsWith('#')) {
          // line without # will be assumed as ts url
          const rawUrl = `${processURL(contentPath)}/${line}`
          const expandedUrl = new URL(rawUrl, window.location.href).toString()
          return expandedUrl
        }
        if (lineMap[line]) {
          return lineMap[line]
        }
        return line
      })
      // eof should be an empty line
      const m3u8string = mappedLines.join('\n') + '\n'

      const url = 'data:application/x-mpegURL;charset=UTF-8,' + encodeURIComponent(m3u8string)

      return url
    })
}
