import type videoJs from 'video.js'
import type Component from 'video.js/dist/types/component'
import './vjs-seek-card.css'
type VideoJs = typeof videoJs

export function addSeekCard(Video: VideoJs): typeof Component {
  const VJSComponent = Video.getComponent('Component')
  class SeekCard extends VJSComponent {
    timeout: null | number = null
    speedUpMode = false
    constructor(player, options = {}) {
      super(player, options)
    }
    createEl() {
      const that = this
      const timeInfo = VJSComponent.prototype.createEl.call(this, 'div', {
        className: 'vjs-seek-card-time',
      })
      const SeekBar = Video.getComponent('SeekBar') as unknown as typeof Component & {
        prototype: {
          getPercent: () => number
          handleMouseMove: (event: any) => void
          calculateDistance: (event: any) => number
          update: () => void
        }
      }

      SeekBar.prototype.handleMouseMove = function handleMouseMove(event: PointerEvent) {
        event.stopPropagation()
        event.stopImmediatePropagation()
        let newTime = this.calculateDistance(event) * this.player_.duration()
        if (newTime === this.player_.duration()) {
          newTime = newTime - 0.1
        }
        this.player_.currentTime(newTime)
        this.update()
        const player = this.player_
        const currentTime = player.currentTime()
        const totalTime = player.duration()
        timeInfo.innerHTML =
          that.timeToString(currentTime) +
          ' <span class="vjs-seek-card-sub-time"> / ' +
          that.timeToString(totalTime) +
          '</span>'
        that.setSeekActive(true)
        // that.el().className = 'vjs-seek-card active'
        if (that.timeout) that.clearTimeout(that.timeout)
        that.timeout = that.setTimeout(() => {
          that.setSeekActive(false)
          // that.el().className = 'vjs-seek-card'
          that.timeout = null
        }, 1000)
      }
      const component = VJSComponent.prototype.createEl.call(this, 'div', {
        className: 'vjs-seek-card',
      })
      component.appendChild(timeInfo)

      const speedInfo = VJSComponent.prototype.createEl.call(this, 'img', {
        className: 'vjs-mobile-control-speed-x2',
        src: `${process.env.PUBLIC_URL}/short-player-ui/icon-speed-x2.svg`,
      })

      component.appendChild(speedInfo)
      return component
    }
    // 格式化時間
    timeToString(time) {
      const minutes = Math.floor(time / 60)
      const seconds = Math.floor(time - minutes * 60)
      const m = minutes < 10 ? '0' + minutes : minutes
      const s = seconds < 10 ? '0' + seconds : seconds
      return m + ':' + s
    }

    // 開啟進度條拖曳
    setSeekActive(v) {
      if (v) {
        this.el().classList.add('vjs-seek-active')
        this.el().closest('.short-video-virtual-page')?.classList.add('video-flag-seeking')
      } else {
        this.el().classList.remove('vjs-seek-active')
        this.el().closest('.short-video-virtual-page')?.classList.remove('video-flag-seeking')
      }
    }
    //開啟加速播放
    setSpeedUpMode(v) {
      this.speedUpMode = !!v
      if (v) {
        this.el().classList.add('vjs-seek-speed-up')
        this.el().closest('.short-video-virtual-page')?.classList.add('video-flag-speed-up')
        this.player_.playbackRate(2)
      } else {
        this.el().classList.remove('vjs-seek-speed-up')
        this.el().closest('.short-video-virtual-page')?.classList.remove('video-flag-speed-up')
        this.player_.playbackRate(1)
      }
    }
  }
  Video.registerComponent('SeekCard', SeekCard)
  return SeekCard
}
