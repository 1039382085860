import isFunction from 'lodash/isFunction'

import { Wrapper, IconBox, ModalWrapper, TipText, ReactModalWrapper } from './Styled'
import { FullWidthPrimaryButton } from 'commonStyledComponents'
import { animated, easings, useSpring } from '@react-spring/web'

export default function DepositSuccess({
  onConfirm,
  onDismiss,
  isOpen,
  confirmText = '回到' + process.env.REACT_APP_SITE_NAME,
}) {
  const style = {
    overlay: {
      zIndex: 100,
      background: 'none',
    },
  }
  const springStyles = useSpring({
    config: { duration: 150, easing: easings.easeInCubic },
    width: '100%',
    height: '100%',
    opacity: isOpen ? 1 : 0.2,
    scale: isOpen ? 1 : 0.6,
  })

  const confirm = (e) => {
    /** 如果是從視頻介面進來, VIP啟用成功後, 自動轉為高清影片, 並導向至原視頻頁面 */
    if (isFunction(onConfirm)) {
      onConfirm()
    } else {
      onDismiss()
    }
    e.stopPropagation()
  }

  return (
    <ModalWrapper onClick={() => onDismiss()}>
      <ReactModalWrapper isOpen={isOpen} style={style}>
        <animated.div style={springStyles}>
          <Wrapper onClick={(e) => e.stopPropagation()}>
            <IconBox></IconBox>
            <TipText>支付确认中...</TipText>
            <FullWidthPrimaryButton onClick={confirm}>{confirmText}</FullWidthPrimaryButton>
          </Wrapper>
        </animated.div>
      </ReactModalWrapper>
    </ModalWrapper>
  )
}
