import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
//
import { selectAnnouncementList } from 'redux/selector/app'
//
import AdItem from './component/AdItem'
import SystemItem from './component/SystemItem'
//
import { AnnouncementType } from 'constant/announcement'
//
import { Wrapper } from './Styled'

/** 公告頁面(分為系統公告 和 彈窗廣告公告) */
export default function Announcement() {
  const announcementList = useSelector(selectAnnouncementList)

  const [announcement, setAnnouncement] = useState([])
  const [currentDisplayAnnouncement, setCurrentDisplayAnnouncement] = useState({})

  useEffect(() => {
    const list = announcementList?.map((entry) => {
      const announcementType = entry.type
      const info = entry.item

      return {
        ...info,
        id: info?.id,
        _id: `${announcementType}_${info?.id}`,
        announcementType,
      }
    })

    setAnnouncement(list)
    setCurrentDisplayAnnouncement(list[0])
  }, [announcementList])

  const handleCancel = (info) => {
    const list = announcement?.filter((el) => el._id !== info._id)
    setAnnouncement(list)
    setCurrentDisplayAnnouncement(list[0])
  }

  if (isEmpty(announcement)) return null

  const ChildComponent = currentDisplayAnnouncement?.announcementType === AnnouncementType.Ad ? AdItem : SystemItem

  return (
    <Wrapper>
      <ChildComponent
        {...currentDisplayAnnouncement}
        currentDisplayAnnouncement={currentDisplayAnnouncement}
        handleCancel={() => handleCancel(currentDisplayAnnouncement)}
      />
    </Wrapper>
  )
}
