import styled, { keyframes } from 'styled-components'

export const CIRCLE_ELEMENT_QTY = 12 // Spinner 元素總共有幾條線

const margin = 0.053,
  lineWidth = 0.0915,
  lineHeight = 0.4102,
  lineColor = '#E6E6E6'

const opacity = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
`

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`

export const LineContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  width: ${margin * 2 + lineHeight * 4}rem;
  height: ${margin * 2 + lineHeight * 4}rem;

  &.pullDownRefresh {
    animation-fill-mode: 'both';
    animation: ${rotate} 1s infinite linear;
  }
`

export const Line = styled.div.attrs((props) => ({
  style: {
    visibility: props.visibleIndex >= props.index ? 'visible' : 'hidden',
  },
}))`
  position: absolute;
  z-index: 1;
  top: 0;
  width: ${lineWidth}rem;
  height: ${lineHeight}rem;
  margin: ${margin}rem;
  border-radius: ${margin}rem;
  background-color: ${lineColor};
  animation-fill-mode: 'both';
  animation: ${opacity} 1s ${({ index }) => (index + 1) * 0.08}s infinite ease-in-out;
  transform: rotate(${({ index }) => (index === 0 ? 1 : index * (360 / CIRCLE_ELEMENT_QTY))}deg);
  transform-origin: ${margin}rem ${lineHeight * 2}rem;
`
