import { IconBack } from '../../Styled'
import { LoadingHintText, LoadingIndicator, LoadingIndicatorInner, Wrapper } from './Styled'
export default function Loading({ onBack }) {
  return (
    <Wrapper>
      <img src={process.env.PUBLIC_URL + '/video-loading/loading.svg'} alt="" />
      <LoadingIndicator>
        <LoadingIndicatorInner />
      </LoadingIndicator>
      <LoadingHintText>精彩即将呈现</LoadingHintText>
      <IconBack onClick={onBack} />
    </Wrapper>
  )
}
