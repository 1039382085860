import { useMemo, useEffect, useState, useRef, useCallback } from 'react'
import { fetchGameMarquee, postGame } from 'api/index'
import { useAlert } from 'react-alert'

import { PageWrapper, GameFrame } from './Styled'
import Toolbar from './components/Toolbar'
import ConfirmModal from './components/ConfirmModal'
import Loading from 'page/GameLobby/component/Loading'
import { useParamsOfPage } from 'hooks/useNavigateTo'
import { usePageActivated } from 'app-layered-layout/usePageActivated'
import { useToastAlert } from 'hooks/useToastAlert'

export default function Game() {
  const { id: gameId, gameType, tpCode, direction } = useParamsOfPage('game-now')
  const pageActivated = usePageActivated()

  const [gameLink, setGameLink] = useState({})
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const gameUrl = useMemo(() => {
    if (gameLink && gameLink.urls) return gameLink.urls[0] || ''
    return ''
  }, [gameLink])
  const frameRef = useRef(null)
  const alert = useAlert()

  const [csList, setCsList] = useState([])
  const preferredCs = useMemo(() => csList.find((m) => m.url), [csList])
  const { toast } = useToastAlert()

  const handleLoadGameInfo = useCallback(async () => {
    setLoading(true)
    try {
      const data = await postGame({ gameId: gameId, gameType, tpCode })
      setGameLink(data)
    } catch (ex) {
      if (ex?.message) {
        toast(ex?.message)
      } else {
        alert.show('游戏载入异常', { position: 'middle' })
      }
    }
    setLoading(false)
  }, [alert, gameId, gameType, toast, tpCode])

  async function loadCustomServiceInfo() {
    const data = await fetchGameMarquee()
    setCsList([...data.customerServiceUrl])
  }

  function handleClose(param) {
    setConfirmModalOpen(true)
  }

  function closeGame() {
    window.close()
  }

  function handleDeposit() {
    /** @type {Window} */
    const parent = window.opener
    parent.postMessage(
      {
        source: 'game-now',
        type: 'deposit',
      },
      '*'
    )
  }

  function handleService() {
    if (preferredCs) {
      window.open(preferredCs.url)
    } else {
      toast('暂无客服支援')
      console.warn('no customer service available')
    }
  }

  useEffect(() => {
    if (gameId) {
      handleLoadGameInfo()
    }
    loadCustomServiceInfo()
  }, [gameId, handleLoadGameInfo])

  return (
    <PageWrapper>
      {pageActivated && gameUrl && <GameFrame ref={frameRef} src={gameUrl} wmode="Opaque" frameborder="0"></GameFrame>}
      <Toolbar
        direction={direction}
        onLobbyClick={handleClose}
        onDepositClick={handleDeposit}
        onServiceClick={handleService}
      ></Toolbar>
      <ConfirmModal
        direction={direction}
        title="退出游戏"
        message="您真的要退出游戏吗？"
        isOpen={confirmModalOpen}
        onCancel={() => setConfirmModalOpen(false)}
        onConfirm={closeGame}
      ></ConfirmModal>
      {loading ? (
        <Loading active={loading} lockLandScape={true}>
          加载中
        </Loading>
      ) : (
        <></>
      )}
    </PageWrapper>
  )
}
