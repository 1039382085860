import { fetchVideoCollectionList } from 'api'
import actions from 'redux/action'
import { FETCH_COLLECTION, RESET_COLLECTION } from 'redux/constant/collection'
import { createListSaga } from 'redux/utils'

const handleCollection = createListSaga(
  'Collection',
  FETCH_COLLECTION,
  RESET_COLLECTION,
  actions.patchCollectionData,
  actions.clearCollectionData,
  fetchVideoCollectionList
)

export default handleCollection
