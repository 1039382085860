import Splash from 'component/LaunchSplash'
import { MaintenancePageWrapper, PageContent, PageContentLine, PageFooter, PageTitle } from './Styled'
import React, { useCallback, useMemo } from 'react'
import { FullWidthPrimaryButton } from 'commonStyledComponents'
import { useSelector } from 'react-redux'
import { selectFullPageError } from 'redux/selector/app'

export default function FullPageError() {
  const info = useSelector(selectFullPageError)
  const title = info?.title || '发生错误'
  const lines = useMemo(() => [`${info?.message || '连线失败'}`], [info])

  const version = useMemo(() => {
    return process.env.REACT_APP_VERSION + '.' + process.env.REACT_APP_GIT_SHA
  }, [])

  const handleRetry = useCallback(() => {
    window.location.replace('/')
  }, [])

  // const landingUrl = useMemo(() => {
  //   if (process.env.REACT_APP_LANDING_PAGE_HOST) return 'https://' + process.env.REACT_APP_LANDING_PAGE_HOST
  // }, [])

  // const handleToLanding = useCallback(() => {
  //   if (process.env.REACT_APP_LANDING_PAGE_HOST) window.open(landingUrl, '_blank')
  // }, [landingUrl])

  return (
    <React.Fragment>
      <Splash></Splash>
      <MaintenancePageWrapper>
        <PageContent>
          <PageTitle>{title}</PageTitle>
          {lines.map((m) => (
            <PageContentLine>{m}</PageContentLine>
          ))}
        </PageContent>

        <FullWidthPrimaryButton onClick={handleRetry}>重试</FullWidthPrimaryButton>
        <PageFooter>
          {/* <PageContentLine>{landingUrl && <span onClick={handleToLanding}>前往官网</span>}</PageContentLine> */}
          <PageContentLine>{version}</PageContentLine>
        </PageFooter>
      </MaintenancePageWrapper>
    </React.Fragment>
  )
}
