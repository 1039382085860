import { usePageActivated } from 'app-layered-layout/usePageActivated'
import { checkDebugSecret } from 'page/SystemConfig'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { selectStation } from 'redux/selector/app'

export const useHasPayment = () => {
  const station = useSelector(selectStation)

  const currentFlags = (() => {
    try {
      const temp = JSON.parse(localStorage.getItem('DEBUG'))
      if (Array.isArray(temp) && temp.every((i) => typeof i === 'string')) {
        if (checkDebugSecret(temp[0])) {
          return temp.slice(1)
        }
      }
    } catch (err) {}

    return []
  })()

  const [flags, setFlags] = useState(() => currentFlags)

  const activated = usePageActivated()

  if (activated) {
    if (JSON.stringify(flags) !== JSON.stringify(currentFlags)) {
      setFlags(currentFlags)
    }
  }

  if (flags.includes('FORCE_PAYMENT_UI')) {
    return true
  }

  if (process.env.REACT_APP_FORCE_HAS_PAYMENT === '1') {
    return true
  }

  if (process.env.REACT_APP_FORCE_HAS_PAYMENT === '0') {
    return false
  }

  return !station.is_free
}
