import Brand from './Brand'
import Bounce from './Bounce'
import Fade from './Fade'
import Line from './Line'
import Indicator from './Indicator'

const LoadingElements = {
  Brand,
  Bounce,
  Fade,
  Indicator,
  Line,
}

export default LoadingElements
