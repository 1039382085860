import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 6px;
`

export const Image = styled.img`
  width: 4.3333rem;
  height: 1.8974rem;
  border-radius: 0.2051rem;
`
