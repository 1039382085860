import styled from 'styled-components'

export const Wrapper = styled.div`
  height: 30rem;
  color: #fff;
  overflow: auto;
  font-size: 0.4rem;
  padding: 0.25rem;
  color: var(--main-color);
`

export const BalanceWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  margin: 0.5rem 0rem;
  color: var(--main-color);
  background: var(--page-profile--item-background);
  border-radius: 145px;
  font-size: 0.35rem;
  font-weight: 700;
  box-shadow: var(--page-profile--wallet-box-shadow);
`

export const Balance = styled.div`
  flex-grow: 1;
  text-align: right;
  font-size: 0.8rem;
`

export const Section = styled.div`
  padding: 0.25rem 0;
`

export const SectionTitle = styled.h3`
  font-weight: 700;
`

export const ItemWrapper = styled.div`
  background: var(--page-profile--item-background);
  color: var(--page-profile--button-color);
  border-radius: 0.25rem;
  padding: 0.5rem;
  font-size: 14px;
  color: var(--page-profile--item-color);
  h3 {
    font-size: 16px;
    font-weight: 700;
  }
  ol {
    list-style: auto;
    padding: 0.2rem;
    padding-left: 0.5rem;
  }
`

export const ButtonListWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.2rem;
  padding: 0.2rem 0;
`

export const CoinButton = styled.button`
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  align-items: center;
  min-width: 3rem;
  padding: 0.2rem 0.5rem;
  background: var(--page-profile--item-background);
  color: var(--page-profile--button-color);
  border-radius: 0.25rem;
  text-align: center;
  box-shadow: var(--page-profile--wallet-box-shadow);
`

export const PriceText = styled.span`
  background: linear-gradient(97.3deg, #f47c0c 9.52%, #f72a5b 78.7%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`
