import type videojs from 'video.js'
import './vjs-mute-button.css'

type VideoJs = typeof videojs

let installed = false

export const addMuteButton = (videojs: VideoJs) => {
  if (installed) {
    return
  }

  installed = true

  const Component = videojs.getComponent('Button')

  class BackButton extends Component {
    createEl() {
      const root: HTMLButtonElement = videojs.dom.createEl(
        'button',
        {
          // Prefixing classes of elements within a player with "vjs-"
          // is a convention used in Video.js.
          className: 'vjs-mute-button vjs-menu-button vjs-control',
        },
        {},
        []
      ) as HTMLButtonElement

      root.appendChild(
        videojs.dom.createEl(
          'i',
          {
            // Prefixing classes of elements within a player with "vjs-"
            // is a convention used in Video.js.
            className: 'vjs-mute-button-icon',
          },
          {},
          []
        )
      )

      const player = this.player()

      root.addEventListener('pointerdown', (ev) => {
        ev.preventDefault()
        ev.stopPropagation()
        player.muted(!player.muted())
      })

      return root
    }
  }

  videojs.registerComponent('MuteButton', BackButton)
}
