import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  flex-shrink: 0;
  width: 2.75rem;
  height: 4rem;
  overflow: hidden;
  border-radius: 0.2564rem;
`

export const ImageIcon = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
