import styled, { keyframes } from 'styled-components'

export const Wrapper = styled.div`
  flex: 1 1 auto;
  padding: 00 0 0.5rem;
`

const blink = keyframes`
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
`

export const Item = styled.div`
  margin: 0 0.5rem;
  height: 1.5rem;
  border-bottom: 1px solid var(--main-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.4rem;
  gap: 0.4rem;

  &.readonly {
    opacity: 0.5;
  }

  &.unsaved {
    animation: ${blink} 0.6s linear 0s infinite;
  }
`

export const ItemTitle = styled.div`
  margin: 0 0.5rem;
  margin-top: 1rem;
  height: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8rem;

  &.readonly {
    opacity: 0.5;
  }
`

export const ItemDesc = styled.div`
  margin: 0 0.5rem;
  height: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.4rem;

  &.readonly {
    opacity: 0.5;
  }
`
export const SafeBottomPadding = styled.div`
  height: env(safe-area-inset-bottom);
`
