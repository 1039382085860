import { videoFavoriteState, videoLikedState } from 'constant/video'
import CoverImage from '../CoverImage'
import InfoWrapper from '../InfoWrapper'
import Toolbar from '../Toolbar'
import UploaderInfo from '../UploaderInfo'
import { StyledVirtualPage } from './Styled'
import { useSelector } from 'react-redux'
import { selectTopicList } from 'redux/selector/app'

const PlaceHolderPage = ({ videoInfo, onBase64ImageLoad, posterSrc = null, onLike, onSave }) => {
  const topicList = useSelector(selectTopicList)

  let topics = []

  if (videoInfo) {
    topics =
      videoInfo.topic_ids
        ?.map((id) => {
          const topicItem = topicList.find((t) => t.id === id)
          if (topicItem) {
            return {
              text: '#' + topicItem.name,
              params: {
                topic_ids: String(topicItem.id),
              },
            }
          } else {
            return null
          }
        })
        .filter((text) => text != null) ?? []
  }
  return (
    <StyledVirtualPage>
      <InfoWrapper>
        <CoverImage src={posterSrc} onBase64ImageLoad={onBase64ImageLoad} base64SrcSource={videoInfo.coverphoto_v} />
        <UploaderInfo topics={topics} uploader={'@' + videoInfo.uploader_name} videoName={videoInfo.title} />
      </InfoWrapper>
      <Toolbar
        key={videoInfo.id}
        liked={videoInfo.like === videoLikedState.TRUE}
        saved={videoInfo.favorite === videoFavoriteState.TRUE}
        likeCount={videoInfo.like_count}
        saveCount={videoInfo.favorite_count}
        onLike={onLike}
        onSave={onSave}
      />
    </StyledVirtualPage>
  )
}

export default PlaceHolderPage
