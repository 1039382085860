import Base64Image from 'component/Base64Image'
import styled from 'styled-components'

export const Wrapper = styled.div`
  // gap為4px, 因為一行兩個, 會有一個 gap , 所以是 4px/2
  width: calc((100% / 2) - (var(--category-grid-gap) / 2));
  position: relative;
`
export const StyledBase64Image = styled(Base64Image)`
  border-radius: 0.15rem;
`
