export function detectUserAgent() {
  const ua = window.navigator.userAgent
  const isIos = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i)
  const isWebkit = !!ua.match(/WebKit/i)
  const isIosSafari = isIos && isWebkit && !ua.match(/CriOS/i)
  return {
    ua,
    isIos,
    isWebkit,
    isIosSafari,
  }
}
