import Animation from 'component/Animation/index.js'
import styled from 'styled-components'
import fullPageLoadingStyle from './full-page-loading/style.js'
import pageLoadingAnimation from './full-page-loading'

export const PageLoadingIcon = styled(Animation).attrs({ animationData: pageLoadingAnimation })`
  ${fullPageLoadingStyle}
`

export default PageLoadingIcon
