import styled from 'styled-components'

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 1.69rem;
  height: 0.68rem;
  line-height: 0.81rem;
  background-image: url('${process.env.PUBLIC_URL}/icon-video/vip.png');
  background-size: 100% 100%;
`
