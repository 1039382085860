import { useEffect, useRef, useState } from 'react'
import { ReactModalWrapper, Toggler, TogglerIcon, ToolbarButton } from './Styled'
import Draggable from 'react-draggable'
import useScreenOrientation from 'hooks/useScreenOrientation'

export default function Toolbar({ onLobbyClick, onDepositClick, onServiceClick, direction }) {
  const [isOpen, setIsOpen] = useState(false)
  const style = {
    overlay: {
      zIndex: 100,
      background: 'rgba(0,0,0,0.5)',
    },
  }

  const [isDragging, setIsDragging] = useState(false)
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [dragTimeout, setDragTimeout] = useState(null)
  const [windowResizeEvent, setWindowResizeEvent] = useState(null)
  const { rotateClassName } = useScreenOrientation(direction)
  const triggerRef = useRef(null)

  function open() {
    setIsOpen(true)
  }

  function onMouseUp(e) {
    clearTimeout(dragTimeout)
    if (!isDragging) {
      e.stopPropagation()
      open()
    }
  }

  function onMouseDown() {
    setIsDragging(false)
    const ko = setTimeout(() => {
      setIsDragging(true)
      setDragTimeout(null)
    }, 200)
    setDragTimeout(ko)
  }

  function handleButtonClick(callback) {
    setIsOpen(false)
    callback()
  }

  useEffect(() => {
    const ref = window.addEventListener('resize', (e) => {
      setPosition({ x: 0, y: 0 })
    })
    setWindowResizeEvent(ref)
    return () => {
      window.removeEventListener('resize', windowResizeEvent)
    }
  }, [])

  return (
    <>
      <Draggable position={position} onDrag={(e, data) => setPosition({ x: data.x, y: data.y })} nodeRef={triggerRef}>
        <Toggler className={rotateClassName} onPointerDown={onMouseDown} onPointerUp={onMouseUp} ref={triggerRef}>
          <TogglerIcon className={rotateClassName}></TogglerIcon>
        </Toggler>
      </Draggable>
      <ReactModalWrapper isOpen={isOpen} style={style} className={'no-focus-outline ' + rotateClassName}>
        <ToolbarButton onClick={() => handleButtonClick(onLobbyClick)}>
          <img src={`${process.env.PUBLIC_URL}/game/toolbar-home.svg`} alt=""></img>
          <div>返回大厅</div>
        </ToolbarButton>
        <ToolbarButton onClick={() => handleButtonClick(onDepositClick)}>
          <img src={`${process.env.PUBLIC_URL}/game/toolbar-deposit.svg`} alt=""></img>
          <div>存款</div>
        </ToolbarButton>
        <ToolbarButton onClick={() => handleButtonClick(onServiceClick)}>
          <img src={`${process.env.PUBLIC_URL}/game/toolbar-service.svg`} alt=""></img>
          <div>客服</div>
        </ToolbarButton>
        <ToolbarButton onClick={() => setIsOpen(false)}>
          <img src={`${process.env.PUBLIC_URL}/game/toolbar-close.svg`} alt=""></img>
          <div>关闭</div>
        </ToolbarButton>
      </ReactModalWrapper>
    </>
  )
}
