import EmptyResult from 'component/EmptyResult'
import { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FETCH_FAVOURITE, RESET_FAVOURITE } from 'redux/constant/favourite'
import { selectFavouriteData } from 'redux/selector/favourite'
import VideoBox from 'component/VideoBox'
import { AreaPanelCmsGridWrapper, InfiniteScrollWrapper, PageScrollWrap } from 'commonStyledComponents'

const Video = () => {
  const dispatch = useDispatch()
  const videoFavourites = useSelector(selectFavouriteData)

  const runOnce = useRef(false)
  const handleFetchVideoList = useCallback(() => {
    if (!runOnce.current) {
      runOnce.current = true
      dispatch({ type: RESET_FAVOURITE })
    }

    dispatch({ type: FETCH_FAVOURITE })
  }, [dispatch])

  useEffect(() => {
    dispatch({ type: RESET_FAVOURITE })
    dispatch({ type: FETCH_FAVOURITE })
  }, [dispatch])

  return !videoFavourites?.list?.length && !videoFavourites.isLoading ? (
    <EmptyResult />
  ) : (
    <PageScrollWrap>
      <InfiniteScrollWrapper
        pageStart={0}
        loadMore={handleFetchVideoList}
        hasMore={videoFavourites?.hasMore}
        // loader={<div className="loader" key={0}>Loading ...</div>}
        useWindow={false}
      >
        {videoFavourites?.list?.length > 0 && (
          <AreaPanelCmsGridWrapper>
            {videoFavourites?.list?.map((item, i) => (
              <VideoBox key={i} videoInfo={item}>
                {i}
              </VideoBox>
            ))}
          </AreaPanelCmsGridWrapper>
        )}
      </InfiniteScrollWrapper>
    </PageScrollWrap>
  )
}

export default Video
