export const GAME_PAYMENT_METHODS = {
  alipay: { id: 'alipay', name: '支付宝', icon: 'account/icon-alipay.png' },
  debit: { id: 'debit', name: '银行卡', icon: 'account/icon-creditcard.png', withNameField: true, nameRequired: true },
  unionpay: { id: 'unionpay', name: '云闪付', icon: 'account/icon-ysf.png' },
  wechat: { id: 'wechat', name: '微信支付', icon: 'account/icon-wechat.png' },
  808: { id: '808', name: '808支付', withNameField: true, nameRequired: true },
  selfdebit: { id: 'selfdebit', name: '银行转帐', icon: 'account/icon-transfer.png' },
  selfusdt: { id: 'selfusdt', name: 'USDT', icon: 'account/icon-usdt.png' },
  obankbank: {
    id: 'obankbank',
    name: '赚钱宝(银)',
    icon: 'account/icon-obankbank.png',
    withBankField: true,
    nameRequired: true,
    accountRequired: true,
    hidden: true,
  },
  obankalipay: {
    id: 'obankalipay',
    name: '赚钱宝(支)',
    icon: 'account/icon-obankalipay.png',
    withNameField: true,
    withAccountField: true,
    nameRequired: true,
    accountRequired: true,
    hidden: true,
  },
}

export const getPaymentMethod = function (code) {
  code = typeof code === 'string' ? code.toLowerCase() : ''
  return GAME_PAYMENT_METHODS[code]
}

export const GAME_PAYMENT_METHOD_KEYS = {
  SELF_DEBIT: 'selfdebit',
  SELF_USDT: 'selfusdt',
  OBANK_BANK: 'obankbank',
  OBANK_ALIPAY: 'obankalipay',
}

export const GAME_DEPOSIT_CHANNEL_PROMOTE_FLAG = {
  PROMOTED: '1',
  HOT: '2',
}

export const GAME_CATEGORY_LIST = [
  {
    name: '热门',
    icon: `${process.env.PUBLIC_URL}/game/category/ic_hot.svg`,
    filter: {
      tagId: 2, // 热门
    },
  },
  {
    name: '视讯',
    icon: `${process.env.PUBLIC_URL}/game/category/ic_video.svg`,
    filter: {
      gameType: 2,
    },
  },
  {
    name: '电子',
    icon: `${process.env.PUBLIC_URL}/game/category/ic_slot.svg`,
    filter: {
      gameType: 4,
    },
  },
  {
    name: '棋牌',
    icon: `${process.env.PUBLIC_URL}/game/category/ic_poker.svg`,
    filter: {
      gameType: 3,
    },
  },
  {
    name: '捕鱼',
    icon: `${process.env.PUBLIC_URL}/game/category/ic_fish.svg`,
    filter: {
      gameType: 1,
    },
  },
  {
    name: '彩票',
    icon: `${process.env.PUBLIC_URL}/game/category/ic_lottery.svg`,
    filter: {
      gameType: 6,
    },
  },
  {
    name: '体育',
    icon: `${process.env.PUBLIC_URL}/game/category/ic_sports.svg`,
    filter: {
      gameType: 5,
    },
  },
]

export const GAME_MESSAGE = {
  NOT_RELEASE: '敬请期待',
  BAN_NOTICE: '会员已锁定，请联系客服',
  VERSION_TOO_LOW:
    '尊敬的用户：由于系统侦测到ID异常，请将描述档移除后重新下载安装；若账户有余额，请联系在线客服协助找回，谢谢！',
}
