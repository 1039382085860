import styled from 'styled-components'

export const TimerWrapper = styled.button`
  position: absolute;
  z-index: 1;
  right: 0.6rem;
  top: 0.2rem;
  top: calc(env(safe-area-inset-top, 0) + 0.2rem);
  background: rgba(0, 0, 0, 0.3);
  border-radius: 2.5rem;
  border: 1px solid #ffffff;
  padding: 0.15rem 0.4rem;
  color: white;
  width: 2.38rem;
`

export const TimerDigit = styled.div`
  display: inline-block;
  font-size: 0.41rem;
  width: 0.2rem;
  margin-left: 0.1rem;
  text-align: center;
`
