import styled from 'styled-components'

export const Wrapper = styled.div`
  background: var(--main-background);
  color: var(--main-color);
  border-radius: 0.25rem;
  width: 7.89rem;
  box-sizing: border-box;
  padding: 0.7rem 0.41rem;
`
export const Title = styled.div`
  text-align: center;
  font-size: 0.4rem;
  line-height: 0.5rem;
`
export const Message = styled.div`
  text-align: left;
  margin-top: 0.4rem;
  font-size: 0.35rem;
  color: #787878;
`
export const Option = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 1.128rem;
  border-bottom: 1px solid #aaa;
  font-size: 0.35rem;
`
export const OptionText = styled.div``
export const OptionStatus = styled.i.attrs((props) => {
  return {
    style: {
      backgroundImage: props.active
        ? `url(${process.env.PUBLIC_URL}/icon/option-status-selected.svg)`
        : `url(${process.env.PUBLIC_URL}/icon/option-status-unselected.svg)`,
    },
  }
})`
  background-size: contain;
  background-repeat: no-repeat;
  flex: 0 0 auto;
  width: 0.38rem;
  height: 0.38rem;
`

export const ActionBox = styled.div`
  margin-top: 0.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.3rem;
`
const BaseAction = styled.div`
  width: 0;
  flex: 1 1 auto;
  height: 0.87rem;
  border-radius: 0.44rem;
  text-align: center;
  font-size: 0.35rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`
export const ActionCancel = styled(BaseAction)`
  background: var(--component-modal-select--cancel-background);
`
export const ActionConfirm = styled(BaseAction)`
  background: var(--component-modal-select--confirm-background);
`
