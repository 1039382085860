import {
  Title,
  Message,
  FatMessage,
  ButtonWrapper,
  CancelButton,
  ConfirmButton,
} from 'component/Modal/ConfirmModal/Styled'
import { animated, useSpring, easings } from '@react-spring/web'
import { Wrapper, ReactModalWrapper } from './Styled'
import useScreenOrientation from 'hooks/useScreenOrientation'

export default function ConfirmModal({ title, message, onConfirm, onCancel, isOpen, direction }) {
  const style = {
    overlay: {
      zIndex: 100,
      background: 'rgba(0,0,0,0.5)',
    },
  }
  const springStyles = useSpring({
    config: { duration: 150, easing: easings.easeInCubic },
    width: '100%',
    height: '100%',
    opacity: isOpen ? 1 : 0.2,
    scale: isOpen ? 1 : 0.6,
    outline: 'none',
  })

  const { rotateClassName } = useScreenOrientation(direction)

  return (
    <ReactModalWrapper className={rotateClassName} isOpen={isOpen} style={style} shouldFocusAfterRender={false}>
      <animated.div style={springStyles}>
        <Wrapper className="no-focus-outline">
          {title ? (
            <>
              <Title>{title}</Title>
              <Message>{message}</Message>
            </>
          ) : (
            <>
              <FatMessage>{message}</FatMessage>
            </>
          )}
          <ButtonWrapper>
            <CancelButton onClick={onCancel}>取消</CancelButton>
            <ConfirmButton onClick={onConfirm}>确定</ConfirmButton>
          </ButtonWrapper>
        </Wrapper>
      </animated.div>
    </ReactModalWrapper>
  )
}
