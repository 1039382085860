import { useCallback, useMemo, useRef } from 'react'
import { useAlert, positions } from 'react-alert'

// workaround for react alert calling onClose too early
const AFTER_CLOSE_DELAY = 300

export default function useAlertWorksActionResult() {
  const alert = useAlert()

  const lastAlertFinished = useRef()

  if (!lastAlertFinished.current) {
    lastAlertFinished.current = Promise.resolve()
  }

  const alertSaved = useCallback(() => {
    const prevAnimation = lastAlertFinished.current
    lastAlertFinished.current = new Promise((r) => {
      return prevAnimation.then(() => {
        alert.info('收藏成功', {
          position: positions.MIDDLE,
          offset: '300px',
          timeout: 1500,
          onClose: () => setTimeout(r, AFTER_CLOSE_DELAY),
        })
      })
    })
  }, [alert])

  const alertUnsaved = useCallback(() => {
    const prevAnimation = lastAlertFinished.current
    lastAlertFinished.current = new Promise((r) => {
      return prevAnimation.then(() => {
        alert.info('已取消收藏', {
          position: positions.MIDDLE,
          offset: '300px',
          timeout: 1500,
          onClose: () => setTimeout(r, AFTER_CLOSE_DELAY),
        })
      })
    })
  }, [alert])

  const alertLiked = useCallback(() => {
    const prevAnimation = lastAlertFinished.current
    lastAlertFinished.current = new Promise((r) => {
      return prevAnimation.then(() => {
        alert.info('喜欢成功', {
          position: positions.MIDDLE,
          offset: '300px',
          timeout: 1500,
          onClose: () => setTimeout(r, AFTER_CLOSE_DELAY),
        })
      })
    })
  }, [alert])

  const alertUnliked = useCallback(() => {
    const prevAnimation = lastAlertFinished.current
    lastAlertFinished.current = new Promise((r) => {
      return prevAnimation.then(() => {
        alert.info('已取消喜欢', {
          position: positions.MIDDLE,
          offset: '300px',
          timeout: 1500,
          onClose: () => setTimeout(r, AFTER_CLOSE_DELAY),
        })
      })
    })
  }, [alert])

  return useMemo(
    () => ({
      alertSaved,
      alertUnsaved,
      alertLiked,
      alertUnliked,
    }),
    [alertSaved, alertUnsaved, alertLiked, alertUnliked]
  )
}
