import { BackButton, HeaderBox, IconBack } from './Styled'
import classNames from 'classnames'
import { useCallback } from 'react'
import { useAppNavigate } from 'app-layered-layout/useAppNavigate'

/** 元件 - PageModal用的 最上方區塊 */
export default function PageModalHeaderBox({ headerTitle = '', className, onClose, ...otherProps }) {
  const navigate = useAppNavigate()

  const handleClose = useCallback(() => {
    if (typeof onClose == 'function') {
      onClose({ close: () => navigate(-1) })
    } else {
      navigate(-1)
    }
  }, [navigate, onClose])

  return (
    <HeaderBox className={classNames(className, 'no-prevent-swipe')} {...otherProps}>
      <BackButton onClick={handleClose}>
        <IconBack />
      </BackButton>
      {headerTitle}
    </HeaderBox>
  )
}
