import { useCallback } from 'react'
//
import { StyledBase64Image, Wrapper } from './Styled'
import { useNavigateTo } from 'hooks/useNavigateTo'

const loadingImage = `/loading/small-load-image.png`

export default function SecTagPromoteItem(info) {
  const { navigateToSecTagList } = useNavigateTo()

  const handleClick = useCallback(
    (info) => {
      navigateToSecTagList({
        headerTitle: info.name,
        params: {
          tag_ids: info.id,
        },
      })
    },
    [navigateToSecTagList]
  )

  return (
    <Wrapper>
      <StyledBase64Image
        // ref={wrapperRef}
        // src={base64Img ? `data:image/jpeg;base64, ${base64Img}` : loadingImage}
        src={info.icon_path}
        placeholder={loadingImage}
        onClick={() => handleClick(info)}
        aspectRatio={134 / 95}
      ></StyledBase64Image>
    </Wrapper>
  )
}
