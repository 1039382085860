import styled, { css, keyframes } from 'styled-components'
import LazyLoad from 'react-lazyload'

const fadeIn = keyframes`
 from { opacity: 0; }
 to { opacity: 1; }
`

export const StyledLazyLoad = styled(LazyLoad)`
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
`

export const NonLazyLoadWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
`
const ImageDiv = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`

export const Image = styled.img`
  height: 0;
  flex-grow: 1;
  flex-shrink: 1;
  object-fit: cover;
  ${(props) =>
    props.animation
      ? css`
          animation: ${fadeIn} 0.4s ease-in;
        `
      : ''}
`

export const ImageWrapper = styled(ImageDiv)`
  ${(props) =>
    props.hasPlaceholder
      ? props.placeholder == null
        ? css`
            background-image: url(${process.env.PUBLIC_URL}/loading/load-image.png);
          `
        : css`
            background-image: url(${process.env.PUBLIC_URL}${props.placeholder});
          `
      : css``}
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  display: flex;

  ${(props) =>
    props.animation
      ? css`
          /* backface-visibility: hidden; */
          /* transform: translate3d(0, 0, 0); */
        `
      : ''}

  ${(props) =>
    props.aspectRatio
      ? css`
          padding-bottom: ${(1 / props.aspectRatio) * 100}%;
        `
      : ''}
`
