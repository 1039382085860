import { useEffect, useState, useRef } from 'react'
import BeatLoader from 'react-spinners/BeatLoader'
import QRCode from 'qrcode.react'
import isEmpty from 'lodash/isEmpty'

import { useSelector } from 'react-redux'

import { selectUserInfo } from 'redux/selector/user'

import { fetchDeviceQrcode } from 'api'
import useHintModal from 'hooks/useHintModal'

import {
  Wrapper,
  BgImg,
  QRCodeWrapper,
  TextBox,
  TextBoxTitle,
  BtnCopySharingUrl,
  Link,
  TextBoxId,
  TextBoxHint,
  Landing,
  BtnClose,
} from './Styled'
// import html2canvas from 'html2canvas'

export default function IDCard() {
  const downloadLinkRef = useRef()
  const cardRef = useRef()
  const userInfo = useSelector(selectUserInfo)
  const [url, setUrl] = useState(null)
  const { closeHintModal } = useHintModal()

  async function download() {
    const card = cardRef.current
    const link = downloadLinkRef.current
    const html2canvas = (await import('html2canvas')).default
    const canvas = await html2canvas(card)
    const imageName = userInfo?.id
    const src = canvas.toDataURL()
    link.href = src
    link.download = `${imageName}.png`
    link.click()
  }

  useEffect(() => {
    if (isEmpty(url)) return
  }, [url])

  useEffect(() => {
    fetchDeviceQrcode().then((response) => {
      setUrl(response?.code)
    })
  }, [])

  return (
    <Wrapper>
      <BgImg ref={cardRef} onClick={download}>
        <QRCodeWrapper>
          {!!url ? (
            <QRCode id={'qrcode'} style={{ width: '100%', height: '100%' }} value={url} level={'H'} />
          ) : (
            <BeatLoader size={10} />
          )}
        </QRCodeWrapper>

        <TextBox>
          <TextBoxTitle>身份卡</TextBoxTitle>
          <TextBoxId>用户:{userInfo?.show_id}</TextBoxId>
          <TextBoxHint>
            用于找回帐号。建议保
            <br />
            存，请勿泄漏。
          </TextBoxHint>
        </TextBox>
        <BtnCopySharingUrl>截图保存图片</BtnCopySharingUrl>
        <Landing>官网地址：yuhuo.tw</Landing>
      </BgImg>
      <BtnClose onClick={closeHintModal} />
      <Link ref={downloadLinkRef}></Link>
    </Wrapper>
  )
}
