import { useEffect, useRef } from 'react'
import { Tab, Tabs as TabContainer, TabContainerInner, TabsType } from './Styled'
import classNames from 'classnames'

export default function Tabs({
  options = /** @type {{id: string, name: string}[]} */ ([]),
  value = '',
  type = TabsType.Fill,
  align = /** @type {'spread' | 'center' | 'left' | 'right'} */ ('center'),
  onChange = (val) => {},
  className,
  autoScroll = true,
  ...props
}) {
  useEffect(() => {
    if (autoScroll) {
      elements.current[value]?.scrollIntoView({ behavior: 'smooth', inline: 'center' })
    }
  })

  const elements = useRef({})
  const onRef = (id, el) => {
    elements.current[id] = el
  }

  return (
    <TabContainer align={align} className={classNames(className, 'no-prevent-swipe')} {...props}>
      <TabContainerInner>
        {options.map((t) => (
          <Tab
            key={t.id}
            type={type}
            className={value === t.id ? 'active' : ''}
            onClick={() => onChange(t.id)}
            ref={(el) => onRef(t.id, el)}
          >
            {t.name}
          </Tab>
        ))}
      </TabContainerInner>
    </TabContainer>
  )
}
