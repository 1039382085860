import { ColoredIcon } from 'component/ColoredIcon'
import styled from 'styled-components'

export const Wrapper = styled.div`
  background: var(--page-game-theme-background);
  border-radius: 0.25rem;
  display: grid;
  padding: 0.4rem 0.2rem;
  grid-template-columns: 40% 60%;
  margin: 0 0.2rem;
  color: #000;
`

export const BalanceWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  align-items: center;
  font-size: 0.3rem;
  border-right: 2px solid #fff;
`

export const ShortcutWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
`

export const ShortcutButton = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  justify-content: center;
  text-align: center;
  align-items: center;
  img {
    width: 0.8rem;
    height: 0.8rem;
  }
`

export const Balance = styled.div`
  font-size: 0.4rem;
  > * {
    vertical-align: middle;
  }
`

export const UidBox = styled.input`
  display: none;
  border: none;
  background: none;
  font-size: 12px;
`

export const CopyButton = styled(ColoredIcon).attrs({
  src: `${process.env.PUBLIC_URL}/icon/copy.png`,
})`
  display: inline-block;
  width: 0.4rem;
  height: 0.4rem;
  vertical-align: middle;
  margin: 0 5px;
  border-radius: 50%;
  img {
    width: 100%;
    height: 100%;
    padding: 0.05rem;
    object-fit: contain;
  }
`
