import Base64Image from 'component/Base64Image'
import styled from 'styled-components'

export const Wrapper = styled.div`
  --columns: ${(props) => props.columns ?? 2};
  width: ${(props) =>
    props?.width != null
      ? props.width
      : 'calc(calc(100% - var(--cms-grid-column-gap, 0.093rem) * calc(var(--columns) - 1)) / var(--columns))'};

  overflow: hidden;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  > * {
    user-select: none;
  }

  > :global(.lazyload-wrapper) {
    display: contents;
  }
`

export const ImageWrapper = styled.div`
  border-radius: var(--component-video-box-vertical--border-radius);
  /* transform: translate3d(0, 0, 0); */
  position: relative;
  overflow: hidden;
`

export const StyledBase64Image = styled(Base64Image)``

export const VideoName = styled.div`
  padding: 0.1rem 0 0;
  font-size: 0.35897rem;
  line-height: 150%; /* 0.53846rem */
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: ${(props) => props.textAlign};

  [data-slide-stack-inactive] & {
    opacity: 0;
  }
`

export const VideoInfo = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding: 0.2rem;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
  color: white;
  font-size: 0.25rem;
  font-weight: 700;
`

export const FavoriteIcon = styled.i.attrs((props) => {
  return {
    style: {
      background: `url(${process.env.PUBLIC_URL}/icon/video-favorite.svg) center / contain no-repeat`,
    },
  }
})`
  margin-right: 5px;
  height: 0.6rem;
  width: 0.6rem;
  background-position: center;
`
