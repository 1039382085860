import { useSelector } from 'react-redux'
import { Label } from './Styled'
import { selectInviteCode } from 'redux/selector/user'

const SplashInviteCode = () => {
  const code = useSelector(selectInviteCode)
  if (!code) {
    return null
  }
  return <Label>{code}</Label>
}

export default SplashInviteCode
