import ShortPlayer from 'component/ShortPlayer'
import styled from 'styled-components'
import UploaderInfo from '../UploaderInfo'
import { VirtualPage } from '../VirtualPage'
import TouchEffect from './components/TouchEffect'
import CoverImage from '../CoverImage'

export const StyledVirtualPage = styled(VirtualPage)`
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  flex-direction: column;
`

export const StyledShortPlayer = styled(ShortPlayer)`
  /* z-index: 0; */
`
export const StyledUploaderInfo = styled(UploaderInfo)`
  /* z-index: 0; */
`
export const StyledTouchEffect = styled(TouchEffect)`
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  pointer-events: none;
`

export const StyledCoverImage = styled(CoverImage)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`
