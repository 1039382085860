import { useState } from 'react'
import {
    Screen1,
    Screen1Icon,
    Screen1IconDecorateHand,
    ScreenItem,
    ScreenDescription,
    Wrapper,
} from './Styled.js'

export default function Tutorial({ isOpen = true, onFinished = () => { } }) {
    const [currentDisplay, setCurrentDisplay] = useState('screen1')
    const screen = isOpen ? currentDisplay : ''
    return (
        <Wrapper className={screen == '' ? 'hidden' : ''}>
            <Screen1
            className={screen !== 'screen1' ? 'hidden' : ''}
                onClick={() => {
                    setCurrentDisplay('')
                    onFinished()
                }}
            >
                <ScreenDescription></ScreenDescription>
                <ScreenItem>
                    <Screen1Icon>
                        <Screen1IconDecorateHand />
                    </Screen1Icon>
                    长按萤幕快转
                </ScreenItem>
            </Screen1>
        </Wrapper>
    )
}
