import styled from 'styled-components'
import { PageScrollWrap } from 'commonStyledComponents'

export const StyledPageScrollWrap = styled(PageScrollWrap)`
  display: flex;
  flex-direction: column;
  gap: 0.205rem;
`

export const BlockColor = styled.div`
  /* width: 100%; */
  background: var(--page-business-relationship--item-background);
  color: var(--page-business-relationship--item-text);
  box-shadow: var(--page-business-relationship--item-shadow);
  display: flex;
  position: relative;
  align-items: center;
  padding: 0.41rem;
  gap: 0.41rem;
  border: var(--page-business-relationship--item-border);
  border-radius: 0.256rem;
  font-size: 0.358rem;
  margin: 0 0.186rem;
`

export const QqIcon = styled.img`
  height: 1.02rem;
  width: 1.02rem;
  object-fit: contain;
`

export const BlockContent = styled.div`
  width: 100%;
  display: flex;
  font-size: 0.358rem;
  justify-content: space-between;
  align-items: center;
  gap: 0.41rem;
`

export const Narrative = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.102rem;
  > p {
    line-height: 0.53rem;
  }
`

export const BtnContact = styled.button`
  background: var(--page-business-relationship--button-background);
  color: var(--page-business-relationship--button-text);
  padding: 0.192rem 0.41rem;
  border-radius: 100px;
  min-height: 0;
`
